/* eslint-disable */
import React, { useEffect, useLayoutEffect, useRef } from "react";
import InlineMessageBar from "../../Components/Widgets/InlineMessageBar";
import { useState } from "react";
import useCommonUtil from "../../Hooks/useCommonUtil";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import useAccountManager from "../../Globals/useAccountManager";
import useFetchErrorHandler from "../../Globals/useFetchErrorHandler";

import { useDataManager } from "../../Globals/useDataManager";
import { SectionExpander } from "../../Components/Widgets/SectionExpander";
import useReactIcons from "../../Hooks/useReactIcons";

const AddressLocation = () => {
  const u = useCommonUtil();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const fe = useFetchErrorHandler();
  const dmgr = useDataManager();
  const ico = useReactIcons();

  const [myAccount, setUser] = useState(am.GetMyAccount());
  const [userModelData, setUserModelData] = useState();

  const [countryList, setCountryList] = useState();
  const [cityList, setCityList] = useState();

  const Refs = {
    refForm: useRef(),
    refCountry: useRef(),
    refCity: useRef(),
    cmdRef: useRef(),
  };

  const [m1, setMsg] = useState({ message: "Ready", mode: "i" });
  const [countryMsg, setCountryMsg] = useState();
  const [cityMsg, setCityMsg] = useState();

  const [city, setCity] = useState(0);

  const countryFiller = `--- Select Country ---`;
  const cityFiller = `--- Select City ---`;

  //#region :: Methods
  useLayoutEffect(() => {
    am.ValidateAccount(() => {
      dmgr.GetCountries((list) => {
        setCountryList(list.ModelData);
        let cCode = myAccount.CountryCode;
        if (cCode) {
          dmgr.GetCities(cCode, (list) => {
            if (list.ModelData) {
              setCityList(list.ModelData);
              setCity(list.ModelData[0]);
              if (myAccount) {
                setUserModelData({
                  UserName: myAccount.UserName,
                  CountryCode: myAccount.CountryCode,
                  City: myAccount.City,
                  CityId: myAccount.CityId,
                  Address: myAccount.Address,
                });
              }
              setCountryMsg();
            } else {
              setCountryMsg({ mode: "e", message: "Unable to acquire city list information." });
            }
          });
        }
      });
    });
  }, []);

  // useEffect(() => {
  //   // eslint-disable-next-line
  //   console.log(userModelData);
  // }, [userModelData]);

  const OnChangeInput = (e, f) => {
    let val = e.currentTarget.value;

    switch (f) {
      case "address":
        setUserModelData({ ...userModelData, Address: val });
        break;
      default:
        break;
    }
  };

  const DisableForm = (b) => {
    if (b) {
      Refs.refForm.current.classList.add("no-touch");
    } else {
      Refs.refForm.current.classList.remove("no-touch");
    }
  };
  const OnSubmitForm = () => {
    console.clear();
    DisableForm(true);

    setMsg({ message: "Checking entries...", mode: "p" });

    let m = userModelData;

    if (!u.Validator.CheckMinMax(m.Address, 3, 100)) {
      setMsg({
        message: "Address is between 3 to 100 characters long.",
        mode: "e",
      });
      DisableForm();
      return;
    }

    fetch(`${g.Api.ApiRoute}/user/UpdateAddressAsync`, {
      method: g.Fetch.POST,
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(userModelData),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
        fe.CatchResponse(r, (m) => {
          setMsg({ mode: "w", message: m });
        });

        DisableForm();

        return null;
      })
      .then((r) => {
        if (!r) {
          DisableForm();
          return;
        }

        if (r.Succeeded) {
          let newData = {
            ...myAccount,
            CountryCode: userModelData.CountryCode,
            City: userModelData.City,
            CityId: userModelData.CityId,
            Address: userModelData.Address,
          };

          am.StoreMyAccount(newData, (d) => {
            setUser(d);
            setMsg({ message: r.Message, mode: "s" });
          });
        } else {
          setMsg({ message: r.Message, mode: "e" });
        }

        DisableForm();
      });
  };

  const OnSelectCountry = (e) => {
    setCityMsg({ mode: "d", message: "Loading cities..." });
    let cCode = e.target.value;

    setUserModelData({ ...userModelData, CountryCode: cCode });

    dmgr.GetCities(cCode, (list) => {
      setCityList(list.ModelData);
      setCity(list.ModelData[0]);
      setCityMsg({ tl: 1000 });
    });
  };

  const OnSelectCity = (e) => {
    console.log(e.target.value);
    let ct = cityList.find((f) => f.CityId === parseInt(e.target.value, 10));
    if (ct) {
      setCity(ct);
      setUserModelData({ ...userModelData, CityId: ct.CityId, City: ct.Name });
    }
  };

  // useEffect(() => {
  //   setUserModelData({ ...userModelData, Name: city.Name });
  // }, [city]);
  //#endregion

  return (
    <>
      <div className="tb-pad-md">
        <SectionExpander
          props={{
            icon: ico.Map,
            title: "Address / Location",
            titleSize: "2em",
            caption: "All entries are required.",
          }}
        >
          {userModelData && myAccount ? (
            <div className="address-location">
              <div ref={Refs.refForm}>
                <ul>
                  <li className="flex v-center">
                    <div>
                      <select
                        defaultValue={`${userModelData.CountryCode}`}
                        onChange={OnSelectCountry}
                      >
                        {countryList ? (
                          countryList.map((c) => (
                            <option key={c.CountryCode} value={`${c.CountryCode}`}>
                              {c.Name}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </select>
                    </div>
                    <div>
                      <InlineMessageBar props={{ options: countryMsg }} />
                    </div>
                  </li>
                  <li className="flex v-center">
                    <div>
                      <select onChange={OnSelectCity} value={userModelData.CityId}>
                        {cityList ? (
                          cityList.map((c) => (
                            <option key={`k_${c.CityId}`} value={c.CityId}>
                              {c.Name}
                            </option>
                          ))
                        ) : (
                          <></>
                          // <option value={cityFiller}>{cityFiller}</option>
                        )}
                      </select>
                    </div>
                    <div>
                      <InlineMessageBar props={{ options: cityMsg }} />
                    </div>
                  </li>

                  <li>Address (Suburb, House #, Subd., or Street, etc.)</li>
                  <li>
                    <input
                      type="text"
                      className="long"
                      maxLength={100}
                      value={userModelData.Address}
                      onChange={(e) => OnChangeInput(e, "address")}
                    />
                  </li>
                </ul>
              </div>
              <div>
                <InlineMessageBar props={{ options: m1 }} />
              </div>
              <div className="tb-pad-md a-right">
                <input type="button" name="" id="" value="SUBMIT" onClick={OnSubmitForm} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </SectionExpander>
      </div>
    </>
  );
};

export default AddressLocation;
