import React, { useEffect, useRef, useState } from "react";
import InlineMessageBar from "../../../../Components/Widgets/InlineMessageBar";
import { SectionExpander } from "../../../../Components/Widgets/SectionExpander";
import Chart from "react-apexcharts";
import useReactIcons from "../../../../Hooks/useReactIcons";
import useAccountManager from "../../../../Globals/useAccountManager";
import useGlobalConstants from "../../../../Globals/useGlobalConstants";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const EducationCourse = () => {
  const ico = useReactIcons();
  const am = useAccountManager();
  const g = useGlobalConstants();
  
  const [msg, setMsg] = useState();

  const [tabIndex, setTabIndex] = useState(0);

  const controller = new AbortController();
  const signal = controller.signal;

  let timer;
  const refs = {
    refGenBtn: useRef(),
  };

  const [chartPast, setChartPast] = useState({
    options: {
      theme: {
        palette: 'palette9' // upto palette10
      },
      title: {
        text: `Course Analysis`,
        align: "center",
        style: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "normal",
          marginTop: "20px",
        },
      },
      chart: {
        id: "Reactions",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          align: "center",
          dataLabels: {
            position: "center",
            //orientation: "vertical"
          },
          // colors: {
          //   ranges: [
          //     { from: 0, to: 1, color: "#999" },
          //     { from: 2, to: 3, color: "#666" },
          //     { from: 4, to: 6, color: "#66cc00" },
          //   ],
          // },
        },
      },
      xaxis: {
        floating: false,
        type: "Category",
        labels: {
          align: "center",
          min: 0,
          formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        forceNiceScale: true,
        floating: false,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 350,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "break-words",
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return value;
          },
        },
      },
    },
    series: [
      {
        name: "Course and School",
        data: [{ x: "", y: 0 }],
      },
    ],
  });

  const [chartPresent, setChartPresent] = useState({
    options: {
      theme: {
        palette: 'palette7' // upto palette10
      },
      title: {
        text: `Course Analysis`,
        align: "center",
        style: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "normal",
          marginTop: "20px",
        },
      },
      chart: {
        id: "Reactions",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          align: "center",
          dataLabels: {
            position: "center",
            //orientation: "vertical"
          },          
        },
      },
      xaxis: {
        floating: false,
        type: "Category",
        labels: {
          align: "center",
          min: 0,
          formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        forceNiceScale: true,
        floating: false,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 350,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "break-words",
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return value;
          },
        },
      },
    },
    series: [
      {
        name: "Course and School",
        data: [{ x: "", y: 0 }],
      },
    ],
  });
  useEffect(() => {
    return () => {
      clearTimeout(timer);
      controller.abort();
    };

    // eslint-disable-next-line
  }, []);

  const DisableControls = (b) => {
    if (refs.refGenBtn.current) {
      if (b) {
        refs.refGenBtn.current.classList.add("display-none");
      } else {
        refs.refGenBtn.current.classList.remove("display-none");
      }
    }
  };

  const GenerateReport = () => {
    DisableControls(1);

    setMsg({ mode: "p", message: "" });

    fetch(`${g.Api.ApiRoute}/ReportsApi/GetEducationCourses/?mode=${tabIndex}`, {
      method: "GET",
      signal: signal,
      headers: am.Identity.GetAuthHeader(),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        if (!j) {
          setMsg({ mode: "e", message: "Ooops. Something went wrong :(" });
          return;
        }

        if (j.Succeeded) {
          let m = [{ name: "Population", data: [] }];
          let arr = j.ModelData;
          for (let i = 0; i < j.ModelData.length; i++) {
            let captions = `[ ${arr[i].CourseAndSchool.Code} ] ${arr[i].CourseAndSchool.Name}`;
            m[0].data.push({
              x: captions,
              y: parseInt(arr[i].Total, 10),
            });
          }

          switch (tabIndex) {
            case 0:
              setChartPast({
                ...chartPast,
                series: m,
                options: {
                  ...chartPast.options,
                  title: {
                    ...chartPast.options.title,
                    text: `Students/Alumni Course Analysis - Past Enrollment`.toUpperCase(),
                  },
                },
              });
              break;
            case 1:
              setChartPresent({
                ...chartPresent,
                series: m,
                options: {
                  ...chartPresent.options,
                  title: {
                    ...chartPresent.options.title,
                    text: `Students/Alumni Course Analysis - Present Enrollment`.toUpperCase(),
                  },
                },
              });
              break;
            default:
              break;
          }

          setMsg({ mode: "s" });
        } else {
          setMsg({ mode: "e", message: "Ooops. Something went wrong :(" });
        }

        timer = setTimeout(() => {
          DisableControls(0);
        }, 3000);
      });
  };

  return (
    <>
      <SectionExpander
        props={{
          icon: ico.GraduationCap,
          title: "Education by Course and School",
          classes: "font-sm all-caps",
          caption: "Members courses in relation with their school or university",
          collapsed: true,
        }}
      >
        <div className="flex v-center">
          <div className="last">
            <InlineMessageBar props={{ options: msg }} />
            <span ref={refs.refGenBtn}>
              <button onClick={GenerateReport}>Generate Report</button>
            </span>
          </div>
        </div>
        <div className="tb-pad-sm"></div>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>Past</Tab>
            <Tab>Current</Tab>
          </TabList>
          <TabPanel>
            <div className="bordered">
              <Chart
                options={chartPast.options}
                series={chartPast.series}
                type="bar"
                width="100%"
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="bordered">
              <Chart
                options={chartPresent.options}
                series={chartPresent.series}
                type="bar"
                width="100%"
              />
            </div>
          </TabPanel>
        </Tabs>
      </SectionExpander>
    </>
  );
};

export default React.memo(EducationCourse);
