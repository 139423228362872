/* eslint-disable */
import React, { useEffect, useRef } from "react";
import InlineMessageBar from "../../Components/Widgets/InlineMessageBar";
import { useState } from "react";
import useCommonUtil from "../../Hooks/useCommonUtil";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import useAccountManager from "../../Globals/useAccountManager";
import useAsyncState from "../../Hooks/useCustomExtensions";
import useFetchErrorHandler from "../../Globals/useFetchErrorHandler";
import { SectionExpander } from "../../Components/Widgets/SectionExpander";
import useReactIcons from "../../Hooks/useReactIcons";

const BasicInfo = () => {
  const u = useCommonUtil();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const ico = useReactIcons();

  //const TUSER = useContext(TargetUserContext);
  const fe = useFetchErrorHandler();

  const [user, setUser] = useAsyncState();
  const [modelData, setModelData] = useState(am.GetMyAccount());
  const [m1, setMsg] = useState({ message: "Ready", mode: "i" });

  const Refs = {
    formRef: useRef(),
  };

  useEffect(() => {
    am.ValidateAccount(() => {
      setUser(am.GetMyAccount());
    });
    // eslint-disable-next-line
  }, []);

  const OnChangeInput = (e, f) => {
    let val = e.currentTarget.value;
    switch (f) {
      case "mention":
        setModelData({ ...modelData, NameMention: val });
        break;
      case "courseid":
        setModelData({ ...modelData, CourseId: val });
        break;
      case "lastname":
        setModelData({ ...modelData, LastName: val });
        break;
      case "firstname":
        setModelData({ ...modelData, FirstName: val });
        break;
      case "middlename":
        setModelData({ ...modelData, MiddleName: val });
        break;
      case "dateofbirth":
        setModelData({ ...modelData, DateOfBirth: val });
        break;

      default:
        break;
    }
  };

  const CheckEntry = () => {
    console.log("Checking etries...");

    setMsg({ message: "Checking etries...", mode: "p" });
    let m = modelData;

    if (!u.Validator.CheckMinMax(m.NameMention, 3, 15)) {
      setMsg({
        message: "Name mention must be 3 to 15 characters long.",
        mode: "e",
      });
      return;
    }

    // if (!u.Validator.CheckMinMax(m.CourseId, 3, 15)) {
    //   setMsg({ message: "Course ID is required.", mode: "e" });
    //   return;
    // }

    if (!u.Validator.CheckMinMax(m.LastName, 2, 30)) {
      setMsg({ message: "Last Name is required.", mode: "e" });
      return;
    }
    if (!u.Validator.CheckMinMax(m.FirstName, 2, 30)) {
      setMsg({ message: "First Name is required.", mode: "e" });
      return;
    }

    if (!u.Date.IsValidDate(m.DateOfBirth)) {
      setMsg({ message: "Invalid Date format.", mode: "e" });
      return;
    }

    Refs.formRef.current.classList.add("no-touch");

    setMsg({ message: "Updating entries. Please wait...", mode: "p" });

    const payload = {
      UserName: m.UserName,
      NameMention: m.NameMention,
      CourseId: m.CourseId,
      LastName: m.LastName,
      FirstName: m.FirstName,
      MiddleName: m.MiddleName,
      DateOfBirth: m.DateOfBirth,
    };

    fetch(`${g.Api.ApiRoute}/user/UpdateBasicInfoAsync`, {
      method: g.Fetch.POST,
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(payload),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }

        fe.CatchResponse(r, (m) => {
          setMsg({ mode: "w", message: m });
        });
      })
      .then((r) => {
        if (!r) return;

        if (r.Succeeded) {
          am.StoreMyAccount(modelData, (d) => {
            //TUSER.SetTargetUser(d);
            setUser(d);
            setMsg({ message: "Basic Info updated.", mode: "s" });
          });
        } else {
          setMsg({ message: r.Message, mode: "e" });
        }

        Refs.formRef.current.classList.remove("no-touch");
      });
  };

  return (
    <div className="tb-pad-md">
      {user ? (
        <SectionExpander
          props={{
            icon: ico.ContactCard,
            title: "Basic Information",
            titleSize: "2em",
            caption: "All entries are required.",
          }}
        >
          <div>
            <div ref={Refs.formRef}>
              <div className="tb-pad-sm"></div>
              <div>
                <strong className="color-red">*</strong> Mention
              </div>
              <div className="font-bold">
                @{" "}
                <input
                  type="text"
                  maxLength={30}
                  value={modelData.NameMention}
                  onChange={(e) => OnChangeInput(e, "mention")}
                />
              </div>
              {/* <div>Last Course Attended *</div>
              <div>
                <input
                  type="text"
                  maxLength={15}
                  value={modelData.CourseId}
                  onChange={(e) => OnChangeInput(e, "courseid")}
                />
              </div> */}
              <div>
                <strong className="color-red">*</strong> Last Name
              </div>
              <div>
                <input
                  type="text"
                  maxLength={30}
                  value={modelData.LastName}
                  onChange={(e) => OnChangeInput(e, "lastname")}
                />
              </div>
              <div>
                <strong className="color-red">*</strong> First Name
              </div>
              <div>
                <input
                  type="text"
                  maxLength={30}
                  value={modelData.FirstName}
                  onChange={(e) => OnChangeInput(e, "firstname")}
                />
              </div>
              <div>Middle Name</div>
              <div>
                <input
                  type="text"
                  maxLength={30}
                  value={modelData.MiddleName}
                  onChange={(e) => OnChangeInput(e, "middlename")}
                />
              </div>
              <div>
                <strong className="color-red">*</strong> Birth Date (MM/DD/YYYY)
              </div>
              <div>
                <input
                  type="text"
                  maxLength={10}
                  defaultValue={modelData.DateOfBirth}
                  onChange={(e) => OnChangeInput(e, "dateofbirth")}
                />
              </div>
            </div>
            <div className="tb-pad-md">
              <InlineMessageBar props={{ options: m1 }} />
            </div>
            <div className="a-right">
              <input type="button" name="" id="" value="SUBMIT" onClick={CheckEntry} />
            </div>
          </div>
        </SectionExpander>
      ) : (
        ""
      )}
    </div>
  );
};

export default BasicInfo;
