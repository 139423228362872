import React, { useEffect, useRef, useState } from "react";
import InlineMessageBar from "../../../../Components/Widgets/InlineMessageBar";
import { SectionExpander } from "../../../../Components/Widgets/SectionExpander";
import Chart from "react-apexcharts";
import useReactIcons from "../../../../Hooks/useReactIcons";
import useAccountManager from "../../../../Globals/useAccountManager";
import useGlobalConstants from "../../../../Globals/useGlobalConstants";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const MembersStats = () => {
  const ico = useReactIcons();
  const am = useAccountManager();
  const g = useGlobalConstants();

  const [tabIndex, setTabIndex] = useState(0);
  //const [dateRange, setDateRange] = useState("date");
  const [msg, setMsg] = useState();

  const controller = new AbortController();
  const signal = controller.signal;
  let timer;
  const refs = {
    refGenBtn: useRef(),
  };

  const [chartLocation, setChartLocation] = useState({
    options: {
      theme: {
        palette: "palette9", // upto palette10
      },
      title: {
        text: `Members by Location`,
        align: "center",
        style: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "normal",
          marginTop: "20px",
        },
      },
      chart: {
        id: "Title",
        foreColor: "#000",
        background: "#f9f9f9",
      },

      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          align: "center",
          dataLabels: {
            position: "center",
            //orientation: "vertical"
          },
          // colors: {
          //   ranges: [
          //     { from: 0, to: 1, color: "#3a3963" },
          //     { from: 2, to: 3, color: "#106285" },
          //     { from: 4, to: 6, color: "#ed195a" },
          //     { from: 7, to: 10, color: "#333" },
          //   ],
          // },
        },
      },
      xaxis: {
        floating: false,
        type: "Category",
        labels: {
          align: "center",
          min: 0,
          formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        forceNiceScale: true,
        floating: false,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 350,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "break-words",
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return value;
          },
        },
      },
    },
    series: [
      {
        name: "Course and School",
        data: [{ x: "", y: 0 }],
      },
    ],
  });
  const [chartGender, setChartGender] = useState({
    options: {
      theme: {
        palette: "palette7", // upto palette10
      },
      title: {
        text: `Statistics by Gender`,
        align: "center",
        style: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "normal",
          marginTop: "20px",
        },
      },
      chart: {
        id: "Gender",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      // fill: {
      //   type: "gradient",
      //   //colors: ["#106285"],
      //   gradient: {
      //     shade: "dark",
      //     type: "horizontal",
      //     shadeIntensity: 1,
      //     //gradientToColors: ["#ed195a"],
      //     inverseColors: true,
      //     opacityFrom: 1,
      //     opacityTo: 1,
      //     stops: [0, 100],
      //     //colorStops: ["#000", "#666", "#ccc"],
      //   },
      // },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          align: "center",
          dataLabels: {
            position: "center",
            fontSize: "20px",
          },
        },
      },
      xaxis: {
        floating: false,
        type: "Gender",
        labels: {
          align: "center",
          min: 0,
          formatter: (val) => parseInt(val, 10),
        },
        style: {
          fontSize: "20px",
        },
      },
      yaxis: {
        //forceNiceScale: true,
        floating: false,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 350,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 400,
            //cssClass: "break-words",
          },
          // offsetX: 0,
          // offsetY: 0,
          // rotate: 0,
          // formatter: (value) => {
          //   return value;
          // },
        },
      },
    },
    series: [
      {
        name: "Category",
        data: [{ x: "", y: 0 }],
        labels: {
          formatter: (val) => parseInt(val, 10),
        },
      },
    ],
  });

  const [chartAge, setChartAge] = useState({
    options: {
      theme: {
        palette: "palette7", // upto palette10
      },
      title: {
        text: `Analysis by Age`,
        align: "center",
        style: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "normal",
          marginTop: "20px",
        },
      },
      chart: {
        id: "Title",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      // fill: {
      //   type: "gradient",
      //   //colors: ["#106285"],
      //   gradient: {
      //     shade: "dark",
      //     type: "horizontal",
      //     shadeIntensity: 1,
      //     //gradientToColors: ["#3cb878"],
      //     inverseColors: false,
      //     opacityFrom: 1,
      //     opacityTo: 1,
      //     stops: [0, 100],
      //     //colorStops: ["#000", "#666", "#ccc"],
      //   },
      // },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          align: "center",
          dataLabels: {
            position: "center",
            //orientation: "vertical"
          },
          // colors: {
          //   ranges: [
          //     { from: 0, to: 1, color: "#3a3963" },
          //     { from: 2, to: 3, color: "#106285" },
          //     { from: 4, to: 6, color: "#ed195a" },
          //     { from: 7, to: 10, color: "#333" },
          //   ],
          // },
        },
      },
      xaxis: {
        floating: false,
        type: "Category",
        labels: {
          align: "center",
          min: 0,
          //formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        forceNiceScale: true,
        floating: false,
        title: { text: "AGE" },
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 350,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "break-words",
          },
          // formatter: (value) => {
          //   return value;
          // },
        },
      },
    },
    series: [
      {
        name: "Course and School",
        data: [{ x: "", y: 0 }],
      },
    ],
  });

  useEffect(() => {
    return () => {
      clearTimeout(timer);
      controller.abort();
    };

    // eslint-disable-next-line
  }, []);

  const DisableControls = (b) => {
    if (refs.refGenBtn.current) {
      if (b) {
        refs.refGenBtn.current.classList.add("display-none");
      } else {
        refs.refGenBtn.current.classList.remove("display-none");
      }
    }
  };

  const GenerateReport = () => {
    DisableControls(1);

    let endPoint = "";
    switch (tabIndex) {
      case 0:
        endPoint = "GetMembersLocAsync";
        break;
      case 1:
        endPoint = "GetMembersGenderAsync";
        break;
      case 2:
        endPoint = "GetMembersAgeAsync";
        break;
      default:
        break;
    }

    setMsg({ mode: "p", message: "" });

    fetch(`${g.Api.ApiRoute}/ReportsApi/${endPoint}`, {
      method: "GET",
      signal: signal,
      headers: am.Identity.GetAuthHeader(),
      //body: JSON.stringify(dateRange),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        if (!j) {
          setMsg({ mode: "e", message: "Error" });
          DisableControls(0);
          return;
        }

        if (j.Succeeded) {
          let m = [{ name: "Population", data: [] }];
          let arr = j.ModelData;
          let grand_total = 0;
          let caption = "";
          let total_ages = 0;
          for (let i = 0; i < j.ModelData.length; i++) {
            grand_total += arr[i].Total;

            switch (tabIndex) {
              case 0:
                caption = `[${arr[i].Data.CountryCode}] ${arr[i].Data.Name}`;
                break;
              case 1:
                caption = `${
                  arr[i].Data.Gender === "M"
                    ? "MALE"
                    : arr[i].Data.Gender === "F"
                    ? "FEMALE"
                    : "RATHER NOT SAY"
                }`;

                break;
              case 2:
                caption = `${arr[i].Data.Age}`;
                total_ages += arr[i].Data.Age;
                break;
              default:
                break;
            }

            m[0].data.push({
              x: caption,
              y: parseInt(arr[i].Total, 10),
            });
          }

          switch (tabIndex) {
            case 0:
              setChartLocation({
                ...chartLocation,
                series: m,
                options: {
                  ...chartLocation.options,
                  title: {
                    ...chartLocation.options.title,
                    text: `Members by Location | TOTAL ${grand_total}`.toUpperCase(),
                    // align: "left",
                    // style: { fontSize: `16px`, marginTop: "20px" },
                  },
                },
              });
              break;
            case 1:
              setChartGender({
                ...chartGender,
                series: m,
                options: {
                  ...chartGender.options,
                  title: {
                    ...chartGender.options.title,
                    text: `Gender Statistics / TOTAL: ${grand_total}`.toUpperCase(),
                    // align: "left",
                    // style: { fontSize: `16px`, marginTop: "20px" },
                  },
                },
              });
              break;
            case 2:
              setChartAge({
                ...chartAge,
                series: m,
                options: {
                  ...chartAge.options,
                  title: {
                    ...chartAge.options.title,
                    text: `Statistics by Age | TOTAL: ${grand_total} | AVERAGE AGE: ${(
                      total_ages / j.ModelData.length
                    ).toFixed(0)}`.toUpperCase(),
                    // align: "left",
                    // style: { fontSize: `16px`, marginTop: "20px" },
                  },
                },
              });
              break;
            default:
              break;
          }

          setMsg({ mode: "s", message: "" });
        } else {
          setMsg({ mode: "e", message: "Error" });
        }

        timer = setTimeout(() => {
          DisableControls(0);
        }, 3000);
      });
  };

  return (
    <>
      <SectionExpander
        props={{
          icon: ico.Users,
          title: "Members Info",
          classes: "font-sm all-caps",
          caption: "Information about members, location and others",
          collapsed: true,
        }}
      >
        <div className="flex v-center">
          <div className="one-4th font-sm"></div>
          <div className="last">
            <InlineMessageBar props={{ options: msg }} />
            <span ref={refs.refGenBtn}>
              <button onClick={GenerateReport}>Generate Report</button>
            </span>
          </div>
        </div>
        <div className="tb-pad-sm"></div>
        <div>
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab>LOCATION</Tab>
              <Tab>GENDER</Tab>
              <Tab>AGE</Tab>
            </TabList>
            <TabPanel>
              <div className="bordered">
                <Chart
                  options={chartLocation.options}
                  series={chartLocation.series}
                  type="bar"
                  width="100%"
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bordered">
                <Chart
                  options={chartGender.options}
                  series={chartGender.series}
                  type="bar"
                  width="100%"
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bordered">
                <Chart
                  options={chartAge.options}
                  series={chartAge.series}
                  type="bar"
                  width="100%"
                />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </SectionExpander>
    </>
  );
};

export default React.memo(MembersStats);
