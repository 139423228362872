import * as signalR from "@microsoft/signalr";

const useGlobalConstants = () => {
  const apiAddr = window.location.hostname?.toLowerCase().includes("hive.org.ph")
      ? process.env.REACT_APP_APISERVER_VM
      : (window.location.hostname?.toLowerCase() === "localhost"
        ? process.env.REACT_APP_APISERVER_DEV
        : process.env.REACT_APP_APISERVER_PROD);

  // in case there are different servers to deploy
  const ApiBase = apiAddr;
  const CdnBase = apiAddr;
  const HubApiBase = apiAddr;

  const f = {
    AppName: {
      ACRONYM: "HIVE",
      LONG_NAME: "Home of Interconnected Visionaries Environment",
      TRADE_MARK: "HIVE Pte. Ltd.",
      VERSION: "1.0.0-ALPHA",
    },
    Domain: {
      NAME: "hivepte.ltd",
    },
    Api: {
      HubEndpoint: `${HubApiBase}/HiveHub`,
      HubApi: `${HubApiBase}/api/HubApi`,
      ApiRoute: `${ApiBase}/api`,
      CdnRoute: `${CdnBase}/cdn`,
      TestConnectApi: `${ApiBase}/api/TestApi/TestConnect`,
      CountryApi: `${ApiBase}/api/DataLookUpApi/GetCountriesAsync`,
      CityApi: `${ApiBase}/api/DataLookUpApi/GetCitiesAsync`,
    },
    ValidateRegDomain: {
      Enabled: true,
      Domain: "@ub.edu.ph",
    },
    Data: {
      CompressedEnc: 0,
    },
    Membership: {
      Rate: 12, //years old
    },
    DbKeys: {
      CityList: "CityList",
      CompanyList: "CompanyList",
      CountryList: "CountryList",
      AcademicList: "AcademicList",
      CourseList: "CourseList",
      HobbyList: "HobbyList",
      JobIndustry: "JobIndustry",
      MessageArchive: "MessageArchive",
      MyHiveFriends: "MyHiveFriends",
      TargetUser: "TargetUser",
      UniversityList: "UniversityList",
      UserData: "UserData",
      UserHiveFriends: "UserHiveFriends",
      MyChatContacts: "MyChatContacts",
      NotificationList: "NotificationList",
    },
    Fetch: {
      POST: "POST",
      GET: "GET",
      TYPEJSON: { "Content-Type": "application/json" },
      TYPEAUTH: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "",
      },
      MULTIPART: { "Content-Type": "multipart/form-data" },
    },
    Timing: {
      ToLogin: 3000,
      Message: 500,
    },
    DefaultImageNames: {
      ProfilePicture: "nap.png",
      HeroPicture: "nah.jpg",
    },
    ProfileDefault: {
      ProfilePicture: "profilepic.jpg",
      HeroPicture: "heropic.jpg",
    },
    Months: [
      { M: 1, D: "JAN" },
      { M: 2, D: "FEB" },
      { M: 3, D: "MAR" },
      { M: 4, D: "APR" },
      { M: 5, D: "MAY" },
      { M: 6, D: "JUN" },
      { M: 7, D: "JUL" },
      { M: 8, D: "AUG" },
      { M: 9, D: "SEPT" },
      { M: 10, D: "OCT" },
      { M: 11, D: "NOV" },
      { M: 12, D: "DEC" },
    ],
    Years: [2020, new Date().getFullYear()], //year when HIVE started
    Roles: {
      ADMIN: "ADMIN",
      DEV: "DEV",
      MEMBER: "MEMBER",
      MOD: "MOD",
    },
    NotificationType: {
      Announcement: "Announcement",
      Connection: "Connection",
      Reaction: "Reaction",
      Message: "Message",
      CaseReport: "CaseReport",
    },
    Image: {
      MAX_WALL_UPLOAD: 3,
      MAX_PER_UPLOAD: 10,
      MAX_SIZE: 2048, //in kb
      REDUCE_DIM: "1024x768",
      REDUCE_THUMB_DIM: "320x180",
      REDUCE_TO: 0.95, //percent
    },
    Video: {
      MAX_SIZE: 25600, //25 Mb
      ALLOWED_FORMAT: ".mp4",
    },
    Mins: {
      SearchPeople: 3,
    },
    Window: {
      ResTreshold: 740, //width for mobile display
      OffsetBottom: 740,
    },
    Hub: {
      HiveHub: "HiveHub",
      Protocol: new signalR.JsonHubProtocol(),
      HubOptions: {
        transport: signalR.HttpTransportType.WebSockets,
        logMessageContent: true,
        skipNegotiation: true,
      },
      ReducerMethods: {
        CONNECT: "CONNECT",
        DISCONNECT: "DISCONNECT",
        INVOKE: "INVOKE",
        REG_CLIENT_METHOD: "REG_CLIENT_METHOD",
      },
    },
    MaxChars: {
      Post: 800,
    },
    MaxAnnouncements: 3,
    Reactions: [
      { ReactionTypeId: 1, Description: "Angry", Count: 0 },
      { ReactionTypeId: 2, Description: "Baffled", Count: 0 },
      { ReactionTypeId: 3, Description: "Confused", Count: 0 },
      { ReactionTypeId: 4, Description: "Cry", Count: 0 },
      { ReactionTypeId: 5, Description: "Frustrated", Count: 0 },
      { ReactionTypeId: 6, Description: "Grin", Count: 0 },
      { ReactionTypeId: 7, Description: "Happy", Count: 0 },
      { ReactionTypeId: 8, Description: "Heart Broken", Count: 0 },
      { ReactionTypeId: 9, Description: "Laugh", Count: 0 },
      { ReactionTypeId: 10, Description: "Like", Count: 0 },
      { ReactionTypeId: 11, Description: "Love", Count: 0 },
      { ReactionTypeId: 12, Description: "Sad", Count: 0 },
      { ReactionTypeId: 13, Description: "Shocked / Wow", Count: 0 },
      { ReactionTypeId: 14, Description: "Tongue", Count: 0 },
    ],
  };

  return f;
};

export default useGlobalConstants;
