import "./HashTags.css";
import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
//import CommentBox from "../Components/CommentBox";
import WallMediaViewer from "../Components/WallMediaViewer";
import InlineMessageBar from "../Components/Widgets/InlineMessageBar";
import PagingControl from "../Components/Widgets/PagingControl";
import useAccountManager from "../Globals/useAccountManager";
import useDataModels from "../Globals/useDataModels";
import useFetchErrorHandler from "../Globals/useFetchErrorHandler";
import useGlobalConstants from "../Globals/useGlobalConstants";
import useCommonUtil from "../Hooks/useCommonUtil";
import useReactIcons from "../Hooks/useReactIcons";

import { SelfHashTriggerContext, TriggerNavToUserContext } from "../AppContexts";

import ReactionPanel from "../Components/Widgets/ReactionPanel";
import { Tooltip } from "react-tippy";

const HashTags = (props) => {
  const h = useHistory();
  const [tags, setTags] = useState();
  const [msgLoad, setMsgLoad] = useState({ mode: "p" });
  const _aborter = new AbortController();
  const am = useAccountManager();
  const dm = useDataModels();
  const g = useGlobalConstants();
  const refPagerModel = useRef(dm.PagingModel);
  const u = useCommonUtil();
  const ico = useReactIcons();
  const fe = useFetchErrorHandler();
  const { SetTriggerNavToUser } = useContext(TriggerNavToUserContext);
  const { setSelfHashTrigger } = useContext(SelfHashTriggerContext);

  const MEDIA = {
    mp4: ".mp4",
    jpg: ".jpg",
  };

  const [myAccount] = useState(am.GetMyAccount());
  const [wallAlbum, setWallAlbum] = useState();
  const [keywordParam, setKeywordParam] = useState(props.match.params.keyword);

  useLayoutEffect(() => {
    am.ValidateAccount();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(props.match.params.keyword);
    console.log(keywordParam);
    if (props.match.params.keyword !== keywordParam) {
      setKeywordParam(props.match.params.keyword);
      setSelfHashTrigger(u.Security.GenerateRandomString(16));
    }

    // eslint-disable-next-line
  }, [props.match.params.keyword]);

  useEffect(() => {
    refPagerModel.current.UserName = myAccount.UserName;
    refPagerModel.current.HashTag = keywordParam;
    GetHashTags();

    return () => {
      console.log("clearing hashtags");
      setTags(null);
    };
    // eslint-disable-next-line
  }, []);

  const OpenUserProfile = () => {
    u.Ui.ScrollToY(0, () => {
      SetTriggerNavToUser(u.Security.GenerateRandomString(10));
    });
  };

  const GetHashTags = (pm) => {
    if (pm) {
      console.log(refPagerModel.current);

      if (refPagerModel.current.CP === refPagerModel.current.TP || refPagerModel.current.TR === 0) {
        pm.MR = false;
        pm.SL = false;
        pm.HashTag = keywordParam;
        pm.setMarker(0);
        console.log("*** No more entries.");
        return;
      }
    }

    fetch(`${g.Api.ApiRoute}/postapi/GetStoriesByHashTagAsync`, {
      signal: _aborter.signal,
      method: g.Fetch.POST,
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(refPagerModel.current),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          fe.CatchResponse(r, (m) => {
            setMsgLoad({ mode: "w", message: m });
          });

          return null;
        }
      })
      .then((r) => {
        //console.log(r);
        if (!r) return;
        if (r.ModelData.List.length) {
          let newList = [];
          if (!tags) {
            setTags(r.ModelData.List);
          } else {
            newList = [...tags, ...r.ModelData.List];
            setTags(newList);
          }

          refPagerModel.current = r.ModelData.PageModel;
          let p = r.ModelData.PageModel;

          if (pm) {
            if (p.TP === p.CP) {
              pm.MR = false;
            }
            pm.SL = false;
            pm.setMarker(0);
          }
        }
        setMsgLoad({ mode: "h" });
      })
      .catch((er) => {
        fe.CatchError(er, (m) => {
          setMsgLoad({ mode: "w", message: m });
        });
      });
  };

  const LoadNextRecords = (pageRef) => {
    if (pageRef.SL) {
      console.log(">>>Loading next records...");
      GetHashTags(pageRef);
    }
  };

  const LaunchWallViewer = (userName, targetMedia, album) => {
    setWallAlbum({
      UserName: userName,
      TargetMedia: targetMedia,
      Album: album,
    });
  };

  //#region :: Search

  const [msgSearch, setMsgSearch] = useState({ mode: "h" });
  const [hashKey, setHashKey] = useState("");
  const OnTypeHashKey = (e) => {
    setHashKey(e.target.value);
  };
  let timer;
  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, []);

  const DisableEntry = (b) => {
    let searchPanel = document.querySelector("#searchPanel");
    if (searchPanel) {
      if (b) {
        searchPanel.classList.add("nt");
      } else {
        searchPanel.classList.remove("nt");
      }
    }
  };
  const OnSearchTags = () => {
    if (!u.Validator.CheckMinMax(hashKey, 2, 30)) {
      setMsgSearch({ mode: "w", message: "Enter two or more characters." });
      timer = setTimeout(() => {
        setMsgSearch({ mode: "h" });
      }, 3000);
      return;
    }

    if (hashKey.includes("#")) {
      setMsgSearch({
        mode: "w",
        message: "Don't include <b>#</b> in your search.",
      });
      return;
    }

    DisableEntry(true);
    setMsgSearch();

    h.push(`/hashtags/${hashKey}`);
    setSelfHashTrigger(u.Security.GenerateRandomString(10));
  };
  //#endregion :: Search

  return (
    <>
      <div className="hash-tags">
        <div className="flex v-center">
          <div>
            <h1>Hashtags</h1>
            <div className="b-pad-sm">
              <InlineMessageBar props={{ options: msgLoad }} />
              <span className="font-bold color-blue">#{keywordParam}</span>{" "}
            </div>
          </div>
          <div className="last">
            <div id="searchPanel">
              <span className="font-bold">#</span>
              <input
                type="text"
                className="search"
                placeholder="search hashtags"
                maxLength="30"
                value={hashKey}
                onChange={OnTypeHashKey}
              />
              <button className="go" onClick={OnSearchTags}>
                {ico.Search}
              </button>
            </div>
            <div>
              <InlineMessageBar props={{ options: msgSearch }} />
            </div>
          </div>
        </div>
        <div>
          {tags ? (
            <ul>
              {tags.map((p) => (
                <li key={`post_${p.PostId}`} id={`liPost_${p.PostId}`}>
                  <div key={`post_${p.PostId}`}>
                    <div className="post-block">
                      <div className="pad-lg">
                        <div className="color-mid-gray flex nowrap">
                          <div>
                            <div className="font-smaller all-caps">
                              <div className="inline-block r-pad-sm">
                                <Link to={`/${p.UserName}`} onClick={() => OpenUserProfile()}>
                                  {p.FullName}
                                </Link>
                              </div>
                              {p.AuthorFeeling ? (
                                p.AuthorFeeling.PostFeel === 1 ? (
                                  <Tooltip
                                    title={ico.GetReactionIcon(p.AuthorFeeling.ReactionTypeId).text}
                                    arrow="true"
                                    arrowSize="big"
                                  >
                                    <div
                                      className={`font-smaller all-caps inline-block ${
                                        ico.GetReactionIcon(p.AuthorFeeling.ReactionTypeId).color
                                      }`}
                                    >
                                      &mdash; is feeling
                                      <span className="font-xxl l-pad-sm">
                                        {ico.GetReactionIcon(p.AuthorFeeling.ReactionTypeId).ico}
                                      </span>
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                            <div>
                              <span className="r-pad-md">{ico.Calendar}</span>
                              <span className="font-xm">
                                {u.Date.FormatDate(p.PostDate, "ddd | MMM-DD-YYYY hh:mm a")}
                              </span>
                            </div>
                            <div className="tb-pad-sm">
                              {p.UniBroadcast ? (
                                <div
                                  className="inline-block font-xl r-pad-md color-theme"
                                  title="University Broadcast"
                                >
                                  {ico.RadioTower}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="font-lg">{u.Data.ParseLinks(p.PostTitle)}</div>
                            </div>
                          </div>
                          <div className="last">
                            <Link to={`/${p.UserName}`} onClick={() => OpenUserProfile()}>
                              <div
                                className="avatar"
                                style={{
                                  backgroundImage: `url(${g.Api.CdnRoute}/${p.UserName}/profile/${p.UserName}${MEDIA.jpg}?ver=${p.PicVer})`,
                                }}
                              ></div>
                            </Link>
                          </div>
                        </div>

                        <div className="b-pad-md">
                          {/* <ShowMoreText textLength={250}>
                            <div className={`font-sm post-body`} plainText={p.PostBody}></div>
                          </ShowMoreText> */}

                          <div className={`font-sm post-body`}>{u.Data.ParseLinks(p.PostBody)}</div>
                          <div>
                            {p.Album?.Media ? (
                              <div className="post-thumbs tb-pad-md">
                                {p.Album.Media.map((media) => (
                                  <div key={media.MediaHashId} className="inline-block">
                                    {media.MediaType === "i" ? (
                                      <img
                                        src={`${g.Api.CdnRoute}/${p.UserName}/albums/wall/${p.Album.AlbumName}/thumbs/t-${media.MediaHashId}${MEDIA.jpg}`}
                                        alt=""
                                        onClick={() =>
                                          LaunchWallViewer(p.UserName, media.MediaHashId, p.Album)
                                        }
                                      ></img>
                                    ) : (
                                      <div className="inline-block">
                                        <video controls>
                                          <source
                                            src={`${g.Api.CdnRoute}/${p.UserName}/albums/wall/post_${p.PostId}/v-${media.MediaHashId}${MEDIA.mp4}`}
                                          />
                                        </video>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <hr />
                        </div>

                        <div className="flex nowrap">
                          <div>
                            <span>
                              <span className="r-pad-sm font-lg">{ico.Messages}</span>
                            </span>
                            <span id={`numComments_${p.PostId}`} title="See who loved this post">
                              {p.NumComments}
                            </span>
                            <span className="r-pad-lg"></span>
                          </div>
                          <div className="last a-right">
                            <ReactionPanel story={p} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )}
          <PagingControl
            props={{
              callBack: (pg) => {
                LoadNextRecords(pg);
              },
            }}
          />
          <WallMediaViewer WallAlbum={wallAlbum} key={"WallPhotoViewer"} />
        </div>
      </div>
    </>
  );
};

export default HashTags;
