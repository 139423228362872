/* eslint-disable */
import React, { useEffect, useLayoutEffect, useRef } from "react";
import InlineMessageBar from "../../Components/Widgets/InlineMessageBar";
import { useState } from "react";
import useCommonUtil from "../../Hooks/useCommonUtil";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import useAccountManager from "../../Globals/useAccountManager";
import { InfoBar } from "../../Components/Widgets/InfoBar";
import { SectionExpander } from "../../Components/Widgets/SectionExpander";
import QRCode from "qrcode.react";
import useFetchErrorHandler from "../../Globals/useFetchErrorHandler";

import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import useReactIcons from "../../Hooks/useReactIcons";

const SecuritySettings = () => {
  const u = useCommonUtil();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const fe = useFetchErrorHandler();
  const ico = useReactIcons();

  const [user, setUser] = useState();

  console.log("Trace: Render whole component");

  useLayoutEffect(() => {
    am.ValidateAccount((usr) => {
      setUser(usr);
    });
  }, []);

  // useEffect(() => {
  //   console.log(user?.TwoFactorEnabled);
  // }, [user]);

  const ComponentChangePassword = () => {
    console.log("Render ChangePasswordComponent");

    const RefsChangePwd = {
      refForm: useRef(),
    };

    const [modelData, setModelData] = useState({
      UserName: "",
      CurrentPassword: "",
      NewPassword: "",
      NewPassword2: "",
    });
    const [pwdMsg, setPwdMsg] = useState({ message: "Ready", mode: "i" });

    const OnChangeInput = (e, f) => {
      let val = e.currentTarget.value;

      switch (f) {
        case "p1":
          setModelData({ ...modelData, CurrentPassword: val });
          break;
        case "p2":
          setModelData({ ...modelData, NewPassword: val });
          break;
        case "p3":
          setModelData({ ...modelData, NewPassword2: val });
          break;
        default:
          break;
      }
    };

    const CheckEntry = () => {
      // Refs.refForm.current.classList.add("no-touch");
      setPwdMsg({ message: "Checking entries...", mode: "p" });

      let m = modelData;

      if (!u.Validator.CheckMinMax(m.CurrentPassword, 1, 25)) {
        setPwdMsg({
          message: "Current Password is required.",
          mode: "e",
        });
        return;
      }
      if (!u.Validator.CheckMinMax(m.NewPassword, 8, 25)) {
        setPwdMsg({
          message: "New Password must be betwen 8 to 25 characters",
          mode: "e",
        });
        return;
      }

      if (!u.Validator.CheckMatch(m.NewPassword2, m.NewPassword)) {
        setPwdMsg({
          message: "New Password entry and Re-enter Password entries must be the same.",
          mode: "e",
        });
        return;
      }

      if (u.Validator.CheckMatch(m.CurrentPassword, m.NewPassword)) {
        setPwdMsg({
          message: "Current Password and New Password cannot be the same.",
          mode: "e",
        });
        return;
      }

      const s = u.Security.ScorePassword(modelData.NewPassword);
      console.log(s);

      if (s.score < 75) {
        setPwdMsg({
          mode: "e",
          message: `Your password score is weak at ${s.score}%. Please make it stronger or more complicated for better security.`,
        });
      } else {
        setPwdMsg({
          mode: "q",
          message: `Your password score is ${s.score}%. Would you like to use this password?`,
          OnNo: () => {
            setPwdMsg({ mode: "r", message: "Ready" });
          },
          OnYes: () => {
            setPwdMsg({
              mode: "p",
              message: `Please wait...`,
            });

            modelData.UserName = user.UserName;
            fetch(`${g.Api.ApiRoute}/authapi/ChangePasswordAsync`, {
              method: g.Fetch.POST,
              headers: am.Identity.GetAuthHeader(),
              body: JSON.stringify(modelData),
            })
              .then((r) => {
                if (r.ok) {
                  return r.json();
                } else {
                  setPwdMsg({
                    mode: "e",
                    message: `Something went wrong. Please try again later.`,
                  });
                  return null;
                }
              })
              .then((r) => {
                console.log(r);
                if (!r) {
                  return;
                }
                if (r.Succeeded) {
                  setPwdMsg({ message: r.Message, mode: "s" });
                } else {
                  setPwdMsg({ message: r.Message, mode: "e" });
                }

                RefsChangePwd.refForm.current.classList.remove("no-touch");
              });
          },
        });
      }
    };

    const TogglePassword = (e) => {
      //console.log(e.currentTarget.checked);

      if (e.currentTarget.checked) {
        var inputs = RefsChangePwd.refForm.current.querySelectorAll("input[type='password']");
        [...inputs].forEach((i) => {
          i.type = "text";
        });
      } else {
        var inputs = RefsChangePwd.refForm.current.querySelectorAll("input[type='text']");
        [...inputs].forEach((i) => {
          i.type = "password";
        });
      }
    };

    return (
      <SectionExpander
        props={{
          title: "Change Password",
          titleSize: "1.5em",
          collapsed: true,
          caption: "Change your password regularly to better protect your account.",
        }}
      >
        <InfoBar
          props={{
            title: "Password Guide",
          }}
        >
          <p>
            <strong> NOTE 1: </strong>
            Please use strong password so it is hard to guess. Use a combination of letters, cases,
            numbers and symbols. Example: <strong className="color-theme">@thLetic#1016</strong>
          </p>
          <p>
            <strong> NOTE 2: </strong>
            The system DOES NOT store your password in plain text, NEITHER encrypt them. The system
            generates a "HASH DIGEST" instead of your password so that it is more secured even
            exposed. The system also remembers your past used passwords in hashed form, therefore,
            you CANNOT use the same passwords you used before.
          </p>
        </InfoBar>

        <div className="flex v-center">
          <div className="one-half bordered radiused4">
            <div className="pad-lg">
              <div ref={RefsChangePwd.refForm}>
                <div>
                  <strong className="color-red">*</strong> Current Password
                </div>
                <div>
                  <input
                    type="password"
                    autoComplete={`${u.Security.GenerateRandomString(10)}`}
                    maxLength={25}
                    value={modelData.CurrentPassword}
                    onChange={(e) => OnChangeInput(e, "p1")}
                  />
                </div>
                <div>
                  <strong className="color-red">*</strong> Enter New Password
                </div>
                <div>
                  <input
                    type="password"
                    autoComplete={`${u.Security.GenerateRandomString(10)}`}
                    maxLength={25}
                    value={modelData.NewPassword}
                    onChange={(e) => OnChangeInput(e, "p2")}
                  />
                </div>
                <div>
                  <strong className="color-red">*</strong> Re-enter New Password
                </div>
                <div>
                  <input
                    type="password"
                    maxLength={25}
                    autoComplete={`${u.Security.GenerateRandomString(10)}`}
                    value={modelData.NewPassword2}
                    onChange={(e) => OnChangeInput(e, "p3")}
                  />
                </div>
                <div>
                  <input
                    type="checkbox"
                    autoComplete="off"
                    id="chkShow"
                    onClick={(e) => TogglePassword(e)}
                  />
                  <label htmlFor="chkShow">Show Password entries.</label>
                </div>
              </div>
              <div className="tb-pad-sm">
                <InlineMessageBar props={{ options: pwdMsg }} />
              </div>
              <div className="a-right">
                <input type="button" name="" id="" value="SUBMIT" onClick={CheckEntry} />
              </div>
            </div>{" "}
          </div>
          <div></div>
        </div>
      </SectionExpander>
    );
  };

  const ComponentRecoveryKey = () => {
    const [recoveryKeys, setRecoveryKeys] = useState();
    const [currentPwd, setCurrentPwd] = useState("");
    const [msgShow, setMsgShowKeys] = useState({ mode: "cl" });
    const [msgNewKey, setMsgNewKey] = useState({ mode: "cl" });

    // ============
    const DisableBtns = (b) => {
      let b_area = document.getElementById("btnArea");
      if (b) {
        if (b_area) {
          let btns = b_area.querySelectorAll("button");
          if (btns) {
            btns.forEach((btn) => {
              btn.classList.add("nt");
            });
          }
        }
      } else {
        if (b_area) {
          let btns = b_area.querySelectorAll("button");
          if (btns) {
            btns.forEach((btn) => {
              btn.classList.remove("nt");
            });
          }
        }
      }
    };
    const OnTypeCurrentPwd = (e) => {
      setCurrentPwd(e.target.value);
    };
    const ShowKeys = () => {
      setMsgShowKeys({ mode: "p", message: "" });
      if (currentPwd) {
        if (!u.Validator.CheckMinMax(currentPwd, 8, 30)) {
          setMsgShowKeys({ mode: "w", message: "Invalid password." });
          return;
        }

        DisableBtns(true);
        //setRecoveryKeys(["Wer34553", "lzrppFS", "Gerc090"]);
        fetch(`${g.Api.ApiRoute}/authapi/GetMyKeysAsync`, {
          method: "POST",
          headers: am.Identity.GetAuthHeader(),
          body: JSON.stringify({ UserPassword: currentPwd }),
        })
          .then((r) => {
            if (r.ok) {
              return r.json();
            }
          })
          .then((j) => {
            if (!j) {
              setMsgShowKeys({ mode: "w", message: "Error" });
              return;
            }
            if (j.Succeeded) {
              setMsgShowKeys({ mode: "s", message: j.Message });
              setRecoveryKeys(j.ModelData?.RecoveryCode?.split(";"));
            } else {
              setMsgShowKeys({ mode: "w", message: j.Message });
            }

            DisableBtns(false);
          })
          .catch((err) => {
            setMsgShowKeys({ mode: "w", message: err });
            DisableBtns(false);
          });
      } else {
        setMsgShowKeys({ mode: "w", message: "Enter current password." });
      }
    };

    const GenerateNewKeys = () => {
      setMsgNewKey({ mode: "p", message: "" });
      if (currentPwd) {
        if (!u.Validator.CheckMinMax(currentPwd, 8, 30)) {
          setMsgNewKey({ mode: "w", message: "Invalid password." });
          return;
        }

        DisableBtns(true);
        //setRecoveryKeys(["Wer34553", "lzrppFS", "Gerc090"]);
        fetch(`${g.Api.ApiRoute}/authapi/GenNewKeysAsync`, {
          method: "POST",
          headers: am.Identity.GetAuthHeader(),
          body: JSON.stringify({ UserPassword: currentPwd }),
        })
          .then((r) => {
            if (r.ok) {
              return r.json();
            }
          })
          .then((j) => {
            if (!j) {
              setMsgNewKey({ mode: "e", message: "Error" });
              return;
            }
            if (j.Succeeded) {
              setMsgNewKey({ mode: "s", message: j.Message });
              if (j.ModelData) {
                setRecoveryKeys(j.ModelData?.RecoveryCode?.split(";"));
              }
            } else {
              setMsgNewKey({ mode: "w", message: j.Message });
            }

            DisableBtns(false);
          })
          .catch((err) => {
            setMsgNewKey({ mode: "e", message: JSON.stringify(err) });
            DisableBtns(false);
          });
      } else {
        setMsgNewKey({ mode: "w", message: "Enter current password." });
      }
    };

    return (
      <>
        <SectionExpander
          props={{
            title: "Recovery Keys",
            collapsed: true,
            titleSize: "1.5em",
            caption: `Recovery keys are used to change your password in case you forgot it. 
              Replace or generate new ones below.`,
          }}
        >
          <div>
            <div className="three-4th b-pad-md">
              <div className="bordered radiused4">
                <div className="pad-lg">
                  <ul className="no-bullet">
                    <li>Enter current password to show or generate new keys.</li>
                    <li className="tb-pad-lg">
                      <div className="fool-chrome">
                        <input type="password" />
                      </div>
                      <input type="password" value={currentPwd} onChange={OnTypeCurrentPwd} />
                    </li>

                    <li>
                      <div id="btnArea">
                        <div className="b-pad-sm">
                          <button onClick={ShowKeys}>Show Current Keys</button>
                          <InlineMessageBar props={{ options: msgShow }} />
                        </div>
                        <div>
                          <button onClick={GenerateNewKeys}>Generate new keys</button>
                          <InlineMessageBar props={{ options: msgNewKey }} />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="three-4th">
              <div className="bordered radiused4">
                <div className="pad-lg">
                  <div className="all-caps">Recovery Keys</div>
                  <div className="font-sm">Keys are case-sensitive</div>
                  <div className="tb-pad-md">
                    <hr />
                  </div>
                  <div className="code2 font-xl">
                    <ul className="no-bullet">
                      {recoveryKeys ? recoveryKeys.map((k) => <li key={k}>{k}</li>) : <></>}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SectionExpander>
      </>
    );
  };
  const ComponentEnableTwoFa = () => {
    console.log("render TwoFaComponent");
    const [qrCode, setQrCode] = useState();
    const [recoveryCodes, setRecoveryCodes] = useState();

    const [msg2Fa, setMsg2fa] = useState({ message: "", isLoading: false });
    const [otpTestMsg, setOtpTestMsg] = useState({
      message: "",
      isLoading: false,
      tl: 1000,
    });

    const [enable2FaMsg, setEnable2FaMsg] = useState({
      mode: "i",
      message: "Ready",
    });

    const [twoFaEnabled, setTwoFaEnabled] = useState(false);

    const Refs = {
      refQrInputs: useRef(),
      refQrFirstCode: useRef(),
      refTestOtpForm: useRef(),
      refDvActivation: useRef(),
      refActivate2Fa: useRef(),
      refProceed: useRef(),
    };

    const [oTpModel, setOtpModel] = useState({
      d1: -1,
      d2: -1,
      d3: -1,
      d4: -1,
      d5: -1,
      d6: -1,
    });

    const OnCheck2Fa = (e) => {
      if (!e.currentTarget.checked) {
        setQrCode(null);
        return;
      }

      setMsg2fa({ message: "Generating 2FA Code...", mode: "p" });

      fetch(`${g.Api.ApiRoute}/AuthApi/Generate2FACode`, {
        method: g.Fetch.POST,
        headers: am.Identity.GetAuthHeader(),
        //body: JSON.stringify(co),
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            fe.CatchResponse(r, (e) => {
              setMsg2fa({
                message: `An error has occured while getting code info from server. ${e}`,
                mode: "e",
              });
            });
            return null;
          }
        })
        .then((r) => {
          if (r) {
            console.log(r);
            setQrCode(r.ModelData);
            setMsg2fa({ mode: "s", message: r.Message });
          } else {
            setMsg2fa({
              message: "An error has occured while getting code info from server.",
              mode: "e",
            });
          }
        })
        .catch((er) => {
          fe.CatchError(er, (m) => {
            setMsg2fa({
              message: m,
              mode: "e",
            });
          });
        });
    };

    const OnTypeOtp = (e, idx) => {
      //if (u.Number.IsInt(e.key)) {
      let nxt = e.currentTarget.nextElementSibling;
      var d = parseInt(e.currentTarget.value, 10);
      var digit = d > -1 ? d : -1;

      switch (idx) {
        case 0:
          setOtpModel({
            ...oTpModel,
            d1: digit,
          });
          break;
        case 1:
          setOtpModel({
            ...oTpModel,
            d2: digit,
          });
          break;
        case 2:
          setOtpModel({
            ...oTpModel,
            d3: digit,
          });
          break;
        case 3:
          setOtpModel({
            ...oTpModel,
            d4: digit,
          });
          break;
        case 4:
          setOtpModel({
            ...oTpModel,
            d5: digit,
          });
          break;
        case 5:
          setOtpModel({
            ...oTpModel,
            d6: digit,
          });
          break;
        default:
          break;
      }

      if (nxt) {
        nxt.focus();
      } else {
        e.preventDefault();
      }
      //}
    };

    const OnClearQrEntries = () => {
      u.Forms.ClearTextInputs(Refs.refQrInputs.current);
      Refs.refQrFirstCode.current.focus();
      setOtpTestMsg({
        mode: "i",
        message: "Fill the boxes above with the 6-digit OTP code.",
      });
    };

    const FormatOTP = () => {
      let values = "";
      Object.keys(oTpModel).forEach((k) => {
        console.log(oTpModel[k]);
        values += oTpModel[k];
      });

      return values;
    };

    const OnVerify = () => {
      u.Ui.DisableEl(Refs.refTestOtpForm.current, true);
      let members = Object.keys(oTpModel);
      console.log(oTpModel);
      if (members) {
        let kulang = members.some((k) => {
          return oTpModel[k] === -1;
        });

        if (kulang) {
          u.Ui.DisableEl(Refs.refTestOtpForm.current);
          setOtpTestMsg({
            mode: "w",
            message: "All entries must be filled-out, digits or numbers only.",
          });
        } else {
          setOtpTestMsg({
            mode: "u",
            message: "Validating from Web API Authentication Server...",
          });

          var userPayload = {
            StringValue: FormatOTP(),
          };

          console.log(userPayload);

          fetch(`${g.Api.ApiRoute}/AuthApi/VerifyOtpCodeAsync`, {
            method: g.Fetch.POST,
            headers: am.Identity.GetAuthHeader(),
            body: JSON.stringify(userPayload),
          })
            .then((r) => {
              if (r.ok) {
                return r.json();
              } else {
                fe.CatchResponse(r, (m) => {
                  setOtpTestMsg({ mode: "w", message: m });
                });
                return null;
              }
            })
            .then((r) => {
              if (r) {
                if (r.Succeeded) {
                  setTwoFaEnabled(true);
                  setRecoveryCodes(r.ModelData || ["xxx"]);

                  let me = am.GetMyAccount();
                  if (me) {
                    me.TwoFactorEnabled = true;
                    am.StoreMyAccount(me, () => {
                      u.Ui.DisableEl(Refs.refProceed.current, true);
                    });
                  } else {
                    setEnable2FaMsg({
                      mode: "e",
                      message: "Local store suspiciously removed.",
                    });
                  }

                  setOtpTestMsg({ mode: "s", message: r.Message });
                } else {
                  Refs.refTestOtpForm.current.classList.remove("no-touch");
                  setOtpTestMsg({ mode: "w", message: r.Message });
                }
              }
            });
        }
      }
    };

    const FormatRecoveryCodes = () => {
      if (recoveryCodes) {
        var html = "<hr/>",
          breaker = 1;
        for (let i = 0; i < recoveryCodes.length; i++) {
          html += `<span class='r-pad-xl'>${recoveryCodes[i]}</span>`;

          if (breaker === 3) {
            html += `<hr/>`;
            breaker = 1;
          } else {
            breaker++;
          }
        }

        return (
          <>
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
          </>
        );
      } else {
        return <></>;
      }
    };

    return (
      <SectionExpander
        props={{
          title: "Two Factor Authentication",
          caption:
            "Two-factor or 2FA authentication adds an extra layer of security to your account.",
        }}
      >
        <div>
          <div>
            <InfoBar props={{ flash: true }}>
              <p>
                2FA is a common practice especially in mobile banking, online games, and sensitive
                software applications requiring hightened security measures. HIVE only accepts 2FA
                via Authenticator app. Please note that we don't support MFA (multi-factor) thru
                e-mail or mobile number text messaging as they are less secure.
              </p>
              <p>
                HIVE supports the following authenticator apps. Please download either one of the
                following from your phone's app store:
              </p>
              <ul className="bullet">
                <li>Microsoft Authenticator</li>
                <li>Google Authenticator</li>
                <li>Authy</li>
                <li>RSA Authenticator (Needs Payment/Partnership)</li>
              </ul>
              <p>
                Once you have downloaded and installed the app, tick the checkbox below to begin.
              </p>
            </InfoBar>
          </div>
          <hr />
          <div className="flex v-center">
            <div className="one-third" ref={Refs.refProceed}>
              <input type="checkbox" id="chk2FA" onChange={OnCheck2Fa} />
              <label htmlFor="chk2FA">Proceed on enabling 2FA</label>
            </div>
            <div className="last">
              <InlineMessageBar props={{ options: msg2Fa }} />
            </div>
          </div>
          <hr />
        </div>
        <div>
          {qrCode ? (
            <ul className="qr-steps">
              <li>
                <h2>Step 1</h2>
                <div className="tb-pad-sm">
                  <strong>Register 2FA Account Signature</strong>
                </div>
                <div>
                  Open your mobile phone's authenticator app. Search for Add Account feature, and
                  use the Authenticator's scan QR Code option to capture the QR Code below.
                </div>
              </li>
              <li></li>
              <li>
                <div className="flex">
                  <div className="b-pad-md">
                    <QRCode
                      value={qrCode.AuthenticatorUri}
                      size={175}
                      bgColor={"#ffffff"}
                      fgColor={"#333"}
                      level={"L"}
                      includeMargin={false}
                      renderAs={"svg"}
                      imageSettings={{
                        src: am.GetMyAvatar(user.UserName),
                        x: null,
                        y: null,
                        height: 24,
                        width: 24,
                        excavate: false,
                      }}
                    />
                  </div>
                  <div className="lr-pad-lg"></div>
                  <div>
                    <div>
                      <div className="font-md b-pad-sm">
                        <div>
                          If your phone has no camera, or not able to scan for some reasons, select
                          manual input in the authenticator app and input the code below{" "}
                          <strong>without spaces</strong>. NOTE: zero (0) has slash. Example:{" "}
                          <span className="qr-code font-lg">
                            X1<span className="color-blue">0</span>YO
                          </span>
                        </div>
                      </div>

                      <div>
                        <hr />
                        <span className="font-xl qr-code color-blue">{qrCode.SharedKey}</span>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li></li>
              <li>
                <h2>Step 2</h2>
                <div className="tb-pad-sm">
                  <strong>Verify and Activate OTP Code</strong>
                </div>
                <div>
                  <p>
                    Once you have registered the account in the authenticator app, verify and
                    activate by inputting the 6-character OTP code generated from the authenticator
                    app.
                  </p>
                  <p>
                    <em>
                      NOTE: The OTP will expire approximately 30 to 90 seconds, and a maximum of up
                      to 3 minutes, depending on the authenticator app you are using. However, the
                      app generates infinite number of random OTP codes.
                    </em>
                  </p>
                </div>
                <div className="tb-pad-sm">
                  <div className="qr-text">
                    <div className="tb-pad-sm" ref={Refs.refQrInputs}>
                      <input
                        type="text"
                        name=""
                        id="n1"
                        ref={Refs.refQrFirstCode}
                        tabIndex="0"
                        maxLength="1"
                        onKeyUp={(e) => OnTypeOtp(e, 0)}
                      />
                      <input
                        type="text"
                        name=""
                        id="n2"
                        tabIndex="1"
                        maxLength="1"
                        onKeyUp={(e) => OnTypeOtp(e, 1)}
                      />
                      <input
                        type="text"
                        name=""
                        id="n3"
                        tabIndex="2"
                        maxLength="1"
                        onKeyUp={(e) => OnTypeOtp(e, 2)}
                      />
                      <input
                        type="text"
                        name=""
                        id="n4"
                        tabIndex="3"
                        maxLength="1"
                        onKeyUp={(e) => OnTypeOtp(e, 3)}
                      />
                      <input
                        type="text"
                        name=""
                        id="n5"
                        tabIndex="4"
                        maxLength="1"
                        onKeyUp={(e) => OnTypeOtp(e, 4)}
                      />
                      <input
                        type="text"
                        name=""
                        id="n6"
                        tabIndex="5"
                        maxLength="1"
                        onKeyUp={(e) => OnTypeOtp(e, 5)}
                      />
                    </div>
                  </div>
                  <div className="tb-pad-sm">
                    <InlineMessageBar props={{ options: otpTestMsg }} />
                  </div>
                  <div ref={Refs.refTestOtpForm}>
                    <input
                      type="button"
                      name=""
                      id="btnTestQrClear"
                      value="Reset"
                      onClick={OnClearQrEntries}
                    />{" "}
                    <input
                      type="button"
                      name=""
                      id="btnTestQr"
                      value="Verify and Activate"
                      onClick={OnVerify}
                    />
                  </div>
                </div>
              </li>
              <li></li>

              <li>
                <h2>Final Step</h2>
                {twoFaEnabled ? (
                  <div>
                    <div className="font-bold">Just in case :)</div>
                    <p>
                      If you lost your phone, stolen or damaged, you can still login using below{" "}
                      <strong>recovery codes</strong>, and create another OTP signature using
                      authenticator app.
                    </p>

                    <p>
                      NOTE: Use of each code set is one-time. Once used, it cannot be re-used. List
                      down the codes and keep it in a safe place.
                    </p>
                    <div className="qr-code font-lg color-blue">
                      <FormatRecoveryCodes />
                    </div>
                    <div className="tb-pad-md">
                      <strong className="color-orange">IMPORTANT</strong>: If you deactivate your
                      2FA, codes above will no longer work. You need to remove your account from the
                      authenticator app and register a new one.
                    </div>
                  </div>
                ) : (
                  <div>Awaiting 2FA Activation</div>
                )}
              </li>
            </ul>
          ) : (
            ""
          )}
        </div>
      </SectionExpander>
    );
  };
  const ComponentDisableTwoFactor = () => {
    console.log("Render ComponentDisableTwoFactor");
    const Refs = {
      refDeactivatButton: useRef(),
      refRecoveryCode: useRef(),
    };

    const [deacMsg, setDeacMsg] = useState({
      mode: "r",
      message: "Ready",
    });

    const OnDeactivate2Fa = () => {
      u.Ui.DisableEl(Refs.refDeactivatButton.current, true);
      setDeacMsg({ mode: "p", message: "Please wait..." });

      // let payload = {
      //   StringValue: Refs.refRecoveryCode.current.value,
      // };

      fetch(`${g.Api.ApiRoute}/AuthApi/Disable2FactorAsync`, {
        method: g.Fetch.POST,
        headers: am.Identity.GetAuthHeader(),
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            fe.CatchResponse(r, (m) => {
              setDeacMsg({ mode: "e", message: m });
            });
            return null;
          }
        })
        .then((r) => {
          if (!r) {
            setDeacMsg({
              mode: "e",
              message: "Something went wrong. Please try again later.",
            });
          } else {
            if (r.Succeeded) {
              //update LS to reflect deactivation
              let me = am.GetMyAccount();
              if (me) {
                me.TwoFactorEnabled = false;
                am.StoreMyAccount(me);
                setDeacMsg({
                  mode: "s",
                  message: `${r.Message} Please log off to verify that HIVE no longer requires OTP to sign in.`,
                });
              } else {
                setDeacMsg({
                  mode: "e",
                  message: `Your user local store has been suspiciously deleted.`,
                });
              }
            } else {
              setDeacMsg({ mode: "e", message: r.Message });
              u.Ui.DisableEl(Refs.refDeactivatButton.current);
            }
          }
        });
    };

    const OnAskDeactivate = () => {
      setDeacMsg({
        mode: "q",
        message: "Are you sure you need to deactivate 2FA?",
        OnYes: () => {
          OnDeactivate2Fa();
        },
        OnNo: () => {
          setDeacMsg({
            mode: "i",
            message: "Enter the OTP Code above, generated by your Authenticator app to deactivate.",
          });
        },
      });
    };
    return (
      <SectionExpander
        props={{
          title: "Two Factor Authentication",
          caption: "Your 2FA Security feature is active.",
        }}
      >
        <div className="tb-pad-lg"></div>
        <div ref={Refs.refDeactivatButton}>
          {/* <span className="r-pad-sm">
            <Tooltip
              title="Enter Recovery Code"
              arrow="true"
              arrowSize="big"
              followCursor="true"
            >
              <input
                type="text"
                ref={Refs.refRecoveryCode}
                name=""
                id="txtOtp"
                placeholder="recovery code"
                maxLength="10"
                className="otp-code"
              />
            </Tooltip>
          </span> */}
          <span>
            <input type="button" value="Deactivate 2FA" onClick={() => OnAskDeactivate()} />
          </span>
        </div>

        <div className="tb-pad-sm">
          <InlineMessageBar props={{ options: deacMsg }} />
        </div>
        <InfoBar props={{ caption: "QUICK TIP", expanded: true }}>
          <p>
            If your phone got stolen with the authenticator app in it, you can deactivate it and
            generate a new one with a new phone. Or, if for some reasons you need to deactivate 2FA,
            just click or tap on the DEACTIVATE 2FA button above.
          </p>
          <p>
            Deactivating the 2FA feature will remove your authenticator app's signature from HIVE
            Authorization Server. If you wish to register a new one, you need to undergo the
            procedure on enabling the 2FA feature again.
          </p>
        </InfoBar>
      </SectionExpander>
    );
  };

  //#region  MAIN COMPONENT
  return (
    <>
      <div className="security-settings tb-pad-md">
        <div>
          <div className="fool-chrome">
            <input
              name="current-password"
              type="text"
              tabIndex="100"
              autoComplete="new-password"
            ></input>
          </div>
        </div>

        <SectionExpander
          props={{
            title: "Security Settings",
            titleSize: "2em",
            collapsed: false,
            caption: "Change your password here or generate new recovery keys",
          }}
        >
          <ComponentChangePassword />
          <ComponentRecoveryKey />
        </SectionExpander>

        {/* {user?.TwoFactorEnabled ? (
          <ComponentDisableTwoFactor />
        ) : (
          <ComponentEnableTwoFa />
        )} */}
      </div>
    </>
  );
  //#endregion
};

export default SecuritySettings;
