import React from "react";
import "./HeroBanner.css";
import useGlobalConstants from "../Globals/useGlobalConstants";
import useCommonUtil from "../Hooks/useCommonUtil";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import useAccountManager from "../Globals/useAccountManager";
import DialogBox from "./Widgets/DialogBox";

const HeroBanner = () => {
  //console.log("Trace: HeroBanner Rerendered");

  const g = useGlobalConstants();
  const u = useCommonUtil();
  const am = useAccountManager();

  const [heroBanner, setHeroBanner] = useState("");
  const [heroFile, setHeroFile] = useState();
  const [dialog, setDialog] = useState();

  const fileRef = useRef();

  const [T, setTargetUser] = useState();

  useEffect(() => {
    am.Identity.GetTargetUser((tuser) => {
      setTargetUser(tuser);
    }, true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (T) {
      setTargetUser(T);
    }
  }, [T]);

  useEffect(() => {
    //console.log("Setting Hero Banner...");
    //console.log("T changed.");

    if (T) {
      if (!T.HeroVer) {
        setHeroBanner(`url(${g.Api.CdnRoute}/${g.DefaultImageNames.HeroPicture})`);
      } else {
        setHeroBanner(
          `url(${g.Api.CdnRoute}/${T.UserName}/albums/wall/hero-${T.UserName}.jpg?v=${T.HeroVer})`
        );
      }
    }
    // eslint-disable-next-line
  }, [T]);

  const OnChangeBg = () => {
    if (!am.Identity.IsMe()) return;
    //console.log("Popping diaglog box");
    fileRef.current.click();
  };

  const OnChangeFile = () => {
    setHeroFile(fileRef.current.files[0]);
  };

  //[heroFile]
  useEffect(() => {
    
    if (!heroFile) return;

    if (u.FileManager.CheckSize(heroFile, g.Image.MAX_SIZE)) {
      setDialog({
        mode: "w",
        title: "FILE SIZE",
        message: `File size is too large. System accepts a maximum of ${
          g.Image.MAX_SIZE
        } KB. You are uploading ${(heroFile.size / 1024).toFixed(0)} KB.`,
        //buttons: [{ caption: "ok", id: "ok" }],
      });
    } else {
      //onsole.log(heroFile);
      const ver = u.Security.GenerateRandomString(10);
      u.FileManager.ResizeImage(heroFile, null, null, async (trans) => {
        let photoModel = {
          ApiEndPoint: `${g.Api.ApiRoute}/mediaapi/UploadHeroImageAsync`,
          AuthToken: am.GetMyAuthToken(),
          PhotoModels: [
            {
              UserName: T.UserName,
              FileName: g.ProfileDefault.HeroPicture,
              FileStream: u.FileManager.DataURItoBlob(trans.dataUrl),
            },
          ],
          OnProgress: (n) => {
            if (n) {
              // SetDialog({
              //   mode: "pb",
              //   title: `UPLOADING - ${n}%`,
              //   message: "Please wait...",
              //   callBackPercent: n,
              // });
            }
          },
          OnCompleted: () => {
            // SetDialog({
            //   mode: "pb",
            //   callBackPercent: n,
            //   title: `UPLOADED - 100%`,
            //   message: `Upload completed.`,
            // });
          },
          Succeeded: (msg) => {
            //remove old pic from cash
            let result = JSON.parse(msg);
            if (result) {
              setHeroBanner(
                `url(${g.Api.CdnRoute}/${T.UserName}/albums/wall/hero-${T.UserName}.jpg?v=${ver})`
              );
              console.log(msg);

              T.HeroVer = result.ModelData.HeroVer;
              u.Data.StoreData(g.DbKeys.UserData, T, (d) => {
                am.StoreMyAccount(d);
              });
            } // SetDialog({ mode: "cl" });
            fileRef.current.value = "";
          },
          OnError: (err) => {
            // SetDialog({ mode: "e", title: "ERROR", message: err });
            fileRef.current.value = "";
            console.log(err);
          },
        };

        u.FileManager.UploadImage(photoModel);
      });

      //
    }
    // eslint-disable-next-line
  }, [heroFile]);

  //console.log("HeroBanner :: Rendered");

  return (
    <>
      <div className="hidden">
        <input
          type="file"
          name="files"
          id="filesUpload"
          ref={fileRef}
          accept="image/*"
          onChange={(e) => OnChangeFile(e)}
        />
      </div>
      <div
        className="hero-banner"
        style={{
          backgroundImage: heroBanner,
        }}
      >
        <div className="image-edit" onClick={OnChangeBg}></div>
      </div>
      <DialogBox Dialog={dialog} />
    </>
  );
};

export default React.memo(HeroBanner);
