import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";

import useNotificationManager from "../../Globals/RealtimeComm/useNotificationManager";
import { SocketContext } from "../../Globals/RealtimeComm/WebSocketReducer";
import useAccountManager from "../../Globals/useAccountManager";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import useCommonUtil from "../../Hooks/useCommonUtil";
import useReactIcons from "../../Hooks/useReactIcons";
import "./NotificationPanel.css";

const NotificationPanel = (props) => {
  const g = useGlobalConstants();
  const u = useCommonUtil();
  const n = useNotificationManager();
  const ico = useReactIcons();
  const am = useAccountManager();

  const { HubConnection } = useContext(SocketContext);

  const [notiList, setNotiList] = useState();
  const [notiState, setNotiState] = useState(props.options);
  //let tmr;

  const CLIENT_METHODS = {
    clientReceiveReaction: "clientReceiveReaction",
    adminReceiveReport: "adminReceiveReport",
    //clientRemoveAnnouncement: "clientRemoveAnnouncement",
  };

  useLayoutEffect(() => {
    HubConnection.on(CLIENT_METHODS.clientReceiveReaction, (obj) => {
      if (obj.TargetUserName === am.GetMyUserName()) {
        n.ShowBell(1);
      }
      //console.clear();
      //console.log(obj);
      n.StoreNotification({
        Id: `react_${obj.ReactionId}`,
        Type: g.NotificationType.Reaction,
        Author: `${obj.FullName}`,
        Message: `${obj.Message} | ${obj.FullName}`,
        Read: 0,
        UserName: obj.UserName,
      });
    });

    HubConnection.on(CLIENT_METHODS.adminReceiveReport, (obj) => {
      //console.log(obj);
      n.ShowBell(1);
      n.StoreNotification({
        Id: `case_${obj.CaseReportId}`,
        Type: g.NotificationType.CaseReport,
        Author: `${obj.FullName}`,
        Message: `A new case has been reported by ${obj.FullName}`,
        Read: 0,
        UserName: obj.UserName,
      });
    });

    // eslint-disable-next-line
  }, []);

  const RetrieveNotifications = () => {
    u.Data.RetrieveData(g.DbKeys.NotificationList, (list) => {
      if (list) {
        setNotiList(list);
      }
    });
  };

  useEffect(() => {
    RetrieveNotifications();

    return () => {
      setNotiList(null);
      setNotiState(null);
    };

    // eslint-disable-next-line
  }, []);

  const refs = {
    bodyRef: useRef(),
  };

  useEffect(() => {
    setNotiState(props.options);

    // eslint-disable-next-line
  }, [props.options]);

  useEffect(() => {
    //console.log(notiState);
    if (notiState) {
      if (notiState.hidden) {
        refs.bodyRef.current.classList.add("hidden");
        n.ActivateBell(false);
      } else {
        n.ShowBell();
        n.ActivateBell(true);
        RetrieveNotifications();
        refs.bodyRef.current.classList.remove("hidden");
      }
    }

    // eslint-disable-next-line
  }, [notiState]);

  const hideMe = () => {
    setNotiState({ ...notiState, hidden: !notiState.hidden });
    props.options.hidden = !props.options.hidden;
  };

  return (
    <div className="noti-panel hidden" ref={refs.bodyRef}>
      <div className="pad-md">
        <div className="all-caps tb-pad-sm header">
          <div className="flex still nowrap v-center">
            <div>Notifications</div>
            <div className="last font-xl">
              <span className="hand r-pad-md" onClick={hideMe}>
                {ico.CloseCircle}
              </span>
            </div>
          </div>
        </div>
        <div className="body">
          <ul>
            {notiList
              ? notiList.map((m) => (
                  <li key={m.Id} className="tb-pad-sm font-smaller">
                    <div className="all-caps color-orange">{m.Type}</div>
                    <div>{m.Message}</div>
                  </li>
                ))
              : ""}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default React.memo(NotificationPanel);
