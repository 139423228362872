import React, { useEffect, useRef, useState } from "react";
import useGlobalConstants from "../../../Globals/useGlobalConstants";
import useReactIcons from "../../../Hooks/useReactIcons";
import { Tooltip } from "react-tippy";
import useCommonUtil from "../../../Hooks/useCommonUtil";
import "./CourseMgt.css";

import scrollToElement from "scroll-to-element";
import DialogBox from "../../../Components/Widgets/DialogBox";
import useAccountManager from "../../../Globals/useAccountManager";
import InlineMessageBar from "../../../Components/Widgets/InlineMessageBar";
import useFetchErrorHandler from "../../../Globals/useFetchErrorHandler";
import { InfoBar } from "../../../Components/Widgets/InfoBar";
import { useDataManager } from "../../../Globals/useDataManager";
import { SectionExpander } from "../../../Components/Widgets/SectionExpander";

const CourseMgt = () => {
  const u = useCommonUtil();
  const ico = useReactIcons();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const fe = useFetchErrorHandler();
  const dmgr = useDataManager();

  const [acadList, setAcadList] = useState();
  const [selectedAcadId, setSelectedAcadId] = useState();

  const [courseList, setCourseList] = useState();
  const [courseFilter, setCourseFilter] = useState();

  const [dialog, setDialog] = useState();
  const [msg, setMsg] = useState();
  const [myAccount] = useState(am.GetMyAccount());

  const _refs = {
    refInter: useRef(),
    refCourseTop: useRef(),
    refAcaTop: useRef(),

    refNewAcaText: useRef(),
    refNewCourseCode: useRef(),
    refNewCourseDesc: useRef(),

    refAcadNewBtn: useRef(),
    refNewAcadDiv: useRef(),
  };

  useEffect(() => {
    setMsg({ mode: "d", message: "Downloading Academic Course list..." });

    dmgr.GetAcads((acadModel) => {
      if (Array.isArray(acadModel.ModelData)) {
        setAcadList(acadModel.ModelData);

        if (acadModel.ModelData.length > 0) {
          dmgr.GetCourses((courseModel) => {
            if (Array.isArray(courseModel.ModelData)) {
              setCourseList(courseModel.ModelData);
              setMsg({ mode: "s", message: "Ready" });

              let selAcad = acadModel.ModelData[0].AcadDepartmentId;
              setSelectedAcadId(selAcad);
            } else {
              setMsg({
                mode: "e",
                message: courseModel,
              });
            }
          });
        }
      } else {
        setMsg({
          mode: "e",
          message: acadModel,
        });
      }
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedAcadId) {
      //console.log(selectedAcad);

      setCourseFilter(courseList.filter((f) => f.AcadDepartmentId === selectedAcadId));
    }
  }, [selectedAcadId]);

  const toggleFormEntries = (tr) => {
    [...tr.querySelectorAll("span.read")].forEach((el) => el.classList.toggle("dn"));
    [...tr.querySelectorAll("span.edit")].forEach((el) => el.classList.toggle("dn"));

    [...tr.querySelectorAll("span.modify")].forEach((el) => el.classList.toggle("dn"));
  };

  const ShowAcadCrud = (show) => {
    if (show) {
      _refs.refNewAcadDiv.current.classList.remove("dn");
      _refs.refAcadNewBtn.current.classList.add("dn");
    } else {
      _refs.refNewAcadDiv.current.classList.add("dn");
      _refs.refAcadNewBtn.current.classList.remove("dn");
    }
  };
  //#region :: Acad Operation

  const [acadTitleCrud, setAcadTtitleCrud] = useState("Add New");
  const [acadModel, setAcadModel] = useState({ AcadDepartmentId: "", Description: "" });
  const [cmdMode, setCmdMode] = useState("new");
  const [newAcad, setNewAcad] = useState(true);

  const OnAcadCommand = (cmd) => {
    setCmdMode(cmd);
    switch (cmd) {
      case "new":
        {
          setNewAcad(true);
          ShowAcadCrud(true);
        }
        break;
      case "edit":
        {
          setNewAcad(false);
          const acad = acadList.find((f) => f.AcadDepartmentId === selectedAcadId);
          if (acad) {
            setAcadModel({
              AcadDepartmentId: acad.AcadDepartmentId,
              Description: acad.Description,
            });
            setAcadTtitleCrud("Modify");
            ShowAcadCrud(true);
          }
        }
        break;
      case "cancel":
        {
          setNewAcad(false);
          setAcadModel({ AcadDepartmentId: "", Description: "" });
          ShowAcadCrud(false);
        }
        break;
      case "remove":
        {
          if (courseFilter.length > 0) {
            setDialog({
              mode: "e",
              title: "Not Possible",
              message: `There are still ${courseFilter.length} courses listed under this department.`,
            });
            return;
          }

          let model = {
            UserName: myAccount.UserName,
            RecordId: selectedAcadId,
          };

          fetch(`${g.Api.ApiRoute}/adminapi/RemoveAcadAsync`, {
            method: "POST",
            headers: am.Identity.GetAuthHeader(),
            body: JSON.stringify(model),
          })
            .then((r) => {
              if (r.ok) {
                return r.json();
              }
            })
            .then((j) => {
              if (j) {
                if (j.Succeeded) {
                  setDialog({
                    mode: "s",
                    title: "Success",
                    message: `Academic Department [${selectedAcadId}] removed.`,
                  });

                  let newList = acadList.filter((f) => f.AcadDepartmentId !== selectedAcadId);
                  if (newList.length) {
                    setSelectedAcadId(newList[0].AcadDepartmentId);
                    setAcadList(newList);
                  } else {
                    setCourseFilter(null);
                  }
                }
              } else {
                setDialog({ mode: "e", title: "Error", message: "Technical Error" });
              }
            })
            .catch((err) => {
              fe.CatchError(err, (msg) => {
                setDialog({ mode: "e", title: "Error", msg: msg });
              });
            });
        }
        break;
      case "save":
        {
          //check

          if (newAcad) {
            let acaExists = acadList.find(
              (f) =>
                f.AcadDepartmentId === acadModel.AcadDepartmentId ||
                f.Description.toLowerCase() === acadModel.Description.trim().toLowerCase()
            );

            if (acaExists) {
              setDialog({
                mode: "e",
                title: "Duplicate",
                message: "Academic record with Code or Description already exists.",
              });
              return;
            }
            if (
              acadModel.Description.trim().length < 2 ||
              acadModel.AcadDepartmentId.trim().length < 2
            ) {
              setDialog({
                mode: "e",
                title: "Required",
                message: "Academic Code and Description are required.",
              });
              return;
            }
          } else {
            let acaExists = acadList.find(
              (f) => f.Description.toLowerCase() === acadModel.Description.trim().toLowerCase()
            );

            if (acaExists) {
              if (
                acaExists.AcadDepartmentId === acadModel.AcadDepartmentId ||
                acaExists.Description.toLowerCase() === acadModel.Description.toLowerCase()
              ) {
                OnAcadCommand("cancel");
                return;
              }
            }
          }

          //edit ito
          console.log(acadModel);
          setDialog({ mode: "p", title: newAcad ? "ADD" : "Modify", message: "Saving Changes..." });

          let model = {
            UserName: myAccount.UserName,
            RecordId: acadModel.AcadDepartmentId,
            StringValue: acadModel.Description,
          };

          //return;

          fetch(`${g.Api.ApiRoute}/adminapi/${newAcad ? "AddAcadAsync" : "EditAcadAsync"}`, {
            method: "POST",
            headers: am.Identity.GetAuthHeader(),
            body: JSON.stringify(model),
          })
            .then((r) => {
              if (r.ok) {
                return r.json();
              }
            })
            .then((j) => {
              if (j) {
                if (j.Succeeded) {
                  setDialog({
                    mode: "s",
                    title: "Success",
                    message: j.Message,
                  });

                  if (newAcad) {
                    //insert to state and make it active
                    setAcadList([acadModel, ...acadList]);
                    setSelectedAcadId(acadModel.AcadDepartmentId);
                  } else {
                    //modify the state
                    //find the index and put it there
                    const idx = acadList.findIndex((i) => i.AcadDepartmentId === selectedAcadId);
                    if (idx >= 0) {
                      //remove it
                      let newList = acadList.filter((f) => f.AcadDepartmentId !== selectedAcadId);
                      //insert it
                      const newRecord = {
                        AcadDepartmentId: selectedAcadId,
                        Description: acadModel.Description,
                      };
                      newList.splice(idx, 0, newRecord);

                      setAcadList(newList);
                    }
                  }

                  OnAcadCommand("cancel");
                }
              } else {
                setDialog({ mode: "e", title: "Error", message: "Technical Error" });
              }
            })
            .catch((err) => {
              fe.CatchError(err, (msg) => {
                setDialog({ mode: "e", title: "Error", msg: msg });
              });
            });
        }
        break;
      default:
        break;
    }
  };

  const OnInputAcadModel = (e, field) => {
    switch (field) {
      case "AcadDepartmentId":
        setAcadModel({ ...acadModel, AcadDepartmentId: e.target.value.trim().toUpperCase() });
        break;
      case "Description":
        setAcadModel({ ...acadModel, Description: e.target.value });
        break;
      default:
        break;
    }
  };

  //#endregion :: Acad Operation

  //#region :: Courses
  const getInputValues = (tr) => {
    let inputs = tr.querySelectorAll("input[type='text']");
    let values = [];
    [...inputs].forEach((t) => {
      values.push(t.value);
    });

    return values;
  };
  const OnRowCommandCourse = (e, mode, course) => {
    //console.log(mode, course);
    const targ = e.target.closest("[data-parent]");

    switch (mode) {
      case "e": //edit
        toggleFormEntries(targ);
        break;
      case "r": //remove
        {
          console.log(course);
          let model = {
            CourseId: course.CourseId,
          };

          setDialog({
            mode: "q",
            title: "Confirm Deletion",
            message: `Please confirm deletion of course item:<br/>
                    ${course.Code} &mdash; ${course.Description}`,
            buttons: [
              { id: "cancel", caption: "cancel" },
              {
                id: "yes",
                caption: "Confirm",
                OnClick: () => {
                  setDialog({
                    mode: "u",
                    title: "Transmit Request",
                    message: "Removing course. Please wait...",
                  });

                  fetch(`${g.Api.ApiRoute}/adminapi/RemoveCourseAsync`, {
                    method: g.Fetch.POST,
                    headers: am.Identity.GetAuthHeader(),
                    body: JSON.stringify(model),
                  })
                    .then((r) => {
                      if (r.ok) {
                        return r.json();
                      } else {
                        fe.CatchResponse(r, (m) => {
                          setDialog({ mode: "e", title: "Error", message: m });
                        });
                      }
                    })
                    .then((j) => {
                      if (!j) return;
                      if (j.Succeeded) {
                        console.log(j);
                        setDialog({ mode: "cl" });

                        //remove from local state;
                        let newCourseList = courseList.filter((c) => c.CourseId !== model.CourseId);
                        newCourseList.sort(u.Data.SortListBy("Code"));
                        setCourseList(newCourseList);

                        //remove from filter
                        let newFilter = courseFilter.filter((c) => c.CourseId !== model.CourseId);
                        setCourseFilter(newFilter);
                      } else {
                        setDialog({
                          mode: "e",
                          title: "Error",
                          message: j.Message,
                        });
                      }
                    })
                    .catch((err) => {
                      fe.CatchError(err, (m) => {
                        setDialog({
                          mode: "e",
                          title: "Error Catched",
                          message: m,
                        });
                      });
                    });
                },
              },
            ],
          });
        }
        break;
      case "s": //save
        {
          let values = getInputValues(targ);
          let model = {
            CourseId: course.CourseId,
            Code: values[0],
            Description: values[1],
          };

          if (!u.Validator.CheckMinMax(model.Code, 2, 15)) {
            setDialog({
              mode: "e",
              title: "Required",
              message: "Course Code must be two or more characters",
            });
            return;
          }

          if (!u.Validator.CheckMinMax(model.Description, 2, 100)) {
            setDialog({
              mode: "e",
              title: "Required",
              message: "Course Description must be two or more characters",
            });
            return;
          }

          if (
            course.Code.trim().toLowerCase() === model.Code.trim().toLowerCase() &&
            course.Description.trim().toLowerCase() === model.Description.trim().toLowerCase()
          ) {
            toggleFormEntries(targ);
            return;
          }

          setDialog({
            mode: "u",
            title: "Transmit",
            message: "Updating course information...",
          });

          fetch(`${g.Api.ApiRoute}/adminapi/EditCourseAsync`, {
            method: g.Fetch.POST,
            headers: am.Identity.GetAuthHeader(),
            body: JSON.stringify(model),
          })
            .then((r) => {
              if (r.ok) return r.json();
            })
            .then((j) => {
              if (!j) return;
              if (j.Succeeded) {
                console.log(j);
                {
                  let toMod = courseList.find((c) => c.CourseId === model.CourseId);
                  if (toMod) {
                    toMod.Code = model.Code;
                    toMod.Description = model.Description;

                    let idx = courseList.findIndex((c) => c.CourseId === model.CourseId);

                    if (idx > -1) {
                      let newList = courseList.filter((c) => c.CourseId !== model.CourseId);
                      newList.splice(idx, 0, toMod);
                      setCourseList(newList);

                      toggleFormEntries(targ);
                    }
                  }
                }

                setDialog({
                  mode: "cl",
                });
              } else {
                setDialog({ mode: "e", title: "Error", message: j.Message });
              }
            })
            .catch((err) => {
              setDialog({ mode: "e", message: err });
            });
        }
        break;
      case "u": //undo
        toggleFormEntries(targ);
        break;
      default:
        break;
    }
  };

  const OnSaveNewCourse = () => {
    setDialog({
      mode: "u",
      title: "Transmit",
      message: "Saving new course under selected program...",
    });

    let model = {
      CourseId: 0,
      Code: _refs.refNewCourseCode.current.value.trim(),
      Description: _refs.refNewCourseDesc.current.value.trim(),
      AcadDepartmentId: selectedAcadId,
    };

    if (model.Code.trim().length < 2 || model.Description.trim().length < 2) {
      setDialog({ mode: "e", message: "Code and Description are required." });
      return;
    }

    var exists = courseFilter.filter(
      (c) => c.Description.toLowerCase() === model.Description.toLowerCase()
    );

    console.log(model, exists);

    if (exists.length) {
      setDialog({
        mode: "w",
        title: "not possible",
        message: "Course Description already exists under the selected academic program.",
      });
      return;
    }

    fetch(`${g.Api.ApiRoute}/adminapi/AddCourseAsync`, {
      method: g.Fetch.POST,
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(model),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        if (!j) return;

        if (j.Succeeded) {
          console.log(j);
          model.CourseId = j.ModelData.CourseId;
          setCourseList([...courseList, model]);
          setCourseFilter([...courseFilter, model]);

          _refs.refNewCourseCode.current.value = "";
          _refs.refNewCourseDesc.current.value = "";

          setDialog({ mode: "cl" });
        } else {
          setDialog({ mode: "e", title: "Error", message: j.Message });
        }
      })
      .catch((err) => {
        setDialog({ mode: "e", title: "Error", message: err });
      });
  };

  const OnSelectAcad = (e) => {
    setSelectedAcadId(e.target.value);
  };
  //#endregion :: Courses
  return (
    <>
      <div className="tb-pad-lg course-mgt">
        <SectionExpander
          props={{
            icon: ico.GraduationCap,
            title: "Course Management",
            titleSize: "2em",
            caption: "Select an academic department and manage courses",
          }}
        >
          <div ref={_refs.refAcaTop}>
            <InlineMessageBar props={{ options: msg }} />
          </div>
          <div>
            <InfoBar props={{ title: "Quick Info", caption: " QUICK INFO" }}>
              <p>
                Below are the courses that members of HIVE can choose under their About Education
                Info. Since there are other students who might have enrolled in different
                universities, they can dynamically add new course title during their entry under the
                My Account section of the web application.
              </p>
            </InfoBar>
          </div>
          <div>
            {acadList ? (
              <>
                <select name="acad" id="acad" value={selectedAcadId} onChange={OnSelectAcad}>
                  {acadList.map((a) => (
                    <option value={a.AcadDepartmentId} key={a.AcadDepartmentId}>
                      {a.AcadDepartmentId} &mdash; {a.Description}
                    </option>
                  ))}
                </select>
                <div className="a-right b-pad-md" ref={_refs.refAcadNewBtn}>
                  <ul className="toolbar">
                    <li>
                      <Tooltip title="Remove Academic Department" position="left-start">
                        <span className="font-xl hand" onClick={() => OnAcadCommand("remove")}>
                          {ico.Bin}
                        </span>
                      </Tooltip>
                    </li>
                    <li>
                      <span className="r-pad-xl"></span>
                    </li>
                    <li>
                      <Tooltip title="Edit Academic Description" position="left-start">
                        <span className="font-xl hand" onClick={() => OnAcadCommand("edit")}>
                          {ico.Pencil}
                        </span>
                      </Tooltip>
                    </li>
                    <li>
                      <span className="r-pad-xl"></span>
                    </li>
                    <li>
                      <Tooltip title="Add New Academic Department" position="left-start">
                        <span className="font-xl hand" onClick={() => OnAcadCommand("new")}>
                          {ico.PlusCircle}
                        </span>
                      </Tooltip>
                    </li>
                  </ul>
                </div>
                <hr />
                <div className="dn" ref={_refs.refNewAcadDiv}>
                  <SectionExpander
                    props={{
                      title: `${acadTitleCrud} Academic Department`,
                      icon: ico.Book,
                      titleSize: "1.5em",
                      hideControl: true,
                    }}
                  >
                    <div
                      className={
                        courseFilter
                          ? courseFilter.length > 0 && cmdMode === "edit"
                            ? "nt"
                            : ""
                          : ""
                      }
                    >
                      <input
                        type="text"
                        maxLength="8"
                        placeholder="Academic CODE"
                        value={acadModel.AcadDepartmentId}
                        onChange={(e) => OnInputAcadModel(e, "AcadDepartmentId")}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        maxLength="125"
                        className="long"
                        placeholder="Description"
                        value={acadModel.Description}
                        onChange={(e) => OnInputAcadModel(e, "Description")}
                      />
                    </div>
                    <div className="a-right">
                      <ul className="toolbar">
                        <li>
                          <Tooltip title="Commit Changes">
                            <span
                              className="font-xl r-pad-md hand"
                              onClick={() => OnAcadCommand("save")}
                            >
                              {ico.Save}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Cancel">
                            <span className="font-xl hand" onClick={() => OnAcadCommand("cancel")}>
                              {ico.Undo}
                            </span>
                          </Tooltip>
                        </li>
                      </ul>
                    </div>
                  </SectionExpander>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div ref={_refs.refCourseTop}>
            <div>
              <h3 className="font-bold">Course List</h3>
              <div>
                <div className="tb-pad-md">
                  <table className="course-list">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>Code</td>
                        <td>Description</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {courseFilter ? (
                        <>
                          {courseFilter.map((c, index) => (
                            <tr key={c.CourseId} data-parent={c.Code}>
                              <td>{index + 1}</td>
                              <td>
                                <span className="read">{c.Code}</span>{" "}
                                <span className="dn edit">
                                  <input type="text" className="short" defaultValue={c.Code} />
                                </span>
                              </td>
                              <td>
                                <span className="read">{c.Description}</span>
                                <span className="dn edit">
                                  <input
                                    type="text"
                                    className="long"
                                    defaultValue={c.Description}
                                  />
                                </span>
                              </td>
                              <td>
                                <span
                                  title="Edit"
                                  className="font-md tool-icon edit"
                                  onClick={(e) => OnRowCommandCourse(e, "e", c)}
                                >
                                  {ico.Pencil}
                                </span>
                                <span
                                  title="Remove"
                                  className="font-md tool-icon edit"
                                  onClick={(e) => OnRowCommandCourse(e, "r", c)}
                                >
                                  {ico.CloseCircle}
                                </span>

                                <span
                                  title="Cancel"
                                  className="font-lg modify tool-icon dn"
                                  onClick={(e) => OnRowCommandCourse(e, "u", c)}
                                >
                                  {ico.Undo}
                                </span>

                                <span
                                  title="Save"
                                  className="font-lg modify tool-icon dn"
                                  onClick={(e) => OnRowCommandCourse(e, "s", c)}
                                >
                                  {ico.Save}
                                </span>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td>NEW</td>
                            <td colSpan="3">
                              <input
                                ref={_refs.refNewCourseCode}
                                type="text"
                                name=""
                                id="txtCourseId"
                                className="short"
                                placeholder="Code"
                              />{" "}
                              <input
                                type="text"
                                className="long"
                                name=""
                                id="txtCourseDesc"
                                ref={_refs.refNewCourseDesc}
                                placeholder="Description"
                              />
                              <Tooltip title="Save Course">
                                <span className="font-xl hand" onClick={OnSaveNewCourse}>
                                  {" "}
                                  {ico.Save}
                                </span>
                              </Tooltip>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <div className="a-right">
                                <span
                                  className="hand"
                                  onClick={() =>
                                    scrollToElement(_refs.refAcaTop.current, {
                                      offset: -100,
                                      duration: 500,
                                    })
                                  }
                                >
                                  <Tooltip title="Back to Academic List">
                                    <span className="font-xl">{ico.ArrowCircleUp} </span>
                                  </Tooltip>
                                </span>
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </SectionExpander>
        <DialogBox Dialog={dialog} key={"CourseMgt"} />
      </div>
    </>
  );
};

export default CourseMgt;
