import React from "react";
import { Link } from "react-router-dom";
import useGlobalConstants from "../Globals/useGlobalConstants";
import useCommonUtil from "../Hooks/useCommonUtil";
import "./FooterBar.css";

const FooterBar = () => {
  const yearTo = new Date().getFullYear();
  const u = useCommonUtil();
  const g = useGlobalConstants();
  
  return (
    <>
      <div id="footerBar" className="footer flex v-center">
        <div className="flex-child">
          <div className="pad-xl">
            <div className="font-smaller b-pad-sm">
              <div>
                Copyright &copy; 2020 - {yearTo} {`${g.AppName.TRADE_MARK}`}
              </div>
              <div>All rights reserved.</div>
              <div>Built with React | .NET CORE</div>
            </div>
            <div className="font-sm">
              <Link
                to="/pages/terms"
                onClick={() => {
                  u.Ui.ScrollToY();
                }}
              >
                Terms and Conditions
              </Link>
              <span className="lr-pad-sm">|</span>
              <Link
                to="/pages/cookie"
                onClick={() => {
                  u.Ui.ScrollToY();
                }}
              >
                Cookie and Local Storage Policy
              </Link>
            </div>
          </div>
        </div>

        <div className="flex-child">
          <div className="pad-xl font-smaller color-gray">
            <div>Written and programmed by BSCS-ETEEAP SY 2019-2021</div>
            <div>CDL; MC; IGS; FNM</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(FooterBar);
