import React, { useEffect, useRef, useState } from "react";
import "./MyAccountLayout.css";
import { Link } from "react-router-dom";
import useReactIcons from "../../Hooks/useReactIcons";
import useCommonUtil from "../../Hooks/useCommonUtil";
import useAccountManager from "../../Globals/useAccountManager";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import { SectionExpander } from "../../Components/Widgets/SectionExpander";
import { usePageLocationService } from "../../Globals/usePageLocationService";
import FooterBar from "../../Components/FooterBar";

const MyAccountLayout = (props) => {
  const ico = useReactIcons();
  const u = useCommonUtil();
  const am = useAccountManager();
  const g = useGlobalConstants();
  const pg = usePageLocationService();
  const [isMobile, setIsMobile] = useState(false);

  const pages = {
    basicinfo: "basicinfo",
    addresslocation: "addresslocation",
    mycasereports: "mycasereports",
    security: "security",
    course: "course",
    job: "job",
    dataanalytics: "dataanalytics",
    adminothers: "adminothers",
    usersmigration: "usersmigration",
    alumniapproval: "alumniapproval",
  };

  const refPages = {
    basicinfo: useRef(),
    addresslocation: useRef(),
    mycasereports: useRef(),
    security: useRef(),
    course: useRef(),
    job: useRef(),
    dataanalytics: useRef(),
    adminothers: useRef(),
    alumniapproval: useRef(),
    usersmigration: useRef(),
  };

  const [user, setUser] = useState();

  useEffect(() => {
    if (!user) {
      setUser(am.GetMyAccount());
    } else {
      const page = pg.GetCurrentPage();
      //const isAdminOrDev = user.Token.Roles.includes(g.Roles.ADMIN) || user.Token.Roles.includes(g.Roles.DEV);

      switch (page) {
        case pages.addresslocation:
          u.Ui.makeMenuItemActive(refPages.addresslocation.current);
          break;
        case pages.basicinfo:
          u.Ui.makeMenuItemActive(refPages.basicinfo.current);
          break;
        case pages.mycasereports:
          u.Ui.makeMenuItemActive(refPages.mycasereports.current);
          break;
        case pages.course:
          u.Ui.makeMenuItemActive(refPages.course.current);
          break;
        case pages.dataanalytics:
          u.Ui.makeMenuItemActive(refPages.dataanalytics.current);
          break;
        case pages.job:
          u.Ui.makeMenuItemActive(refPages.job.current);
          break;
        case pages.security:
          u.Ui.makeMenuItemActive(refPages.security.current);
          break;
        case pages.adminothers:
          u.Ui.makeMenuItemActive(refPages.adminothers.current);
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [user]);

  const ActivateMenu = (e) => {
    e.stopPropagation();

    if (isMobile) {
      u.Ui.ScrollToY(200);
    }

    u.Ui.makeMenuItemActive(e.currentTarget);
  };
  const hive_menu = "hive_menu";
  const [menuHidden, setMenuHidden] = useState();

  useEffect(() => {
    var w = window.innerWidth;
    var h = window.innerHeight;
    //console.log(w, "x", h);
    let im = w <= g.Window.ResTreshold || h <= g.Window.ResTreshold;
    if (im) {
      setIsMobile(im);

      setMenuHidden({ collapsed: false });
      let ex = document.querySelector("#menuExpander");
      if (ex) {
        ex.classList.add("dn");
      }
      return;
    }

    let menu_state = u.Data.Cookies.GetValue(hive_menu);
    //console.log(menu_state);
    if (!menu_state) {
      //create the cookie
      u.Data.Cookies.StoreValue(hive_menu, { collapsed: false });
      setMenuHidden({ collapsed: false });
    } else {
      setMenuHidden(u.Data.Cookies.GetValue(hive_menu));
    }

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!menuHidden) return;

    let menu = document.getElementById("accountMenu");
    if (!menuHidden.collapsed) {
      if (menu) {
        let h = menu.querySelectorAll(".h");
        let chev = menu.querySelectorAll(".chev");
        let expCont = menu.querySelectorAll(".exp-cont");

        if (chev) {
          chev[0].classList.remove("display-none");
          chev[1].classList.add("display-none");
          chev[1].classList.remove("color-theme");
        }

        if (expCont) {
          expCont.forEach((el) => {
            el.classList.remove("display-none");
          });
        }

        menu.classList.remove("hide");
        setTimeout(() => {
          if (h) {
            h.forEach((el) => el.classList.remove("display-none"));
          }
        }, 250);
      }
    } else {
      if (menu) {
        let h = menu.querySelectorAll(".h");
        let chev = menu.querySelectorAll(".chev");
        let expCont = menu.querySelectorAll(".exp-cont");

        if (h) {
          h.forEach((el) => el.classList.add("display-none"));
        }

        if (chev) {
          chev[0].classList.add("display-none");
          chev[1].classList.remove("display-none");
          chev[1].classList.add("color-theme");
        }

        if (expCont) {
          expCont.forEach((el) => {
            el.classList.add("display-none");
          });
        }
        menu.classList.add("hide");
      }
    }

    // eslint-disable-next-line
  }, [menuHidden]);

  const HideMenu = (b) => {
    setMenuHidden({ collapsed: b });
    u.Data.Cookies.StoreValue(hive_menu, { collapsed: b });
  };

  return (
    <>
      {/* <MainNav /> */}
      <div className="my-body-container my-account">
        <div className="pad-lg myl">
          <h2>
            <Link to="/myaccount">My Account</Link>
          </h2>
        </div>
        <div className="flex">
          <div className="menu" id="accountMenu">
            <div
              id="menuExpander"
              className="chev a-right font-xl lr-pad-lg hand"
              onClick={() => HideMenu(true)}
            >
              {ico.ChevronLeft}
            </div>
            <div
              className="chev a-right font-xl lr-pad-lg hand display-none"
              onClick={() => HideMenu(false)}
            >
              {ico.ChevronRight}
            </div>
            <div className="pad-lg">
              <SectionExpander
                props={{
                  icon: ico.ContactCard,
                  title: `MY ACCOUNT`,
                }}
              >
                <ul>
                  <li ref={refPages.basicinfo} onClick={(e) => ActivateMenu(e)}>
                    <Link to="/myaccount/basicinfo">
                      <span className="r-pad-lg font-xl">{ico.BusinessCard}</span>
                      <span className="h">Basic Info</span>
                    </Link>
                  </li>
                  <li ref={refPages.addresslocation} onClick={(e) => ActivateMenu(e)}>
                    <span>
                      <Link to="/myaccount/addresslocation">
                        <span className="r-pad-lg font-xl">{ico.Map}</span>
                        <span className="h">Address / Location</span>
                      </Link>
                    </span>
                  </li>
                  <li ref={refPages.mycasereports} onClick={(e) => ActivateMenu(e)}>
                    <span>
                      <Link to="/myaccount/mycasereports">
                        <span className="r-pad-lg font-xl">{ico.Report}</span>
                        <span className="h">My Case Reports</span>
                      </Link>
                    </span>
                  </li>

                  <li>
                    <hr />
                  </li>
                  <li ref={refPages.security} onClick={(e) => ActivateMenu(e)}>
                    <Link to="/myaccount/security">
                      <span className="r-pad-lg font-xl">{ico.PadLock}</span>
                      <span className="h">Security Settings</span>
                    </Link>
                  </li>
                </ul>
              </SectionExpander>
              {user ? (
                <>
                  {user.Token.Roles.includes(g.Roles.ADMIN) ||
                  user.Token.Roles.includes(g.Roles.DEV) ||
                  user.Token.Roles.includes(g.Roles.MOD) ? (
                    <SectionExpander
                      props={{
                        icon: ico.Shield2l,
                        title: `ADMINISTRATIVE`,
                      }}
                    >
                      <ul>
                        {!user.Token.Roles.includes(g.Roles.MOD) ? (
                          <>
                            <li ref={refPages.course} onClick={(e) => ActivateMenu(e)}>
                              <Link to="/admin/course">
                                <span className="r-pad-lg font-xl">{ico.GraduationCap}</span>
                                <span className="h">Course Management</span>
                              </Link>
                            </li>
                            <li ref={refPages.job} onClick={(e) => ActivateMenu(e)}>
                              <Link to="/admin/job">
                                <span className="r-pad-lg font-xl">{ico.BriefCase}</span>
                                <span className="h">Job Management</span>
                              </Link>
                            </li>
                          </>
                        ) : (
                          <></>
                        )}
                        <li>
                          <hr />
                        </li>
                        <li
                          ref={refPages.dataanalytics}
                          className="li-dataanalytics"
                          onClick={(e) => ActivateMenu(e)}
                        >
                          <Link to="/admin/dataanalytics">
                            <span className="r-pad-lg font-xl">{ico.GraphUp}</span>
                            <span className="h">Data Analytics</span>
                          </Link>
                        </li>
                        <li>
                          <hr />
                        </li>
                        {!user.Token.Roles.includes(g.Roles.MOD) ? (
                          <>
                            {/* <li ref={refPages.alumniapproval} onClick={(e) => ActivateMenu(e)}>
                              <Link to="/admin/alumniapproval">
                                <span className="r-pad-lg font-xl">{ico.CodeBox}</span>
                                <span className="h">Alumni Approval</span>
                              </Link>
                            </li> */}

                            <li ref={refPages.usersmigration} onClick={(e) => ActivateMenu(e)}>
                              <Link to="/admin/usersmigration">
                                <span className="r-pad-lg font-xl">{ico.Users}</span>
                                <span className="h">Users Migration</span>
                              </Link>
                            </li>

                            <li ref={refPages.adminothers} onClick={(e) => ActivateMenu(e)}>
                              <Link to="/admin/adminothers">
                                <span className="r-pad-lg font-xl">{ico.Shield2l}</span>
                                <span className="h">Other Tasks</span>
                              </Link>
                            </li>
                          </>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </SectionExpander>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="auto-width full-width">
            <div className="l-pad-lg">{props.children}</div>
          </div>
        </div>
      </div>
      <FooterBar />
    </>
  );
};

export default MyAccountLayout;
