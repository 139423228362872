import React, { useContext, useEffect, useRef, useState } from "react";
import InlineMessageBar from "../../Components/Widgets/InlineMessageBar";
import { SectionExpander } from "../../Components/Widgets/SectionExpander";
import useAccountManager from "../../Globals/useAccountManager";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import useReactIcons from "../../Hooks/useReactIcons";
import { Tooltip } from "react-tippy";
import scrollToElement from "scroll-to-element";
import "react-tippy/dist/tippy.css";

import "./AboutMe.css";
import useDataModels from "../../Globals/useDataModels";
import { useDataManager } from "../../Globals/useDataManager";
import useAsyncState from "../../Hooks/useCustomExtensions";
import useCommonUtil from "../../Hooks/useCommonUtil";
import useFetchErrorHandler from "../../Globals/useFetchErrorHandler";
import DialogBox from "../../Components/Widgets/DialogBox";
import { EducJobTriggerContext } from "../../AppContexts";

const AboutMe = () => {
  const am = useAccountManager();
  const u = useCommonUtil();
  const ico = useReactIcons();
  const dm = useDataModels();
  const dmgr = useDataManager();
  const g = useGlobalConstants();
  const fe = useFetchErrorHandler();
  const { SetTriggerEducJob } = useContext(EducJobTriggerContext);

  const [myAccount, setMyAccount] = useState(am.GetMyAccount());
  const [T, setTargetUser] = useState();
  const [dialog, setDialog] = useState();

  const [stateMonths, setMonths] = useState([]);
  const [stateYears, setYears] = useState([]);

  const [stateExpired, setStateExpired] = useState(true);

  const [stateCountries, setCountries] = useState();
  const [stateCities, setCities] = useState();
  const [stateJobIndustries, setJobIndustries] = useState();
  const [stateJobTitles, setJobTitles] = useState();
  const [stateCompanies, setCompanies] = useState();
  const [stateUnis, setUnis] = useState();
  const [stateHobbyList, setHobbyList] = useState();

  const [stateMyJobs, setMyJobs] = useState();

  const [stateMsg, setMsg] = useState({
    mode: "p",
    message: "Loading resources...",
  });

  const DISPLAY_NONE = "display-none";
  const NO_TOUCH = "no-touch";
  const DEFAULT_VAL = "xxx";

  //#region :: Global
  useEffect(() => {
    am.ValidateAccount(() => {
      setStateExpired(false); //we have a valid token
      am.Identity.GetTargetUser((usr) => {
        setTargetUser(usr);
      });
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (T) {
      am.GetMyEducations(T.UserName, (educs) => {
        setMyEducList(educs);
      });

      am.GetMyJobs(T.UserName, (jobs) => {
        setMyJobs(jobs);
      });

      am.GetMyHobbies(T.UserName, (hobs) => {
        setMyHobbyList(hobs);
      });
    }
    // eslint-disable-next-line
  }, [T]);

  //[stateExpired]
  useEffect(() => {
    if (stateExpired) return;

    let years = [];
    let currentYear = new Date().getFullYear();

    setMonths(dm.Months);

    for (let i = 1960; i <= currentYear; i++) {
      years.push(i);
    }

    setYears(years);

    if (!stateCourseList) {
      dmgr.GetCourses((model) => {
        setCourseList(model.ModelData);
      });
    }

    if (!stateUnis) {
      dmgr.GetUniversities((model) => {
        setUnis(model.ModelData);
      });
    }

    if (!stateCountries) {
      dmgr.GetCountries((model) => {
        setCountries(model.ModelData);
      });
    }

    if (!stateJobIndustries) {
      dmgr.GetJobIndustry((model) => {
        setJobIndustries(model.ModelData);
      });
    }

    if (!stateCompanies) {
      dmgr.GetCompanies((model) => {
        setCompanies(model.ModelData);
      });
    }

    if (!stateCities) {
      dmgr.GetCities("PHL", (model) => {
        setCities(model.ModelData);
      });
    }

    if (!stateHobbyList) {
      dmgr.GetHobbyList((model) => {
        setHobbyList(model.ModelData);
      });
    }

    return () => {
      // to do
    };

    // eslint-disable-next-line
  }, [stateExpired]);

  useEffect(() => {
    if (stateCountries && stateJobIndustries && stateCompanies && stateUnis && stateHobbyList) {
      setMsg({ ...stateMsg, tl: 250 });
    }

    // eslint-disable-next-line
  }, [stateCountries, stateJobIndustries, stateCompanies, stateUnis, stateHobbyList]);

  //#endregion

  //=================================
  //#region :: Education

  const initEducModel = JSON.stringify({
    AppUserSchoolId: "0",
    UserName: myAccount ? myAccount.UserName : "",
    EntryBy: myAccount ? myAccount.UserName : "",
    CountryCode: "PHL",
    CityId: "0",
    CourseId: "0",
    CourseCode: "",
    AcadDepartmentId : "",
    CourseTitle: "",
    SchoolId: "0",
    SchoolName: "",
    TitleRecognition: "",
    FromMonth: "0",
    FromMonthDesc: "",
    FromYear: 2000,
    ToMonth: "0",
    ToMonthDesc: "",
    ToYear: 2001,
    IsCurrentlyEnrolled: 0,
  });

  const [stateMyEducList, setMyEducList] = useState();

  const [stateEducMsg, setEducMsg] = useState({
    mode: "i",
    message: "Fill the form out above as completely as possible.",
  });
  const [userEducModel, setUserEducModel] = useState(JSON.parse(initEducModel));
  const [stateCourseList, setCourseList] = useState();

  const [selectedCountry, setSelectedCountry] = useState();

  const educRef = {
    refCourseManual: useRef(),
    refUniManual: useRef(),
    refToYear: useRef(),
    refToAlign: useRef(),
    refSubmit: useRef(),

    //educ
    refAddEduc: useRef(),
    refEducForm: useRef(),
  };

  const GetCities = (ccode) => {
    dmgr.GetCities(ccode, (model) => {
      setCities(model.ModelData);
    });
  };
  useEffect(() => {
    //console.log(selectedCountry);
    if (selectedCountry) {
      GetCities(selectedCountry);
    }

    // eslint-disable-next-line
  }, [selectedCountry]);

  const OnChangeEducModel = (e, el) => {
    switch (el) {
      case "CourseId":
        let course = stateCourseList.find(
          (f) => u.Number.ParseIntBase10(f.CourseId) === u.Number.ParseIntBase10(e.target.value)
        );
        setUserEducModel({
          ...userEducModel,
          CourseId: e.target.value,
          CourseCode: course ? course.Code : "",
          CourseTitle: "",
          AcadDepartmentId : course.AcadDepartmentId
        });

        if (course) {
          educRef.refCourseManual.current.classList.add(DISPLAY_NONE);
        } else {
          educRef.refCourseManual.current.classList.remove(DISPLAY_NONE);
        }
        break;
      case "CourseTitle":
        let acro = u.Data.GetAcronym2(e.target.value);
        setUserEducModel({
          ...userEducModel,
          CourseId: "0",
          CourseTitle: e.target.value,
          CourseCode: acro,
        });
        break;
      case "CourseCode":
        setUserEducModel({ ...userEducModel, CourseCode: e.target.value });
        break;
      case "SchoolId":
        let uni = stateUnis.find(
          (f) => u.Number.ParseIntBase10(f.SchoolId) === u.Number.ParseIntBase10(e.target.value)
        );
        if (uni) {
          setSelectedCountry(uni.CountryCode);

          setUserEducModel({
            ...userEducModel,
            SchoolId: e.target.value,
            SchoolName: "",
            CountryCode: uni.CountryCode,
            CityId: uni.CityId,
          });
          educRef.refUniManual.current.classList.add(DISPLAY_NONE);
        } else {
          setUserEducModel({
            ...userEducModel,
            SchoolId: e.target.value,
            CityId: "0",
          });
          educRef.refUniManual.current.classList.remove(DISPLAY_NONE);
        }
        break;
      case "SchoolName":
        setUserEducModel({ ...userEducModel, SchoolName: e.target.value });
        break;
      case "CountryCode":
        //if (e.target.value)
        setUserEducModel({ ...userEducModel, CountryCode: e.target.value });
        dmgr.GetCities(e.target.value, (model) => {
          setCities(model.ModelData);
        });
        //setUserEducModel({ ...userEducModel, CountryCode: e.target.value });
        break;
      case "CityId":
        setUserEducModel({ ...userEducModel, CityId: e.target.value });
        break;
      case "TitleRecognition":
        setUserEducModel({
          ...userEducModel,
          TitleRecognition: e.target.value,
        });
        break;
      case "FromMonth":
        setUserEducModel({ ...userEducModel, FromMonth: e.target.value });
        break;
      case "FromYear":
        setUserEducModel({ ...userEducModel, FromYear: e.target.value });
        break;
      case "ToMonth":
        setUserEducModel({ ...userEducModel, ToMonth: e.target.value });
        break;
      case "ToYear":
        setUserEducModel({ ...userEducModel, ToYear: e.target.value });
        break;
      case "IsCurrentlyEnrolled":
        setUserEducModel({
          ...userEducModel,
          IsCurrentlyEnrolled: e.target.checked ? 1 : 0,
        });
        if (e.target.checked) {
          educRef.refToYear.current.classList.add(DISPLAY_NONE);
          educRef.refToAlign.current.classList.add("v-center");
        } else {
          educRef.refToYear.current.classList.remove(DISPLAY_NONE);
          educRef.refToAlign.current.classList.remove("v-center");
        }
        break;
      default:
        break;
    }
  };

  const ValidateEducEntry = () => {
    let model = userEducModel;
    if (model.CourseId === "0" && model.CourseTitle.trim().length === 0) {
      setEducMsg({
        mode: "e",
        message: "Please select a course or type one if it is not listed.",
      });
      return;
    }

    {
      let exists = stateCourseList.findIndex(
        (c) =>
          c.Code.toLowerCase() === model.CourseCode.toLowerCase() &&
          c.Description.toLowerCase() === model.CourseTitle.toLowerCase()
      );
      if (exists >= 0) {
        setEducMsg({
          mode: "e",
          message: "The Course title already exists in the list. Please select it.",
        });
        return;
      }
    }

    //school name
    {
      if (model.SchoolName.trim().length === 0 && model.SchoolId === "0") {
        setEducMsg({
          mode: "e",
          message: "Please select a school/university name or type one if it is not listed.",
        });
        return;
      }

      let uniName = model.SchoolName.toLowerCase().trim();

      if (uniName.length > 0) {
        let exists = stateUnis.findIndex((f) => f.Name.toLowerCase() === uniName);
        if (exists >= 0) {
          setEducMsg({
            mode: "e",
            message: "The school or university name is already in the list. Please select it.",
          });
          return;
        }
      }
    }

    //Country and City

    if (model.CountryCode === DEFAULT_VAL) {
      setEducMsg({
        mode: "e",
        message: "Please select Country/Location where the school is located.",
      });
      return;
    }

    if (model.CityId === "0") {
      setEducMsg({
        mode: "e",
        message: "Please select City/location where the school is located.",
      });
      return;
    }

    //dates
    let descFrom = dm.Months.find(
      (d) => u.Number.ParseIntBase10(d.Id) === u.Number.ParseIntBase10(model.FromMonth)
    );

    if (descFrom) {
      model.FromMonthDesc = descFrom.Name;
    }

    let descTo = dm.Months.find(
      (d) => u.Number.ParseIntBase10(d.Id) === u.Number.ParseIntBase10(model.ToMonth)
    );

    if (descTo) {
      model.ToMonthDesc = descTo.Name;
    }

    if (!model.IsCurrentlyEnrolled) {
      let fromDate = new Date(model.FromYear, model.FromMonth);
      let toDate = new Date(model.ToYear, model.ToMonth);
      if (fromDate > toDate) {
        setEducMsg({
          mode: "e",
          message: "School year end date must be later than the start date.",
        });

        return;
      }
    } else {
      let toDate = new Date();
      model.ToYear = toDate.getFullYear();
      model.ToMonth = toDate.getMonth() + 1;
    }

    //no more errors found
    return model;
  };

  const DisableSubmitEduc = (b) => {
    if (b) {
      educRef.refSubmit.current.classList.add(NO_TOUCH);
    } else {
      educRef.refSubmit.current.classList.remove(NO_TOUCH);
    }
  };

  const PersistEduc = (myEducList) => {
    if (myEducList) {
      console.log(myEducList);

      let educ = { CourseCode: "", SchoolName: "", AcadDepartmentId : "" };
      let school;
      if (myEducList.length === 1) {
        school = myEducList[0];
      } else if (myEducList.length > 1) {
        let sortedList = myEducList.sort(u.Data.SortListBy("ToYear", true));
        school = sortedList[0];
      }

      if (school) {
        educ.CourseCode = school.CourseCode;
        educ.SchoolName = school.SchoolName;
        educ.AcadDepartmentId = userEducModel.AcadDepartmentId;
      }

      let _maccount = myAccount;
      _maccount.LastCourseAttended = educ;
      setMyAccount({ ..._maccount, LastCourseAttended: educ });
      SetTriggerEducJob(educ);
      am.StoreMyAccount(_maccount);
    }
  };

  const OnAddEducation = () => {
    educRef.refEducForm.current.classList.toggle(DISPLAY_NONE);
    educRef.refAddEduc.current.classList.toggle(DISPLAY_NONE);
    setUserEducModel(JSON.parse(initEducModel));
  };

  const OnSaveEducation = () => {
    let vmodel = ValidateEducEntry();
    console.log(vmodel);
    if (vmodel) {
      DisableSubmitEduc(true);
      setEducMsg({
        mode: "p",
        message: "Submitting education record...",
      });

      fetch(`${g.Api.ApiRoute}/UserInfoApi/AddEducationAsync`, {
        method: "POST",
        headers: am.Identity.GetAuthHeader(),
        body: JSON.stringify(vmodel),
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            fe.CatchResponse(r, (m) => {
              setEducMsg({
                mode: "e",
                message: m,
              });
              DisableSubmitEduc();
            });
          }
        })
        .then((j) => {
          DisableSubmitEduc();
          if (!j) {
            setEducMsg({
              mode: "e",
              message: "Oops. Something went wrong.",
            });
          } else {
            if (j.Succeeded) {
              if (stateMyEducList) {
                let newList = stateMyEducList;
                newList.push(j.ModelData);
                newList.sort(u.Data.SortListBy("ToYear", true));
                setMyEducList(newList);
                PersistEduc(newList);
              } else {
                let newList = [j.ModelData];
                setMyEducList(newList);
                PersistEduc(newList);
              }

              //setSelectedCountry("PHL");
              GetCities("PHL");
              setUserEducModel(JSON.parse(initEducModel));

              setEducMsg({
                mode: "s",
                message: "Education record submitted.",
              });
            } else {
              setEducMsg({
                mode: "e",
                message: `Oops. Something went wrong. ${j.Message}`,
              });
            }
          }
        })
        .catch((err) => {
          DisableSubmitEduc();
          fe.CatchError(err, (m) => {
            setEducMsg({
              mode: "s",
              message: m,
            });
          });
        });
    }
  };

  const OnRemoveEducation = (educ) => {
    setDialog({
      mode: "q",
      title: "Remove",
      message: `Are you sure you need to remove this education record?<br/><br/>
        ${educ.CourseTitle.toUpperCase()}<br/>
        ${educ.SchoolName}<br/>
        ${educ.CountryName}, ${educ.CityName}<br/>
        ${educ.FromMonthDesc} ${educ.FromYear} &mdash; ${educ.ToMonthDesc} ${educ.ToYear}`,
      buttons: [
        { id: "cancel", caption: "NO" },
        {
          id: "yes",
          caption: "YES",
          OnClick: () => {
            let model = {
              AppUserSchoolId: educ.AppUserSchoolId,
              UserName: myAccount.UserName,
            };
            fetch(`${g.Api.ApiRoute}/UserInfoApi/RemoveUserEducAsync`, {
              method: "POST",
              headers: am.Identity.GetAuthHeader(),
              body: JSON.stringify(model),
            })
              .then((r) => {
                if (r.ok) {
                  return r.json();
                } else {
                  fe.CatchResponse(r);
                }
              })
              .then((j) => {
                if (!j) {
                  setDialog({ mode: "e", message: "Something went wrong." });
                  return;
                }

                if (j.Succeeded) {
                  let newList = stateMyEducList.filter(
                    (f) => f.AppUserSchoolId !== model.AppUserSchoolId
                  );

                  setMyEducList(newList);
                  PersistEduc(newList);
                } else {
                  setDialog({ mode: "e", message: j.Message });
                }
              })
              .catch((err) => {
                fe.CatchError(err, (m) => {
                  setDialog({ mode: "e", message: m });
                });
              });
          },
        },
      ],
    });
  };

  //#endregion

  //=================================
  //#region  :: JOB / PROFESSION
  const initUserJobModel = JSON.stringify({
    CompanyId: 0,
    CompanyName: "",
    CountryCode: DEFAULT_VAL,
    FromMonth: "0",
    FromMonthDesc: "",
    FromYear: "2000",
    IsPresentJob: 0,
    JobId: DEFAULT_VAL,
    JobIndustryId: DEFAULT_VAL,
    JobTitle: "",
    ToMonth: "0",
    ToMonthDesc: "",
    ToYear: "2001",
    UserName: myAccount ? myAccount.UserName : "",
  });
  const [stateUserJobModel, setUserJobModel] = useAsyncState(JSON.parse(initUserJobModel));

  const [stateAddWorkMsg, setAddWorkMsg] = useState({
    mode: "i",
    message: "Fill the form out above as completely as possible.",
  });
  let refs = {
    refAddJob: useRef(),
    refBriefcaseAdd: useRef(),
    refCheckCurrentJob: useRef(),
    refJobForm: useRef(),
    refToAlign: useRef(),
    refToDate: useRef(),
    reftxtPosOrTitle: useRef(),
  };

  const OnClickAddJob = () => {
    refs.refAddJob.current.classList.toggle(DISPLAY_NONE);
    refs.refBriefcaseAdd.current.classList.toggle(DISPLAY_NONE);
  };

  const OnChangeJobModel = (e, el) => {
    switch (el) {
      case "JobIndustryId":
        let industry = stateJobIndustries.find((f) => f.JobIndustryId === e.target.value);
        if (industry) {
          setJobTitles(industry.Jobs);
          setUserJobModel({
            ...stateUserJobModel,
            JobIndustryId: e.target.value,
            UserName: myAccount.UserName,
          });
        } else {
          setJobTitles(null);
        }
        break;
      case "JobId":
        setUserJobModel({
          ...stateUserJobModel,
          JobId: e.target.value,
        });
        break;
      case "JobTitle":
        setUserJobModel({
          ...stateUserJobModel,
          JobTitle: e.target.value,
        });
        break;
      case "CompanyId":
        setUserJobModel({
          ...stateUserJobModel,
          CompanyId: e.target.value,
          CompanyName: "",
        });
        break;
      case "CompanyName":
        setUserJobModel({
          ...stateUserJobModel,
          CompanyName: e.target.value,
        });
        break;
      case "CountryCode":
        setUserJobModel({
          ...stateUserJobModel,
          CountryCode: e.target.value,
        });
        break;
      case "FromMonth":
        setUserJobModel({
          ...stateUserJobModel,
          FromMonth: e.target.value,
        });
        break;
      case "FromYear":
        setUserJobModel({
          ...stateUserJobModel,
          FromYear: e.target.value,
        });
        break;
      case "ToMonth":
        setUserJobModel({
          ...stateUserJobModel,
          ToMonth: e.target.value,
        });
        break;
      case "ToYear":
        setUserJobModel({
          ...stateUserJobModel,
          ToYear: e.target.value,
        });
        break;
      case "IsPresentJob":
        setUserJobModel({
          ...stateUserJobModel,
          IsPresentJob: e.target.checked ? 1 : 0,
        });

        refs.refToDate.current.classList.toggle(DISPLAY_NONE);
        refs.refToAlign.current.classList.toggle("v-center");
        break;
      default:
        break;
    }
  };

  const OnCancelAddJob = () => {
    OnClickAddJob();
    setUserJobModel(JSON.parse(initUserJobModel));
    setAddWorkMsg({
      mode: "i",
      message: "Fill-out the form above as completely as possible.",
    });
  };

  const ValidateJobEntry = (model) => {
    if (model.JobIndustryId === DEFAULT_VAL) {
      setAddWorkMsg({ mode: "e", message: "Job Industry is required." });
      return;
    }

    if (model.JobId === DEFAULT_VAL) {
      setAddWorkMsg({ mode: "e", message: "Job Category is required." });
      return;
    }

    if (u.Number.ParseIntBase10(model.CompanyId) === 0 && model.CompanyName.trim().length === 0) {
      setAddWorkMsg({
        mode: "e",
        message: "Please select a company name. If it is not listed, type it manually.",
      });
      return;
    }

    if (model.CountryCode === DEFAULT_VAL) {
      setAddWorkMsg({ mode: "e", message: "Company location is required." });
      return;
    }

    if (model.CompanyName.trim().length !== 0) {
      //if (model.CompanyName.trim().toLowerCase() === )
      let exist = stateCompanies.findIndex(
        (f) => f.CompanyName.toLowerCase() === model.CompanyName.trim().toLowerCase()
      );
      if (exist >= 0) {
        setAddWorkMsg({
          mode: "e",
          message: "The company name you manually specified already exists in the list.",
        });
        return;
      }

      if (!u.Validator.CheckMinMax(model.CompanyName, 3, 100)) {
        setAddWorkMsg({
          mode: "e",
          message:
            "If specifying company name, it should be three characters or more. If possible, not an acronym.",
        });
        return;
      }
    }

    if (!model.IsPresentJob) {
      let fromDate = new Date(model.FromYear, model.FromMonth);
      let toDate = new Date(model.ToYear, model.ToMonth);
      if (fromDate > toDate) {
        setAddWorkMsg({
          mode: "e",
          message: "Job end date must be later than the start date.",
        });

        return;
      }
    }

    let descFrom = dm.Months.find(
      (d) => u.Number.ParseIntBase10(d.Id) === u.Number.ParseIntBase10(model.FromMonth)
    );

    if (descFrom) {
      model.FromMonthDesc = descFrom.Name;
    }

    let descTo = dm.Months.find(
      (d) => u.Number.ParseIntBase10(d.Id) === u.Number.ParseIntBase10(model.ToMonth)
    );

    if (descTo) {
      model.ToMonthDesc = descTo.Name;
    }

    return true;
  };
  const OnAddNewJob = () => {
    let form = refs.refJobForm.current;
    //validate
    let model = stateUserJobModel;
    let check = ValidateJobEntry(model);

    if (!check) return;

    //console.log(model);
    setAddWorkMsg({
      mode: "u",
      message: "Submitting job info...",
    });

    form.classList.add(NO_TOUCH);

    fetch(`${g.Api.ApiRoute}/UserInfoApi/AddJobAsync`, {
      method: "POST",
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(model),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          fe.CatchResponse(r, (m) => {
            setAddWorkMsg({ mode: "e", message: m });
          });
          form.classList.remove(NO_TOUCH);
          return null;
        }
      })
      .then((j) => {
        if (!j) {
          //setAddWorkMsg({ mode: "e", message: "Something went wrong." });
        } else {
          if (j.Succeeded) {
            console.log(j);
            setAddWorkMsg({ mode: "s", message: j.Message });
            setUserJobModel(JSON.parse(initUserJobModel));

            setMyJobs([j.ModelData, ...stateMyJobs]);
          } else {
            setAddWorkMsg({ mode: "e", message: j.Message });
          }
        }

        form.classList.remove(NO_TOUCH);
      })
      .catch((err) => {
        fe.CatchError(err, (m) => {
          form.classList.remove(NO_TOUCH);
          setAddWorkMsg({ mode: "e", message: m });
        });
      });
  };
  const OnRemoveJob = (job) => {
    setDialog({
      mode: "q",
      title: "Remove",
      message: `Are you sure you need to remove this job?<br/><br/>
        ${job.JobTitle !== "NA" ? job.JobTitle.toUpperCase() : job.JobCategory.toUpperCase()}<br/>
        ${job.CompanyName}<br/>
        ${job.CountryName}<br/>
        ${job.FromMonthDesc} ${job.FromYear} &mdash; ${job.ToMonthDesc} ${job.ToYear}<br/>`,
      buttons: [
        { id: "cancel", caption: "NO" },
        {
          id: "yes",
          caption: "YES",
          OnClick: () => {
            let model = {
              AppUserJobId: job.AppUserJobId,
            };
            fetch(`${g.Api.ApiRoute}/UserInfoApi/RemoveUserJobAsync`, {
              method: "POST",
              headers: am.Identity.GetAuthHeader(),
              body: JSON.stringify(model),
            })
              .then((r) => {
                if (r.ok) {
                  return r.json();
                } else {
                  fe.CatchResponse(r);
                }
              })
              .then((j) => {
                if (!j) {
                  setDialog({ mode: "e", message: "Something went wrong." });
                  return;
                }

                if (j.Succeeded) {
                  let newList = stateMyJobs.filter((f) => f.AppUserJobId !== model.AppUserJobId);
                  setMyJobs(newList);
                } else {
                  setDialog({ mode: "e", message: j.Message });
                }
              })
              .catch((err) => {
                fe.CatchError(err, (m) => {
                  setDialog({ mode: "e", message: m });
                });
              });
          },
        },
      ],
    });
  };
  //#endregion

  //=================================
  //#region :: Hobbies/Interests
  const initHobbyModel = JSON.stringify({
    AppUserHobbyId: "0",
    HobbyInterestId: "0",
    UserName: myAccount ? myAccount.UserName : "",
    Description: "",
    Details: "",
  });

  const [stateHobbyMsg, setHobbyMsg] = useState({
    mode: "i",
    message: "Ready",
  });
  const [stateHobbyModel, setHobbyModel] = useState(JSON.parse(initHobbyModel));
  const [stateMyHobbyList, setMyHobbyList] = useState();
  const [stateHobbyCmd, setHobbyCmd] = useState("add");
  const refHobby = {
    refHobbyForm: useRef(),
    refAddHobby: useRef(),
    refNewDesc: useRef(),

    refHobbySection: useRef(),
    refHobbyDropDown: useRef(),
    refHobbList: useRef(),
  };

  const OnChangeHobbyModel = (e, action) => {
    switch (action) {
      case "HobbyInterestId":
        if (e.target.value !== "0") {
          refHobby.refNewDesc.current.classList.add(DISPLAY_NONE);
        } else {
          refHobby.refNewDesc.current.classList.remove(DISPLAY_NONE);
        }
        setHobbyModel({
          ...stateHobbyModel,
          HobbyInterestId: e.target.value,
          Description: "",
        });
        break;
      case "Description":
        setHobbyModel({
          ...stateHobbyModel,
          Description: e.target.value,
          AppUserHobbyId: "0",
        });
        break;
      case "Details":
        setHobbyModel({
          ...stateHobbyModel,
          Details: e.target.value,
        });
        break;
      default:
        break;
    }
  };
  const OnHobbyEntry = () => {
    scrollToElement(refHobby.refHobbySection.current, {
      offset: -200,
      duration: 500,
    });
    refHobby.refHobbyForm.current.classList.toggle(DISPLAY_NONE);
    refHobby.refAddHobby.current.classList.toggle(DISPLAY_NONE);
    refHobby.refHobbList.current.classList.toggle(NO_TOUCH);
  };

  const VerifyHobbyEntry = () => {
    let model = stateHobbyModel;

    if (model.Description.trim().length > 0) {
      if (!u.Validator.CheckMinMax(model.Description, 5, 100)) {
        setHobbyMsg({
          mode: "e",
          message: "If providing new description, it must be five or more characters.",
        });
        return;
      }
    } else {
      if (model.HobbyInterestId === "0") {
        setHobbyMsg({
          mode: "e",
          message:
            "Please select a hobby or interest from the list. Or you can provide one if it is not listed.",
        });
        return;
      }
    }

    if (model.Details.trim().length > 0) {
      if (!u.Validator.CheckMinMax(model.Details, 5, 100)) {
        setHobbyMsg({
          mode: "e",
          message: "If providing details, it must be five or more characters long.",
        });
        return;
      }
    }

    if (stateMyHobbyList) {
      let exist = stateMyHobbyList.find(
        (f) => parseInt(f.HobbyInterestId, 10) === parseInt(model.HobbyInterestId, 10)
      );

      if (exist) {
        setHobbyMsg({
          mode: "e",
          message: "That hobby already exists in your list.",
        });
        return;
      }
    }

    return model;
  };

  const OnHobbyCommand = (h, action) => {
    switch (action) {
      case "cancel":
        setHobbyModel(JSON.parse(initHobbyModel));
        refHobby.refHobbyForm.current.classList.toggle(DISPLAY_NONE);
        refHobby.refAddHobby.current.classList.toggle(DISPLAY_NONE);

        refHobby.refNewDesc.current.classList.remove(DISPLAY_NONE);
        refHobby.refHobbList.current.classList.remove(NO_TOUCH);
        refHobby.refHobbyDropDown.current.classList.remove(NO_TOUCH);

        break;
      case "add":
        OnHobbyEntry();
        setHobbyCmd("add");
        refHobby.refNewDesc.current.classList.remove(DISPLAY_NONE);
        break;
      case "edit":
        refHobby.refNewDesc.current.classList.add(DISPLAY_NONE);
        refHobby.refHobbyDropDown.current.classList.add(NO_TOUCH);

        {
          let model = {
            AppUserHobbyId: h.AppUserHobbyId,
            HobbyInterestId: h.HobbyInterestId,
            UserName: h.UserName,
            Description: "",
            Details: h.Details,
          };
          setHobbyModel(model);

          OnHobbyEntry();
          setHobbyCmd("edit");
          console.log(h);
        }
        break;
      case "save":
        {
          let model = VerifyHobbyEntry();
          if (model) {
            console.log(model);
            setHobbyMsg({
              mode: "u",
              message: "Saving record. Please wait...",
            });

            fetch(
              `${g.Api.ApiRoute}/UserInfoApi/${
                stateHobbyCmd === "add" ? "AddUserHobbyAsync" : "EditUserHobbyAsync"
              }`,
              {
                method: "POST",
                headers: am.Identity.GetAuthHeader(),
                body: JSON.stringify(model),
              }
            )
              .then((r) => {
                if (r.ok) {
                  return r.json();
                } else {
                  fe.CatchResponse(r, (m) => {
                    setHobbyMsg({ mode: "e", message: m });
                  });
                }
              })
              .then((j) => {
                if (!j) {
                  setHobbyMsg({
                    mode: "e",
                    message: "Oops. Something went wrong.",
                  });
                } else {
                  if (j.Succeeded) {
                    refHobby.refNewDesc.current.classList.remove(DISPLAY_NONE);

                    if (stateHobbyCmd === "edit") {
                      let hmaster = stateHobbyList.find(
                        (f) => f.HobbyInterestId === model.HobbyInterestId
                      );

                      let newList = stateMyHobbyList;
                      let idx = newList.findIndex((f) => f.AppUserHobbyId === model.AppUserHobbyId);
                      if (idx >= 0 && hmaster) {
                        model.Description = hmaster.Description;
                        newList.splice(idx, 1, model);
                        setMyHobbyList(newList);
                      } else {
                        setHobbyMsg({
                          mode: "e",
                          message: "Unable to locate state record.",
                        });
                      }
                    } else {
                      if (!stateMyHobbyList) {
                        setMyHobbyList([j.ModelData]);
                      } else {
                        setMyHobbyList([j.ModelData, ...stateMyHobbyList]);
                      }
                      setHobbyModel(JSON.parse(initHobbyModel));
                    }

                    setHobbyMsg({ mode: "s", message: j.Message });
                  } else {
                    setHobbyMsg({
                      mode: "e",
                      message: "Oops. Something went wrong.",
                    });
                  }
                }
              })
              .catch((err) => {
                fe.CatchError(err, (m) => {
                  setHobbyMsg({ mode: "e", message: m });
                });
              });
          }
        }
        break;
      case "remove":
        setDialog({
          mode: "q",
          message: `Are you sure you need to remove: <hr/>
            ${h.Description} <br/>
            ${h.Details}`,
          buttons: [
            { id: "cancel", caption: "Cancel" },
            {
              id: "yes",
              caption: "Yes",
              OnClick: () => {
                let model = {
                  AppUserHobbyId: h.AppUserHobbyId,
                };

                fetch(`${g.Api.ApiRoute}/UserInfoApi/RemoveUserHobbyAsync`, {
                  method: "POST",
                  headers: am.Identity.GetAuthHeader(),
                  body: JSON.stringify(model),
                })
                  .then((r) => {
                    if (r.ok) {
                      return r.json();
                    } else {
                      fe.CatchResponse(r, (m) => {
                        setHobbyMsg({ mode: "e", message: m });
                      });
                    }
                  })
                  .then((j) => {
                    if (!j) {
                      setHobbyMsg({
                        mode: "e",
                        message: "Ooops. Something went wrong.",
                      });
                    } else {
                      if (j.Succeeded) {
                        let newList = stateMyHobbyList.filter(
                          (f) => f.AppUserHobbyId !== model.AppUserHobbyId
                        );
                        setMyHobbyList(newList);
                      } else {
                        setHobbyMsg({
                          mode: "e",
                          message: "Ooops. Something went wrong.",
                        });
                      }
                    }
                  })
                  .catch((err) => {
                    fe.CatchError(err, (m) => {
                      setHobbyMsg({
                        mode: "e",
                        message: m,
                      });
                    });
                  });
              },
            },
          ],
        });
        break;
      default:
        break;
    }
  };
  //#endregion

  //#region  :: MAIN COMPONENT
  return (
    <>
      <div className="about-me">
        <div>
          <InlineMessageBar props={{ options: stateMsg }} />
        </div>
        <div>
          <SectionExpander
            props={{
              icon: ico.Building,
              title: `Education`,
            }}
          >
            <div className="tb-pad-lg">
              <div>
                <ul>
                  {T && myAccount ? (
                    <>
                      <li className="b-pad-lg">
                        {T.UserName === myAccount.UserName ? (
                          <div className="a-right b-pad-sm" ref={educRef.refAddEduc}>
                            <button onClick={OnAddEducation}>Add</button>
                            <hr className="tb-pad-sm" />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="bordered radiused4 display-none" ref={educRef.refEducForm}>
                          <div className="pad-md">
                            <div>
                              <h3 className="tb-pad-sm">Add Education</h3>
                              <div>
                                <select
                                  value={userEducModel.CourseId}
                                  onChange={(e) => OnChangeEducModel(e, "CourseId")}
                                >
                                  <option value={"0"} key={"crs_0"}>
                                    -- Select Course | Manual Input --
                                  </option>
                                  {stateCourseList
                                    ? stateCourseList.map((course) => (
                                        <option
                                          key={`crs_${course.CourseId}`}
                                          value={course.CourseId}
                                        >
                                          {course.Code} &mdash; {course.Description}
                                        </option>
                                      ))
                                    : ""}
                                </select>
                              </div>
                              <div className="flex v-center" ref={educRef.refCourseManual}>
                                <div>
                                  <Tooltip
                                    trigger="click"
                                    title="The one you provide will be prioritized."
                                    arrow="true"
                                    arrowSize="big"
                                  >
                                    <input
                                      type="text"
                                      className="long"
                                      maxLength="100"
                                      value={userEducModel.CourseTitle}
                                      onChange={(e) => OnChangeEducModel(e, "CourseTitle")}
                                      placeholder="Enter course title if not listed"
                                    />
                                  </Tooltip>
                                </div>
                                <div className="last">
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      OnChangeEducModel(e, "CourseCode");
                                    }}
                                    value={userEducModel.CourseCode}
                                    className="short"
                                    maxLength="8"
                                    placeholder="course code"
                                  />
                                </div>
                              </div>
                              <div>
                                <select
                                  value={userEducModel.SchoolId}
                                  onChange={(e) => OnChangeEducModel(e, "SchoolId")}
                                >
                                  <option value={"0"} key={"uni_0"}>
                                    -- Select School/University | Manual Input--
                                  </option>
                                  {stateUnis
                                    ? stateUnis.map((uni) => (
                                        <option key={`uni_${uni.SchoolId}`} value={uni.SchoolId}>
                                          {uni.Name}
                                        </option>
                                      ))
                                    : ""}
                                </select>
                              </div>
                              <div ref={educRef.refUniManual}>
                                <Tooltip
                                  title="The one supplied will be prioritized"
                                  arrow="true"
                                  arrowSize="big"
                                  trigger="click"
                                >
                                  <input
                                    type="text"
                                    id="txtUniName"
                                    maxLength="150"
                                    className="long"
                                    value={userEducModel.SchoolName}
                                    onChange={(e) => {
                                      OnChangeEducModel(e, "SchoolName");
                                    }}
                                    placeholder="Enter school name if not listed"
                                  />
                                </Tooltip>
                              </div>
                              <div>
                                <select
                                  value={userEducModel.CountryCode}
                                  onChange={(e) => OnChangeEducModel(e, "CountryCode")}
                                >
                                  <option key={DEFAULT_VAL} value={DEFAULT_VAL}>
                                    -- Location --
                                  </option>
                                  {stateCountries
                                    ? stateCountries.map((country) => (
                                        <option
                                          key={country.CountryCode}
                                          value={country.CountryCode}
                                        >
                                          {country.Name}
                                        </option>
                                      ))
                                    : ""}
                                </select>
                              </div>
                              <div>
                                <select
                                  value={userEducModel.CityId}
                                  onChange={(e) => OnChangeEducModel(e, "CityId")}
                                >
                                  <option key={DEFAULT_VAL} value={DEFAULT_VAL}>
                                    -- City --
                                  </option>
                                  {stateCities
                                    ? stateCities.map((j) => (
                                        <option key={`city_${j.CityId}`} value={j.CityId}>
                                          {j.Name}
                                        </option>
                                      ))
                                    : ""}
                                </select>
                              </div>
                              <div>
                                <input
                                  type="text"
                                  className="long"
                                  maxLength="150"
                                  value={userEducModel.TitleRecognition}
                                  placeholder="Enter comment, title, award/recognition if any."
                                  onChange={(e) => OnChangeEducModel(e, "TitleRecognition")}
                                />
                              </div>
                              <div className="flex" ref={educRef.refToAlign}>
                                <div className="one-half">
                                  <div className="ib r-pad-lg">FROM</div>
                                  <select
                                    value={userEducModel.FromMonth}
                                    onChange={(e) => OnChangeEducModel(e, "FromMonth")}
                                    className="month"
                                  >
                                    {stateMonths.map((m) => (
                                      <option key={m.Name} value={m.Id}>
                                        {m.Name}
                                      </option>
                                    ))}
                                  </select>
                                  <select
                                    className="year"
                                    value={userEducModel.FromYear}
                                    onChange={(e) => OnChangeEducModel(e, "FromYear")}
                                  >
                                    {stateYears.map((y) => (
                                      <option key={y} value={y}>
                                        {y}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div>
                                  <div ref={educRef.refToYear}>
                                    <div className="ib r-pad-lg">To</div>
                                    <div className="ib">
                                      <select
                                        className="month"
                                        value={userEducModel.ToMonth}
                                        onChange={(e) => OnChangeEducModel(e, "ToMonth")}
                                      >
                                        {stateMonths.map((m) => (
                                          <option key={m.Name} value={m.Id}>
                                            {m.Name}
                                          </option>
                                        ))}
                                      </select>
                                      <select
                                        className="year"
                                        value={userEducModel.ToYear}
                                        onChange={(e) => OnChangeEducModel(e, "ToYear")}
                                      >
                                        {stateYears.map((y) => (
                                          <option key={y} value={y}>
                                            {y}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      id="chkCurrentSchool"
                                      value={userEducModel.IsCurrentlyEnrolled}
                                      onChange={(e) => OnChangeEducModel(e, "IsCurrentlyEnrolled")}
                                    />
                                    <label htmlFor="chkCurrentSchool">Currently enrolled</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="tb-pad-sm" />
                            <div className="a-right tb-pad-lg" ref={educRef.refSubmit}>
                              <input type="button" value="Close" onClick={OnAddEducation} />{" "}
                              <input type="button" value="Save" onClick={OnSaveEducation} />
                            </div>
                            <div>
                              <InlineMessageBar props={{ options: stateEducMsg }} />
                            </div>
                          </div>
                        </div>
                      </li>
                      {stateMyEducList ? (
                        stateMyEducList.map((edu) => (
                          <li key={`edu_${edu.AppUserSchoolId}`} className="b-pad-lg">
                            <div className="flex v-center all-caps">
                              <div>
                                {edu.CourseCode} | {edu.CourseTitle}
                              </div>
                              {T.UserName === myAccount.UserName ? (
                                <div
                                  className="remove-btn last hand font-xl color-gray"
                                  title="Remove"
                                  onClick={() => OnRemoveEducation(edu)}
                                >
                                  {ico.CloseCircle}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="font-sm">
                              <div className="font-bold">{edu.SchoolName}</div>

                              <div>
                                {edu.CountryName}, {edu.CityName}
                              </div>

                              <div>
                                <span>
                                  {edu.FromMonthDesc} {edu.FromYear}
                                </span>{" "}
                                &mdash;{" "}
                                {!edu.IsCurrentlyEnrolled ? (
                                  <span>
                                    {edu.ToMonthDesc} {edu.ToYear}
                                  </span>
                                ) : (
                                  <span>Present</span>
                                )}
                              </div>
                              <div className="font-bold font-italic">{edu.TitleRecognition}</div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </div>
          </SectionExpander>

          <SectionExpander
            props={{
              icon: ico.BriefCase,
              title: "Job / Profession",
            }}
          >
            <div>
              <div className="tb-pad-lg">
                <ul>
                  {T && myAccount ? (
                    T.UserName === myAccount.UserName ? (
                      <li style={{ marginBottom: "20px" }}>
                        <div className="a-right" ref={refs.refBriefcaseAdd}>
                          <button onClick={OnClickAddJob}>Add</button>
                          <hr className="tb-pad-sm" />
                        </div>
                        <div className="bordered radiused4 display-none" ref={refs.refAddJob}>
                          <div className="pad-md" ref={refs.refJobForm}>
                            <div>
                              <h2>Add New Job</h2>
                              <div>
                                <select
                                  onChange={(e) => OnChangeJobModel(e, "JobIndustryId")}
                                  value={stateUserJobModel.JobIndustryId}
                                >
                                  <option value={DEFAULT_VAL} key={DEFAULT_VAL}>
                                    -- Select Job Industry --
                                  </option>
                                  {stateJobIndustries
                                    ? stateJobIndustries.map((ind) => (
                                        <option key={ind.JobIndustryId} value={ind.JobIndustryId}>
                                          {ind.Description}
                                        </option>
                                      ))
                                    : ""}
                                </select>
                              </div>
                              <div>
                                <select
                                  onChange={(e) => OnChangeJobModel(e, "JobId")}
                                  value={stateUserJobModel.JobId}
                                >
                                  <option key={DEFAULT_VAL} value={DEFAULT_VAL}>
                                    -- Select Job Category --
                                  </option>
                                  {stateJobTitles
                                    ? stateJobTitles.map((j) => (
                                        <option key={j.JobId} value={j.JobId}>
                                          {j.Description}
                                        </option>
                                      ))
                                    : ""}
                                </select>
                              </div>
                              <div>
                                <Tooltip
                                  title="e.g.: Sr. Engineer, Team Lead, etc."
                                  arrow="true"
                                  arrowSize="big"
                                  trigger="click"
                                >
                                  <input
                                    type="text"
                                    id="txtPosOrTitle"
                                    maxLength="100"
                                    value={stateUserJobModel.JobTitle}
                                    onChange={(e) => OnChangeJobModel(e, "JobTitle")}
                                    placeholder="Position/Title"
                                  />
                                </Tooltip>
                              </div>

                              <div>
                                <div>
                                  <select
                                    value={stateUserJobModel.CompanyId}
                                    onChange={(e) => OnChangeJobModel(e, "CompanyId")}
                                  >
                                    <option key={"0"} value={"0"}>
                                      -- Select Company --
                                    </option>
                                    {stateCompanies
                                      ? stateCompanies.map((c) => (
                                          <option key={c.CompanyId} value={c.CompanyId}>
                                            {c.CompanyName}
                                          </option>
                                        ))
                                      : ""}
                                  </select>
                                </div>
                                <div>
                                  <Tooltip
                                    title="The one you supplied will be prioritized."
                                    arrow="true"
                                    arrowSize="big"
                                    trigger="click"
                                  >
                                    <input
                                      value={stateUserJobModel.CompanyName}
                                      onChange={(e) => OnChangeJobModel(e, "CompanyName")}
                                      type="text"
                                      className="long"
                                      placeholder="Type company here if not listed"
                                    />
                                  </Tooltip>
                                </div>
                                <div>
                                  <select
                                    onChange={(e) => OnChangeJobModel(e, "CountryCode")}
                                    value={stateUserJobModel.CountryCode}
                                  >
                                    <option key={DEFAULT_VAL} value={DEFAULT_VAL}>
                                      -- Company Location --
                                    </option>
                                    {stateCountries
                                      ? stateCountries.map((country) => (
                                          <option
                                            key={country.CountryCode}
                                            value={country.CountryCode}
                                          >
                                            {country.Name}
                                          </option>
                                        ))
                                      : ""}
                                  </select>
                                </div>
                              </div>
                              <div className="flex" ref={refs.refToAlign}>
                                <div className="one-half">
                                  <div className="ib r-pad-lg">From</div>
                                  <select
                                    value={stateUserJobModel.FromMonth}
                                    className="month"
                                    onChange={(e) => OnChangeJobModel(e, "FromMonth")}
                                  >
                                    {stateMonths.map((m) => (
                                      <option key={m.Name} value={m.Id}>
                                        {m.Name}
                                      </option>
                                    ))}
                                  </select>
                                  <select
                                    className="year"
                                    value={stateUserJobModel.FromYear}
                                    onChange={(e) => OnChangeJobModel(e, "FromYear")}
                                  >
                                    {stateYears.map((y) => (
                                      <option key={y} value={y}>
                                        {y}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div>
                                  <div ref={refs.refToDate}>
                                    <div className="ib r-pad-lg">To</div>
                                    <div className="ib">
                                      <select
                                        className="month"
                                        value={stateUserJobModel.ToMonth}
                                        onChange={(e) => OnChangeJobModel(e, "ToMonth")}
                                      >
                                        {stateMonths.map((m) => (
                                          <option key={m.Name} value={m.Id}>
                                            {m.Name}
                                          </option>
                                        ))}
                                      </select>
                                      <select
                                        className="year"
                                        value={stateUserJobModel.ToYear}
                                        onChange={(e) => OnChangeJobModel(e, "ToYear")}
                                      >
                                        {stateYears.map((y) => (
                                          <option key={y} value={y}>
                                            {y}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      id="chkCurrentJob"
                                      ref={refs.refCheckCurrentJob}
                                      value={stateUserJobModel.IsPresentJob}
                                      onChange={(e) => OnChangeJobModel(e, "IsPresentJob")}
                                    />
                                    <label htmlFor="chkCurrentJob">I currently work here</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="tb-pad-sm" />
                            <div className="a-right tb-pad-lg">
                              <input type="button" value="Close" onClick={OnCancelAddJob} />{" "}
                              <input type="button" value="Save" onClick={OnAddNewJob} />
                            </div>
                            <div>
                              <InlineMessageBar props={{ options: stateAddWorkMsg }} />
                            </div>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}

                  {stateMyJobs ? (
                    stateMyJobs.map((j) => (
                      <li key={`j_${j.AppUserJobId}`} className="b-pad-lg">
                        <div className="flex v-center all-caps">
                          <div>{j.JobTitle === "NA" ? j.JobCategory : j.JobTitle}</div>
                          {T.UserName === myAccount.UserName ? (
                            <div
                              className="remove-btn last hand font-xl color-gray"
                              title="Remove"
                              onClick={() => OnRemoveJob(j)}
                            >
                              {ico.CloseCircle}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="font-sm">
                          <div>{j.Industry}</div>
                          <div>{j.JobTitle !== "NA" ? j.JobCategory : ""}</div>
                          <div className="font-bold">{j.CompanyName}</div>
                          <div className="font-bold">{j.CountryName}</div>
                          <div>
                            From{" "}
                            <span>
                              {j.FromMonthDesc} {j.FromYear}
                            </span>{" "}
                            &mdash;{" "}
                            {!j.IsPresentJob ? (
                              <span>
                                {j.ToMonthDesc} {j.ToYear}
                              </span>
                            ) : (
                              <span>Present</span>
                            )}
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </div>
          </SectionExpander>

          <SectionExpander props={{ icon: ico.Camera, title: "Hobbies and Interests" }}>
            <div className="tb-pad-lg" ref={refHobby.refHobbySection}>
              <ul>
                {T && myAccount ? (
                  T.UserName === myAccount.UserName ? (
                    <li style={{ marginBottom: "20px" }}>
                      <div className="a-right" ref={refHobby.refAddHobby}>
                        <button onClick={() => OnHobbyCommand(null, "add")}>Add</button>
                        <hr className="tb-pad-sm" />
                      </div>
                      <div className="bordered radiused4 display-none" ref={refHobby.refHobbyForm}>
                        <div className="pad-md">
                          <div>
                            <div className="b-pad-md">
                              {stateHobbyCmd === "add" ? <h2>New</h2> : <h2>Edit</h2>}
                            </div>
                            <div ref={refHobby.refHobbyDropDown}>
                              <select
                                onChange={(e) => OnChangeHobbyModel(e, "HobbyInterestId")}
                                value={stateHobbyModel.HobbyInterestId}
                              >
                                <option value={"0"} key={"hob_0"}>
                                  -- Select Hobby/Interest | Manual Input --
                                </option>
                                {stateHobbyList
                                  ? stateHobbyList.map((h) => (
                                      <option
                                        key={`hob_${h.HobbyInterestId}`}
                                        value={h.HobbyInterestId}
                                      >
                                        {h.Description}
                                      </option>
                                    ))
                                  : ""}
                              </select>
                            </div>
                            <div ref={refHobby.refNewDesc}>
                              <Tooltip
                                title="e.g.: Sea Surfing, Skydiving, etc."
                                arrow="true"
                                arrowSize="big"
                                trigger="click"
                                position="top-end"
                              >
                                <input
                                  type="text"
                                  id="txtNewHobby"
                                  className="long"
                                  maxLength="100"
                                  value={stateHobbyModel.Description}
                                  onChange={(e) => OnChangeHobbyModel(e, "Description")}
                                  placeholder="Enter hobby description if not listed."
                                />
                              </Tooltip>
                            </div>

                            <div>
                              <Tooltip
                                title="e.g.: I do comedy blogging/writing"
                                arrow="true"
                                trigger="click"
                                arrowSize="big"
                                position="top-end"
                              >
                                <input
                                  type="text"
                                  id="txtElaborateHobby"
                                  className="long"
                                  maxLength="100"
                                  value={stateHobbyModel.Details}
                                  onChange={(e) => OnChangeHobbyModel(e, "Details")}
                                  placeholder="Details (optional)"
                                />
                              </Tooltip>
                            </div>
                          </div>
                          <hr className="tb-pad-sm" />
                          <div className="a-right tb-pad-lg">
                            <input
                              type="button"
                              value="Close"
                              onClick={() => OnHobbyCommand(null, "cancel")}
                            />{" "}
                            <input
                              type="button"
                              value="Save"
                              onClick={() => OnHobbyCommand(null, "save")}
                            />
                          </div>
                          <div>
                            <InlineMessageBar props={{ options: stateHobbyMsg }} />
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                <li ref={refHobby.refHobbList}>
                  {stateMyHobbyList ? (
                    <>
                      <ul>
                        {stateMyHobbyList.map((h) => (
                          <li key={`j_${h.AppUserHobbyId}`} className="b-pad-lg">
                            <div className="flex v-center all-caps">
                              <div>{h.Description}</div>
                              <div className="last">
                                {T.UserName === myAccount.UserName ? (
                                  <>
                                    <span
                                      className="edit-btn last hand font-lg color-gray r-pad-sm"
                                      title="Edit"
                                      onClick={() => OnHobbyCommand(h, "edit")}
                                    >
                                      {ico.Pencil}
                                    </span>
                                    <span
                                      className="remove-btn last hand font-lg color-gray"
                                      title="Remove"
                                      onClick={() => OnHobbyCommand(h, "remove")}
                                    >
                                      {ico.CloseCircle}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="font-sm">
                              <div>{h.Details}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </li>
              </ul>
            </div>
          </SectionExpander>
        </div>
      </div>
      <DialogBox Dialog={dialog} key={"AboutMe"} />
    </>
  );
  //#endregion :: MAIN COMPONENT
};

export default AboutMe;
