import React, { useEffect, useRef, useState } from "react";

import useAccountManager from "../Globals/useAccountManager";
import useGlobalConstants from "../Globals/useGlobalConstants";
import useCommonUtil from "../Hooks/useCommonUtil";
import useReactIcons from "../Hooks/useReactIcons";
import "./PhotoViewer.css";
import DialogBox from "./Widgets/DialogBox";

const PhotoViewer = (props) => {
  const u = useCommonUtil();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const ico = useReactIcons();

  const [T, setTargetUser] = useState();

  const [dialog, setDialog] = useState();

  const param = props.props;

  const [mediaAlbum, setPhotoAlbum] = useState();
  const [thumbList, setThumbList] = useState([]);

  const [selectedMedia, setSelectedMedia] = useState();
  const [photoUrl, setPhotoUrl] = useState();
  const [isCoverCb, setIsCoverCb] = useState(false);
  const Refs = {
    refWindow: useRef(),
    fileRef: useRef(),
  };

  const IsMe = am.Identity.IsMe();

  const OnResizeWindow = () => {
    if (Refs.refWindow.current) {
      Refs.refWindow.current.style.height = `${document.body.offsetHeight}px`;
    }
  };

  OnResizeWindow();

  useEffect(() => {
    am.Identity.GetTargetUser((tuser) => {
      setTargetUser(tuser);
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Refs.refWindow.current) {
      Refs.refWindow.current.style.height = `${document.body.offsetHeight}px`;
    }
    window.addEventListener("resize", OnResizeWindow);
    return () => {
      window.removeEventListener("resize", OnResizeWindow);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (param.album) {
      setPhotoAlbum(param.album);
    }
    // eslint-disable-next-line
  }, [param.album]);

  useEffect(() => {
    if (mediaAlbum) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [mediaAlbum]);

  const OnHideViewer = () => {
    Refs.refWindow.current.classList.add("hide");
    param.resetCallback();
    setPhotoAlbum(null);
    setSelectedMedia(null);
    setThumbList([]);
    setDialog({ mode: "cl" });
  };

  const OnChangeFile = async () => {
    let files = Refs.fileRef.current.files;
    let remaining = files.length;
    let tmr;
    //console.log(files);
    if (files.length) {
      if (files.length > g.Image.MAX_PER_UPLOAD) {
        setDialog({
          mode: "w",
          title: `NOT PERMITTED`,
          message: `You can only upload ${g.Image.MAX_PER_UPLOAD} simultaneous photos at a time.`,
          callBackPercent: 1,
        });
        Refs.fileRef.current.value = "";
        return;
      }

      setDialog({
        mode: "pb",
        title: `Uploading ${files.length > 1 ? "Photos" : "Photo"}`,
        message: `Please wait...`,
        callBackPercent: 1,
      });

      //prepare payload
      let photoPayload = {
        UserName: T.UserName,
        ApiEndPoint: `${g.Api.ApiRoute}/mediaapi/UploadPhotosAsync`,
        AuthToken: am.GetMyAuthToken(),
        AlbumId: mediaAlbum.AlbumId,
        MediaModels: [],
        MediaType: "i",
        OnProgress: (n) => {
          if (n) {
            setDialog({
              mode: "pb",
              title: `UPLOADING - ${n}%`,
              message: "Please wait...",
              callBackPercent: n,
            });
          }
        },
        OnCompleted: (n) => {
          setDialog({
            mode: "pb",
            callBackPercent: n,
            title: `UPLOADED - 100%`,
            message: `Upload completed.`,
          });
        },
        Succeeded: (msg) => {
          //const result = JSON.parse(msg);
          const newPhotos = JSON.parse(msg).ModelData;
          //console.log(newPhotos);
          if (newPhotos) {
            if (thumbList) {
              setThumbList([...newPhotos, ...thumbList]);
            } else {
              setThumbList(newPhotos);
            }
          }
          setDialog({ mode: "cl" });

          Refs.fileRef.current.value = "";
        },
        OnError: (err) => {
          setDialog({ mode: "e", title: "ERROR", message: err });
          console.log(err);
        },
      };

      //generate main photos
      [...files].forEach((f) => {
        let pad = u.Security.GenerateRandomString(5);
        //created reduced main photos
        u.FileManager.ResizeImage(f, g.Image.REDUCE_DIM, g.Image.REDUCE_TO, (trans) => {
          u.FileManager.HashToHexDigit(trans.dataUrl, (fhash) => {
            photoPayload.MediaModels.push({
              FileName: `m-${pad}${fhash}`,
              FileStream: u.FileManager.DataURItoBlob(trans.dataUrl),
              OriginalSize: `${trans.origSize.w}x${trans.origSize.h}`,
              AdjustedSize: g.Image.REDUCE_DIM,
              MediaType: "i",
            });

            //create thumbs
            u.FileManager.ResizeImage(f, g.Image.REDUCE_THUMB_DIM, g.Image.REDUCE_TO, (trans) => {
              photoPayload.MediaModels.push({
                UserName: T.UserName,
                FileName: `t-${pad}${fhash}`,
                FileStream: u.FileManager.DataURItoBlob(trans.dataUrl),
                OriginalSize: `${trans.origSize.w}x${trans.origSize.h}`,
                AdjustedSize: g.Image.REDUCE_THUMB_DIM,
                MediaType: "i",
              });
              
              remaining--;
            });
          });
        });
      });

      //wait for the hashing to complete
      tmr = setInterval(() => {
        if (remaining <= 0) {
          u.FileManager.UploadMedia(photoPayload); //fire XHR upload
          clearTimeout(tmr);
        }
      }, 1000);

      //console.log(photoPayload);
    }
  };

  const OnButtonCommand = (cmd) => {
    if (cmd === "add") {
      Refs.fileRef.current.click();
    } else if (cmd === "remove") {
      if (selectedMedia) {
        setDialog({
          mode: "q",
          title: "DELETE PHOTO",
          message: "Do you want to delete the selected photo?",
          buttons: [
            {
              id: "ok",
              caption: "Yes",
              OnClick: () => {
                console.log(selectedMedia);
                fetch(`${g.Api.ApiRoute}/mediaapi/DeletePhotoAsync`, {
                  method: g.Fetch.POST,
                  headers: am.Identity.GetAuthHeader(),
                  body: JSON.stringify({
                    UserName: T.UserName,
                    IntValue: selectedMedia.MediaId,
                  }),
                })
                  .then((r) => {
                    return r.ok ? r.json() : null;
                  })
                  .then((r) => {
                    if (r) {
                      console.log(r);
                      if (r.Succeeded) {
                        //remove from view
                        let tmp = thumbList.filter((t) => t.MediaId !== selectedMedia.MediaId);
                        setThumbList(tmp);
                        setSelectedMedia(null);
                      } else {
                        setDialog({
                          mode: "e",
                          title: "REMOVE FAILED",
                          message: r.message,
                        });
                      }
                    }
                  });
              },
            },
            {
              id: "no",
              caption: "No",
            },
          ],
        });
      } else {
        setDialog({
          mode: "w",
          title: "Select Photo | Remove",
          message: "Please select a photo to remove.",
        });
      }
    } else if (cmd === "cover") {
      if (selectedMedia) {
        setDialog({
          mode: "q",
          title: "Album Cover",
          message: "Replace album cover with this photo?",
          buttons: [
            {
              id: "ok",
              caption: "Yes",
              OnClick: () => {
                setIsCoverCb(true);
                param.changeCoverCallback(`${selectedMedia.MediaHashId}`);
                setTimeout(() => {
                  setIsCoverCb(false);
                }, 1000);
              },
            },
            { id: "no", caption: "No" },
          ],
        });
      } else {
        setDialog({
          mode: "w",
          title: "Select Photo | Album Cover",
          message: "Please select a photo to make album cover.",
        });
      }
    }
  };

  useEffect(() => {
    if (mediaAlbum && !isCoverCb) {
      if (mediaAlbum.IsWallMedia) {
        setPhotoUrl(`${g.Api.CdnRoute}/${T.UserName}/albums/wall/${mediaAlbum.AlbumName}`);
      } else {
        setPhotoUrl(
          `${g.Api.CdnRoute}/${T.UserName}/albums/${u.Data.ParseSlug(mediaAlbum.AlbumName, true)}`
        );
      }
      var p = new URLSearchParams({
        albumId: mediaAlbum.AlbumId,
      });
      fetch(`${g.Api.ApiRoute}/mediaapi/GetPhotosAsync/?${p}`, {
        headers: am.Identity.GetAuthHeader(),
      })
        .then((r) => {
          return r.ok ? r.json() : null;
        })
        .then((r) => {
          //console.log(r);
          setThumbList(r.ModelData);
        });
    }
    // eslint-disable-next-line
  }, [mediaAlbum]);

  const OnClickThumb = (id, e) => {
    setSelectedMedia(null);
    u.Ui.makeMenuItemActive(e, "selected");
    const media = thumbList.find((x) => x.MediaId === id);
    if (media) {
      setSelectedMedia(media);
    }
  };


  return (
    <>
      {mediaAlbum ? (
        <div className="photo-viewer" ref={Refs.refWindow}>
          <div className="hidden">
            <input
              type="file"
              name="files"
              multiple
              id="filesUpload"
              ref={Refs.fileRef}
              accept="image/*"
              onChange={OnChangeFile}
            />
          </div>
          <div className="pad-xl">
            <div className="close-btn" onClick={OnHideViewer}>
              <span className="font-xl">{ico.CloseCircle}</span>
            </div>
            <div className="pad-lg"></div>
            <div className="all-caps b-pad-md">
              <span className="r-pad-md">{ico.Image}</span> {mediaAlbum.AlbumName} |{" "}
              {u.Date.FormatDate(mediaAlbum.DateCreated, "Ddd MMM-DD-YYYY hh:mm a")}
            </div>
            {IsMe ? (
              <div className="a-right">
                {!mediaAlbum.IsWallMedia ? (
                  <input type="button" value="Add" onClick={() => OnButtonCommand("add")} />
                ) : (
                  ""
                )}
                {selectedMedia ? (
                  <>
                    <input type="button" value="Remove" onClick={() => OnButtonCommand("remove")} />
                    <input
                      type="button"
                      value="Make Album Cover"
                      onClick={() => OnButtonCommand("cover")}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
            <div>
              {thumbList ? (
                <div className="thumb-list">
                  {thumbList.map((p) =>
                    p.MediaType === "i" ? (
                      <div
                        key={p.MediaHashId}
                        className="thumb"
                        onClick={(e) => OnClickThumb(p.MediaId, e.currentTarget)}
                        style={{
                          backgroundImage: `url(${photoUrl}/thumbs/t-${p.MediaHashId}.jpg)`,
                        }}
                      ></div>
                    ) : (
                      <div
                        className="thumb video"
                        key={p.MediaHashId}
                        onClick={(e) => OnClickThumb(p.MediaId, e.currentTarget)}
                      >
                        {ico.Film}
                      </div>
                    )
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="pad-sm"></div>
            {selectedMedia ? (
              <div className="media-frame">
                <div className="flex">
                  <div className="color-mid-gray full-width">
                    <div className="pad-xl">
                      {selectedMedia.MediaType === "i" ? (
                        <img
                          alt="Full View"
                          src={`${photoUrl}/m-${selectedMedia.MediaHashId}.jpg`}
                        />
                      ) : (
                        <video width="100%" height="auto" controls>
                          <source
                            src={`${g.Api.CdnRoute}/${T.UserName}/albums/wall/${mediaAlbum.AlbumName}/v-${selectedMedia.MediaHashId}.mp4`}
                          />
                        </video>
                      )}
                    </div>
                  </div>
                  {/* <div>
                    <div className="pad-xl">
                      <div className="comments color-mid-gray">
                        <div className="pad-sm">
                          <div>
                            <div className="b-pad-sm">
                              <div className="pad-sm a-right">
                                <span
                                  className="hand"
                                  title="Click to like"
                                  onClick={() => OnPhotoReact(1, selectedMedia.MediaId)}
                                >
                                  <i
                                    className={`fa ${
                                      selectedMedia.Likes > 0 ? "fa-thumbs-up" : "fa-thumbs-o-up"
                                    }  fa-lg fa-mi color-blue`}
                                  ></i>
                                  {selectedMedia.Likes}
                                </span>
                                <span
                                  className="hand l-pad-sm"
                                  title="Click to heart"
                                  onClick={() => OnPhotoReact(2, selectedMedia.MediaId)}
                                >
                                  <i
                                    className={`fa ${
                                      selectedMedia.Hearts > 0 ? "fa-heart" : "fa-heart-o"
                                    } fa-lg fa-mi color-red`}
                                  ></i>
                                  {selectedMedia.Hearts}
                                </span>
                              </div>
                            </div>
                            <div className="l-pad-sm">{selectedMedia.Description}</div>
                            <div title="Edit" className="a-right" onClick={OnEditDescription}>
                              <i className="fa fa-pencil-square-o fa-lg hand"></i>
                            </div>
                          </div>
                          <div className="b-pad-sm"></div>
                          <hr />

                          <div className="pad-sm">
                            <h2>Comments</h2>
                            <hr />
                            <div>No comments as of the moment.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <DialogBox Dialog={dialog} key={"UserWall"} />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PhotoViewer;
