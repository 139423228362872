import React, { useEffect, useRef, useState } from "react";
import "./OtherAdminTasks.css";

import { SectionExpander } from "../../../Components/Widgets/SectionExpander";
import useReactIcons from "../../../Hooks/useReactIcons";
import "react-tabs/style/react-tabs.css";
import useGlobalConstants from "../../../Globals/useGlobalConstants";
import useAccountManager from "../../../Globals/useAccountManager";
import InlineMessageBar from "../../../Components/Widgets/InlineMessageBar";
import useCommonUtil from "../../../Hooks/useCommonUtil";
import { Link } from "react-router-dom";
import DialogBox from "../../../Components/Widgets/DialogBox";

const OtherAdminTasks = () => {
  const ico = useReactIcons();
  const g = useGlobalConstants();
  const u = useCommonUtil();
  const am = useAccountManager();

  const [userCaseList, setUserCaseList] = useState();

  const [msgUser, setMsgUser] = useState();
  const [msgSystem, setMsgSystem] = useState();
  const [msgBan, setMsgBan] = useState();

  const [dialog, SetDialog] = useState();

  const [myAccount] = useState(am.GetMyAccount());

  //#region :: User Cases
  const refs = {
    refUserResolve: useRef(),
    refUserTableList: useRef(),

    refSystemResolve: useRef(),
    refSystemTableList: useRef(),
  };
  const [userResolveMode, setUserResolveMode] = useState(-1);

  const DisplayUserProgress = (b) => {
    setMsgUser({ mode: b ? "p" : "h" });
    if (refs.refUserResolve.current && refs.refUserTableList.current) {
      if (b) {
        refs.refUserResolve.current.classList.add("dn");
        refs.refUserTableList.current.classList.add("dn");
      } else {
        refs.refUserResolve.current.classList.remove("dn");
        refs.refUserTableList.current.classList.remove("dn");
      }
    }
  };

  const EnableUserCasesChks = () => {
    let chkboxes = document.querySelectorAll(`.chk-usercases`);
    if (chkboxes) {
      chkboxes.forEach((c) => {
        c.classList.remove("nt");
      });
    }
  };

  const GetUserCaseReports = () => {
    if (userResolveMode < 0) return;
    EnableUserCasesChks();
    DisplayUserProgress(true);

    fetch(`${g.Api.ApiRoute}/adminapi/GetCaseReports/?m=${1}&r=${userResolveMode}`, {
      method: "GET",
      headers: am.Identity.GetAuthHeader(),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        DisplayUserProgress();

        if (!j) {
          return;
        }
        if (j.Succeeded) {
          setUserCaseList(j.ModelData);
        }
      });
  };

  useEffect(() => {
    if (userResolveMode < 0) return;
    GetUserCaseReports();

    // eslint-disable-next-line
  }, [userResolveMode]);

  const OnSelectUserResolveMode = (v) => {
    setUserResolveMode(v.target.value);
  };

  const [userResolveIssue, setUserResolveIssue] = useState(0);
  useEffect(() => {
    console.log(userResolveIssue);
  }, [userResolveIssue]);

  const OnUserCheckResolve = (e, record) => {
    console.log(record);
    let chkbox = document.querySelector(`#chk_${record.CaseReportId}`);

    let resolved = e.target.checked ? 1 : 0;

    let payload = {
      CaseReportId: record.CaseReportId,
      ResolvedByUser: myAccount.UserName,
      IsResolved: resolved,
    };

    setUserResolveIssue(resolved);

    SetDialog({ mode: "p", message: `${resolved === 1 ? "Resolving" : "Unresolving"} issue...` });

    fetch(`${g.Api.ApiRoute}/adminapi/ResolveUserIssue`, {
      method: "POST",
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(payload),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          SetDialog({ mode: "e", message: "Unable to transact with the API Server." });
          chkbox.checked = false;
        }
      })
      .then((j) => {
        if (!j) {
          SetDialog({ mode: "e", message: "Unable to transact with the API Server." });
          chkbox.checked = false;
          return;
        }

        if (j.Succeeded) {
          SetDialog({
            mode: "s",
            title: `${resolved === 1 ? "resolved" : "unresolved"}`,
            message: `Issue has been ${resolved === 1 ? "resolved" : "unresolved"}.`,
          });

          chkbox.classList.add("nt");
        } else {
          SetDialog({ mode: "s", title: "Technical", message: "Unable to resolve issue." });
          chkbox.checked = false;
        }
      })
      .catch((err) => {
        SetDialog({
          mode: "e",
          title: "Technical Error",
          message: "Unable to transact with the API Server.",
        });
        chkbox.checked = false;
      });
  };

  //#endregion :: User Cases ============================================

  //#region  :: System Cases
  const [systemResolveMode, setSystemResolveMode] = useState(-1);
  const [systemCaseList, setSystemCaseList] = useState();

  useEffect(() => {
    if (systemResolveMode < 0) return;
    GetSystemCaseReports();

    // eslint-disable-next-line
  }, [systemResolveMode]);

  const DisplaySystemProgress = (b) => {
    setMsgSystem({ mode: b ? "p" : "h" });
    if (refs.refSystemResolve.current && refs.refSystemTableList.current) {
      if (b) {
        refs.refSystemResolve.current.classList.add("dn");
        refs.refSystemTableList.current.classList.add("dn");
      } else {
        refs.refSystemResolve.current.classList.remove("dn");
        refs.refSystemTableList.current.classList.remove("dn");
      }
    }
  };

  const OnSystemCheckResolve = (e, record) => {
    console.log(record);
    let chkbox = document.querySelector(`#chkUsr_${record.CaseReportId}`);

    let resolved = e.target.checked ? 1 : 0;

    let payload = {
      CaseReportId: record.CaseReportId,
      ResolvedByUser: myAccount.UserName,
      IsResolved: resolved,
    };

    setUserResolveIssue(resolved);

    SetDialog({ mode: "p", message: `${resolved === 1 ? "Resolving" : "Unresolving"} issue...` });

    fetch(`${g.Api.ApiRoute}/adminapi/ResolveUserIssue`, {
      method: "POST",
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(payload),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          SetDialog({ mode: "e", message: "Unable to transact with the API Server." });
          chkbox.checked = false;
        }
      })
      .then((j) => {
        if (!j) {
          SetDialog({ mode: "e", message: "Unable to transact with the API Server." });
          chkbox.checked = false;
          return;
        }

        if (j.Succeeded) {
          SetDialog({
            mode: "s",
            title: `${resolved === 1 ? "resolved" : "unresolved"}`,
            message: `Issue has been ${resolved === 1 ? "resolved" : "unresolved"}.`,
          });

          chkbox.classList.add("nt");
        } else {
          SetDialog({ mode: "s", title: "Technical", message: "Unable to resolve issue." });
          chkbox.checked = false;
        }
      })
      .catch((err) => {
        SetDialog({
          mode: "e",
          title: "Technical Error",
          message: "Unable to transact with the API Server.",
        });
        chkbox.checked = false;
      });
  };
  const EnableSystemCasesChks = () => {
    let chkboxes = document.querySelectorAll(`.chk-systemcases`);
    if (chkboxes) {
      chkboxes.forEach((c) => {
        c.classList.remove("nt");
      });
    }
  };
  const OnSelectSystemResolveMode = (v) => {
    setSystemResolveMode(v.target.value);
  };

  const GetSystemCaseReports = () => {
    if (systemResolveMode < 0) return;

    EnableSystemCasesChks();
    DisplaySystemProgress(true);

    fetch(`${g.Api.ApiRoute}/adminapi/GetCaseReports/?m=${2}&r=${systemResolveMode}`, {
      method: "GET",
      headers: am.Identity.GetAuthHeader(),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        DisplaySystemProgress();

        if (!j) {
          return;
        }
        if (j.Succeeded) {
          setSystemCaseList(j.ModelData);
        }
      });
  };

  //#endregion :: System Cases
  const [banMode, setBanMode] = useState();
  const [banList, setBanList] = useState();

  const OnSelectCurrentBans = (v) => {
    setBanMode(v.target.value);
  };

  const GetBanList = () => {
    if (!banMode) return;

    setMsgBan({ mode: "p", message: "Acquiring ban list..." });

    fetch(`${g.Api.ApiRoute}/reportsapi/GetBanRecordsAsync/?mode=${banMode}`, {
      method: "GET",
      headers: am.Identity.GetAuthHeader(),
    })
      .then((r) => {
        if (r.ok) return r.json();
      })
      .then((j) => {
        if (!j) {
          setMsgBan({
            mode: "e",
            message: "A technical error has occured. Please try again later.",
          });
          return;
        }

        if (j.Succeeded) {
          console.log(j);
          setBanList(j.ModelData);
          setMsgBan({ mode: "s", message: j.Message });
        } else {
          setBanList([]);
          setMsgBan({
            mode: "e",
            message: "A technical error has occured. Please try again later.",
          });
        }
      })
      .catch((err) => {
        setMsgBan({
          mode: "e",
          message: `An error has occured ${err}`,
        });
      });
  };

  useEffect(() => {
    GetBanList();
    // eslint-disable-next-line
  }, [banMode]);
  //#region User Bans

  //#endregion

  // RENDER BODY
  return (
    <div className="admin-tasks">
      <SectionExpander
        props={{
          icon: ico.Shield2l,
          title: "Other Administrative Tasks",
          caption: "See below list of other operations for admin",
        }}
      >
        <div className="tb-pad-lg">
          <SectionExpander
            props={{
              icon: ico.Report,
              title: "User Case Reports",
              collapsed: true,
              caption: "Cases reported by users",
            }}
          >
            <div>
              <div>
                <div className="bordered">
                  <div className="pad-md">
                    <div className="tb-pad-sm">
                      <div>
                        <InlineMessageBar props={{ options: msgUser }} />
                      </div>

                      <div className="pad-md bordered radiused4" ref={refs.refUserResolve}>
                        <div className="flex v-center">
                          <div>
                            <div className="r-pad-lg inline-block">
                              <span className="r-pad-md">
                                <input
                                  type="radio"
                                  name="radUser"
                                  id="uUnresolved"
                                  //defaultChecked={true}
                                  value={0}
                                  onChange={OnSelectUserResolveMode}
                                />
                                <label htmlFor="uUnresolved">Unresolved</label>
                              </span>
                              <span>
                                <input
                                  type="radio"
                                  name="radUser"
                                  id="uResolved"
                                  value={1}
                                  onChange={OnSelectUserResolveMode}
                                />
                                <label htmlFor="uResolved">Resolved</label>
                              </span>
                            </div>
                            <span className="r-pad-xl">
                              <button
                                className="refresh"
                                title="Refresh List"
                                onClick={GetUserCaseReports}
                              >
                                <span className="font-lg">{ico.Refresh}</span>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div ref={refs.refUserTableList}>
                      <table className="case-list">
                        <thead>
                          <tr>
                            <th style={{ width: "55px" }}>Case #</th>
                            <th style={{ width: "250px" }}>Reporter</th>
                            <th style={{ width: "auto" }}>Target</th>
                            <th style={{ width: "150px" }}>Resolved</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userCaseList ? (
                            userCaseList.map((uc) => (
                              <tr key={`uc_${uc.CaseReportId}`}>
                                <td>
                                  <div className="a-center">{uc.CaseReportId}</div>
                                </td>
                                <td>
                                  <Link to={`/${uc.UserName}`} onClick={() => u.Ui.ScrollToY()}>
                                    <div className="flex v-center">
                                      <div
                                        className="avatar"
                                        style={{
                                          backgroundImage: `url(${g.Api.CdnRoute}/${
                                            uc.UserName
                                          }/profile/${
                                            uc.UserName
                                          }.jpg?v=${u.Security.GenerateRandomString(10)})`,
                                        }}
                                      ></div>
                                      <div className="l-pad-sm">
                                        {/* <div>{uc.UserName}</div> */}

                                        <div className="font-smaller all-caps">{uc.FullName}</div>
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/${uc.TargetUserName}`}
                                    onClick={() => u.Ui.ScrollToY()}
                                  >
                                    <div className="flex v-center">
                                      <div
                                        className="avatar"
                                        style={{
                                          backgroundImage: `url(${g.Api.CdnRoute}/${
                                            uc.TargetUserName
                                          }/profile/${
                                            uc.TargetUserName
                                          }.jpg?v=${u.Security.GenerateRandomString(10)})`,
                                        }}
                                      ></div>
                                      <div className="l-pad-sm">
                                        {/* <div>{uc.TargetUserName}</div> */}
                                        <div className="font-smaller all-caps">
                                          {uc.TargetFullName}
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                  <div className="font-sm bordered radiused4">
                                    <div className="pad-md">
                                      <span className="font-smaller font-bold">
                                        {u.Date.FormatDate(
                                          uc.DateSubmitted,
                                          "DDD MM-DD-YY hh:mm a"
                                        )}
                                      </span>
                                      <div>
                                        <b>CASE:</b> <span className="font-sm">{uc.Reason}</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="a-center">
                                    <input
                                      type="checkbox"
                                      id={`chk_${uc.CaseReportId}`}
                                      className="chk-usercases"
                                      defaultChecked={uc.IsResolved}
                                      onChange={(e) => OnUserCheckResolve(e, uc)}
                                    />
                                    {uc.IsResolved === 1 ? (
                                      <div className="font-sm">{uc.ResolvedByUser}</div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SectionExpander>

          <SectionExpander
            props={{
              icon: ico.Bug,
              collapsed: true,
              title: "System Case Reports",
              caption: "Cases reported by users",
            }}
          >
            <div>
              <div>
                <div>
                  <div className="bordered">
                    <div className="pad-md">
                      <div className="tb-pad-sm">
                        <div>
                          <InlineMessageBar props={{ options: msgSystem }} />
                        </div>

                        <div ref={refs.refSystemResolve}>
                          <div className="bordered radiused4 pad-md">
                            <div className="r-pad-lg inline-block">
                              <span className="r-pad-md">
                                <input
                                  type="radio"
                                  name="radSystem"
                                  id="sUnresolved"
                                  //defaultChecked={true}
                                  value={0}
                                  onChange={OnSelectSystemResolveMode}
                                />
                                <label htmlFor="sUnresolved">Unresolved</label>
                              </span>
                              <span>
                                <input
                                  type="radio"
                                  name="radSystem"
                                  id="sResolved"
                                  value={1}
                                  onChange={OnSelectSystemResolveMode}
                                />
                                <label htmlFor="sResolved">Resolved</label>
                              </span>
                            </div>
                            <span className="r-pad-xl">
                              <button
                                className="refresh"
                                title="Refresh List"
                                onClick={GetSystemCaseReports}
                              >
                                <span className="font-lg">{ico.Refresh}</span>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div ref={refs.refSystemTableList}>
                        <table className="case-list">
                          <thead>
                            <tr>
                              <th style={{ width: "60px" }}>CASE #</th>
                              <th style={{ width: "250px" }}>Reporter</th>
                              <th>Reason</th>
                              <th style={{ width: "150px" }}>Resolved</th>
                            </tr>
                          </thead>
                          <tbody>
                            {systemCaseList ? (
                              systemCaseList.map((uc) => (
                                <tr key={`uc_${uc.CaseReportId}`}>
                                  <td>
                                    <div className="a-center">{uc.CaseReportId}</div>
                                  </td>
                                  <td>
                                    <Link to={`/${uc.UserName}`} onClick={() => u.Ui.ScrollToY()}>
                                      <div className="flex v-center">
                                        <div
                                          className="avatar"
                                          style={{
                                            backgroundImage: `url(${g.Api.CdnRoute}/${
                                              uc.UserName
                                            }/profile/${
                                              uc.UserName
                                            }.jpg?v=${u.Security.GenerateRandomString(10)})`,
                                          }}
                                        ></div>
                                        <div className="l-pad-sm">
                                          {/* <div>{uc.UserName}</div> */}

                                          <div className="font-smaller all-caps">{uc.FullName}</div>
                                        </div>
                                      </div>
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="color-orange">
                                      <span className="font-smaller">
                                        {u.Date.FormatDate(
                                          uc.DateSubmitted,
                                          "DDD MM-DD-YY hh:mm a"
                                        )}
                                      </span>
                                    </div>
                                    <div className="font-sm">{uc.Reason}</div>
                                  </td>
                                  <td>
                                    <div className="a-center">
                                      <div>
                                        <input
                                          type="checkbox"
                                          id={`chkUsr_${uc.CaseReportId}`}
                                          className="chk-systemcases"
                                          defaultChecked={uc.IsResolved}
                                          onChange={(e) => OnSystemCheckResolve(e, uc)}
                                        />
                                      </div>
                                      {uc.IsResolved === 1 ? <div>{uc.ResolvedByUser}</div> : <></>}
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SectionExpander>

          <SectionExpander
            props={{
              icon: ico.Stop,
              collapsed: true,
              title: "Banned Members",
              caption: "List of banned members",
            }}
          >
            <div>
              <div>
                <div>
                  <div className="bordered">
                    <div className="pad-md">
                      <div className="tb-pad-sm">
                        <div className="b-pad-sm">
                          <InlineMessageBar props={{ options: msgBan }} />
                        </div>

                        <div ref={refs.refSystemResolve}>
                          <div className="bordered radiused4 pad-md">
                            <div className="r-pad-lg inline-block">
                              <span className="r-pad-md">
                                <input
                                  type="radio"
                                  name="radBan"
                                  id="banCurrent"
                                  value={0}
                                  onChange={OnSelectCurrentBans}
                                />
                                <label htmlFor="banCurrent">Current</label>
                              </span>
                              <span>
                                <input
                                  type="radio"
                                  name="radBan"
                                  id="ban_Lifted"
                                  value={1}
                                  onChange={OnSelectCurrentBans}
                                />
                                <label htmlFor="ban_Lifted">Lifted</label>
                              </span>
                            </div>
                            <span className="r-pad-xl">
                              <button className="refresh" title="Refresh List" onClick={GetBanList}>
                                <span className="font-lg">{ico.Refresh}</span>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div ref={refs.refSystemTableList}>
                        <table className="case-list">
                          <thead>
                            <tr>
                              <th style={{ width: "250px" }}>User</th>
                              <th style={{ width: "250px" }}>Banned By</th>
                              <th>Date / Reason</th>
                            </tr>
                          </thead>
                          <tbody>
                            {banList ? (
                              banList.map((ban) => (
                                <tr key={`ban_${ban.AccountBanId}`}>
                                  <td>
                                    <Link to={`/${ban.UserName}`} onClick={() => u.Ui.ScrollToY()}>
                                      <div className="flex v-center">
                                        <div
                                          className="avatar"
                                          style={{
                                            backgroundImage: `url(${g.Api.CdnRoute}/${ban.UserName}/profile/${ban.UserName}.jpg?v=${ban.PicVer})`,
                                          }}
                                        ></div>
                                        <div className="l-pad-sm">
                                          <div className="font-smaller all-caps">
                                            {ban.FullName}
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/${ban.BannedByUserName}`}
                                      onClick={() => u.Ui.ScrollToY()}
                                    >
                                      <div className="flex v-center">
                                        <div
                                          className="avatar"
                                          style={{
                                            backgroundImage: `url(${g.Api.CdnRoute}/${ban.BannedByUserName}/profile/${ban.BannedByUserName}.jpg?v=${ban.ByPicVer})`,
                                          }}
                                        ></div>
                                        <div className="l-pad-sm">
                                          <div className="font-smaller all-caps">
                                            {ban.ByFullName}
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="color-orange">
                                      <span className="font-smaller">
                                        {u.Date.FormatDate(
                                          ban.DateBanned,
                                          "DDD MM-DD-YY hh:mm:ss a"
                                        )}
                                      </span>
                                      <span className='lr-pad-sm'>&mdash;</span>
                                      <span className="font-smaller">
                                        {u.Date.FormatDate(
                                          ban.DateUntil,
                                          "DDD MM-DD-YY hh:mm:ss a"
                                        )}
                                      </span>
                                    </div>
                                    <div className="font-sm">{ban.Reason}</div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SectionExpander>
        </div>
      </SectionExpander>
      <DialogBox Dialog={dialog} />
    </div>
  );
};

export default React.memo(OtherAdminTasks);
