import React from "react";
//import HiveLogo from "../Components/Widgets/HiveLogo";

const NotFound = () => {
  return (
    <div className="body-container">
      <div className="page">
        <div className="pad-xl">
          {/* <div>
            <HiveLogo props={{ width: "100px" }} />
          </div> */}
          <div className="tb-pad-lg"></div>
          <h1>Not Found</h1>
          <div>The page or resource you are looking for does not exists.</div>
          <div>Or, the administrator has restricted access to it.</div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
