import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import DialogBox from "../Components/Widgets/DialogBox";
import useAccountManager from "../Globals/useAccountManager";
import useCommonUtil from "../Hooks/useCommonUtil";
import { TriggerNavToUserContext } from "../AppContexts";

const CheckBan = () => {
  const [dialog, SetDialog] = useState();
  const am = useAccountManager();
  const u = useCommonUtil();
  const h = useHistory();

  const { SetTriggerNavToUser } = useContext(TriggerNavToUserContext);

  useEffect(() => {
    let myAccount = am.GetMyAccount();
    am.Identity.GetTargetUser((t) => {
      
      if (t.AccountBans) {
        if (t.AccountBans.length === 0) return;

        SetDialog({
          mode: "w",
          title: "Ban Status",
          message: `${t.FirstName} ${
            t.LastName
          } is currently banned. You cannot view this person's profile at the moment.
          This person's account will be lifted on ${u.Date.FormatDate(
            t.AccountBans[0].DateUntil,
            "Ddd MMM DD, YYYY hh:mm:ss a"
          )}.`,
          buttons: [
            {
              id: "ok",
              caption: "Ok",
              OnClick: () => {
                h.push(`/${myAccount.UserName}`);
                SetTriggerNavToUser(u.Security.GenerateRandomString(10));
              },
            },
          ],
        });
      }
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <DialogBox Dialog={dialog} />
    </>
  );
};

export default React.memo(CheckBan);
