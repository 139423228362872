import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { TriggerNavToUserContext } from "../../AppContexts";
import useAccountManager from "../../Globals/useAccountManager";
import useCommonUtil from "../../Hooks/useCommonUtil";

const HiveLogo = (props) => {
  const u = useCommonUtil();
  const h = useHistory();
  const am = useAccountManager();

  const { SetTriggerNavToUser } = useContext(TriggerNavToUserContext);
  const [userName] = useState(am.GetMyUserName());

  const OnNavigateHome = () => {
    u.Ui.ScrollToY(0, () => {
      if (userName) {
        h.push(`/${userName}`);
        SetTriggerNavToUser(u.Security.GenerateRandomString(10));
      } else {
        h.push("/login")
      }
    });
  };

  return (
    <>
      <img
        onClick={() => OnNavigateHome()}
        className="hand"
        style={{ width: props.props ? props.props.width : "100px" }}
        src="/images/hive-logo.svg"
        alt="HIVE"
        title="Home of Interconnected Visionaries Environment"
      />
    </>
  );
};

export default HiveLogo;
