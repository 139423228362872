import React, { useContext, useEffect, useRef, useState } from "react";

import { animateScroll } from "react-scroll";
import { useLayoutEffect } from "react";
import useChatManager from "../Globals/RealtimeComm/useChatManager";
import useCommonUtil from "../Hooks/useCommonUtil";
import useGlobalConstants from "../Globals/useGlobalConstants";
import useAccountManager from "../Globals/useAccountManager";
import { SocketContext } from "../Globals/RealtimeComm/WebSocketReducer";
import useDataModels from "../Globals/useDataModels";
import useNotificationManager from "../Globals/RealtimeComm/useNotificationManager";
import InlineMessageBar from "../Components/Widgets/InlineMessageBar";
import useReactIcons from "../Hooks/useReactIcons";
import { TriggerNavToUserContext } from "../AppContexts";
import { Link } from "react-router-dom";
import DialogBox from "../Components/Widgets/DialogBox";

const Messages = () => {
  const { SetTriggerNavToUser } = useContext(TriggerNavToUserContext);

  const cm = useChatManager();
  const u = useCommonUtil();
  const am = useAccountManager();
  const g = useGlobalConstants();
  const dm = useDataModels();
  const noti = useNotificationManager();
  const ico = useReactIcons();

  const [myAccount] = useState(am.GetMyAccount());
  const v = u.Security.GenerateRandomString(10); //avatar version to keep the latest cached

  const { HubConnection, HubDispatch } = useContext(SocketContext);
  const hmethod = g.Hub.ReducerMethods;

  const [contactList, setContactList] = useState([]);
  const [msgList, setMsgList] = useState([]);
  const [chatList, setChatList] = useState();
  const [searchList, setSearchList] = useState();
  const [dialog, setDialog] = useState();

  const [selectedGroup, setSelectedGroup] = useState();
  //const [activeGroup, setActiveGroup] = useState();

  const [chatAvatar, setChatAvatar] = useState();

  const [chatSizeSynced, setChatSizeSynced] = useState(false);

  const [progConve, setProgConve] = useState({
    mode: "r",
    message: "",
    isLoading: false,
  });
  const [progSender, setProgSender] = useState({
    message: "",
    mode: "p",
  });

  const Refs = {
    msgRef: useRef(),
    refChatEntry: useRef(),
    ulChatRef: useRef(),
    chatPanel: useRef(),
    messagesEndRef: useRef(),
    txtSearchPeople: useRef(),
    searchPanelRef: useRef(),
  };

  const closureRefs = {
    refActiveGroup: useRef(),
  };

  useLayoutEffect(() => {
    const au = am.Identity.GetAuthHeader();
    if (!au) {
      am.LogMeOff();
      return;
    }

    am.ValidateAccount(() => {
      GetMyChatContacts();
      setChatAvatar({
        MyAvatar: `${g.Api.CdnRoute}/${am.GetMyUserName()}/profile/${
          myAccount.UserName
        }.jpg?v=${myAccount.PicVer}`,
        TalkAvatar: "",
        TalkName: "",
      });
    });

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "scroll";
    };

    // eslint-disable-next-line
  }, []);

  const GetMyChatContacts = () => {
    cm.GetMyChatContacts((data, err) => {
      setChatList(data.MembersOf);
    });
  };

  const OnCheckNewMessages = () => {
    cm.GetIfHasUnreadMessages((err) => {
      console.log(err);
    });
  };

  const AppendNewMessage = (msgModel) => {
    let newMsg = {
      MessageId: u.Security.GenerateRandomString(7),
      MessageBody: msgModel.MessageBody,
      DateSent: u.Date.FormatDate(new Date(), "MM-DD-YYYY hh:mm:ss a"),
      UserName: msgModel.UserName,
    };

    setMsgList((prevList) => [...prevList, newMsg]);
    noti.ShowMessage(false);
  };

  let throtSize;
  const ResizeChatArea = () => {
    clearTimeout(throtSize);

    throtSize = setTimeout(() => {
      let contact_list = document.querySelector(".contact-list");
      //let chat_list = document.querySelector(".chat-list").getBoundingClientRect();
      let chat_panel = document.querySelector(".chat-panel");

      if (chat_panel && contact_list) {
        contact_list.style.height = `${window.innerHeight - 150}px`;
        chat_panel.style.height = `${window.innerHeight - 290}px`;
      }
    }, 10);
  };

  const ReconnectToChat = (headerId) => {
    if (selectedGroup || headerId) {
      HubDispatch({
        method: hmethod.INVOKE,
        payload: {
          ServerMethod: "ServerJoinConversation",
          Args: [selectedGroup.GroupGuid],
        },
        callBack: () => {
          console.log(`Conversation joined ${selectedGroup.GroupGuid}`);
        },
      });
    }
  };

  const OnSelectContact = (contact, e) => {
    if (e.currentTarget.classList.contains("active")) return;

    setMsgList(null);
    ResizeChatArea();

    Refs.msgRef.current.value = "";
    setSelectedGroup(contact);

    setChatAvatar({
      ...chatAvatar,
      TalkAvatar:
        contact.GroupType === 2
          ? `${g.Api.CdnRoute}/${contact.UserName}/profile/${contact.UserName}.jpg?v=${v}`
          : "/images/group.jpg",
      TalkName: contact.GroupName,
    });

    u.Ui.makeMenuItemActive(e.currentTarget);
    e.currentTarget.classList.remove("has-message");
    Refs.refChatEntry.current.classList.remove("no-touch");
  };

  const OnPressEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      OnSendMessage();
    }
  };

  const OnSendMessage = () => {
    //ResizeChatArea();
    const grp = closureRefs.refActiveGroup.current;

    let txt = Refs.msgRef.current.value.trim();
    console.log(txt, grp);

    if (!txt || !grp) return;

    let msg = dm.HubMessageDto;
    msg.MessageId = 0;
    msg.UserName = myAccount.UserName;
    msg.MessageBody = u.Data.ParseLinks(txt);
    msg.HubGroupId = grp.HubGroupId;
    msg.GroupGuid = grp.GroupGuid;

    //reset it
    Refs.msgRef.current.value = "";

    //console.log(msg);

    HubDispatch({
      method: hmethod.INVOKE,
      payload: {
        ServerMethod: "ServerSendMessageToGroup",
        Args: [msg],
      },
    });
  };
  /* #region :: EFFECTS */
  //check messages
  useEffect(() => {
    OnCheckNewMessages();

    if (!chatSizeSynced) {
      setChatSizeSynced(true);
      ResizeChatArea();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    animateScroll.scrollToBottom({
      containerId: "chatPanel",
      offset: 100,
    });
    // eslint-disable-next-line
  }, [msgList]);

  // Client invocations from server
  useEffect(() => {
    //setProgConve({ mode: "p", message: "" });

    HubConnection.on("ClientJoinedGroup", (vw) => {
      setProgConve({ isLoading: false });
    });

    HubConnection.on("clientReceiveGroupMessage", (msgModel) => {
      //console.log("clientReceiveGroupMessage", msgModel);
      const grp = closureRefs.refActiveGroup.current;
      if (grp) {
        if (msgModel.HubGroupId === grp.HubGroupId) {
          AppendNewMessage(msgModel);
        } else {
          noti.ShowMessage(msgModel.UserName !== myAccount.UserName);
        }
      } else {
        noti.ShowMessage(true);
      }
    });

    HubConnection.on("GroupReceiveMessage", (model) => {
      console.log(model);
    });

    HubConnection.on("ClientNewMessageHeader", (msgHeader) => {
      console.log(msgHeader);
      setContactList((prevList) => [msgHeader, ...prevList]);
    });

    HubConnection.onreconnected(() => {
      ReconnectToChat();
    });

    window.addEventListener("resize", ResizeChatArea);

    //IMPORTANT. if this is not done, going to this page wil add another, the same handler.
    return () => {
      setChatSizeSynced(false);
      //HubConnection.onreconnected(null);
      window.removeEventListener("resize", ResizeChatArea);

      HubConnection.off("clientReceiveGroupMessage");
    };

    // eslint-disable-next-line
  }, []);

  // [ActiveGroup]
  useEffect(() => {
    //console.log(activeGroup);
    if (!closureRefs.refActiveGroup.current) return;

    HubDispatch({
      method: hmethod.INVOKE,
      payload: {
        ServerMethod: "ServerJoinGroupChat",
        Args: [closureRefs.refActiveGroup.current],
      },
    });
  }, [closureRefs.refActiveGroup.current]);

  // detect undreads
  const DetectUnreads = () => {
    const ulGroups = document.querySelectorAll(
      "ul.groups .has-message"
    )?.length;
    if (ulGroups === 0) {
      noti.ShowMessage(false);
    }
  };

  // [selectedGroup]
  useEffect(() => {
    if (selectedGroup) {
      setProgConve({ isLoading: true });
      const gMsg = dm.GroupData;
      gMsg.HubGroupId = selectedGroup.HubGroupId;
      gMsg.UserName = myAccount.UserName;
      gMsg.GroupGuid = selectedGroup.UserName;
      gMsg.GroupType = selectedGroup.GroupType;

      fetch(`${g.Api.HubApi}/GetConversationAsync`, {
        headers: am.Identity.GetAuthHeader(),
        method: g.Fetch.POST,
        body: JSON.stringify(gMsg),
      })
        .then((r) => {
          return r.ok ? r.json() : null;
        })
        .then((r) => {
          if (r.Succeeded) {
            r.ModelData.forEach((m) => {
              m.DateSent = u.Date.FormatDate(
                m.DateSent,
                "DDD MMM-DD-YYYY hh:mm:ss a"
              );
            });

            closureRefs.refActiveGroup.current = {
              HubGroupId: selectedGroup.HubGroupId,
              GroupGuid: selectedGroup.GroupGuid,
            };
            setMsgList(r.ModelData);
            DetectUnreads(selectedGroup);
            setProgConve({ isLoading: false });
          } else {
            setDialog({
              mode: "e",
              title: "error",
              message: "An error has occured while getting messages.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [selectedGroup]);

  //when searching contacts
  const OnSearchStartTyping = (e) => {
    const key = e.target?.value.toLowerCase();
    if (key.length > 2) {
      const list = u.Data.GetData(g.DbKeys.MyChatContacts)?.MembersOf;

      const results = list.filter((s) =>
        s.GroupName.toLowerCase().includes(key)
      );

      if (results.length) {
        setChatList(results);
      }
    } else {
      setChatList(u.Data.GetData(g.DbKeys.MyChatContacts)?.MembersOf);
    }
  };

  const OnSelectSearch = (e) => {
    console.log(e);

    let target = contactList.find((f) => f.Cn === e);
    //console.log('target', target);
    if (!target) {
      //index is -(negative when not found)
      console.log("Creating new message header...");
      let contact = chatList.find((c) => c.Cn === e);

      let msgModel = dm.GroupMessageVw;

      msgModel.From = `${myAccount.FirstName} ${myAccount.LastName}`;
      msgModel.GId = contact.Cn;
      msgModel.Body = "[Conversation Started]";

      //reset it
      Refs.msgRef.current.value = "";

      HubDispatch({
        method: hmethod.INVOKE,
        payload: {
          ServerMethod: "ServerCreateGroup",
          Args: [msgModel],
        },
        callBack: () => {
          //plot it
        },
      });
    } else {
      console.log("not in chat list yet.");
    }
  };

  const OnFocusSearch = (e) => {
    Refs.searchPanelRef.current.classList.add("shown");
  };

  const OnExitSearch = (e) => {
    Refs.searchPanelRef.current.classList.remove("shown");
  };
  /* #endregion */

  const OpenProfile = () => {
    SetTriggerNavToUser(u.Security.GenerateRandomString(10));
  };

  const ChatToolView = () => {
    return (
      <div className="chat-tool flex a-end v-center hand">
        <div className="a-right pad-sm">
          {ico.PlusCircle} Add Group 
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="pad-sm">
          <div>
            <input
              type="text"
              placeholder="search"
              className="search-msg"
              // onFocus={OnFocusSearch}
              // onBlur={OnExitSearch}
              onChange={OnSearchStartTyping}
              ref={Refs.txtSearchPeople}
            />
          </div>
        </div>
      </div>
      <div className="flex-pane">
        <div className="contact-list">          
          <div>
            {chatList ? (
              <ul className="groups">
                {chatList.map((m) => (
                  <li
                    key={`p${m.GroupGuid}`}
                    className={m.HasMessage ? "has-message" : ""}
                    onClick={(e) => OnSelectContact(m, e)}
                  >
                    {m.GroupName}
                  </li>
                ))}
              </ul>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="chat-list">
          <div className="chat-title">
            <div className="flex p-center">
              <div>
                <div className="pad-md">
                  <div
                    className="chat-avatar big"
                    style={{ backgroundImage: `url(${chatAvatar?.TalkAvatar}` }}
                  ></div>
                </div>
              </div>
              <div>
                <div className="font-bold all-caps">
                  <InlineMessageBar props={{ options: progConve }} />
                  <span>{chatAvatar?.TalkName}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="chat-panel" ref={Refs.chatPanel} id="chatPanel">
              <div className="pad-md">
                {msgList ? (
                  <ul>
                    {msgList?.map((m) => (
                      <li key={`m-${m.MessageId}`} className="chat-messages">
                        <div>
                          <div
                            className={`flex still nowrap v-center a-end`}
                            style={{ marginBottom: "30px" }}
                          >
                            <div
                              className={
                                m.UserName === myAccount.UserName
                                  ? "my-message"
                                  : "their-message"
                              }
                            >
                              <div className={`font-smaller color-mid-gray`}>
                                <span>{m.DateSent}</span>
                                <span className="l-pad-sm">
                                  {m.UserName === myAccount.UserName ? (
                                    <>
                                      {myAccount.FirstName} {myAccount.LastName}
                                    </>
                                  ) : (
                                    <>{selectedGroup.Fn}</>
                                  )}
                                </span>
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: m.MessageBody,
                                }}
                              ></div>
                            </div>
                            {m.UserName !== myAccount.UserName ? (
                              <div className="chat-link inline-block">
                                <Link
                                  to={`/${m.UserName}`}
                                  onClick={OpenProfile}
                                >
                                  <div
                                    className="chat-avatar"
                                    style={{
                                      backgroundImage: `url('${g.Api.CdnRoute}/${m.UserName}/profile/${m.UserName}.jpg?v=${v}')`,
                                    }}
                                  ></div>
                                </Link>
                              </div>
                            ) : (
                              <div className="chat-link inline-block">
                                <Link
                                  to={`/${myAccount.UserName}`}
                                  onClick={OpenProfile}
                                >
                                  <div
                                    className="chat-avatar"
                                    style={{
                                      marginLeft: "10px",
                                      marginTop: "10px",
                                      backgroundImage: `url('${chatAvatar.MyAvatar}')`,
                                    }}
                                  ></div>
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* <div className="pad-md media-bar">
              <span className="font-lg">{ico.Images}</span>
            </div> */}
            <div className="msg-input" ref={Refs.refChatEntry}>
              <div className="flex flex-end still v-center">
                <div className="full-width">
                  <textarea
                    className="chat-box"
                    maxLength="250"
                    rows="2"
                    ref={Refs.msgRef}
                    placeholder="Type message here"
                    onKeyDown={(e) => OnPressEnter(e)}
                  ></textarea>
                </div>
                <div className="a-center">
                  <div
                    className="send-btn hand pad-sm"
                    onClick={(e) => OnSendMessage(e)}
                  >
                    <span style={{ fontSize: "2em", marginRight: "10px" }}>
                      {ico.Send}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogBox Dialog={dialog} key={"Messages"} />
    </>
  );
};

export default Messages;

//F#
