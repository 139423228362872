/* eslint-disable */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./MyCaseReports.css";

import InlineMessageBar from "../../Components/Widgets/InlineMessageBar";
import { useState } from "react";
import { SectionExpander } from "../../Components/Widgets/SectionExpander";
import useReactIcons from "../../Hooks/useReactIcons";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import useAccountManager from "../../Globals/useAccountManager";
import useCommonUtil from "../../Hooks/useCommonUtil";

const MyCaseReports = () => {
  const g = useGlobalConstants();
  const am = useAccountManager();
  const u = useCommonUtil();

  const [msg, setMsg] = useState({ message: "Ready", mode: "i" });
  //const [dialog, setDialog] = useState({ mode: "cl" });
  const ico = useReactIcons();

  const [myCaseList, setSystemCaseList] = useState();

  const GetMyCaseList = () => {
    setMsg({ mode: "p", message: "Fetching your case reports..." });
    fetch(`${g.Api.ApiRoute}/user/GetMyCaseReportsAsync`, {
      method: "GET",
      headers: am.Identity.GetAuthHeader(),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        if (!j) {
          return;
        }
        if (j.Succeeded) {
          setMsg({ mode: "s", message: j.Message });
          setSystemCaseList(j.ModelData);
        }
      });
  };
  useEffect(() => {
    GetMyCaseList();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="tb-pad-md my-case-reports">
      <SectionExpander
        props={{
          icon: ico.Report,
          title: "My Case Reports",
          titleSize: "2em",
          caption: "Below is a list of your reported cases",
        }}
      >
        <div className="tb-pad-md">
          <div className="b-pad-md">
            <InlineMessageBar props={{ options: msg }} />
          </div>
          <table className="case">
            <thead>
              <tr>
                <th style={{ width: "60px" }}>CASE #</th>
                <th style={{ width: "55px" }}>Resolved</th>
                <th>Date</th>
                <th>Concern</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {myCaseList ? (
                myCaseList.map((cas) => (
                  <tr
                    key={`uc_${cas.CaseReportId}`}
                    //className={`${cas.IssueType === 1 ? "user" : "system"}`}
                  >
                    <td>
                      <div className="a-center">{cas.CaseReportId}</div>
                    </td>
                    <td className="a-center font-smaller font-bold">
                      {cas.IsResolved === 1 ? (
                        <span className="color-green">YES</span>
                      ) : (
                        <span className="color-red">NO</span>
                      )}
                    </td>
                    <td>
                      <div>
                        <span className="font-smaller">
                          {u.Date.FormatDate(cas.DateSubmitted, "DDD MM-DD-YY hh:mm a")}
                        </span>
                      </div>
                    </td>
                    <td className="font-smaller all-caps font-bold">
                      <div>
                        {cas.IssueType === 2 ? (
                          <div>System</div>
                        ) : (
                          <div>
                            <div className="b-pad-md">User</div>
                            
                            <Link to={`/${cas.TargetUserName}`}>
                              <div>
                                <div
                                  className="avatar"
                                  style={{
                                    backgroundImage: `url(${g.Api.CdnRoute}/${
                                      cas.TargetUserName
                                    }/profile/${
                                      cas.TargetUserName
                                    }.jpg?v=${u.Security.GenerateRandomString(10)})`,
                                  }}
                                ></div>
                                <div>{cas.TargetFullName}</div>
                              </div>
                            </Link>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      {cas.IsResolved === 1 ? <div>{cas.ResolvedByUser}</div> : <></>}
                      <div>
                        <div className="font-sm">{cas.Reason}</div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </SectionExpander>
    </div>
  );
};

export default MyCaseReports;
