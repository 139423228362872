import React from "react";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import "./CookiePolicy.css";

const CookiePolicy = () => {
  const g = useGlobalConstants();
  const tm = `${g.AppName.TRADE_MARK}`;

  return (
    <div className="cookie-policy">
      <div className="pad-sm">
        <h2 className="font-bold">Cookie and Local Storage Policy</h2>
        <p>Updated: Wednesday, May 05, 2021</p>
        <hr />
        <div className="tb-pad-md">
          <p>
            <strong>What Are Cookies in Computing?</strong>
          </p>

          <p>
            Cookies are used by many online companies. From gathering the technology you use,
            browser version, or location (if you permit it), just to name a few. Different companies
            use cookies differently, and so do we, here in {tm}
          </p>

          <p>
            As a common practice with almost all professional websites, this site uses cookies,
            which are tiny files that are downloaded to your computer, that is, to greatly improve
            your experience. This page describes what information we gather, how we use it and why
            we sometimes need to store these cookies.
          </p>

          <p>
            For more general information on cookies see the Wikipedia article on{" "}
            <a
              href="https://en.wikipedia.org/wiki/HTTP_cookie"
              target="_blank"
              rel="noopener noreferrer"
            >
              HTTP Cookies
            </a>
            .
          </p>

          <p>
            <strong>How We Use Cookies</strong>
          </p>

          <p>
            We use cookies for a variety of reasons detailed below. Unfortunately in most cases
            there are no industry standard options for disabling cookies without completely
            disabling the functionality and features they add to this site. It is recommended that
            you leave on all cookies if you are not sure whether you need them or not in case they
            are used to provide a service that you use.
          </p>

          <p>
            <strong>The Cookies We Use/Set</strong>
          </p>

          <ul>
            <li>
              <p>
                <strong>
                  <em>Account related cookies</em>
                </strong>
              </p>
              <p>
                If you register or create an account with this website, then we will use cookies for
                the management of the signup process and general administration. These cookies will
                usually be deleted when you log out, however in some cases they may remain
                afterwards just to remember your site preferences when logged out.
              </p>
            </li>

            <li>
              <p>
                <strong>
                  <em>Login related cookies and local store</em>
                </strong>
              </p>
              <p>
                We use local storage instead of cookies when you are logged-in so that the
                website/browser can remember this fact. This prevents you from having to log-in
                every single time you visit a new page. These stored data are typically removed or
                cleared when you log out to ensure that you can only access restricted features and
                areas when logged in.
              </p>
            </li>

            <li>
              <p>
                <strong>
                  <em>Email newsletters related cookies</em>
                </strong>
              </p>
              <p>At the moment, we don't utilize email messages nor services.</p>
              {/* <p>
                This site offers newsletter or email subscription services and cookies may be used
                to remember if you are already registered and whether to show certain notifications
                which might only be valid to subscribed/unsubscribed users.
              </p> */}
            </li>

            <li>
              <p>
                <strong>
                  <em>Survey-related cookies</em>
                </strong>
              </p>
              <p>
                This feature isn't available yet, but if so in the near future -- from time to time
                we offer user surveys and questionnaires to provide you with interesting insights,
                helpful tools, or to understand our users more accurately. These surveys may use
                cookies to remember who has already taken part in a survey or to provide you with
                accurate results after you change pages.
              </p>
            </li>

            <li>
              <p>
                <strong>
                  <em>Forms related cookies</em>
                </strong>
              </p>
              <p>
                When you submit data to through a form such as those found on About Me page or
                comment forms, cookies may be set to remember your user details or preference for
                future correspondence.
              </p>
            </li>

            <li>
              <p>
                <strong>
                  <em>Site preferences cookies</em>
                </strong>
              </p>
              <p>
                In order to provide you with a great experience on this site, we provide the
                functionality to set your preferences for how this site runs when you use it. In
                order to remember your preferences we need to set cookies so that this information
                can be called whenever you interact with a page is affected by your preferences. For
                example, when you visit your account page, the menu on the left can be collapsed or
                expanded. When you leave that page, and revisit it again, the state of the menu will
                be applied, same as the last time you visit it.
              </p>
            </li>
          </ul>

          <p>
            <strong>Third Party Cookies</strong>
          </p>

          <p>
            Google font free subscription will embed or leave a cookie regarding the font (linked)
            in this website for usage statistics.
          </p>

          <p>
            <strong>More Information</strong>
          </p>

          <p>
            We hope that this cookie policy statement has clarified things for you, and as was
            previously mentioned if there is something that you aren't sure whether you need or not,
            it is usually safer to leave cookies enabled in case it does interact with one of the
            features you use on this site.
          </p>

          <p>
            If you are still looking for more information, then you can contact us through one of
            our preferred contact methods:
          </p>

          <ul>
            <li>Email: contact@hivepte.ltd</li>
          </ul>
        </div>

        <p>{"//"} End of Cookie Policy statement. Nothing follows.</p>
      </div>
    </div>
  );
};

export default React.memo(CookiePolicy);
