import React from "react";

const MyEducations = () => {
  //const [m1, setMsg] = useState({ message: "Ready", mode: "i" });

  return (
    <>
      <div className="tb-pad-lg">
        <h1>Work {"&"} Education</h1>

        <hr />
      </div>
    </>
  );
};

export default MyEducations;
