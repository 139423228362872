import React, { useContext, useEffect, useRef, useState } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { SocketContext } from "../../Globals/RealtimeComm/WebSocketReducer";
import useAccountManager from "../../Globals/useAccountManager";
import useFetchErrorHandler from "../../Globals/useFetchErrorHandler";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import useCommonUtil from "../../Hooks/useCommonUtil";
import useReactIcons from "../../Hooks/useReactIcons";
import "./ReactionPanel.css";

const ReactionPanel = (props) => {
  const [post] = useState(props.story);
  const fe = useFetchErrorHandler();
  const ico = useReactIcons();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const myAccount = am.GetMyAccount();
  const u = useCommonUtil();
  const { HubConnection, HubDispatch } = useContext(SocketContext);

  const [usersReaction, setUsersReaction] = useState();
  const [reactionSummary, setReactionSummary] = useStateIfMounted();

  const [postStatus, setPostStatus] = useState({ succeeded: true, message: "" });

  const refs = {
    refMore: useRef(),
    reactionSummary: useRef(),
  };

  let tmr;

  const OnReactStory = (emoteId) => {
    if (refs.refMore.current) {
      refs.refMore.current.classList.add("hidden");
    }

    if (post) {
      let _payLoad = {
        PostId: post.PostId,
        ReactionTypeId: emoteId,
        UserName: myAccount.UserName,
        TargetUserName: post.UserName,
      };

      fetch(`${g.Api.ApiRoute}/postapi/ReactOnStoryAsync`, {
        method: g.Fetch.POST,
        headers: am.Identity.GetAuthHeader(),
        body: JSON.stringify(_payLoad),
      })
        .then((r) => {
          if (r && r.ok) {
            return r.json();
          } else {
            fe.CatchResponse(r, (m) => {
              //console.log(m);
              setPostStatus({ succeeded: false, message: m });
            });
          }
        })
        .then((r) => {
          if (r) {
            if (r.Succeeded) {
              let reactDto = r.ModelData;
              //console.log(reactDto);

              if (reactDto.ReactionTypeId === -1) {
                let newList = usersReaction.filter((f) => f.UserName !== reactDto.UserName);
                //console.log(newList);
                setUsersReaction(newList);
              } else {
                reactDto.FullName = am.GetMyFullName();
                if (usersReaction) {
                  let newList = usersReaction.filter((f) => f.UserName !== reactDto.UserName);
                  setUsersReaction([reactDto, ...newList]);
                } else {
                  setUsersReaction([reactDto]);
                  //setUsersReaction([reactDto]);
                }

                //send to receiver
                reactDto.Message = post.PostTitle;
                //reactDto.UserName = post.UserName;
                reactDto.TargetUserName = post.UserName;
                reactDto.Type = g.NotificationType.Reaction;

                HubDispatch({
                  method: g.Hub.ReducerMethods.INVOKE,
                  payload: {
                    ServerMethod: "ServerSendReaction",
                    Args: [reactDto],
                  },
                  callBack: () => {
                    refs.reactionSummary.current.Summary.map((m) => (m.ImHere = false));
                    HubDispatch({
                      method: g.Hub.ReducerMethods.INVOKE,
                      payload: {
                        ServerMethod: "ServerUpdateReactionPanel",
                        Args: [post.UserName, refs.reactionSummary.current],
                      },
                    });
                  },
                });
              }
            } else {
              setPostStatus({ succeeded: false, message: r.Message });
              tmr = setTimeout(() => {
                setPostStatus({ succeeded: true, message: "" });
              }, 5000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const showEmoticons = () => {
    if (refs.refMore.current) {
      //.toggle cannot be used here sinc document has also control.

      if (refs.refMore.current.classList.contains("hidden")) {
        refs.refMore.current.classList.remove("hidden");
      } else {
        refs.refMore.current.classList.add("hidden");
      }
    }
  };

  function merge(arr1, arr2) {
    // Merge
    const merged = [...arr1, ...arr2];
    const out = [];

    // iterate
    for (let obj of merged) {
      // Destruct the object to get ReactionTypeId
      //const { ReactionTypeId, Count } = obj;

      const { ReactionTypeId } = obj;

      // find ReactionTypeId
      const found = out.find((obj) => obj.ReactionTypeId === ReactionTypeId);

      // If an object *is* found add this object's quantity to it...
      if (!found) {
        out.push({ ...obj });
      } else {
        //found.Count++;
      }
    }

    return out;
  }

  const ReplotSummary = (data_summary) => {
    if (!data_summary) return;

    if (data_summary.PostId === post.PostId) {
      if (data_summary) {
        refs.reactionSummary.current.Summary = merge(refs.reactionSummary.current.Summary, data_summary.Summary);
        setReactionSummary(refs.reactionSummary.current.Summary);
      } else {
        setPostStatus({ succeeded: false, message: "Server can't transmit reaction." });
      }
    }
  };

  useEffect(() => {
    HubConnection.on("clientUpdateReactionPanel", (d) => ReplotSummary(d));

    return () => {
      HubConnection.off("clientUpdateReactionPanel", ReplotSummary);
    };
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    if (post) {
      if (props.story.Reactions) setUsersReaction(props.story.Reactions);
    }
    return () => {
      setTimeout(tmr);
    };
    // eslint-disable-next-line
  }, [post]);

  useEffect(() => {
    if (usersReaction) {
      //console.log(usersReaction);
      let group = u.Data.GroupBy(usersReaction, "ReactionTypeId");
      let summary = [];
      for (let key in group) {
        let o = {
          ReactionTypeId: parseInt(key),
          Count: group[key].length,
          ImHere: group[key].findIndex((f) => f.UserName === myAccount.UserName) >= 0,
        };
        summary.push(o);
      }

      refs.reactionSummary.current = { Summary: summary, PostId: post.PostId };

      setReactionSummary(refs.reactionSummary.current.Summary);
    }
    // eslint-disable-next-line
  }, [usersReaction]);

  return (
    <>
      <div id={`post_reaction_${post.PostId}`} className="reaction-panel">
        <div>
          {reactionSummary
            ? reactionSummary.map((r) => (
                <div key={`k_${r.ReactionTypeId}`} className="inline-block">
                  <div className={`${r.ImHere ? "color-theme" : ""}`}>
                    <span
                      className="font-xl r-pad-sm hand"
                      onClick={() => OnReactStory(r.ReactionTypeId)}
                      title={`${r.ImHere ? "You reacted with this emoticon" : ""}`}
                    >
                      {ico.GetReactionIcon(r.ReactionTypeId).ico}
                    </span>
                    <span className="r-pad-lg">{r.Count}</span>
                  </div>
                </div>
              ))
            : ""}

          <span className="r-pad-md"></span>
          {usersReaction ? (
            <button onClick={showEmoticons}>
              <span className="font-xl">{ico.ReactionPlus}</span>
            </button>
          ) : (
            ""
          )}
        </div>
        {!postStatus.succeeded ? <div className="font-smaller color-red">{postStatus.message}</div> : ""}
        <div className="more hidden" ref={refs.refMore}>
          <div className="pad-md">
            <ul>
              <li>
                <div className="a-right">
                  <span className="close" onClick={showEmoticons}>
                    {ico.CloseCircle}
                  </span>
                </div>
                <hr />
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnReactStory(10)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ThumbUp}</span>
                      <span className="font-sm">Like</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnReactStory(11)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.Heart}</span>
                      <span className="font-sm">Love</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnReactStory(1)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionAngry}</span>
                      <span className="font-sm">Angry</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnReactStory(2)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionBaffled}</span>
                      <span className="font-sm">Baffled</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnReactStory(3)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionConfused}</span>
                      <span className="font-sm">Confused</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnReactStory(4)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionCrySad}</span>
                      <span className="font-sm">Cry Lonely</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnReactStory(5)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionFrustrated}</span>
                      <span className="font-sm">Frustrated</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnReactStory(6)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionGrin}</span>
                      <span className="font-sm">Grin</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnReactStory(7)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionHappy}</span>
                      <span className="font-sm">Happy</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnReactStory(8)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionHeartBroken}</span>
                      <span className="font-sm">Heart Broken</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnReactStory(9)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionLaugh}</span>
                      <span className="font-sm">Laugh</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnReactStory(12)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionSad}</span>
                      <span className="font-sm">Sad</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnReactStory(13)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionWow}</span>
                      <span className="font-sm">Shocked/Wow</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnReactStory(14)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionGrin2}</span>
                      <span className="font-sm">Tongue</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ReactionPanel);
