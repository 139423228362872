import React from "react";
import FooterBar from "../../Components/FooterBar";
import "./AppLayout.css";

const AppLayout = (props) => {
  // const FadeTransition = (props) => (
  //   <Transition {...props} classNames="example" in={true} timeout={500} />
  // );

  return (
    <>      
      {/* <MainNav/> */}
      <div>{props.children}</div>
      <FooterBar/>
    </>
  );
};

export default AppLayout;
