import React from "react";
import HiveLogo from "../../Components/Widgets/HiveLogo";

import "./InfoLayout.css";

const InfoLayout = (props) => {
  return (
    <>
      <div className="info-layout">
        <div className="body-container">
          <div className="pad-lg">
            <div>
              <HiveLogo />
            </div>
          </div>
          <hr />
          <div>
            <div className="pad-lg">{props.children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoLayout;
