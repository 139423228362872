import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import useReactIcons from "../../Hooks/useReactIcons";
import "./InlineMessageBar.css";

const InlineMessageBar = (props) => {
  const refLabels = {
    mainLabel: useRef(),
    container: useRef(),
  };

  const [icon, setIcon] = useState();
  const ico = useReactIcons();
  const [opt, setOpt] = useState();

  useEffect(() => {
    let options = props.props.options;
    if (!options) {
      return;
    }

    setOpt(options);

    let t,
      d = 2000;
    let container = refLabels.container.current;

    if (options) {
      container.classList.remove("hide");
      //container.classList.add("hide");
      // setTimeout(() => {
      //   container.classList.remove("hide");
      // }, 150);

      switch (options.mode) {
        case "i":
        case "r":
          setIcon(ico.InfoBlue);
          break;
        case "l":
          setIcon(ico.PadLock);
          break;
        case "d":
          setIcon(ico.CloudDownloadA);
          break;
        case "u":
          setIcon(ico.CloudUploadA);
          break;
        case "p":
          setIcon(ico.CircleSpinner);
          break;
        case "g":
          setIcon(ico.GearSpin);
          break;
        case "e":
          setIcon(ico.Error);
          break;
        case "s":
          setIcon(ico.CheckGreen);
          break;
        case "w":
          setIcon(ico.WarningOrange);
          break;
        case "q":
          setIcon(ico.Question);
          break;
        case "rel":
          setIcon(ico.Refresh);
          break;
        case "h":
          setIcon("");
          container.classList.add("hide");
          break;
        default:
          setIcon("");
          //container.classList.add("hide");
          break;
      }

      if (options.tl) {
        t = setTimeout(() => {
          container.classList.add("hide");
        }, options.tl);
      }
    } else {
      t = setTimeout(() => {
        container.classList.add("hide");
      }, d);
    }

    return () => {
      clearTimeout(t);
    };
    // eslint-disable-next-line
  }, [props.props.options]);

  const dummyEvent = () => {};

  return (
    <>
      <div ref={refLabels.container} className="inline-messagebar">
        <div className="padflex v-center">
          {opt ? (
            <>
              <span
                style={{ fontSize: "x-large" }}
                className={`${opt ? "r-pad-sm" : ""}`}
              >
                {icon}
              </span>
              {opt.message ? (
                <div
                  className="inline-block"
                  dangerouslySetInnerHTML={{ __html: opt.message }}
                ></div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {opt ? (
            opt.mode === "q" ? (
              <div className="l-pad-sm">
                <input
                  type="button"
                  value="No"
                  onClick={opt.OnNo ? opt.OnNo : dummyEvent}
                />
                <input
                  type="button"
                  value="Yes"
                  onClick={opt.OnNo ? opt.OnYes : dummyEvent}
                />
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default InlineMessageBar;
