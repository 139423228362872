import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { TriggerNavToUserContext } from "../AppContexts";
import useNotificationManager from "../Globals/RealtimeComm/useNotificationManager";
import { SocketContext } from "../Globals/RealtimeComm/WebSocketReducer";
import useAccountManager from "../Globals/useAccountManager";
import useGlobalConstants from "../Globals/useGlobalConstants";
import useCommonUtil from "../Hooks/useCommonUtil";
import useReactIcons from "../Hooks/useReactIcons";
import "./UniBroadCastSection.css";
import WallMediaViewer from "./WallMediaViewer";
import { useStateIfMounted } from "use-state-if-mounted";
import ShowMoreText from "./Widgets/ShowMoreText";

const UniBroadCastSection = () => {
  const { HubConnection } = useContext(SocketContext);
  const ico = useReactIcons();
  const [broadcastList, setBroadcastList] = useStateIfMounted();
  const n = useNotificationManager();
  const u = useCommonUtil();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const { SetTriggerNavToUser } = useContext(TriggerNavToUserContext);

  const [wallAlbum, setWallAlbum] = useState();

  const CLIENT_METHODS = {
    clientReceiveUniBroadCast: "clientReceiveUniBroadCast",
    clientRemoveAnnouncement: "clientRemoveAnnouncement",
  };

  const refList = useRef();

  useEffect(() => {
    const _aborter = new AbortController();

    RegisterHubEvents();
    FetchAnnouncements(_aborter);

    return () => {
      _aborter.abort();
      // console.log(`Unsubscribe : SignarlR events`);
      // HubConnection.off(CLIENT_METHODS.clientReceiveUniBroadCast);
      // HubConnection.off(CLIENT_METHODS.clientRemoveAnnouncement);
      // setBroadcastList(null);
      // setWallAlbum(null);
      // refList.current = null;
    };

    // eslint-disable-next-line
  }, []);

  const FetchAnnouncements = (aborter) => {
    fetch(`${g.Api.ApiRoute}/postapi/GetAnnouncementsAsync`, {
      method: "GET",
      aborter: aborter.signal,
      headers: am.Identity.GetAuthHeader(),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        if (!j) {
          console.log("An error occured.");
          return;
        }

        if (j.Succeeded) {
          refList.current = j.ModelData;
          setBroadcastList(j.ModelData);
          //console.log(j);
        } else {
          console.log(j);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const PlotAnnouncement = (post) => {
    n.StoreNotification(
      {
        Id: `post_${post.PostId}`,
        Type: g.NotificationType.Announcement,
        Author: `${post.FullName}`,
        Message: `${post.PostTitle} : ${post.PostBody.substring(0, 125)}...`,
        Read: 0,
      },
      (data) => {
        if (data) {
          console.log(data);
        }
        n.ShowBell(true);
      }
    );
    //n.ShowBell(true);
    //console.log(refList.current);

    if (refList.current.length) {
      let found = refList.current.findIndex((f) => f.PostId === post.PostId);
      console.log(found);
      if (found >= 0) {
        let newList = refList.current.filter((f) => f.PostId !== post.PostId);
        newList.splice(found, 0, post);
        refList.current = newList;
        setBroadcastList(newList);
      } else {
        if (refList.current.length >= g.MaxAnnouncements) {
          refList.current.pop();
        }

        refList.current = [post, ...refList.current];
        setBroadcastList(refList.current);
      }
    } else {
      refList.current = [post];
      setBroadcastList(refList.current);
    }
  };

  const RemoveAnnouncement = (post) => {
    if (refList.current) {
      let newList = refList.current.filter((f) => f.PostId !== post.PostId);
      refList.current = newList;
      setBroadcastList(refList.current);
      n.RemoveNotification({ Id: `post_${post.PostId}` });
    }
  };

  const RegisterHubEvents = () => {
    HubConnection.on(CLIENT_METHODS.clientReceiveUniBroadCast, (msg, data) => {
      PlotAnnouncement(data);
    });
    HubConnection.on(CLIENT_METHODS.clientRemoveAnnouncement, (msg, data) => {
      RemoveAnnouncement(data);
    });
  };

  const GotoUserPage = () => {
    u.Ui.ScrollToY(0, () => {
      SetTriggerNavToUser(u.Security.GenerateRandomString(10));
    });
  };

  const LaunchWallViewer = (userName, targetMedia, album) => {
    setWallAlbum({
      UserName: userName,
      TargetMedia: targetMedia,
      Album: album,
    });
  };

  return (
    <>
      <div className="uni-broadcast">
        <div>
          {broadcastList ? (
            <div className="pad-lg">
              <hr />
              <div className="all-caps">
                <span className="r-pad-sm font-lg">{ico.RadioTower}</span>
                <span className="font-sm">Announcements</span>
              </div>
              <hr />
              <div className="tb-pad-md"></div>
              <div>
                <ul>
                  {broadcastList.map((p) => (
                    <li key={`announce_${p.PostId}`}>
                      <div className="bracket">
                        <div className="font-smaller">
                          {u.Date.FormatDate(p.PostDate, "YYYY-MM-DD hh:mm a")}
                        </div>
                        <div className="font-bold">{p.PostTitle}</div>
                        <div className="b-pad-sm all-caps font-smaller">
                          <Link to={`/${p.UserName}`} onClick={GotoUserPage}>
                            {p.FullName}
                          </Link>
                        </div>
                        <ShowMoreText textLength={150}>
                          <div
                            className="font-smaller"
                            plainText={p.PostBody}
                            dangerouslySetInnerHTML={{ __html: p.PostBody }}
                          ></div>
                        </ShowMoreText>
                        <div>
                          {p.Album?.Media ? (
                            <div className="tb-pad-sm">
                              <div className="post-thumbs">
                                {p.Album.Media.map((media) => (
                                  <div key={media.MediaHashId} className="inline-block">
                                    {media.MediaType === "i" ? (
                                      <img
                                        src={`${g.Api.CdnRoute}/${p.UserName}/albums/wall/${p.Album.AlbumName}/thumbs/t-${media.MediaHashId}.jpg`}
                                        alt=""
                                        onClick={() =>
                                          LaunchWallViewer(p.UserName, media.MediaHashId, p.Album)
                                        }
                                      ></img>
                                    ) : (
                                      <div className="inline-block">
                                        <video controls>
                                          <source
                                            src={`${g.Api.CdnRoute}/${p.UserName}/albums/wall/post_${p.PostId}/v-${media.MediaHashId}.mp4`}
                                          />
                                        </video>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="tb-pad-sm"></div>
                    </li>
                  ))}
                </ul>
              </div>
              <WallMediaViewer WallAlbum={wallAlbum} key={"WallMediaViewer"} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(UniBroadCastSection);
