import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
//import CommentBox from "../../Components/CommentBox";
import WallMediaViewer from "../../Components/WallMediaViewer";
import InlineMessageBar from "../../Components/Widgets/InlineMessageBar";
import PagingControl from "../../Components/Widgets/PagingControl";
import useAccountManager from "../../Globals/useAccountManager";
import useDataModels from "../../Globals/useDataModels";
import useFetchErrorHandler from "../../Globals/useFetchErrorHandler";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import useCommonUtil from "../../Hooks/useCommonUtil";
import useReactIcons from "../../Hooks/useReactIcons";

import { TriggerNavToUserContext } from "../../AppContexts";

import "./Feeds.css";
import ReactionPanel from "../../Components/Widgets/ReactionPanel";
import ShowMoreText from "../../Components/Widgets/ShowMoreText";
import { Tooltip } from "react-tippy";
import DialogBox from "../../Components/Widgets/DialogBox";

const Feeds = () => {
  const h = useHistory();
  const [T, setTargetUser] = useState();
  const [feeds, setFeeds] = useState();
  const [msgLoad, setMsgLoad] = useState({ message: "LOADING...", mode: "p" });
  const _aborter = new AbortController();
  const am = useAccountManager();
  const dm = useDataModels();
  const g = useGlobalConstants();
  const refPagerModel = useRef(dm.PagingModel);
  const u = useCommonUtil();
  const ico = useReactIcons();
  const fe = useFetchErrorHandler();
  const { SetTriggerNavToUser } = useContext(TriggerNavToUserContext);

  const MEDIA = {
    mp4: ".mp4",
    jpg: ".jpg",
  };

  const [wallAlbum, setWallAlbum] = useState();
  const [myAccount, setMyAcount] = useState(am.GetMyAccount());
  const [dialog, setDialog] = useState();

  useLayoutEffect(() => {
    am.ValidateAccount();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    am.Identity.GetTargetUser((usr) => {
      setTargetUser(usr);
    });
    // eslint-disable-next-line
  }, []);

  //[feeds]
  useEffect(() => {
    if (T) {
      refPagerModel.current.UserName = T.UserName;
      GetFeeds();
    }

    return () => {
      console.log("clearing feeds");
      setFeeds(null);
    };
    // eslint-disable-next-line
  }, [T]);

  const OpenUserProfile = (usr) => {
    u.Ui.ScrollToY(0, () => {
      h.push(`/${usr}`);
      SetTriggerNavToUser(u.Security.GenerateRandomString(10));
    });
  };

  const GetFeeds = (pm) => {
    if (pm) {
      console.log(refPagerModel.current);

      if (refPagerModel.current.CP === refPagerModel.current.TP || refPagerModel.current.TR === 0) {
        pm.MR = false;
        pm.SL = false;
        pm.setMarker(0);
        console.log("*** No more feeds.");
        return;
      }
    }

    fetch(`${g.Api.ApiRoute}/postapi/GetConnectionsPosts`, {
      signal: _aborter.signal,
      method: g.Fetch.POST,
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(refPagerModel.current),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          fe.CatchResponse(r, (m) => {
            setMsgLoad({ mode: "w", message: m });
          });

          return null;
        }
      })
      .then((r) => {
        //console.log(r);
        if (!r) return;
        if (r.ModelData.List.length) {
          let newList = [];
          if (!feeds) {
            //newList = r.ModelData.List.sort(u.Data.SortListBy("PostDate", true));
            setFeeds(r.ModelData.List);
          } else {
            newList = [...feeds, ...r.ModelData.List];
            //newList = newList.sort(u.Data.SortListBy("PostDate", true));
            setFeeds(newList);
          }

          refPagerModel.current = r.ModelData.PageModel;
          let p = r.ModelData.PageModel;

          if (pm) {
            if (p.TP === p.CP) {
              pm.MR = false;
            }
            pm.SL = false;
            pm.setMarker(0);
          }
        }
        setMsgLoad({ ...msgLoad, tl: g.Timing.Message });
      })
      .catch((er) => {
        fe.CatchError(er, (m) => {
          setMsgLoad({ mode: "w", message: m });
        });
      });
  };

  const LoadNextRecords = (pageRef) => {
    if (pageRef.SL) {
      console.log(">>>Loading next records...");
      GetFeeds(pageRef);
    }
  };

  const LaunchWallViewer = (userName, targetMedia, album) => {
    setWallAlbum({
      UserName: userName,
      TargetMedia: targetMedia,
      Album: album,
    });
  };

  const ModeratePost = (post) => {
    fetch(`${g.Api.ApiRoute}/adminapi/ModeratePostAsync`, {
      method: "POST",
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(post),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        if (j) {
          if (j.Succeeded) {
            console.log(j);
            setDialog({
              mode: "s",
              title: "Banned",
              message:
                post.Banned == 1
                  ? `Story post has been marked for suspension/ban. This may take a while. It will be removed gradually by the system.`
                  : `Story has been moderated. This may take a while to update on all users' FEEDS.`,
            });
          } else {
            setDialog({ mode: "e", message: "Ooops. Technical error occured." });
          }
        } else {
          setDialog({ mode: "e", message: "Ooops. Technical error occured." });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const OnModeratePost = (post) => {
    setDialog({
      mode: "text",
      title: "Moderate Post",
      message:
        "Moderate or delete post. Your name will appear in the post should you wish to edit/moderate.",
      defaultVal: post.PostBody,
      buttons: [
        {
          id: "delete",
          caption: "Delete",
          OnClick: () => {
            let _payload = post;
            _payload.Banned = 1;
            ModeratePost(_payload);
          },
        },
        {
          id: "moderate",
          caption: "Moderate",
          OnClick: (text) => {
            let _payload = post;
            _payload.Banned = 2;            
            _payload.PostBody = `[MODERATED BY : ${myAccount.UserName}] ${text}`;
            ModeratePost(_payload);
          },
        },
        { id: "cancel", caption: "Cancel" },
      ],
    });
    console.log(post);
  };

  return (
    <>
      <div className="feeds">
        <div className="b-pad-sm">
          <InlineMessageBar props={{ options: msgLoad }} />
        </div>
        {feeds ? (
          <ul>
            {feeds.map((p) => (
              <li key={`post_${p.PostId}`} id={`liPost_${p.PostId}`}>
                <div key={`post_${p.PostId}`}>
                  <div className="post-block">
                    <div className="pad-lg">
                      <div className="color-mid-gray flex nowrap">
                        <div>
                          <div className="font-smaller all-caps">
                            <div className="inline-block r-pad-sm">
                              <Link
                                to={`/${p.UserName}`}
                                onClick={() => OpenUserProfile(p.UserName)}
                              >
                                {p.FullName}
                              </Link>
                            </div>
                            {p.AuthorFeeling ? (
                              p.AuthorFeeling.PostFeel === 1 ? (
                                <Tooltip
                                  title={ico.GetReactionIcon(p.AuthorFeeling.ReactionTypeId).text}
                                  arrow="true"
                                  arrowSize="big"
                                >
                                  <div
                                    className={`font-smaller all-caps inline-block ${
                                      ico.GetReactionIcon(p.AuthorFeeling.ReactionTypeId).color
                                    }`}
                                  >
                                    &mdash; is feeling
                                    <span className="font-xxl l-pad-sm">
                                      {ico.GetReactionIcon(p.AuthorFeeling.ReactionTypeId).ico}
                                    </span>
                                  </div>
                                </Tooltip>
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                          <div>
                            <span className="r-pad-md">{ico.Calendar}</span>
                            <span className="font-xm">
                              {u.Date.FormatDate(p.PostDate, "ddd | MMM-DD-YYYY hh:mm a")}
                            </span>
                          </div>
                          <div className="tb-pad-sm">
                            {p.UniBroadcast ? (
                              <div
                                className="inline-block font-xl r-pad-md color-theme"
                                title="University Broadcast"
                              >
                                {ico.RadioTower}
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="font-lg">{u.Data.ParseLinks(p.PostTitle)}</div>
                          </div>
                        </div>
                        <div className="last">
                          <div>
                            <Link to={`/${p.UserName}`} onClick={() => OpenUserProfile(p.UserName)}>
                              <div
                                className="avatar"
                                style={{
                                  backgroundImage: `url(${g.Api.CdnRoute}/${p.UserName}/profile/${p.UserName}${MEDIA.jpg}?ver=${p.PicVer})`,
                                }}
                              ></div>
                            </Link>
                          </div>
                          {myAccount.Roles?.includes("MOD") ||
                          myAccount.Roles?.includes("ADMIN") ? (
                            <div className="tb-pad-sm a-center">
                              <div className="b-pad-md"></div>

                              <Tooltip title="Moderate/Delete">
                                <span
                                  onClick={() => OnModeratePost(p)}
                                  className="font-xl color-orange r-pad-sm hand"
                                  title="Moderate/Remove"
                                >
                                  {ico.Warning}
                                </span>
                              </Tooltip>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="b-pad-md">
                        <ShowMoreText textLength={150}>
                          <div className={`font-sm post-body`} plainText={p.PostBody}></div>
                        </ShowMoreText>
                        <div>
                          {p.Album?.Media ? (
                            <div className="post-thumbs tb-pad-md">
                              {p.Album.Media.map((media) => (
                                <div key={media.MediaHashId} className="inline-block">
                                  {media.MediaType === "i" ? (
                                    <img
                                      src={`${g.Api.CdnRoute}/${p.UserName}/albums/wall/${p.Album.AlbumName}/thumbs/t-${media.MediaHashId}${MEDIA.jpg}`}
                                      alt=""
                                      onClick={() =>
                                        LaunchWallViewer(p.UserName, media.MediaHashId, p.Album)
                                      }
                                    ></img>
                                  ) : (
                                    <div className="inline-block">
                                      <video controls>
                                        <source
                                          src={`${g.Api.CdnRoute}/${p.UserName}/albums/wall/post_${p.PostId}/v-${media.MediaHashId}${MEDIA.mp4}`}
                                        />
                                      </video>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <hr />
                      </div>

                      <div className="flex nowrap">
                        <div>
                          <span>
                            <span className="r-pad-sm font-lg">{ico.Messages}</span>
                          </span>
                          <span id={`numComments_${p.PostId}`} title="See who loved this post">
                            {p.NumComments}
                          </span>
                          <span className="r-pad-lg"></span>
                        </div>
                        <div className="last a-right">
                          <ReactionPanel story={p} />
                        </div>
                      </div>
                    </div>
                    {/* <div className="pad-lg">
                      <CommentBox props={{ post: p, user: T }} />
                    </div> */}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
        <PagingControl
          props={{
            callBack: (pg) => {
              LoadNextRecords(pg);
            },
          }}
        />
        <WallMediaViewer WallAlbum={wallAlbum} key={"WallPhotoViewer"} />
        <DialogBox Dialog={dialog} key={"UserWall"} />
      </div>
    </>
  );
};

export default Feeds;
