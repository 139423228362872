import React from "react";
import "./CollabLayout.css";

const CollabLayout = (props) => {
  return (
    <>
      <div className="collab-layout">
        <div>{props.children}</div>
      </div>
    </>
  );
};

export default CollabLayout;
