import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useCommonUtil from "../../Hooks/useCommonUtil";
//import useGlobalConstants from "../../Globals/useGlobalConstants";
import useReactIcons from "../../Hooks/useReactIcons";
import "./CookiePrompt.css";

const CookiePrompt = () => {
  //const g = useGlobalConstants();
  const HIVE_COOKIE_POLICY = "HIVE_COOKIE_POLICY";
  const h = useHistory();
  const u = useCommonUtil();
  const ico = useReactIcons();

  const [hasRead, setHasRead] = useState();

  useEffect(() => {
    let wnd = document.querySelector(".cookie-prompt");
    if (wnd) {
      let c = u.Data.Cookies.GetValue(HIVE_COOKIE_POLICY);
      if (!c) {
        console.log("No cookie policy stored");
        u.Data.Cookies.StoreValue(HIVE_COOKIE_POLICY, { cookiePolicyRead: false });
        wnd.classList.add("show");
      } else {
        if (c.cookiePolicyRead) {
          wnd.classList.remove("show");
        } else {
          wnd.classList.add("show");
        }
      }
    } else {
      console.log("unable to find cookie prompt.");
    }
    // eslint-disable-next-line
  }, [hasRead]);

  const OnAcceptCookie = () => {
    let accept = { cookiePolicyRead: true };
    u.Data.Cookies.StoreValue(HIVE_COOKIE_POLICY, accept, () => {
      setHasRead(accept);
    });
  };
  return (
    <div className="cookie-prompt">
      <div className="pad-lg">
        <div className="all-caps">
          <span className="font-xl r-pad-sm">{ico.Cookie}</span>
          <span className="title">Cookie and Storage Policy</span>
        </div>
        <hr />
        <div className="b-pad-md font-sm">
          This website uses cookies. Please accept or read our Cookie Policy. If you continue to use
          this website, you are bound to, automatically adhere and agree to our COOKIE and STORAGE
          POLICY. Otherwise, kindly refrain from using this website.
        </div>
        <div>
          <button onClick={OnAcceptCookie}>
            <span className="r-pad-sm font-xl">{ico.ThumbUp}</span>I ACCEPT
          </button>
          <span className="r-pad-sm"></span>
          <button
            onClick={() => {
              u.Ui.ScrollToY(0, () => {
                h.push("/pages/cookiepolicy");
              });
            }}
          >
            <span className="r-pad-sm font-xl">{ico.Book}</span>
            Read It
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CookiePrompt);
