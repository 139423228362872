import React, { useState } from "react";
import { SocketContext } from "./WebSocketReducer";
import { useContext } from "react";
import { useEffect } from "react";

import useNotificationManager from "./useNotificationManager";
import useAccountManager from "../useAccountManager";
import useGlobalConstants from "../useGlobalConstants";
import DialogBox from "../../Components/Widgets/DialogBox";
import useCommonUtil from "../../Hooks/useCommonUtil";

const UserNotifications = () => {
  const u = useCommonUtil();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const noti = useNotificationManager();

  const methods = g.Hub.ReducerMethods;
  const { HubConnection, HubDispatch } = useContext(SocketContext);
  const [dialog, SetDialog] = useState();
  const [myAccount] = useState(am.GetMyAccount());

  const RegisterUserMethods = () => {
    console.log("[UserNotifications] RegisterUserMethods...");

    HubConnection.on("ClientAdminOrModJoined", (msg) => {
      console.log(msg);
      //noti.ShowMessage(true);
    });

    HubConnection.on("ClientReceiveDirectMsg", (obj) => {
      console.log(obj);
      if (obj.ToUserName === myAccount.UserName) {
        noti.ShowMessage(1);
      }
    });

    HubConnection.on("ClientMessageAlert", () => {
      console.log("1st method ClientReceiveMessage called.");
      noti.ShowMessage(1);
    });

    HubConnection.on("ClientRecieveMessageAlert", (p) => {
      console.log(p);
      //noti.ShowMessage(p > 0);
      noti.ShowMessage(1);
    });

    HubConnection.on("clientReceiveBan", (ban) => {
      console.log(ban);

      if (ban.TargetUserName === myAccount.UserName) {
        u.Data.ClearStorage(() => {
          SetDialog({
            mode: "w",
            message: `Your account has been banned by ${ban.BannedByUserName}
              for the following reason: <hr/>${ban.BanReason}<hr/>
              Ban until: ${u.Date.FormatDate(ban.DateUntil, "Ddd MM-DD-YYYY hh:mm:ss a")}.`,
            buttons: [
              {
                id: "ok",
                caption: "Exit",
                OnClick: () => {
                  console.log(ban);
                  am.LogMeOff();
                },
              },
            ],
          });
        });
      }
    });

    setTimeout(() => {
      HubDispatch({
        method: methods.INVOKE,
        payload: {
          ServerMethod: "ServerCheckMessageAsync",
          Args: [am.GetMyUserName()],
        },
        callBack: () => {},
      });
    }, 2000);
  };

  //on refresh, did mount
  useEffect(() => {
    const user = am.GetMyAccount();
    if (!user) return;
    const deviceId = am.GetMyDeviceId(user.UserName);
    const _payLoad = {
      UserName: user.UserName,
      //ConnectionId: "",
      GroupName: "",
      Roles: user.Roles,
      FullName: `${user.FirstName} ${user.LastName}`,
      DeviceId: deviceId,
    };
    if (user) {
      HubDispatch({
        method: methods.CONNECT,
        callBack: () => {
          HubDispatch({
            method: methods.INVOKE,
            payload: {
              ServerMethod: "ServerJoinGroup",
              Args: [_payLoad],
            },
            callBack: () => {
              console.log();
              RegisterUserMethods();
            },
          });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DialogBox Dialog={dialog} key={"UserNotifications"} />
    </>
  );
};

export default React.memo(UserNotifications);
