import React, { useEffect, useRef } from "react";
import useReactIcons from "../../Hooks/useReactIcons";
import "./InfoBar.css";

export const InfoBar = (props) => {
  const icon = useReactIcons();
  const refs = {
    icon: useRef(),
    panel: useRef(),
  };

  const CollapseOrExpand = () => {
    refs.panel.current.classList.toggle("show");
    refs.icon.current.classList.toggle("on");
  };

  useEffect(() => {
    if (props.props) {
      if (props.props.expanded) {
        CollapseOrExpand();
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        <div className="info-bar">
          <div className="title-bar">
            <div
              title="Quick Guide"
              className="a-right icon-bar"
              style={{
                backgroundColor: props.props
                  ? props.props.colors
                    ? props.props.colors.b
                    : "lemonchiffon"
                  : "lemonchiffon",
              }}
              onClick={CollapseOrExpand}
              ref={refs.icon}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="title">
                  {props.props
                    ? props.props.caption
                      ? props.props.caption
                      : "QUICK GUIDE"
                    : "QUICK GUIDE"}
                </span>
                <span
                  className={`bulb ${
                    props.props ? (props.props.flash ? "impulse" : "") : ""
                  }`}
                >
                  {icon.Bulb}
                </span>
              </div>
            </div>
          </div>
          <div className="info-panel" ref={refs.panel}>
            <div className="pad-md">
              <div>
                <strong>
                  {props.props
                    ? props.props.title
                      ? props.props.title
                      : "Quick Guide"
                    : "Quick Guide"}
                </strong>
              </div>
              <hr />

              <div className="info-body">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
