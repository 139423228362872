import React, { useEffect, useRef, useState } from "react";
import InlineMessageBar from "../../../../Components/Widgets/InlineMessageBar";
import { SectionExpander } from "../../../../Components/Widgets/SectionExpander";
import Chart from "react-apexcharts";
import useReactIcons from "../../../../Hooks/useReactIcons";
import useAccountManager from "../../../../Globals/useAccountManager";
import useGlobalConstants from "../../../../Globals/useGlobalConstants";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const CommonTopic = () => {
  const ico = useReactIcons();
  const am = useAccountManager();
  const g = useGlobalConstants();

  const [tabIndex, setTabIndex] = useState(0);
  const [msg, setMsg] = useState();

  const controller = new AbortController();
  const signal = controller.signal;
  let timer;
  const refs = {
    refGenBtn: useRef(),
  };

  const [chartTagStory, setChartTagStory] = useState({
    options: {
      theme: {
        palette: 'palette7' // upto palette10
      },
      title: {
        text: `Tags by Story`,
        align: "center",
        style: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "normal",
          marginTop: "20px",
        },
      },
      chart: {
        id: "Title",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          align: "center",
          dataLabels: {
            position: "center",
            //orientation: "vertical"
          },
          // colors: {
          //   ranges: [
          //     { from: 0, to: 1, color: "#3a3963" },
          //     { from: 2, to: 3, color: "#106285" },
          //     { from: 4, to: 6, color: "#ed195a" },
          //     { from: 7, to: 10, color: "#333" },
          //   ],
          // },
        },
      },
      xaxis: {
        floating: false,
        type: "Category",
        labels: {
          align: "center",
          min: 0,
          formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        forceNiceScale: true,
        floating: false,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 350,
          style: {
            fontSize: "16px",
            color: "#ffcc00",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "color-blue",
          },
          offsetX: 5,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return value;
          },
        },
      },
    },
    series: [
      {
        name: "Tags",
        data: [{ x: "", y: 0 }],
      },
    ],
  });

  const [chartTagComment, setChartTagComment] = useState({
    options: {
      theme: {
        palette: 'palette8' // upto palette10
      },
      title: {
        text: `Tags by Comments`,
        align: "center",
        style: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "normal",
          marginTop: "20px",
        },
      },
      chart: {
        id: "Title",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          align: "center",
          dataLabels: {
            position: "center",
            //orientation: "vertical"
          },
          // colors: {
          //   ranges: [
          //     { from: 0, to: 1, color: "#3a3963" },
          //     { from: 2, to: 3, color: "#106285" },
          //     { from: 4, to: 6, color: "#ed195a" },
          //     { from: 7, to: 10, color: "#333" },
          //   ],
          // },
        },
      },
      xaxis: {
        floating: false,
        type: "Category",
        labels: {
          align: "center",
          min: 0,
          formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        // forceNiceScale: true,
        floating: false,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 350,
          style: {
            fontSize: "16px",
            color: "#ffcc00",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "color-blue",
          },
          offsetX: 5,
          offsetY: 0,
          rotate: 0,
        },
      },
    },
    series: [
      {
        name: "Tags",
        data: [{ x: "", y: 0 }],
      },
    ],
  });

  useEffect(() => {
    return () => {
      clearTimeout(timer);
      controller.abort();
    };
    // eslint-disable-next-line
  }, []);

  const DisableControls = (b) => {
    if (refs.refGenBtn.current) {
      if (b) {
        refs.refGenBtn.current.classList.add("display-none");
      } else {
        refs.refGenBtn.current.classList.remove("display-none");
      }
    }
  };

  const GenerateReport = () => {
    DisableControls(1);

    setMsg({ mode: "p", message: "" });

    fetch(`${g.Api.ApiRoute}/ReportsApi/GetHashTagsAsync/?mode=${tabIndex}`, {
      method: "GET",
      signal: signal,
      headers: am.Identity.GetAuthHeader(),
      //body: JSON.stringify(dateRange),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        if (!j) {
          setMsg({ mode: "e", message: "Ooops. Something went wrong :(" });
          return;
        }

        if (j.Succeeded) {
          let m = [{ name: "Frequency", data: [] }];
          let arr = j.ModelData;
          let arr_caption = [];
          for (let i = 0; i < j.ModelData.length; i++) {
            arr_caption.push(`#${arr[i].Data.HashTag}`);

            m[0].data.push({
              x: `${arr[i].Data.HashTag}`,
              y: parseInt(arr[i].Total, 10),
            });
          }

          switch (tabIndex) {
            case 0:
              setChartTagStory({
                ...chartTagStory,
                series: m,
                options: {
                  ...chartTagStory.options,
                  title: {
                    ...chartTagStory.options.title,
                    text: `Frequency of tags by Story`.toUpperCase(),
                  },
                  xaxis: { ...chartTagStory.xaxis, categories: arr_caption },
                },
              });
              break;
            case 1:
              setChartTagComment({
                ...chartTagComment,
                series: m,
                options: {
                  ...chartTagComment.options,
                  title: {
                    ...chartTagComment.options.title,
                    text: `Frequency of tags by Tags`.toUpperCase(),
                  },
                  xaxis: { ...chartTagComment.xaxis, categories: arr_caption },
                },
              });
              break;
            default:
              break;
          }

          setMsg({ mode: "s", message: "" });
        } else {
          setMsg({ mode: "e", message: "Ooops. Something went wrong :(" });
        }

        timer = setTimeout(() => {
          DisableControls(0);
        }, 3000);
      });
  };

  return (
    <>
      <SectionExpander
        props={{
          icon: ico.HashTag,
          title: "Common Topics / Tagged Words",
          classes: "font-sm all-caps",
          caption: "Data drill down of the community hobbies and interests",
          collapsed: true,
        }}
      >
        <div className="flex v-center">
          <div className="last">
            <InlineMessageBar props={{ options: msg }} />
            <span ref={refs.refGenBtn}>
              <button onClick={GenerateReport}>Generate Report</button>
            </span>
          </div>
        </div>
        <div className="tb-pad-sm"></div>
        <div>
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab>By Story</Tab>
              <Tab>By Comments</Tab>
            </TabList>
            <TabPanel>
              <div className="bordered">
                <Chart
                  options={chartTagStory.options}
                  series={chartTagStory.series}
                  type="bar"
                  width="100%"
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bordered">
                <Chart
                  options={chartTagComment.options}
                  series={chartTagComment.series}
                  type="bar"
                  width="100%"
                />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </SectionExpander>
    </>
  );
};

export default React.memo(CommonTopic);
