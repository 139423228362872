import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import HiveLogo from "../Components/Widgets/HiveLogo";
import InlineMessageBar from "../Components/Widgets/InlineMessageBar";
import useAccountManager from "../Globals/useAccountManager";
import useGlobalConstants from "../Globals/useGlobalConstants";
import useCommonUtil from "../Hooks/useCommonUtil";
import useReactIcons from "../Hooks/useReactIcons";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { InfoBar } from "../Components/Widgets/InfoBar";
import useFetchErrorHandler from "../Globals/useFetchErrorHandler";

const Login = () => {
  const hist = useHistory();
  const gc = useGlobalConstants();
  const u = useCommonUtil();
  const am = useAccountManager();
  const ico = useReactIcons();

  const fe = useFetchErrorHandler();

  //const [, setDialog] = useState();

  const [otpEnabled, setOtpEnabled] = useState(false);

  const [msg1, setMsg1] = useState({
    message: "Ready",
    mode: "s",
  });

  const [userLogin, setUserLogin] = useState({
    UserName: "",
    UserPassword: "",
    OtpCode: "",
    UseRecoveryCode: false,
  });

  const loginAborter = new AbortController();

  const inputRefs = {
    userName: useRef(),
    password: useRef(),
    loginLabel: useRef(),
    passwordBtn: useRef(),
    registerBtn: useRef(),
    otpInput: useRef(),
    chkRecovery: useRef(),
  };

  useEffect(() => {
    loginAborter.onabort = (e) => {
      console.log("Aborting login...", e);
    };

    return () => {
      loginAborter.abort();
    };

    // eslint-disable-next-line
  }, []);

  const OnUserInput = (e, m) => {
    //console.log(e.target.value, m);
    switch (m) {
      case "u":
        setUserLogin({
          ...userLogin,
          UserName: `${e.target.value}`,
        });
        break;
      case "p":
        setUserLogin({ ...userLogin, UserPassword: e.target.value });
        break;
      case "o":
        setUserLogin({ ...userLogin, OtpCode: e.target.value });
        break;
      case "r":
        setUserLogin({ ...userLogin, UseRecoveryCode: e.target.checked });
        break;
      default:
        break;
    }
  };

  const ValidateEntries = () => {
    if (!u.Validator.checkEmail(userLogin.UserName)) {
      setMsg1({ mode: "w", message: "Please enter a valid email address." });
      return false;
    }

    if (!u.Validator.CheckMinMax(userLogin.UserPassword, 8, 30)) {
      setMsg1({ mode: "w", message: "Invalid password." });
      return false;
    }

    return true;
  };

  const OnLogin = () => {
    if (!ValidateEntries()) return;

    setMsg1({ message: "Logging in...", mode: "p" });
    u.Ui.DisableEl(inputRefs.passwordBtn.current, true);

    if (userLogin) {
      const loginSignal = loginAborter.signal;

      fetch(`${gc.Api.ApiRoute}/authapi/LoginAsync`, {
        method: gc.Fetch.POST,
        signal: loginSignal,
        headers: gc.Fetch.TYPEJSON,
        body: JSON.stringify(userLogin),
      })
        .then((r) => {
          if (r) {
            console.log(r);
            return r.json();
          } else {
            console.log("No response from server");
            return false;
          }
        })
        .then((r) => {
          console.log(r);
          if (!r) {
            console.log("No response from server");
            setMsg1({
              message: "Unable to read or communicate with API service.",
              mode: "e",
            });
          } else {
            if (r.Succeeded) {
              setMsg1({
                message: "Access Granted. Loading Profile...",
                mode: r.Succeeded ? "p" : "w",
              });

              const usr = r.ModelData.User;

              usr.DateOfBirth = u.Date.FormatDate(
                usr.DateOfBirth,
                "MM/DD/YYYY"
              );

              am.RemoveMyAccount();
              am.StoreMyAccount(usr);
              window.location = `/${usr.UserName}`;
            } else {
              am.RemoveMyAccount();

              if (r.ModelData?.TwoFaEnabled) {
                setOtpEnabled(r.ModelData.TwoFaEnabled);

                setMsg1({
                  message: r.ModelData.TwoFaEnabled
                    ? r.Message
                    : "Access Denied",
                  mode: r.Succeeded ? "p" : "w",
                });

                inputRefs.otpInput.current.focus();
              } else {
                setMsg1({
                  message: r.Message,
                  mode: r.Succeeded ? "p" : "w",
                });
              }
            }

            u.Ui.DisableEl(inputRefs.passwordBtn.current);
          }
        })
        .catch((err) => {
          //determine if abrupt component change
          fe.CatchError(err, (m) => {
            if (hist.location.pathname.toLowerCase().includes("login")) {
              u.Ui.DisableEl(inputRefs.passwordBtn.current);

              setMsg1({
                mode: "e",
                message: m,
              });
            }
          });
        });
    }
  };

  const OnRegister = () => {
    loginAborter.abort();
    hist.push("/register");
  };

  //#region :: Forgot Password
  const [forgotMsg, setForgotMsg] = useState({
    mode: "i",
    message:
      "Enter either one of your recovery codes below, and the new desired password.",
  });
  const [newPwdMsg, setNewPwdMsg] = useState({
    mode: "i",
    message: "Enter new password",
  });
  const ShowQPanel = (b) => {
    let qPanel = document.querySelector("#qPanel");
    if (b) {
      qPanel.classList.remove("dn");
    } else {
      qPanel.classList.add("dn");
    }

    if (!u.Validator.checkEmail(userLogin.UserName)) {
      setForgotMsg({ mode: "w", message: "Please enter your email above." });
      return;
    }
  };

  const [newPwdModel, setNewPwdModel] = useState({
    UserName: "",
    NewPassword: "",
    NewPassword2: "",
    RecoveryCode: "",
  });

  const DisableSubmit = (b) => {
    let recoveryInput = document.querySelector("#recoveryInput");

    if (b) {
      if (recoveryInput) {
        recoveryInput.classList.add("nt");
      }
    } else {
      if (recoveryInput) {
        recoveryInput.classList.remove("nt");
      }
    }
  };
  const ValidateNewCred = (payLoad) => {
    if (!u.Validator.CheckMinMax(payLoad.RecoveryCode, 8, 12)) {
      setNewPwdMsg({ mode: "w", message: "Invalid Recovery Code." });
      return;
    }

    if (!u.Validator.checkEmail(payLoad.UserName)) {
      setNewPwdMsg({
        mode: "w",
        message: "Please enter your email-user name above.",
      });
      return;
    }

    if (
      !u.Validator.CheckMinMax(payLoad.NewPassword, 8, 30) ||
      !u.Validator.CheckMinMax(payLoad.NewPassword2, 8, 30)
    ) {
      setNewPwdMsg({
        mode: "w",
        message: "Passwords must be minimum of 8 characters and maximum of 30.",
      });
      return;
    }

    if (payLoad.NewPassword !== payLoad.NewPassword2) {
      setNewPwdMsg({
        mode: "w",
        message: "New Password and Repeat Password entries should be equal.",
      });
      return;
    }

    let v = u.Security.ScorePassword(payLoad.NewPassword);
    if (v.score < 75) {
      setNewPwdMsg({
        mode: "w",
        message: `Your password is weak at a rating of <code>${v.score}%</code>. 
          Please use a strong password. e.g. <b><code>B0dy#bu1Ldin6</code></b>`,
      });
      return;
    }

    return true;
  };
  const OnSubmitCode = () => {
    let payLoad = { ...newPwdModel, UserName: userLogin.UserName };
    if (!ValidateNewCred(payLoad)) return;

    setNewPwdMsg({ mode: "p", message: "Validating recovery code..." });
    DisableSubmit(true);

    fetch(`${gc.Api.ApiRoute}/authapi/ChangePwdRecoveryAsync`, {
      method: "POST",
      headers: gc.Fetch.TYPEJSON,
      body: JSON.stringify(payLoad),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          setNewPwdMsg({
            mode: "e",
            message: "Unable to communicate with the API Server.",
          });
          DisableSubmit();
        }
      })
      .then((j) => {
        if (!j) {
          setNewPwdMsg({ mode: "e", message: "Invalid process." });
          DisableSubmit();
          return;
        }

        if (j.Succeeded) {
          console.log(j);
          setNewPwdMsg({ mode: "s", message: j.Message });
        } else {
          setNewPwdMsg({ mode: "e", message: j.Message });
          DisableSubmit();
        }
      });
  };

  const OnInputNewCred = (e, idx) => {
    switch (idx) {
      case 0:
        setNewPwdModel({ ...newPwdModel, RecoveryCode: e.target.value.trim() });
        break;
      case 1:
        setNewPwdModel({ ...newPwdModel, NewPassword: e.target.value.trim() });
        break;
      case 2:
        setNewPwdModel({ ...newPwdModel, NewPassword2: e.target.value.trim() });
        break;
      default:
        break;
    }
  };

  //#endregion

  return (
    <>
      <div className="login-page">
        <div className="flex v-center login-banner">
          <div>
            <div className="pad-lg">
              <HiveLogo props={{ width: "150px" }} />
              <div className="all-caps font-sm">
                HOME OF INTERCONNECTED VISIONARIES ENVIRONMENT
              </div>
              <h2>
                A private social media platform
                <br />
                for every company, university or any organization.
              </h2>
            </div>
          </div>
          <div className="one-half" style={{ marginLeft: "auto" }}>
            <div className="login-box">
              <div className="pad-lg">
                <ul className="login">
                  <li>
                    <Tooltip
                      title="Enter your registered email."
                      arrowSize="big"
                      arrow="true"
                      followCursor="true"
                      position="top-start"
                    >
                      <input
                        value={userLogin.UserName}
                        type="text"
                        autoComplete="false"
                        placeholder="Email Address"
                        onChange={(e) => OnUserInput(e, "u")}
                      />
                    </Tooltip>
                  </li>

                  <li>
                    <input
                      ref={inputRefs.password}
                      type="password"
                      value={userLogin.UserPassword}
                      placeholder="Password"
                      onChange={(e) => OnUserInput(e, "p")}
                    />
                  </li>

                  <li>
                    <InlineMessageBar props={{ options: msg1 }} />
                  </li>
                  {otpEnabled ? (
                    <li>
                      <div>
                        <InfoBar>
                          <p>
                            If you don't have your phone with the authenticator
                            app, you can use the recovery code listed during the
                            activation of the 2-Factor Authentication process.
                          </p>
                          <p>
                            Just tick the checkbox "Use recovery code instead"
                            below and enter the recovery code.
                          </p>
                        </InfoBar>
                      </div>
                      <div>
                        <span className="r-pad-sm">
                          <input
                            ref={inputRefs.otpInput}
                            type="text"
                            className="auth-code"
                            maxLength="12"
                            onChange={(e) => OnUserInput(e, "o")}
                          />
                        </span>
                        <span>
                          <input
                            ref={inputRefs.chkRecovery}
                            type="checkbox"
                            name=""
                            id="chkRecovery"
                            className="agree"
                            onChange={(e) => OnUserInput(e, "r")}
                          />
                          <label htmlFor="chkRecovery">
                            Use recovery code instead
                          </label>
                        </span>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}
                  <li>
                    <div className="tb-pad-lg">
                      <input
                        id="btnLogin"
                        ref={inputRefs.passwordBtn}
                        type="submit"
                        value="Login"
                        className="hand"
                        onClick={OnLogin}
                      />
                    </div>
                  </li>
                  <li>
                    <div>
                      <div className="b-pad-md"></div>
                      <div
                        id="fpwd"
                        className="a-center font-bold color-blue b-pad-md hand"
                        onClick={() => ShowQPanel(true)}
                      >
                        I forgot my password
                      </div>
                      <div id="qPanel" className="tb-pad-lg dn">
                        <div className="bordered radiused4 pad-md">
                          <div className="flex still nowrap">
                            <div className="b-pad-md">
                              <InlineMessageBar
                                props={{ options: forgotMsg }}
                              />
                            </div>
                            <div className="last">
                              <span
                                className="font-xl hand"
                                onClick={() => ShowQPanel(false)}
                              >
                                {ico.CloseCircle}
                              </span>
                            </div>
                          </div>
                          <div id="recoveryInput">
                            <div>
                              <input
                                placeholder="Recovery Code"
                                className="loose"
                                type="text"
                                maxLength="8"
                                value={newPwdModel.RecoveryCode}
                                onChange={(e) => OnInputNewCred(e, 0)}
                              />
                            </div>
                            <div id="newPwd">
                              <div>
                                <input
                                  className="loose"
                                  type="password"
                                  placeholder="New Password"
                                  value={newPwdModel.NewPassword}
                                  onChange={(e) => OnInputNewCred(e, 1)}
                                />
                                <input
                                  className="loose"
                                  type="password"
                                  placeholder="Repeat Password"
                                  value={newPwdModel.NewPassword2}
                                  onChange={(e) => OnInputNewCred(e, 2)}
                                />
                              </div>
                              <div className="a-right">
                                <button onClick={OnSubmitCode}>Submit</button>
                              </div>
                            </div>
                          </div>
                          <div className="tb-pad-sm">
                            <InlineMessageBar props={{ options: newPwdMsg }} />
                          </div>
                        </div>
                      </div>
                      <div className="tb-pad-xl">
                        <InfoBar props={{expanded : true, caption: 'INFO', title: "ACCOUNT REGISTRATION"}}>
                          Registration to this website is controlled by your
                          school or organization. Please contact your org's
                          Admin to register for a new account.
                        </InfoBar>
                      </div>
                    </div>
                  </li>
                  {/* <li>
                    <div className="tb-pad-lg">
                      <button
                        id="google"
                        className="google"
                        onClick={LoginViaGoogle}
                      >
                        <span className="font-xl">{ico.GoogleColor} </span>
                        <span>Login via Google</span>
                      </button>
                    </div>
                  </li> */}
                  {/* <li className="tb-pad-lg">
                    <input
                      type="button"
                      name=""
                      ref={inputRefs.registerBtn}
                      id="btnRegister"
                      value="Create New Account"
                      onClick={OnRegister}
                    />
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
