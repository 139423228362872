import React, { useRef, useState } from "react";
import { useEffect } from "react";
import useReactIcons from "../../Hooks/useReactIcons";

import "./PagingControl.css";

const PagingControl = (props) => {
  const callBack = props.props ? props.props.callBack : null;
  const [marker, setMarker] = useState(0);
  const ico = useReactIcons();

  const pageCtrl = useRef({
    SL: false,
    MR: true,
    R: false,
    setMarker: setMarker,
  });

  //[]
  useEffect(() => {
    let t;
    let target = document.getElementById("bottomMarker");

    if (!target) return;

    const OnPageScroll = () => {
      clearTimeout(t);
      if (!pageCtrl.current.MR) {
        //console.log("No more records");
        return;
      }
      if (pageCtrl.current.SL) {
        console.log("still loading");
        return;
      }

      let _marker = 0;

      t = setTimeout(() => {
        let currentPos = Math.max(
          document.documentElement.scrollTop, //varying
          document.body.scrollTop //this is always 0
        );

        _marker = `${target.offsetTop - currentPos - window.innerHeight}`;

        if (_marker < 0) {
          setMarker(_marker < 0);
        }
      }, 50);
    };

    if (window.addEventListener) {
      window.addEventListener("scroll", OnPageScroll);
      window.addEventListener("onscroll", OnPageScroll);
      window.addEventListener("touchmove", OnPageScroll);
    }

    return () => {
      clearTimeout(t);
      window.removeEventListener("scroll", OnPageScroll);
      window.removeEventListener("onscroll", OnPageScroll);
      window.removeEventListener("touchmove", OnPageScroll);
    };
    // eslint-disable-next-line
  }, []);

  //[marker]
  useEffect(() => {
    let pager = pageCtrl.current;
    if (marker) {
      if (pager.SL) return;

      pager.SL = true;
      if (pager.MR) {
        if (callBack) callBack(pager);
      }
    } else {
      pager.SL = false;
    }
    // eslint-disable-next-line
  }, [marker]);

  return (
    <>
      <div className="pad-md">
        <div id="bottomMarker" className="a-right">
          {marker ? (
            <>
              <div>
                <i className="fa fa-lg fa-cloud-download faa-falling faa-fast animated color-blue fa-mi"></i>
                <span className="font-xl">{ico.CircleSpinner}</span>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default PagingControl;
