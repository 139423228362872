const useDataModels = () => {
  const d = {
    UserPlatform: {
      UserPlatformId: 0,
      Browser: "",
      Os: "",
      IpAddress: "",
    },
    AppUserDto: {
      UserName: "",
      ConnectionsCount: 0,
      ProfileViews: 0,
      NameOrNickname: "",
      LastName: "",
      FirstName: "",
      MiddleName: "",
      Gender: "",
      TempPassword: "",
      NewPassword: "",
    },
    RegModel: {
      UserName: "",
      FirstName: "",
      LastName: "",
      NameMention: "",
      Password: "",
      Password2: "",
      DateOfBirth: "",
      Gender: "G",
      CountryCode: "",
      CityId: 0,
    },
    PhotoModel: {
      UserName: "",
      FileName: "",
      FileStream: undefined,
    },
    GroupMessageVw: {
      GId: "",
      From: "",
      Body: "",
    },
    GroupData: {
      UserName: "",
      HubGroupId: "",
      GroupGuid: "",
      GroupType: "",
    },
    HubMessageDto: {
      MessageId: 0,
      HubGroupId: "",
      UserName: "",
      ToUserName: "",
      MessageBody: "",
      DeviceId: "",
      DateSent: "",
      Seen: 0,
    },
    ChatContact: {
      GroupId: "",
      GroupGuid: "",
      Cn: "",
      Fn: "",
    },
    MessageHeaderDto: {
      Id: 0,
      FromAppUserId: "",
      FromFullName: "",
      ToAppUserId: "",
      ToFullName: "",
      Read: 0,
      DateCreated: undefined,
    },
    Months: [
      { Id: 0, Name: "JAN" },
      { Id: 1, Name: "FEB" },
      { Id: 2, Name: "MAR" },
      { Id: 3, Name: "APR" },
      { Id: 4, Name: "MAY" },
      { Id: 5, Name: "JUN" },
      { Id: 6, Name: "JUL" },
      { Id: 7, Name: "AUG" },
      { Id: 8, Name: "SEPT" },
      { Id: 9, Name: "OCT" },
      { Id: 10, Name: "NOV" },
      { Id: 11, Name: "DEC" },
    ],
    PagingModel: {
      UserName: "",
      FP: 0, //number of first records to load
      TR: 0, //Total Records
      TP: 1, //total Pages
      PS: 5, //page size / records per page
      CP: 0, //current page
    },
    PagingModelFriends: {
      UserName: "",
      FP: 0, //number of first records to load
      TR: 0, //Total Records
      TP: 1, //total Pages
      PS: 9, //page size / total records per page | ideal : 50
      CP: 0, //current page
    },
  };

  return d;
};

export default useDataModels;
