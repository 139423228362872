import React, { useEffect, useRef, useState } from "react";
import DateRangePicker from "../../../../Components/Widgets/DateRangePicker";
import InlineMessageBar from "../../../../Components/Widgets/InlineMessageBar";
import { SectionExpander } from "../../../../Components/Widgets/SectionExpander";
import useAccountManager from "../../../../Globals/useAccountManager";
import useGlobalConstants from "../../../../Globals/useGlobalConstants";
import useReactIcons from "../../../../Hooks/useReactIcons";
import Chart from "react-apexcharts";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const SentimentReactions = () => {
  const ico = useReactIcons();
  const g = useGlobalConstants();
  const am = useAccountManager();

  const [dateRange, setDateRange] = useState({ From: "", To: "", Params: [] });
  const [tabIndex, setTabIndex] = useState(0);
  const controller = new AbortController();
  const signal = controller.signal;
  const [msg, setMsg] = useState();

  let timer;
  const refs = {
    refGenBtn: useRef(),
  };

  useEffect(() => {
    DisableControls(0);
    setMsg({ mode: "h" });
    // eslint-disable-next-line
  }, [dateRange]);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
      controller.abort();
    };
    // eslint-disable-next-line
  }, []);

  const [chartData, setChartData] = useState({
    options: {
      theme: {
        palette: 'palette3' // upto palette10
      },
      title: {
        text: `Sentiment Analysis`,
        align: "left",
        style: {
          fontFamily: "Montserrat",
          fontSize: `16px`,
          fontWeight: "normal",
          marginTop: "20px",
        },
      },
      chart: {
        id: "Reactions",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      plotOptions: {
        bar: {
          //borderRadius: 1,
          distributed: true,
          horizontal: true,
          dataLabels: { enabled: true, position: "center", formatter: (x) => parseInt(x, 10) },
        },
        colors: {
          ranges: [
            { from: 0, to: 1, color: "#999" },
            { from: 2, to: 3, color: "#666" },
            { from: 4, to: 6, color: "#333" },
          ],
        },
      },
      xaxis: {
        //categories: [1991, 1992, 1993],
        type: "Category",
        labels: {
          formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        floating: false,
        title: { text: "Reactions" },
        style: { fontFamily: "Montserrat", fontWeight: "normal" },
      },
    },
    series: [
      {
        name: "Sentiments",
        data: [{ x: "", y: 0 }],
      },
    ],
  });

  const [chartByAuthor, setChartByAuthor] = useState({
    options: {
      theme: {
        palette: 'palette5' // upto palette10
      },
      title: {
        text: `Sentiment Analysis - By Author Setiment`,
        align: "left",
        style: {
          fontFamily: "Montserrat",
          fontSize: `16px`,
          fontWeight: "normal",
          marginTop: "20px",
        },
      },
      chart: {
        id: "Reactions",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      plotOptions: {
        bar: {
          //borderRadius: 1,
          distributed: true,
          horizontal: true,
          dataLabels: { enabled: true, position: "center", formatter: (x) => parseInt(x, 10) },
        },
        // colors: {
        //   ranges: [
        //     { from: 0, to: 1, color: "#999" },
        //     { from: 2, to: 3, color: "#666" },
        //     { from: 4, to: 6, color: "#333" },
        //   ],
        // },
      },
      xaxis: {
        //categories: [1991, 1992, 1993],
        type: "Category",
        labels: {
          formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        floating: false,
        title: { text: "Reactions" },
        style: { fontFamily: "Montserrat", fontWeight: "normal" },
      },
    },
    series: [
      {
        name: "Sentiments",
        data: [{ x: "", y: 0 }],
      },
    ],
  });
  const DisableControls = (b) => {
    if (refs.refGenBtn.current) {
      if (b) {
        refs.refGenBtn.current.classList.add("display-none");
      } else {
        refs.refGenBtn.current.classList.remove("display-none");
      }
    }
  };
  const GenerateReport = () => {
    //console.log(dateRange);
    if (new Date(dateRange.From) > new Date(dateRange.To)) {
      setMsg({ mode: "w", message: "End date must be later than the start date." });
      return;
    }
    if (new Date(dateRange.To) > new Date()) {
      setMsg({ mode: "w", message: "End date must be earlier than today's date." });
      return;
    }

    // let endPoint = "";
    // switch (tabIndex) {
    //   case 0:
    //     endPoint = "GetSentimentReport";
    //     break;
    //   case 1:
    //     endPoint = "GetSentimentByAuthor";
    //     break;
    // }

    let model = { ...dateRange, Params: [`${tabIndex}`] };
    DisableControls(1);
    setMsg({ mode: "p", message: "" });
    //setChartData(null);
    // setChartByAuthor({
    //   ...chartByAuthor,
    //   series: { ...chartByAuthor.series, data: [{ name: "Sentiments" }, { x: "", y: 0 }] },
    // });

    setChartByAuthor({
      ...chartByAuthor,
      series: [
        {
          name: "Sentiments",
          data: [{ x: "", y: 0 }],
        },
      ],
      options: {
        ...chartByAuthor.options,
        title: {
          ...chartByAuthor.options.title,
          text: `Sentiment Analysis by Author Post [${dateRange.From} — ${dateRange.To}]`,
          // align: "left",
          // style: { fontSize: `16px`, marginTop: "20px" },
        },
      },
    });

    fetch(`${g.Api.ApiRoute}/ReportsApi/GetSentimentReportAsync`, {
      method: "POST",
      signal: signal,
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(model),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        if (!j) {
          setMsg({ mode: "e", message: "Error" });
          return;
        }

        if (j.Succeeded) {
          let m = [{ name: "Sentiment", data: [] }];
          let arr = j.ModelData;
          for (let i = 0; i < j.ModelData.length; i++) {
            m[0].data.push({ x: arr[i].Description, y: parseInt(arr[i].Total, 10) });
          }

          switch (tabIndex) {
            case 0:
              setChartData({
                ...chartData,
                series: m,
                options: {
                  ...chartData.options,
                  title: {
                    ...chartData.options.title,
                    text: `Sentiment Analysis by Post Reactions [${dateRange.From} — ${dateRange.To}]`,
                    // align: "left",
                    // style: { fontSize: `16px`, marginTop: "20px" },
                  },
                },
              });
              break;
            case 1:
              setChartByAuthor({
                ...chartByAuthor,
                series: m,
                options: {
                  ...chartByAuthor.options,
                  title: {
                    ...chartByAuthor.options.title,
                    text: `Sentiment Analysis by Author Post [${dateRange.From} — ${dateRange.To}]`,
                    // align: "left",
                    // style: { fontSize: `16px`, marginTop: "20px" },
                  },
                },
              });
              break;
            default:
              break;
          }

          setMsg({ mode: "s", message: "" });
        } else {
          setMsg({ mode: "e", message: "Error" });
        }

        timer = setTimeout(() => {
          DisableControls(0);
        }, 3000);
      });
  };

  return (
    <>
      <SectionExpander
        props={{
          icon: ico.ReactionPlus,
          title: "Sentiment via Reactions",
          classes: "font-sm all-caps",
          caption: "See if the community is happy or sad, confused, shocked or having fun.",
          collapsed: true,
        }}
      >
        <div className="flex v-center">
          <div className="one-3rd font-sm">
            <DateRangePicker options={{ SetDateRange: setDateRange }} key={"SentimentReactions"} />
          </div>
          <div className="last">
            <InlineMessageBar props={{ options: msg }} />
            <span ref={refs.refGenBtn}>
              <button onClick={() => GenerateReport()}>Generate Report</button>
            </span>
          </div>
        </div>
        <div className="tb-pad-sm"></div>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>Over All</Tab>
            <Tab>By Author's Feeling</Tab>
          </TabList>
          <TabPanel>
            <div className="bordered">
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                width="100%"
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="bordered">
              <Chart
                options={chartByAuthor.options}
                series={chartByAuthor.series}
                type="bar"
                width="100%"
              />
            </div>
          </TabPanel>
        </Tabs>
      </SectionExpander>
    </>
  );
};

export default React.memo(SentimentReactions);
