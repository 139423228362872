import React from "react";
import { Link, withRouter } from "react-router-dom";
import "./ContentBlock.css";

import { TriggerNavToUserContext } from "../../AppContexts";
import { useContext } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import useCommonUtil from "../../Hooks/useCommonUtil";
import { useState } from "react";
import useAccountManager from "../../Globals/useAccountManager";
import { usePageLocationService } from "../../Globals/usePageLocationService";


const ContentBlock = (props) => {
  const u = useCommonUtil();
  const am = useAccountManager();
  const pg = usePageLocationService();

  const [userName, setUserId] = useState("");
  const [myAccount] = useState(am.GetMyAccount());
  const { HomeTriggered } = useContext(TriggerNavToUserContext);
  const [T, setTargetUser] = useState();

  const refLocs = {
    userWall: useRef(),
    feeds: useRef(),
    photosv: useRef(),
    about: useRef(),
  };

  useEffect(() => {
    am.Identity.GetTargetUser((tuser) => {
      setTargetUser(tuser);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (T) {
      setUserId(T.UserName);
      SelectMenu();
    }
    // eslint-disable-next-line
  }, [T]);

  //in case of full-page refresh
  const SelectMenu = () => {
    if (!T) return;

    let page = pg.GetCurrentPage();
    switch (page) {
      case pg.PageNames.feeds:
        u.Ui.makeMenuItemActive(refLocs.feeds.current, "active");
        break;
      case pg.PageNames.media:
        u.Ui.makeMenuItemActive(refLocs.photosv.current, "active");
        break;
      case pg.PageNames.about:
        u.Ui.makeMenuItemActive(refLocs.about.current, "active");
        break;
      default:
        u.Ui.makeMenuItemActive(refLocs.userWall.current, "active");
        break;
    }
  };

  const OnActivateMenu = (e) => {
    u.Ui.makeMenuItemActive(e.currentTarget, "active");
  };

  useEffect(() => {
    SelectMenu();
    /* eslint-disable */
  }, [HomeTriggered]);

  return (
    <>
      {T ? (
        <div className="content-block">
          <div className="pad-lg">
            <div className="menu-bar">
              <div className="tb-pad-lg">
                <ul>
                  <li id="wall" ref={refLocs.userWall} onClick={(e) => OnActivateMenu(e)}>
                    <Link to={`/${userName}`}>WALL</Link>
                  </li>
                  {am.GetMyUserName() === T.UserName ? (
                    <li id="feeds" ref={refLocs.feeds} onClick={(e) => OnActivateMenu(e)}>
                      <Link to={`/${userName}/feeds`}>FEEDS</Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  <li id="photosv" ref={refLocs.photosv} onClick={(e) => OnActivateMenu(e)}>
                    <Link to={`/${userName}/media`}>MEDIA</Link>
                  </li>
                  <li id="about" ref={refLocs.about} onClick={(e) => OnActivateMenu(e)}>
                    <Link to={`/${userName}/about`}>{myAccount.UserName === T.UserName ? <>ABOUT ME</> : "ABOUT"}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div>{props.children}</div>
          </div>          
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default withRouter(ContentBlock);
