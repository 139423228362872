import React, { useEffect, useRef, useState } from "react";
import InlineMessageBar from "../../../../Components/Widgets/InlineMessageBar";
import { SectionExpander } from "../../../../Components/Widgets/SectionExpander";
import Chart from "react-apexcharts";
import useReactIcons from "../../../../Hooks/useReactIcons";
import useAccountManager from "../../../../Globals/useAccountManager";
import useGlobalConstants from "../../../../Globals/useGlobalConstants";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const JobIndustryAnalytics = () => {
  const ico = useReactIcons();
  const am = useAccountManager();
  const g = useGlobalConstants();

  const [msg, setMsg] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [engagement, setEngagement] = useState(0);

  const controller = new AbortController();
  const signal = controller.signal;
  let timer;
  const refs = {
    refGenBtn: useRef(),
  };

  const [chartIndustry, setChartIndustry] = useState({
    options: {
      theme: {
        palette: "palette9", // upto palette10
      },
      title: {
        text: `Job Analytics by Industry`.toUpperCase(),
        align: "center",
        style: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "normal",
        },
      },
      chart: {
        id: "Title",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          align: "center",
          dataLabels: {
            position: "center",
            //orientation: "vertical"
          },
        },
      },
      xaxis: {
        floating: false,
        type: "Category",
        labels: {
          align: "center",
          min: 0,
          formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        forceNiceScale: true,
        floating: false,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 350,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "break-words",
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return value;
          },
        },
      },
    },
    series: [
      {
        name: "Course and School",
        data: [{ x: "", y: 0 }],
      },
    ],
  });
  const [chartCountry, setChartCountry] = useState({
    options: {
      theme: {
        palette: "palette7", // upto palette10
      },
      title: {
        text: `Job Analytics by Country`.toUpperCase(),
        align: "center",
        style: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "normal",
        },
      },
      chart: {
        id: "Title",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          align: "center",
          dataLabels: {
            position: "center",
            //orientation: "vertical"
          },
        },
      },
      xaxis: {
        floating: false,
        type: "Category",
        labels: {
          align: "center",
          min: 0,
          formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        forceNiceScale: true,
        floating: false,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 350,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "break-words",
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return value;
          },
        },
      },
    },
    series: [
      {
        name: "Course and School",
        data: [{ x: "", y: 0 }],
      },
    ],
  });
  const [chartIndCountry, setChartIndCountry] = useState({
    options: {
      theme: {
        palette: "palette6", // upto palette10
      },
      title: {
        text: `Job Analytics by Country and Industry`.toUpperCase(),
        align: "center",
        style: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "normal",
        },
      },
      chart: {
        id: "Title",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          align: "center",
          dataLabels: {
            position: "center",
            //orientation: "vertical"
          },
        },
      },
      xaxis: {
        floating: false,
        type: "Category",
        labels: {
          align: "center",
          min: 0,
          formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        forceNiceScale: true,
        floating: false,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 350,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "break-words",
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return value;
          },
        },
      },
    },
    series: [
      {
        name: "Course and School",
        data: [{ x: "", y: 0 }],
      },
    ],
  });

  useEffect(() => {
    return () => {
      clearTimeout(timer);
      controller.abort();
    };

    // eslint-disable-next-line
  }, []);

  const DisableControls = (b) => {
    if (refs.refGenBtn.current) {
      if (b) {
        refs.refGenBtn.current.classList.add("display-none");
      } else {
        refs.refGenBtn.current.classList.remove("display-none");
      }
    }
  };

  const GenerateReport = () => {
    DisableControls(1);
    let endpoint = "";

    setMsg({ mode: "p", message: "" });

    switch (tabIndex) {
      case 0:
        endpoint = "GetIndustryJobsAsync";
        break;
      case 1:
        endpoint = "GetIndustryByCountryAsync";
        break;
      case 2:
        endpoint = "GetJobIndCountryAsync";
        break;
      default:
        break;
    }

    let par = new URLSearchParams({ e: engagement });
    fetch(`${g.Api.ApiRoute}/ReportsApi/${endpoint}/?${par}`, {
      method: "GET",
      signal: signal,
      headers: am.Identity.GetAuthHeader(),
      //body: JSON.stringify(dateRange),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        if (!j) {
          setMsg({ mode: "e", message: "Error" });
          return;
        }

        if (j.Succeeded) {
          let m = [{ name: "Population", data: [] }];
          let arr = j.ModelData;
          let caption = "";
          let grand_total = 0;
          for (let i = 0; i < j.ModelData.length; i++) {
            grand_total += arr[i].Total;

            switch (tabIndex) {
              case 0:
                caption = `${arr[i].Data.Industry}`;
                break;
              case 1:
                caption = `${arr[i].Data.Country}`;
                break;
              case 2:
                caption = `[ ${arr[i].Data.Country} ]  ${arr[i].Data.Industry}`;
                break;
              default:
                break;
            }

            m[0].data.push({
              x: caption,
              y: parseInt(arr[i].Total, 10),
            });
          }

          switch (tabIndex) {
            case 0:
              setChartIndustry({
                ...chartIndustry,
                series: m,
                options: {
                  ...chartIndustry.options,
                  title: {
                    ...chartIndustry.options.title,
                    text: `JOB INDUSTRY ANALYTICS - ${
                      engagement === 0 ? "PAST" : "PRESENT"
                    } ENGAGEMENT [ TOTAL : ${grand_total} ]`,
                  },
                },
              });
              break;
            case 1:
              setChartCountry({
                ...chartCountry,
                series: m,
                options: {
                  ...chartCountry.options,
                  title: {
                    ...chartCountry.options.title,
                    text: `JOB BY COUNTRY ANALYTICS - ${
                      engagement === 0 ? "PAST" : "PRESENT"
                    } ENGAGEMENT [ TOTAL : ${grand_total} ]`,
                  },
                },
              });
              break;
            case 2:
              setChartIndCountry({
                ...chartIndCountry,
                series: m,
                options: {
                  ...chartIndCountry.options,
                  title: {
                    ...chartIndCountry.options.title,
                    text: `JOB BY INDUSTRY & COUNTRY ANALYTICS - ${
                      engagement === 0 ? "PAST" : "PRESENT"
                    } ENGAGEMENT [ TOTAL : ${grand_total} ]`,
                  },
                },
              });
              break;
            default:
              break;
          }

          setMsg({ mode: "s", message: "" });
        } else {
          setMsg({ mode: "e", message: "Error" });
        }

        timer = setTimeout(() => {
          DisableControls(0);
        }, 3000);
      });
  };

  // useEffect(() => {
  //   console.log(engagement);
  // }, [engagement]);

  const OnEngChange = (e) => {
    setEngagement(e.target.value);
  };
  return (
    <>
      <SectionExpander
        props={{
          icon: ico.BriefCase,
          title: "Job Industry Analytics",
          classes: "font-sm all-caps",
          caption: "Community jobs and industry, career and profession",
          collapsed: true,
        }}
      >
        <div className="flex">
          <div className="one-3rd font-sm">
            <div className="group">
              <div className="pad-md">
                <span>Engagement: </span>
                <input
                  type="radio"
                  name="eng"
                  id="past"
                  value={0}
                  defaultChecked={true}
                  onChange={OnEngChange}
                />
                <label htmlFor="past">Past</label>
                <input type="radio" name="eng" id="curr" value={1} onChange={OnEngChange} />
                <label htmlFor="curr">Current</label>
              </div>
            </div>
          </div>
          <div className="last a-right">
            <InlineMessageBar props={{ options: msg }} />
            <span ref={refs.refGenBtn}>
              <button onClick={GenerateReport}>Generate Report</button>
            </span>
          </div>
        </div>
        <div className="tb-pad-sm"></div>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>Industry</Tab>
            <Tab>Country</Tab>
            <Tab>Industry {"&"} Country</Tab>
          </TabList>
          <TabPanel>
            <div className="bordered">
              <Chart
                options={chartIndustry.options}
                series={chartIndustry.series}
                type="bar"
                width="100%"
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="bordered">
              <Chart
                options={chartCountry.options}
                series={chartCountry.series}
                type="bar"
                width="100%"
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="bordered">
              <Chart
                options={chartIndCountry.options}
                series={chartIndCountry.series}
                type="bar"
                width="100%"
              />
            </div>
          </TabPanel>
        </Tabs>
      </SectionExpander>
    </>
  );
};

export default React.memo(JobIndustryAnalytics);
