import useCommonUtil from "../../Hooks/useCommonUtil";
import useGlobalConstants from "../useGlobalConstants";

const useNotificationManager = () => {
  const dn = "dn";
  const g = useGlobalConstants();
  const u = useCommonUtil();

  const f = {
    ActivateBell: (enabled) => {
      let parent = document.getElementById("mainNav");
      if (parent) {
        let targ = parent.querySelector(".item-bell");
        //console.log(targ);
        if (enabled) {
          if (targ) {
            targ.classList.add("color-orange");
          }
        } else {
          if (targ) {
            targ.classList.remove("color-orange");
          }
        }
      }
    },
    ShowBell: (b) => {
      let parent = document.getElementById("mainNav");
      if (parent) {
        let targ = parent.querySelector(".bell");
        if (b) {
          targ.classList.remove(dn);
          let snd = new Audio("/media/hive3.ogg");
          if (snd) {
            //snd.muted = true;
            snd.play();
          }
        } else {
          targ.classList.add(dn);
        }
      }
    },
    ShowMessage: (b) => {
      let parent = document.getElementById("mainNav");
      if (parent) {
        let targ = parent.querySelector(".envelope");
        if (b) {
          targ.classList.remove(dn);
        } else {
          targ.classList.add(dn);
        }
      }
    },
    StoreNotification: (obj, fn) => {
      let noti = {
        Id: obj.Id,
        Author: obj.Author,
        Type: obj.Type,
        Message: obj.Message,
        Date: u.Date.FormatDate(new Date(), "Ddd MM/DD/YYYY hh:mm a"),
        Read: obj.Read,
      };

      let newList = [];

      u.Data.RetrieveData(g.DbKeys.NotificationList, (list) => {
        if (list) {
          if (list.length > 250) {
            list.splice(list.length, 1);
          }

          let idx = list.findIndex((f) => f.Id === noti.Id);
          if (idx >= 0) {
            newList = list.filter((f) => f.Id !== noti.Id);
            newList.splice(idx, 0, noti);
          } else {
            newList = [noti, ...list];
          }

          u.Data.StoreData(g.DbKeys.NotificationList, newList, (l) => {
            if (typeof fn === "function") {
              fn.call(l);
            }
          });
        } else {
          newList.push(noti);

          u.Data.StoreData(g.DbKeys.NotificationList, newList, (l) => {
            if (typeof fn === "function") {
              fn.call(l);
            }
          });
        }
      });
    },
    RemoveNotification: (obj, fn) => {
      let noti = {
        Id: obj.Id,
      };

      u.Data.RetrieveData(g.DbKeys.NotificationList, (list) => {
        if (list) {
          let idx = list.findIndex((f) => f.Id === noti.Id);
          if (idx >= 0) {
            let newList = list.filter((f) => f.Id !== noti.Id);
            u.Data.StoreData(g.DbKeys.NotificationList, newList, (l) => {
              if (typeof fn === "function") {
                fn.call(l);
              }
            });
          }
        }
      });
    },
  };

  return f;
};

export default useNotificationManager;
