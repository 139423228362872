import React, { useRef, useState } from "react";
import "./FeelingPanel.css";

import { Tooltip } from "react-tippy";
import useReactIcons from "../../Hooks/useReactIcons";

const FeelingPanel = (props) => {
  const ico = useReactIcons();
  const [feelIcon, setFeelIcon] = useState({ ico: ico.ReactionPlus, text: "I Feel ..." });
  const refs = {
    refMore: useRef(),
    reactionSummary: useRef(),
  };

  const showEmoticons = () => {
    if (refs.refMore.current) {
      //.toggle cannot be used here sinc document has also control.
      if (refs.refMore.current.classList.contains("hidden")) {
        refs.refMore.current.classList.remove("hidden");
      } else {
        refs.refMore.current.classList.add("hidden");
      }
    }
  };

  const OnClickFeeling = (n) => {
    props.state.setImFeeling({
      ...props.state.imFeeling,
      ReactionTypeId: n,
    });
    setFeelIcon(ico.GetReactionIcon(n));
    showEmoticons();
  };

  return (
    <>
      <div className="feeling-panel">
        <div>
          <div className="r-pad-md">
            <Tooltip title="Add feeling to this post" arrow="true" arrowSize="big">
              <button onClick={showEmoticons}>
                <span className="r-pad-md">{feelIcon.ico}</span>
                <span className="font-xm">{feelIcon.text}</span>
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="feelings hidden" ref={refs.refMore}>
          <div className="pad-md">
            <ul>
              <li>
                <div className="flex v-center">
                  <div>I Feel...</div>
                  <div className="close last" onClick={showEmoticons}>
                    {ico.CloseCircle}
                  </div>
                </div>
                <hr />
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnClickFeeling(15)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.Angel}</span>
                      <span className="font-sm">Blessed</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnClickFeeling(11)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.Heart}</span>
                      <span className="font-sm">Love</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnClickFeeling(1)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionAngry}</span>
                      <span className="font-sm">Angry</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnClickFeeling(2)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionBaffled}</span>
                      <span className="font-sm">Baffled</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnClickFeeling(3)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionConfused}</span>
                      <span className="font-sm">Confused</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnClickFeeling(4)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionCrySad}</span>
                      <span className="font-sm">Crying</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnClickFeeling(5)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionFrustrated}</span>
                      <span className="font-sm">Frustrated</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnClickFeeling(6)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionGrin}</span>
                      <span className="font-sm">Grin</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnClickFeeling(7)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionHappy}</span>
                      <span className="font-sm">Happy</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnClickFeeling(8)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionHeartBroken}</span>
                      <span className="font-sm">Heartbroken</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnClickFeeling(9)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionLaugh}</span>
                      <span className="font-sm">Laughing</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnClickFeeling(12)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionSad}</span>
                      <span className="font-sm">Sad</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex v-center still">
                  <div className="one-half menu" onClick={() => OnClickFeeling(13)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionWow}</span>
                      <span className="font-sm">Shocked/Wow</span>
                    </div>
                  </div>
                  <div className="one-half menu" onClick={() => OnClickFeeling(14)}>
                    <div className="lr-pad-sm">
                      <span className="font-xl r-pad-sm">{ico.ReactionGrin2}</span>
                      <span className="font-sm">Teasing</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default React.memo(FeelingPanel);
