import useAccountManager from "../Globals/useAccountManager";
import useGlobalConstants from "../Globals/useGlobalConstants";

const usePostManager = () => {
  const g = useGlobalConstants();
  const am = useAccountManager();

  const f = {
    //GetMyConnections: () => {},
    IsConnectedToUser: (appUserId, targetUser, fn) => {
      if (appUserId === targetUser) return;

      //console.log("IsConnectedToUser: Determining user connection...");
      let p = new URLSearchParams({
        appUserId: appUserId,
        targetUser: targetUser,
      });

      fetch(`${g.Api.ApiRoute}/User/IsUserConnectedToMe/?${p}`, {
        headers: am.Identity.GetAuthHeader(),
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
        })
        .then((r) => {
          if (typeof fn === "function") {
            if (r) {
              fn.call(this, r.Succeeded);
            }
          }
        });

      //return false;
    },
  };

  return f;
};

export default usePostManager;
