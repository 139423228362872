import React, { useEffect, useRef, useState } from "react";
import useGlobalConstants from "../../../Globals/useGlobalConstants";
import useReactIcons from "../../../Hooks/useReactIcons";
import useCommonUtil from "../../../Hooks/useCommonUtil";

import DialogBox from "../../../Components/Widgets/DialogBox";
import useAccountManager from "../../../Globals/useAccountManager";
import InlineMessageBar from "../../../Components/Widgets/InlineMessageBar";
import useFetchErrorHandler from "../../../Globals/useFetchErrorHandler";

import "./JobMgt.css";
import { useDataManager } from "../../../Globals/useDataManager";
import { SectionExpander } from "../../../Components/Widgets/SectionExpander";

const JobMgt = () => {
  const u = useCommonUtil();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const ico = useReactIcons();
  const fe = useFetchErrorHandler();
  const dmgr = useDataManager();

  const DEFAULT_SELECTVAL = "xxx";

  const [selectedIndustry, setSelectedIndustry] = useState();
  const [industryList, setIndustryList] = useState();

  //const [stateJobList, setStateJobList] = useState([]);
  const [dialog, setDialog] = useState();
  const [msg, setMsg] = useState();

  //const [industryCaption, setIndustryCaption] = useState("NEW");
  //const [toolBarEvents, setToolbarEvents] = useState();

  const refs = {
    refIndDropDown: useRef(),
    refIndOperation: useRef(),
    refNewInd: useRef(false),
    refTextInd: useRef(),
    refFlagNewInd: useRef(false),
    refJobList: useRef(),

    refIndustryId: useRef(DEFAULT_SELECTVAL),

    //jobs
    refNewJobText: useRef(""),
  };

  // const flags = {
  //   flagSaveMode: useRef("ADD"), //add
  // };

  //console.log("Trace: render component");
  //#region :: Job Industry
  //[]
  useEffect(() => {
    setMsg({ mode: "d", message: "Loading Job Industries..." });

    dmgr.GetJobIndustry((result) => {
      if (result) {
        if (Array.isArray(result.ModelData)) {
          setIndustryList(result.ModelData);
          setSelectedIndustry(result.ModelData[0]);
          //setStateJobList(result.ModelData[0].Jobs);

          setMsg({ mode: "s", message: "" });
          //subscribeToolbarEvent(true);
        } else {
          setMsg({ mode: "s", message: result.ModelData });
        }
      } else {
        setMsg({
          mode: "e",
          message: "An error has occured while getting the list.",
        });
      }
    });

    // eslint-disable-next-line
  }, []);

  // const HideOrShowIndustryEntry = () => {
  //   refs.refIndDropDown.current.classList.toggle("no-touch");
  //   refs.refJobList.current.classList.toggle("no-touch");
  //   refs.refNewInd.current.classList.toggle("dn");
  //   refs.refIndOperation.current.classList.toggle("no-touch");
  //   refs.refTextInd.current.focus();
  // };

  // const OnAddIndustry = () => {
  //   flags.flagSaveMode.current = "ADD";
  //   HideOrShowIndustryEntry();
  //   refs.refTextInd.current.value = "";
  //   setIndustryCaption("NEW ");
  // };

  const OnSelectIndustry = (e) => {
    let v = e.target.value;
    if (v === DEFAULT_SELECTVAL) {
      return;
    }

    let ind = industryList.find((i) => i.JobIndustryId === v);

    if (ind) {
      setSelectedIndustry(ind);
    }
  };

  /*
  const OnSaveIndustry = () => {
    if (!u.Validator.CheckMinMax(refs.refTextInd.current.value, 3, 100)) {
      setDialog({
        mode: "e",
        title: "Required",
        message:
          "Job industry description must be three (3) or more characters, not exceeding 150.",
      });
      return;
    }

    setDialog({
      mode: "u",
      title: "Transmit",
      message: "Saving job industry...",
    });

    let _aborter = new AbortController();
    var model = {
      JobIndustryId: refs.refIndustryId.current.value,
      Description: refs.refTextInd.current.value.trim(),
    };

    fetch(
      `${g.Api.ApiRoute}/adminapi/${
        flags.flagSaveMode.current === "ADD" ? "AddJobIndustryAsync" : "EditJobIndustryAsync"
      }`,
      {
        method: "POST",
        headers: am.Identity.GetAuthHeader(),
        aborter: _aborter.signal,
        body: JSON.stringify(model),
      }
    )
      .then((r) => {
        if (r.ok) {
          return r.json();
        }

        fe.CatchResponse(r, (m) => {
          setDialog({ mode: "e", title: "Transmit Error", message: m });
        });
      })
      .then((r) => {
        if (!r) return;
        if (r.Succeeded) {
          let indModel = u.Data.GetData(g.DbKeys.JobIndustry);
          indModel.CacheVersion = r.CacheVersion;
          let newList = [];
          if (flags.flagSaveMode.current === "ADD") {
            indModel.ModelData.push(r.ModelData);
            setIndustryList(indModel.ModelData);
            //setStateJobList(null);
          } else {
            newList = indModel.ModelData.filter((f) => f.JobIndustryId !== model.JobIndustryId);

            let stateInd = industryList.find((i) => i.JobIndustryId === model.JobIndustryId);

            if (stateInd) {
              stateInd.Description = model.Description;
              setSelectedIndustry(stateInd);
              newList.push(stateInd);
              indModel.ModelData = newList;
            }
          }

          u.Data.StoreData(g.DbKeys.JobIndustry, indModel);
          setSelectedIndustry(selectedIndustry);
          setMsg({
            mode: "s",
            message: "Job industry saved.",
          });
          setDialog({ mode: "cl" });
          refs.refTextInd.current.value = "";
        } else {
          setDialog({ mode: "e", title: "Ooops", message: r.Message });
        }
      })
      .catch((err) => {
        fe.CatchError(err, (m) => {
          setDialog({ mode: "e", title: "Transmit Error", message: m });
        });
      });

    return () => {
      _aborter.abort();
    };
  };
*/
  // const OnEditIndustry = () => {
  //   var toEdit = industryList.find((item) => item.JobIndustryId === selectedIndustry.JobIndustryId);
  //   console.log(selectedIndustry);

  //   if (toEdit) {
  //     flags.flagSaveMode.current = "EDIT";
  //     refs.refTextInd.current.value = toEdit.Description;
  //     setIndustryCaption("EDIT ");
  //     HideOrShowIndustryEntry();
  //   }
  // };

  //[selectedIndustry]
  // useEffect(() => {
  //   if (
  //     !refs.refIndustryId.current.value ||
  //     refs.refIndustryId.current.value === DEFAULT_SELECTVAL
  //   ) {
  //     return;
  //   }

  //   let ind = industryList.find((f) => f.JobIndustryId === refs.refIndustryId.current.value);
  //   if (ind) {
  //     setStateJobList(ind.Jobs);
  //   } else {
  //     setStateJobList([]);
  //   }

  //   // eslint-disable-next-line
  // }, [refs.refIndustryId.current.value]);

  // useEffect(() => {
  //   if (stateJobList) {
  //     subscribeToolbarEvent(true);
  //   }
  //   // eslint-disable-next-line
  // }, [stateJobList]);
  /*
  const OnRemoveIndustry = () => {
    if (selectedIndustry.Jobs.length > 0) {
      setDialog({
        mode: "w",
        title: "Not Possible",
        message: "There are job titles inside this industry. It cannot be removed.",
      });
      return;
    }

    setDialog({
      mode: "q",
      message: "This job industry can be removed. Do you want to continue?",
      buttons: [
        {
          id: "can",
          caption: "Cancel",
        },
        {
          id: "ok",
          caption: "Yes",
          OnClick: () => {
            setDialog({
              mode: "p",
              title: "Remove",
              message: "Removing job industry...",
            });

            var model = {
              JobIndustryId: selectedIndustry.JobIndustryId,
            };

            fetch(`${g.Api.ApiRoute}/adminapi/RemoveJobIndustryAsync`, {
              method: "POST",
              headers: am.Identity.GetAuthHeader(),
              body: JSON.stringify(model),
            })
              .then((r) => {
                if (r.ok) {
                  return r.json();
                }

                fe.CatchResponse(r, (m) => {
                  setDialog({ mode: "e", title: "Remove Error", message: m });
                });
              })
              .then((r) => {
                if (!r) return;
                if (r.Succeeded) {
                  let newListModel = u.Data.GetData(g.DbKeys.JobIndustry);
                  let newList = newListModel.ModelData.filter(
                    (f) => f.JobIndustryId !== model.JobIndustryId
                  );

                  newListModel.ModelData = newList;
                  u.Data.StoreData(g.DbKeys.JobIndustry, newListModel, () => {
                    setIndustryList(newListModel.ModelData);

                    setDialog({
                      mode: "s",
                      title: "Removed",
                      message: "Job industry removed.",
                    });
                  });
                } else {
                  setDialog({ mode: "e", title: "Ooops", message: r.Message });
                }
              })
              .catch((err) => {
                fe.CatchError(err, (m) => {
                  setDialog({ mode: "e", title: "Transmit Error", message: m });
                });
              });
          },
        },
      ],
    });
  };

  */
  //#endregion

  //#region Helpers
  const toggleFormEntries = (tr) => {
    [...tr.querySelectorAll("span.read")].forEach((el) => el.classList.toggle("dn"));
    [...tr.querySelectorAll("span.edit")].forEach((el) => el.classList.toggle("dn"));

    [...tr.querySelectorAll("span.modify")].forEach((el) => el.classList.toggle("dn"));
  };

  const getInputValues = (tr) => {
    let inputs = tr.querySelectorAll("input[type='text']");
    let values = [];
    [...inputs].forEach((t) => {
      values.push(t.value);
    });

    return values;
  };

  //#endregion

  //#region :: Job Title Management

  const OnRowCommand = (e, mode, job) => {
    let targ = e.target.closest("[data-tr]");

    switch (mode) {
      case "e":
      case "u":
        toggleFormEntries(targ);
        break;
      case "s":
        let jobStore = u.Data.GetData(g.DbKeys.JobIndustry);
        let v = getInputValues(targ);

        let model = {
          JobIndustryId: selectedIndustry.JobIndustryId,
          JobId: job.JobId,
          Description: v[0].trim(),
        };

        let jobDescExist = selectedIndustry.Jobs.find(
          (f) =>
            f.Description.toLowerCase().trim() === model.Description.toLowerCase() &&
            f.JobId !== model.JobId
        );
        if (jobDescExist) {
          setDialog({
            mode: "e",
            title: "Duplicate",
            message:
              "A job title specified already exists in the job list or other industry job lists.",
          });
          return;
        }

        if (model.Description.toLowerCase() === job.Description.trim().toLowerCase()) {
          toggleFormEntries(targ);
          return;
        }

        if (!u.Validator.CheckMinMax(model.Description, 5, 150)) {
          setDialog({
            mode: "e",
            title: "Required",
            message: "Description must be five or more characters.",
          });
          return;
        }
        // submit to server
        fetch(`${g.Api.ApiRoute}/adminapi/EditJobTitleAsync`, {
          method: "POST",
          headers: am.Identity.GetAuthHeader(),
          body: JSON.stringify(model),
        })
          .then((r) => {
            if (r.ok) {
              return r.json();
            }
            fe.CatchResponse(r, (m) => {
              setDialog({ mode: "e", title: "Modify Error", message: m });
            });
          })
          .then((j) => {
            if (!j) return;
            if (j.Succeeded) {
              toggleFormEntries(targ);

              if (jobStore) {
                let j_idx = selectedIndustry.Jobs.findIndex((t) => t.JobId === job.JobId);
                let i_idx = jobStore.ModelData.findIndex(
                  (f) => f.JobIndustryId === selectedIndustry.JobIndustryId
                );

                if (j_idx >= 0 && i_idx >= 0) {
                  let ind = selectedIndustry;
                  ind.Jobs.splice(j_idx, 1, j.ModelData);
                  setSelectedIndustry(ind);

                  jobStore.ModelData.splice(i_idx, 1, ind);
                  u.Data.StoreData(g.DbKeys.JobIndustry, jobStore);
                }
              } else {
                setDialog({
                  mode: "e",
                  title: "Oops",
                  message: "Local store has been tampered.",
                });
              }
              //let newList = stateJobList.filter((t) => t.JobId !== job.JobId);
            } else {
              setDialog({ mode: "e", title: "Oops", message: j.Message });
            }
          })
          .then((err) => {
            fe.CatchError(err, (m) => {
              setDialog({ mode: "e", title: "Catch Error", message: m });
            });
          });

        break;
      case "r": //remove
        setDialog({
          mode: "q",
          title: "Remove",
          message: "Remove job title?",
          buttons: [
            { id: "cancel", caption: "Cancel" },
            {
              id: "yes",
              caption: "Yes",
              OnClick: () => {
                var model = {
                  JobId: job.JobId,
                };

                fetch(`${g.Api.ApiRoute}/adminapi/RemoveJobTitleAsync`, {
                  method: "POST",
                  headers: am.Identity.GetAuthHeader(),
                  body: JSON.stringify(model),
                })
                  .then((r) => {
                    if (r.ok) {
                      return r.json();
                    }
                    fe.CatchResponse(r, (m) => {
                      setDialog({
                        mode: "e",
                        title: "Modify Error",
                        message: m,
                      });
                    });
                  })
                  .then((j) => {
                    if (!j) {
                      setMsg({ mode: "e", message: "Something went wrong" });
                      return;
                    }

                    if (j.Succeeded) {
                      let industryStore = u.Data.GetData(g.DbKeys.JobIndustry);
                      if (industryStore) {
                        let newJobList = selectedIndustry.Jobs.filter((f) => f.JobId !== job.JobId);

                        let newInd = selectedIndustry;
                        newInd.Jobs = newJobList;
                        setSelectedIndustry(newInd);

                        let indIdx = industryStore.ModelData.findIndex(
                          (f) => f.JobIndustryId === newInd.JobIndustryId
                        );

                        if (indIdx >= 0) {
                          industryStore.ModelData.splice(indIdx, 1, newInd);
                          industryStore.CacheVersion = j.CacheVersion;
                          u.Data.StoreData(g.DbKeys.JobIndustry, industryStore);

                          setMsg({
                            mode: "s",
                            message: j.Message,
                          });
                        } else {
                          setMsg({
                            mode: "e",
                            message: "Something went wrong.",
                          });
                        }
                      }
                    } else {
                      setDialog({
                        mode: "e",
                        title: "Oops",
                        message: j.Message,
                      });
                    }
                  })
                  .then((err) => {
                    fe.CatchError(err, (m) => {
                      setDialog({
                        mode: "e",
                        title: "Oops",
                        message: m,
                      });
                    });
                  });
              },
            },
          ],
        });
        break;
      default:
        break;
    }
  };

  const OnSaveNewJobTitle = () => {
    if (!u.Validator.CheckMinMax(refs.refNewJobText.current.value, 2, 150)) {
      setDialog({
        mode: "e",
        title: "Required",
        message: "Job Title must be two or more characters and not more than 150.",
      });
      return;
    }

    let ex = selectedIndustry.Jobs.find(
      (f) => f.Description.toLowerCase() === refs.refNewJobText.current.value.trim().toLowerCase()
    );
    if (ex) {
      setDialog({
        mode: "e",
        title: "Duplicate",
        message: "That job title already exists in the job title list.",
      });
      return;
    }

    let model = {
      //JobId: "unknown",
      JobIndustryId: selectedIndustry.JobIndustryId,
      Description: refs.refNewJobText.current.value.trim(),
    };

    fetch(`${g.Api.ApiRoute}/adminapi/AddNewJobTitleAsync`, {
      method: "POST",
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(model),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
        fe.CatchResponse(r, (m) => {
          setDialog({ mode: "e", title: "Remove Error", message: m });
        });
      })
      .then((j) => {
        if (!j) return;

        if (j.Succeeded) {
          if (selectedIndustry) {
            dmgr.GetJobIndustry((jobIndustryStore) => {
              if (jobIndustryStore) {
                if (Array.isArray(jobIndustryStore.ModelData)) {
                  let iList = jobIndustryStore.ModelData;
                  let targetIndustry = iList.find(
                    (f) => f.JobIndustryId === selectedIndustry.JobIndustryId
                  );

                  if (targetIndustry) {
                    let indx = iList.findIndex(
                      (f) => f.JobIndustryId === selectedIndustry.JobIndustryId
                    );
                    if (indx >= 0) {
                      let jndx = targetIndustry.Jobs.findIndex(
                        (f) => f.JobId === j.ModelData.JobId
                      );
                      if (jndx >= 0) {
                        targetIndustry.Jobs.splice(jndx, 1, j.ModelData);
                      } else {
                        targetIndustry.Jobs.push(j.ModelData);
                      }

                      //targetIndustry.Jobs = [targetIndustry.Jobs];

                      iList.splice(indx, 1, targetIndustry);
                      console.log("inserting...");

                      jobIndustryStore.ModelData = iList;
                      jobIndustryStore.CacheVersion = j.CacheVersion;
                      u.Data.StoreData(g.DbKeys.JobIndustry, jobIndustryStore, () => {
                        setIndustryList(iList);
                        //console.log(targetIndustry);
                        setSelectedIndustry(targetIndustry);
                      });
                    } else {
                      console.log("Unable to find Job Industry");
                    }
                    //}
                  }
                } else {
                  setDialog({
                    mode: "e",
                    title: "Incompatible Data",
                    message: "The local store has different data structure.",
                  });
                }
              } else {
                setDialog({
                  mode: "e",
                  title: "Data Tampered",
                  message: "The local store has been tampered.",
                });
              }
            });

            refs.refNewJobText.current.value = "";
            refs.refNewJobText.current.focus();
          } else {
            setDialog({ mode: "e", title: "Ooops", message: j.Message });
          }
        } else {
          setDialog({ mode: "e", title: "Ooops", message: j.Message });
        }
      })
      .catch((err) => {
        fe.CatchError(err, (m) => {
          setDialog({ mode: "e", title: "Error Catched", message: m });
        });
      });
  };

  //#endregion

  return (
    <div className="tb-pad-md">
      <SectionExpander
        props={{
          icon: ico.BriefCase,
          title: "Profession and Job Management",
          titleSize: "2em",
          caption: "Manage job industry listing and its related job or profession",
        }}
      >
        <div className="job-list">
          <div className="tb-pad-md">
            {industryList ? (
              <>
                <div>
                  <div className="b-pad-md">
                    <span ref={refs.refIndDropDown}>
                      {selectedIndustry ? (
                        <select
                          ref={refs.refIndustryId}
                          name=""
                          value={selectedIndustry.JobIndustryId}
                          id="selIndustry"
                          onChange={OnSelectIndustry}
                        >
                          {industryList.map((i) => (
                            <option key={i.JobIndustryId} value={i.JobIndustryId}>
                              {i.Description}
                            </option>
                          ))}
                        </select>
                      ) : (
                        ""
                      )}
                    </span>

                    {/* <span className="l-pad-lg" ref={refs.refIndOperation}>
                      <ToolBar options={toolBarEvents} />
                    </span> */}
                  </div>
                  <div>
                    <InlineMessageBar props={{ options: msg }} />
                  </div>
                  <div className="dn tb-pad-sm bordered radiused4" ref={refs.refNewInd}>
                    <div className="pad-lg">
                      {/* <div className="l-pad-sm font-bold">{industryCaption}</div> */}
                      <div>
                        <input
                          type="text"
                          className="long"
                          ref={refs.refTextInd}
                          placeholder="Enter new industry description"
                        />
                      </div>
                      {/* <div>
                        <div className="a-right">
                          <span
                            title="Cancel"
                            className="font-xl tool-icon"
                            onClick={() => {
                              HideOrShowIndustryEntry();
                            }}
                          >
                            {ico.Undo}
                          </span>
                          <span title="Save" className="font-xl tool-icon" onClick={OnSaveIndustry}>
                            {ico.Save}
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="tb-pad-lg" ref={refs.refJobList}>
                    <div>
                      <table>
                        <thead>
                          <tr>
                            <td className="a-center">#</td>
                            <td>Job Description</td>
                            <td style={{ width: "80px" }}></td>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedIndustry ? (
                            selectedIndustry.Jobs.map((j, x) => (
                              <tr key={x} data-tr={j.JobId}>
                                <td>
                                  <div className="a-right">{x + 1}</div>
                                </td>
                                <td>
                                  <span className="read">{j.Description}</span>

                                  <span className="edit dn">
                                    <input
                                      type="text"
                                      className="long"
                                      maxLength="150"
                                      onClick={(e) => e.stopPropagation()}
                                      defaultValue={j.Description}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span
                                    title="Edit"
                                    className="font-md tool-icon edit"
                                    onClick={(e) => OnRowCommand(e, "e", j)}
                                  >
                                    {ico.Pencil}
                                  </span>
                                  <span
                                    title="Remove"
                                    className="font-md tool-icon edit"
                                    onClick={(e) => OnRowCommand(e, "r", j)}
                                  >
                                    {ico.CloseCircle}
                                  </span>
                                  <span
                                    title="Cancel"
                                    className="font-lg modify tool-icon dn"
                                    onClick={(e) => OnRowCommand(e, "u", j)}
                                  >
                                    {ico.Undo}
                                  </span>

                                  <span
                                    title="Save"
                                    className="font-lg modify tool-icon dn"
                                    onClick={(e) => OnRowCommand(e, "s", j)}
                                  >
                                    {ico.Save}
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td>NEW:</td>
                            <td>
                              <input type="text" className="long" ref={refs.refNewJobText} />
                            </td>
                            <td>
                              <span
                                title="Save"
                                className="font-xl tool-icon"
                                onClick={OnSaveNewJobTitle}
                              >
                                {ico.Save}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </SectionExpander>
      <DialogBox Dialog={dialog} key={"JobMgt"} />
    </div>
  );
};

export default React.memo(JobMgt);
