/* eslint-disable */
import React from "react";
import { useState } from "react";
import { SectionExpander } from "../../Components/Widgets/SectionExpander";
import useReactIcons from "../../Hooks/useReactIcons";
import SentimentReactions from "./Admin/DataReports/SentimentReactions";
import { InfoBar } from "../../Components/Widgets/InfoBar";
import EducationCourse from "./Admin/DataReports/EducationCourse";
import JobIndustryAnalytics from "./Admin/DataReports/JobIndustryAnalytics";
import HobbiesInterests from "./Admin/DataReports/HobbiesInterests";
import MembersStats from "./Admin/DataReports/MembersStats";
import CommonTopic from "./Admin/DataReports/CommonTopic";
import CaseReports from "./Admin/DataReports/CaseReports";
import UserPlatform from "./Admin/DataReports/UserPlatform";

const DataAnalytics = () => {
  const [m1, setMsg] = useState({ message: "Ready", mode: "i" });
  const [dialog, setDialog] = useState({ mode: "cl" });
  const ico = useReactIcons();

  const CheckEntry = () => {
    setDialog({
      mode: "wi",
      title: "Data Analytics",
      message:
        "Please follow the pattern of updating data from Basic Info or Address / Location modules.",
    });
  };
  return (
    <div className="tb-pad-md">
      <SectionExpander
        props={{
          icon: ico.GraphUp,
          title: "Data Analytics",
          titleSize : '2em',
          minHeight: "740px",
          caption: "Produce reports or extract valuable information",
        }}
      >
        <div>
          <div>
            <InfoBar>
              <ul>
                <li>
                  <span className="font-lg">{ico.InfoBlue}</span> Click the title of each report
                  type below.
                </li>
                <li>
                  <span className="font-xl">{ico.WarningOrange}</span> Generating complex reports
                  may impact the performance of the entire servers.
                </li>
                <li>
                  <span className="font-xl">{ico.WarningOrange}</span> Generating reports with long
                  date range will severely impact the performance of the servers.
                </li>
              </ul>
            </InfoBar>
          </div>
          <div>
            <UserPlatform />
            <MembersStats />
            <SentimentReactions />
            <CommonTopic />
            <EducationCourse />
            <JobIndustryAnalytics />
            <HobbiesInterests />
            <CaseReports />
          </div>
          <div></div>
        </div>
      </SectionExpander>
    </div>
  );
};

export default DataAnalytics;
