import { useState } from "react";

const useAsyncState = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const setter = (nVal) =>
    new Promise((resolve) => {
      setValue(nVal);
      resolve(nVal);
    });
  return [value, setter];
};

export default useAsyncState;
