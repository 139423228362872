import React, { useEffect, useRef, useState } from "react";
import { InfoBar } from "../../../Components/Widgets/InfoBar";
import { SectionExpander } from "../../../Components/Widgets/SectionExpander";
import useAccountManager from "../../../Globals/useAccountManager";
import useGlobalConstants from "../../../Globals/useGlobalConstants";
import useCommonUtil from "../../../Hooks/useCommonUtil";
import useReactIcons from "../../../Hooks/useReactIcons";

const UsersMigration = () => {
  const ico = useReactIcons();
  const [fileExcel, setFileExcel] = useState();
  const [statusList, setStatusList] = useState();
  const u = useCommonUtil();
  const g = useGlobalConstants();
  const am = useAccountManager();

  const refs = {
    fileName: useRef(),
    uploadBtn: useRef(),
    prog: useRef(),
    btns: useRef(),
    fileExcel: useRef(),
  };

  useEffect(() => {
    setStatusList([
      {
        id: u.Security.GenerateRandomString(10),
        type: "i",
        msg: "Awaiting file upload.",
      },
    ]);
  }, []);

  const ClearOutPut = () => {
    setStatusList([
      {
        type: "l",
        id: u.Security.GenerateRandomString(10),
        msg: `Workbook file assigned. Ready for upload : ${fileExcel.name}`,
      },
    ]);
  };

  useEffect(() => {
    if (fileExcel) {
      if (fileExcel.name.toLowerCase().includes(".xlsx")) {
        refs.fileName.current.innerText = `${fileExcel.name} | ${(
          fileExcel.size / 1024
        ).toFixed(2)} KB`;
        ClearOutPut();
        refs.uploadBtn.current.classList.remove("nt");
        // setStatusList([
        //   ...statusList,
        //   {
        //     type: "l",
        //     id: u.Security.GenerateRandomString(10),
        //     msg: `Workbook file assigned : ${fileExcel.name}`,
        //   },
        // ]);
      } else {
        setStatusList((prevState) => [
          ...prevState,
          {
            id: u.Security.GenerateRandomString(10),
            type: "e",
            msg: `Invalid file detected : ${fileExcel.name}`,
          },
        ]);
        MarkDone();
      }
    } else {
      refs.fileName.current.innerText = "";
      if (refs.fileExcel.current) {
        refs.fileExcel.current.value = "";
      }
      refs.uploadBtn.current.classList.add("nt");
      setStatusList([
        {
          type: "i",
          id: u.Security.GenerateRandomString(10),
          msg: `Awaiting for file to upload.`,
        },
      ]);
    }
  }, [fileExcel]);

  const OnFileChange = (e) => {
    console.log(e.target);
    var file = e.target.files[0];
    if (file) {
      setFileExcel(file);
    } else {
      setFileExcel(null);
    }
  };

  const MarkDone = (clear) => {
    refs.prog.current.classList.add("dn");
    refs.btns.current.classList.remove("nt");
    //refs.uploadBtn.classList.add("nt");
    if (clear) {
      setFileExcel(null);
    }
  };

  const OnSubmitFile = () => {
    if (fileExcel) {
      refs.prog.current.classList.remove("dn");
      refs.btns.current.classList.add("nt");
      setStatusList([
        ...statusList,
        {
          id: u.Security.GenerateRandomString(10),
          type: "i",
          msg: "Uploading workbook file and processing users list... This may take a while.",
        },
      ]);

      const formData = new FormData();
      formData.append(fileExcel.name, fileExcel);
      fetch(`${g.Api.ApiRoute}/MigrationApi/BatchRegisterAsync`, {
        method: "POST",
        body: formData,
        headers: am.Identity.GetBinAuthHeader(),
      })
        .then((r) => {
          if (r.ok) return r.json();
        })
        .then((j) => {
          console.log(j);
          if (j) {
            setStatusList((prevState) => [...prevState, ...j.ModelData]);
            if (j.Succeeded) {
              refs.uploadBtn.current.classList.add("nt");
            }
          } else {
            setStatusList((prevState) => [
              ...prevState,
              {
                id: u.Security.GenerateRandomString(10),
                type: "e",
                msg: "Server error",
              },
            ]);
          }
          MarkDone();
        })
        .catch((err) => {
          console.log(err);
          setStatusList((prevState) => [
            ...prevState,
            {
              id: u.Security.GenerateRandomString(10),
              type: "e",
              msg: "Unable to fetch from Web API.",
            },
          ]);
          MarkDone();
        });
    }
  };

  return (
    <div className="users-migration">
      <SectionExpander
        props={{
          icon: ico.Users,
          title: `Users Migration`,
          titleSize: "2em",
          caption: `This feature module allows the administration to upload a list of users to the system using a workbook file.`,
        }}
      >
        <InfoBar
          props={{
            title: "BEFORE YOU BEGIN",
            caption: "IMPORTANT",
            flash: true,
            expanded: true,
          }}
        >
          <ul className="font-sm">
            <li className="bold">
              <span className="font-xl color-red bold">{ico.Exclamation2}</span>{" "}
              This process is dangerous if the following conditions are not met.
              It may lead to total system failure.
            </li>
            <li>
              <span className="font-xl color-orange">{ico.Exclamation2}</span>{" "}
              Verify that the file is virus and macro-free!
            </li>
            <li>
              <span className="font-xl color-orange">{ico.Exclamation2}</span>{" "}
              Please ensure the validity and veracity of the workbook file!
            </li>
            <li>
              <span className="font-xl color-orange">{ico.Exclamation2}</span>{" "}
              Ensure the format and data contained in the workbook file conforms
              to the requirement!
            </li>
          </ul>
        </InfoBar>
        <div>
          <SectionExpander
            props={{
              title: "Download User record template",
              titleSize: "1.5em",
              icon: ico.ExcelFile,
              caption: `Fill-out a form for migrating users into the HIVE database repository.`,
            }}
          >
            <div>
              <a
                href="/template/hive-batch-reg-template.xlsx"
                className="color-blue"
                target={"_blank"}
              >
                <span className="font-lg color-purple">
                  {ico.CloudDownload}
                </span>
                <span className="l-pad-lg font-bold">
                  Click here to download User Migration template workbook file.
                </span>
              </a>
            </div>
          </SectionExpander>
          <SectionExpander
            props={{
              title: "Upload Users list workbook",
              titleSize: "1.5em",
              icon: ico.ExcelFile,
              caption: `Choose the file containing the the list of users from the template.`,
            }}
          >
            <div className="b-pad-md color-blue">
              <b>NOTE:</b> Each user record is inserted or registered in a
              250-millisecond interval. This is by design to give the backend
              system time to create each user repository directories.
            </div>
            <div className="tb-pad-md" ref={refs.btns}>
              <div>
                <label
                  htmlFor="fileExcel"
                  className="excel-upload"
                  onClick={() => MarkDone(true)}
                >
                  <span className="font-xl r-pad-sm">{ico.ExcelFile}</span>{" "}
                  Browse
                </label>
                <input
                  className="dn"
                  id="fileExcel"
                  ref={refs.fileExcel}
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={OnFileChange}
                />
                <span
                  className="l-pad-lg font-bold color-green"
                  ref={refs.fileName}
                ></span>
              </div>
              <div className="tb-pad-md nt" ref={refs.uploadBtn}>
                <button onClick={OnSubmitFile} style={{ width: "130px" }}>
                  <span className="font-xl">{ico.ArrowCircleUp}</span> Upload
                </button>
              </div>
            </div>

            <div className="tb-pad-md">
              <div className="bordered">
                <div className="pad-lg">
                  <span className="font-xl r-pad-md dn" ref={refs.prog}>
                    {ico.CircleSpinner}
                  </span>
                  <span>Output Result</span>
                </div>
                <hr />
                <div
                  className="tb-pad-md"
                  style={{ height: "220px", overflowY: "scroll" }}
                >
                  <div className="pad-md">
                    <ul className="bullet">
                      {statusList ? (
                        statusList.map((st) => (
                          <li
                            key={st.id}
                            className={`${
                              st.type === "i"
                                ? "color-black"
                                : st.type === "e"
                                ? "color-red"
                                : st.type === "l"
                                ? "color-blue"
                                : st.type === "s"
                                ? "color-green"
                                : ""
                            }`}
                          >
                            {st.msg}
                          </li>
                        ))
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </SectionExpander>
        </div>
      </SectionExpander>
    </div>
  );
};

export default UsersMigration;
