import React, { useEffect, useRef, useState } from "react";
import useGlobalConstants from "../Globals/useGlobalConstants";
import useCommonUtil from "../Hooks/useCommonUtil";
import useReactIcons from "../Hooks/useReactIcons";
import "./WallPhotoViewer.css";

const WallMediaViewer = (props) => {
  const u = useCommonUtil();
  const g = useGlobalConstants();
  const ico = useReactIcons();
  const WallAlbum = props.WallAlbum;
  const [targetImg, setTargetImg] = useState();
  const [mediaList, setMediaList] = useState();
  const refs = {
    self: useRef(),
  };

  useEffect(() => {   

    if (WallAlbum) {
      setTargetImg(WallAlbum.TargetMedia);
      refs.self.current.classList.remove("hidden");
      setMediaList(WallAlbum.Album.Media.filter((m) => m.MediaType === "i"));
    }
    // eslint-disable-next-line
  }, [WallAlbum]);

  const SelectItem = (e, imgHash) => {
    setTargetImg(imgHash);
    u.Ui.makeMenuItemActive(e.target);
  };

  const CloseMe = () => {
    refs.self.current.classList.add("hidden");
  };

  //console.log("Render WallPhotoViewer");

  return (
    <div className="wall-photo hidden" ref={refs.self}>
      <div className="thumbs flex still grow v-center">
        <div>
          {mediaList
            ? mediaList.map((i) => (
                <div
                  key={i.MediaHashId}
                  className={`pics ${i.MediaHashId === WallAlbum.TargetMedia ? "active" : ""}`}
                  onClick={(e) => SelectItem(e, i.MediaHashId)}
                  style={{
                    backgroundImage:
                      WallAlbum.Album.IsWallMedia === 1
                        ? `url(${g.Api.CdnRoute}/${WallAlbum.UserName}/albums/wall/${WallAlbum.Album.AlbumName}/thumbs/t-${i.MediaHashId}.jpg)`
                        : `url(${g.Api.CdnRoute}/${WallAlbum.UserName}/albums/${WallAlbum.Album.AlbumNameRoute}/thumbs/t-${i.MediaHashId}.jpg)`,
                  }}
                ></div>
              ))
            : ""}
        </div>
        <div className="last close" onClick={CloseMe}>
          <span>{ico.CloseCircle}</span>
        </div>
      </div>
      {WallAlbum && targetImg ? (
        <div
          className="blow-up"
          style={{
            backgroundImage:
              WallAlbum.Album.IsWallMedia === 1
                ? `url(${g.Api.CdnRoute}/${WallAlbum.UserName}/albums/wall/${WallAlbum.Album.AlbumName}/m-${targetImg}.jpg)`
                : `url(${g.Api.CdnRoute}/${WallAlbum.UserName}/albums/${WallAlbum.Album.AlbumNameRoute}/m-${targetImg}.jpg)`,
          }}
        ></div>
      ) : (
        ""
      )}
    </div>
  );
};

export default React.memo(WallMediaViewer);
