import useCommonUtil from "../Hooks/useCommonUtil";
import useAccountManager from "./useAccountManager";
import useFetchErrorHandler from "./useFetchErrorHandler";
import useGlobalConstants from "./useGlobalConstants";

export const useDataManager = () => {
  const g = useGlobalConstants();
  const u = useCommonUtil();
  const am = useAccountManager();
  const fe = useFetchErrorHandler();

  const f = {
    GetCountries: (fn) => {
      let cachedCountries = u.Data.GetData(g.DbKeys.CountryList);

      let cacheVersion = cachedCountries ? cachedCountries.CacheVersion : "not-set";

      fetch(`${g.Api.CountryApi}/?cacheVersion=${cacheVersion}`, {
        method: "GET",
        //headers: g.Fetch. //am.Identity.GetAuthHeader(),
      })
        .then((r) => {
          //console.log(r);
          if (r.ok) {
            return r.json();
          } else {
            fe.CatchResponse(r, (m) => {
              fn.call(this, m);
            });
          }
        })
        .then((j) => {
          if (j) {
            if (j.Succeeded) {
              if (j.CacheVersion === cacheVersion) {
                if (typeof fn === "function") {
                  fn.call(this, cachedCountries);
                }
              } else {
                u.Data.StoreData(g.DbKeys.CountryList, j);
                if (typeof fn === "function") {
                  fn.call(this, j);
                }
              }
            } else {
              fn.call(this, []);
            }
          } else {
            fn.call(this, false);
          }
        })
        .catch((err) => {
          fn.call(this, false);
        });
    },    

    GetCities: (countryCode, fn) => {
      //console.log(countryCode);

      const cityListName = g.DbKeys.CityList;

      fetch(`${g.Api.CityApi}/?countryCode=${countryCode}`, {
        method: "GET",
        //headers: am.Identity.GetAuthHeader(),
      })
        .then((r) => {
          //console.log(r);
          if (r.ok) {
            return r.json();
          } else {
            fe.CatchResponse(r);
            return null;
          }
        })
        .then((j) => {
          if (j) {
            if (j.Succeeded) {
              u.Data.StoreData(cityListName, j);
              if (typeof fn === "function") {
                fn.call(this, j);
              }
            } else {
              fn.call(this, false);
            }
          } else {
            fn.call(this, false);
          }
        })
        .catch((err) => {
          fn.call(this, false);
        });
    },

    GetJobIndustry: (fn) => {
      let cachedIndustry = u.Data.GetData(g.DbKeys.JobIndustry);
      let model = {
        cacheVersion: cachedIndustry ? cachedIndustry.CacheVersion : "not-set",
      };

      let param = new URLSearchParams(model);

      fetch(`${g.Api.ApiRoute}/DataLookUpApi/GetJobIndustryAsync/?${param}`, {
        method: g.Fetch.GET,
        headers: am.Identity.GetAuthHeader(),
      })
        .then((r) => {
          if (r.ok) return r.json();
          fe.CatchResponse(r, (m) => {
            fn.call(this, "Unable to communicate with API Server.");
          });
        })
        .then((j) => {
          if (!j) {
            fn.cal(this, "Unable to communicate with API Server.");
          } else if (j.Succeeded) {
            if (model.cacheVersion !== j.CacheVersion) {
              u.Data.StoreData(g.DbKeys.JobIndustry, j);
              fn.call(this, j);
            } else {
              fn.call(this, cachedIndustry);
            }
          } else {
            fn.cal(this, j.Message);
          }
        })
        .catch((err) => {
          fe.CatchError(err, (m) => {
            fn.call(this, m);
          });
        });
    },

    GetAcads: (fn) => {
      let acadList = u.Data.GetData(g.DbKeys.AcademicList);
      let model = {
        cacheVersion: acadList ? acadList.CacheVersion : "not-set",
      };
      //console.log(model);
      let param = new URLSearchParams(model);

      fetch(`${g.Api.ApiRoute}/DataLookUpApi/GetAcadsAsync/?${param}`, {
        method: g.Fetch.GET,
        headers: am.Identity.GetAuthHeader(),
      })
        .then((r) => {
          if (r.ok) return r.json();
          fe.CatchResponse(r, (m) => {
            fn.call(this, m);
          });
        })
        .then((j) => {
          if (!j) return;
          if (j.Succeeded) {
            if (model.cacheVersion !== j.CacheVersion) {
              u.Data.StoreData(g.DbKeys.AcademicList, j);
              fn.call(this, j);
            } else {
              fn.call(this, acadList);
            }
          } else {
            fn.cal(this, j.Message);
          }
        })
        .catch((err) => {
          fe.CatchError(err, (m) => {
            fn.call(this, m);
          });
        });
    },

    GetCourses: (fn) => {
      let cachedCourses = u.Data.GetData(g.DbKeys.CourseList);
      let model = {
        cacheVersion: cachedCourses ? cachedCourses.CacheVersion : "not-set",
      };
      //console.log(model);
      let param = new URLSearchParams(model);

      fetch(`${g.Api.ApiRoute}/DataLookUpApi/GetCoursesAsync/?${param}`, {
        method: g.Fetch.GET,
        headers: am.Identity.GetAuthHeader(),
      })
        .then((r) => {
          if (r.ok) return r.json();
          fe.CatchResponse(r, (m) => {
            fn.call(this, m);
          });
        })
        .then((j) => {
          if (!j) return;
          if (j.Succeeded) {
            if (model.cacheVersion !== j.CacheVersion) {
              u.Data.StoreData(g.DbKeys.CourseList, j);
              fn.call(this, j);
            } else {
              fn.call(this, cachedCourses);
            }
          } else {
            fn.cal(this, j.Message);
          }
        })
        .catch((err) => {
          fe.CatchError(err, (m) => {
            fn.call(this, m);
          });
        });
    },
    GetCompanies: (fn) => {
      let cachedCompanies = u.Data.GetData(g.DbKeys.CompanyList);
      let model = {
        cacheVersion: cachedCompanies ? cachedCompanies.CacheVersion : "not-set",
      };

      let param = new URLSearchParams(model);

      fetch(`${g.Api.ApiRoute}/DataLookUpApi/GetCompaniesAsync/?${param}`, {
        method: g.Fetch.GET,
        headers: am.Identity.GetAuthHeader(),
      })
        .then((r) => {
          if (r.ok) return r.json();
          fe.CatchResponse(r, (m) => {
            fn.call(this, "error");
          });
        })
        .then((j) => {
          if (!j) return;
          if (j.Succeeded) {
            if (model.cacheVersion !== j.CacheVersion) {
              u.Data.StoreData(g.DbKeys.CompanyList, j);
              fn.call(this, j);
            } else {
              fn.call(this, cachedCompanies);
            }
          } else {
            fn.cal(this, []);
          }
        })
        .catch((err) => {
          fe.CatchError(err, (m) => {
            fn.call(this, []);
          });
        });
    },
    GetUniversities: (fn) => {
      let cachedUnis = u.Data.GetData(g.DbKeys.UniversityList);
      let model = {
        cacheVersion: cachedUnis ? cachedUnis.CacheVersion : "not-set",
      };

      let param = new URLSearchParams(model);

      fetch(`${g.Api.ApiRoute}/DataLookUpApi/GetUniversitiesAsync/?${param}`, {
        method: g.Fetch.GET,
        headers: am.Identity.GetAuthHeader(),
      })
        .then((r) => {
          if (r.ok) return r.json();
          fe.CatchResponse(r, (m) => {
            fn.call(this, "error");
          });
        })
        .then((j) => {
          if (!j) return;
          if (j.Succeeded) {
            if (model.cacheVersion !== j.CacheVersion) {
              u.Data.StoreData(g.DbKeys.UniversityList, j);
              fn.call(this, j);
            } else {
              fn.call(this, cachedUnis);
            }
          } else {
            fn.cal(this, []);
          }
        })
        .catch((err) => {
          fe.CatchError(err, (m) => {
            fn.call(this, []);
          });
        });
    },
    GetHobbyList: (fn) => {
      let cachedHobList = u.Data.GetData(g.DbKeys.HobbyList);

      let cacheVersion = cachedHobList ? cachedHobList.CacheVersion : "not-set";

      fetch(`${g.Api.ApiRoute}/DataLookUpApi/GetHobbyListAsync/?cacheVersion=${cacheVersion}`, {
        method: g.Fetch.GET,
        headers: am.Identity.GetAuthHeader(),
      })
        .then((r) => {
          if (r.ok) return r.json();
          fe.CatchResponse(r, (m) => {
            fn.call(this, "error");
          });
        })
        .then((j) => {
          if (!j) return;
          if (j.Succeeded) {
            if (cacheVersion !== j.CacheVersion) {
              u.Data.StoreData(g.DbKeys.HobbyList, j);
              fn.call(this, j);
            } else {
              fn.call(this, cachedHobList);
            }
          } else {
            fn.cal(this, []);
          }
        })
        .catch((err) => {
          fe.CatchError(err, (m) => {
            fn.call(this, []);
          });
        });
    },
    // Store ======================================================
    StoreCompany: (objList, fn) => {
      let cachedCompanies = u.Data.GetData(g.DbKeys.CompanyList);
      if (cachedCompanies) {
        cachedCompanies.ModelData.push(objList);
        u.Data.StoreData(g.DbKeys.CompanyList, cachedCompanies);
        if (typeof fn === "function") {
          fn.call(this, cachedCompanies);
        }
      }
    },
  };

  return f;
};
