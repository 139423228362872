import React, { useContext, useEffect, useRef } from "react";
import "./ProfileHeader.css";

import useCommonUtil from "../Hooks/useCommonUtil";
import useGlobalConstants from "../Globals/useGlobalConstants";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import SocialBar from "./SocialBar";
import useAccountManager from "../Globals/useAccountManager";
import useReactIcons from "../Hooks/useReactIcons";
import { AvatarContext } from "../Globals/AvatarNotifierReducer";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

import useFetchErrorHandler from "../Globals/useFetchErrorHandler";
import DialogBox from "./Widgets/DialogBox";
import InlineMessageBar from "./Widgets/InlineMessageBar";
import { SocketContext } from "../Globals/RealtimeComm/WebSocketReducer";
import { EducJobTriggerContext } from "../AppContexts";

const ProfileHeader = () => {
  //console.log("Trace: Render profile header.");

  const u = useCommonUtil();
  const h = useHistory();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const ico = useReactIcons();
  const fe = useFetchErrorHandler();
  const [T, setTargetUser] = useState();
  const [myAccount] = useState(am.GetMyAccount());
  const [editableFields, setEditableFields] = useState({
    PunchLine: "",
    Website: "",
  });

  const [userAvatar, setUserAvatar] = useState("");
  const { AvatarDispatch } = useContext(AvatarContext);
  const { HubDispatch } = useContext(SocketContext);
  const { TriggerEducJob } = useContext(EducJobTriggerContext);

  const [msgBar, setMsgBar] = useState({
    mode: "d",
    message: "LOADING",
  });

  const [dialog, setDialog] = useState();

  const fieldNames = {
    PunchLine: "PunchLine",
    Website: "Website",
  };

  const [editPunch, setEditPunch] = useState(false);
  const [editWebsite, setEditWebsite] = useState(false);

  useEffect(() => {
    //console.log(urlUserName);
    am.Identity.GetTargetUser((tuser) => {
      //console.log("ProfileHeader : GetTargetUser", tuser);
      setTargetUser(tuser);
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!T) return;
    if (TriggerEducJob === "default") return;

    // console.log(TriggerEducJob);
    // console.log("Updating Education and Job");

    if (am.Identity.IsMe()) {
      setTargetUser({ ...T, LastCourseAttended: TriggerEducJob });
    }
    // eslint-disable-next-line
  }, [TriggerEducJob]);

  useEffect(() => {
    if (T) {
      if (T.UserName === myAccount.UserName) {
        AvatarDispatch({
          method: "UPDATE",
          AvatarLink: `url(${g.Api.CdnRoute}/${T.UserName}/profile/${T.UserName}.jpg?v=${T.PicVer})`,
          ChangedFromPage: "ProfileHeader",
        });
      }

      if (!T.PicVer) {
        setUserAvatar(
          `url(${g.Api.CdnRoute}/${g.DefaultImageNames.ProfilePicture})`
        );
      } else {
        setUserAvatar(
          `url(${g.Api.CdnRoute}/${T.UserName}/profile/${T.UserName}.jpg?v=${T.PicVer})`
        );
      }
      setEditableFields({
        PunchLine: T.PunchLine,
        Website: T.Website,
      });
    } else {
      setMsgBar({ mode: "p", message: "" });
    }
    // eslint-disable-next-line
  }, [T]);

  //#region :: Methods
  const OnEditPuncLine = () => {
    if (myAccount.UserName === T.UserName) {
      setEditPunch(!editPunch);
    }
  };

  const OnEditWebsite = () => {
    setEditWebsite(!editWebsite);
  };

  const OnSaveEditableData = (f) => {
    let payload = {
      ColumnName: f,
      Value: "",
    };

    let hasChanged = false;

    switch (f) {
      case fieldNames.PunchLine:
        payload.Value = editableFields.PunchLine;
        if (payload.Value !== T.PunchLine) {
          hasChanged = true;
        }
        setEditPunch(false);

        break;
      case fieldNames.Website:
        payload.Value = editableFields.Website;
        if (payload.Value !== T.Website) {
          hasChanged = true;
        }
        setEditWebsite(false);
        break;
      default:
        break;
    }

    if (hasChanged) {
      fetch(`${g.Api.ApiRoute}/user/UpdateHeaderData`, {
        method: g.Fetch.POST,
        headers: am.Identity.GetAuthHeader(),
        body: JSON.stringify(payload),
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            console.log(r);
            fe.CatchResponse(r, (m) => {
              console.log(m);
            });
            return null;
          }
        })
        .then((r) => {
          if (!r) return;
          if (r.Succeeded) {
            am.GetMyAccount((d) => {
              let mUser = d;
              switch (f) {
                case fieldNames.PunchLine:
                  setTargetUser({
                    ...T,
                    PunchLine: editableFields.PunchLine,
                  });
                  mUser.PunchLine = editableFields.PunchLine;
                  break;
                case fieldNames.Website:
                  setTargetUser({
                    ...T,
                    Website: editableFields.Website,
                  });
                  mUser.Website = editableFields.Website;
                  break;
                default:
                  break;
              }
              am.StoreMyAccount(mUser);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const OnChangeEditableFields = (e, f) => {
    switch (f) {
      case fieldNames.PunchLine:
        setEditableFields({
          ...editableFields,
          PunchLine: e.currentTarget.value,
        });
        break;
      case fieldNames.Website:
        setEditableFields({
          ...editableFields,
          Website: e.currentTarget.value,
        });
        break;
      default:
        break;
    }
  };

  const DetectSaveOrDiscard = (e) => {
    if (e.key === "Escape") {
      setEditPunch(!editPunch);
    }
  };
  //#endregion

  //#region
  const fileRef = useRef();
  const OnChangeProfilePic = () => {
    if (!am.Identity.IsMe()) return;
    fileRef.current.click();
  };
  const [profilePic, setProfilePic] = useState();
  const OnChangeFile = () => {
    setProfilePic(fileRef.current.files[0]);
  };
  useEffect(() => {
    if (profilePic) {
      if (u.FileManager.CheckSize(profilePic, g.Image.MAX_SIZE)) {
        setDialog({
          mode: "w",
          title: "FILE SIZE",
          message: `File size is too large. System only accepts ${
            g.Image.MAX_SIZE
          } KB or ${g.Image.MAX_SIZE / 1024} MB.`,
          buttons: [{ caption: "ok", id: "ok" }],
        });
      } else {
        //onsole.log(profilePic);
        setDialog({ mode: "p", message: "Uploading profile picture..." });

        u.FileManager.ResizeImage(
          profilePic,
          g.Image.REDUCE_DIM,
          g.Image.REDUCE_TO,
          (trans) => {
            let photoModel = {
              UserName: T.UserName,
              ApiEndPoint: `${g.Api.ApiRoute}/mediaapi/UploadProfileImageAsync`,
              AuthToken: am.GetMyAuthToken(),
              PhotoModels: [
                {
                  UserName: T.UserName,
                  FileName: g.ProfileDefault.ProfilePicture, //`${hash}.jpg`, //no bearing on this scenario.
                  FileStream: u.FileManager.DataURItoBlob(trans.dataUrl),
                },
              ],
              OnProgress: (n) => {
                if (n) {
                  // SetDialog({
                  //   mode: "pb",
                  //   title: `UPLOADING - ${n}%`,
                  //   message: "Please wait...",
                  //   callBackPercent: n,
                  // });
                }
              },
              OnCompleted: () => {
                // SetDialog({
                //   mode: "pb",
                //   title: `UPLOADED - ${n ? n : 100}%`,
                //   message: `Upload done.`,
                //   callBackPercent: n ? n : 100,
                // });
              },
              Succeeded: (msg) => {
                var result = JSON.parse(msg);
                var picVer = result.ModelData.PicVer;
                am.GetMyAccount((me) => {
                  me.PicVer = `${picVer}`;
                  am.StoreMyAccount(me, (d) => {
                    setTargetUser(d);
                  });
                });

                fileRef.current.value = "";
                setDialog({ mode: "cl" });
                //SetDialog({ mode: "cl" });
              },
              OnError: (err) => {
                //SetDialog({ mode: "e", title: "ERROR", message: err });
                //console.log(err);
                setDialog({
                  mode: "e",
                  message:
                    "It seems you are offline, or the server has technical problem.",
                });
              },
            };

            u.FileManager.UploadImage(photoModel);
          }
        );

        //
      }
    }
    // eslint-disable-next-line
  }, [profilePic]);
  //#endregion

  //#region :: optional

  const ShowMyFriends = () => {
    u.Ui.ScrollToY(0, () => {
      setTimeout(() => {
        h.push(`/${am.Identity.GetUrlUserName()}/connections`);
      }, 100);
    });
  };
  //#endregion

  const OnReportCase = (issueType) => {
    setDialog({
      mode: "text",
      maxLength: g.MaxChars.Post,
      title:
        issueType === 1
          ? `Report: ${T.FirstName} ${T.LastName}`
          : `Report: Bug or Issue`,
      message: `Please make the case report as short and accurate as possible. Exceeding text entry will be truncated.`,
      buttons: [
        { id: "cancel", caption: "Cancel" },
        {
          id: "report",
          caption: "Report",
          OnClick: (reason) => {
            if (u.Data.ValidateLongEntry(reason, 30)) {
              setDialog({
                mode: "w",
                title: "Spam detected",
                message:
                  "An ambiguous long text entry found. Please refrain from doing this action.",
              });
              return;
            }

            const payload = {
              CaseReportId: 0,
              IssueType: issueType,
              UserName: myAccount.UserName,
              FullName: `${myAccount.FirstName} ${myAccount.LastName}`,
              TargetFullName: `${T.FirstName} ${T.LastName}`,
              TargetUserName: T.UserName,
              Reason:
                reason.length > g.MaxChars.Post
                  ? reason.substring(0, g.MaxChars.Post)
                  : reason,
            };

            if (reason.length < 5) {
              setDialog({
                mode: "e",
                message:
                  "Reason is too short. Must be five (5) characters or more, not exceeding 200.",
              });
              return;
            }

            fetch(`${g.Api.ApiRoute}/user/ReportUserCaseAsync`, {
              method: "POST",
              headers: am.Identity.GetAuthHeader(),
              body: JSON.stringify(payload),
            })
              .then((r) => {
                if (r.ok) {
                  return r.json();
                }
              })
              .then((j) => {
                if (!j) {
                  console.log("report error");
                  return;
                }

                if (j.Succeeded) {
                  setDialog({ mode: "s", message: `${j.Message}` });
                  HubDispatch({
                    method: "INVOKE",
                    payload: {
                      ServerMethod: "ServerNotifyCaseReport",
                      Args: [j.ModelData],
                    },
                    callBack: () => {
                      //console.log(cn);
                    },
                  });
                } else {
                  setDialog({ mode: "e", message: j.Message });
                }
              })
              .catch((err) => {
                setDialog({ mode: "s", message: err });
              });
            console.log(payload);
          },
        },
      ],
    });
  };

  const OnReloadActualConnections = () => {
    const t = document.getElementById("__numFriends");
    if (t) {      
      if (am.Identity.IsMe()) {
        t.innerHTML = "Reloading...";
        am.GetMyNumFriends((num) => {          
          t.innerHTML = `<strong>${num}</strong> ${
            num > 1 ? "Connections" : "Connection"
          }`;
        });
      }
    }
  };

  return (
    <>
      <div id="profileHeader" className="profile-header">
        {T ? (
          <>
            <div>
              <div>
                <div className="pad-sm">
                  <div style={{ display: "none" }}>
                    <input
                      type="file"
                      name="files"
                      id="filesUpload"
                      ref={fileRef}
                      accept="image/*"
                      onChange={(e) => OnChangeFile(e)}
                    />
                  </div>

                  <div
                    className="avatar hand"
                    onClick={OnChangeProfilePic}
                    style={{
                      backgroundImage: userAvatar,
                    }}
                  >
                    {T.Roles.includes(g.Roles.ADMIN) ? (
                      <div className="role-block admin" title={g.Roles.ADMIN}>
                        <span className="impulse">{ico.Shield2l}</span>
                      </div>
                    ) : T.Roles.includes(g.Roles.MOD) ? (
                      <div className="role-block mod" title={g.Roles.MOD}>
                        {ico.Audio}
                      </div>
                    ) : T.Roles.includes(g.Roles.DEV) ? (
                      <div className="role-block dev" title={g.Roles.DEV}>
                        <span className="font-lg">{ico.NavigatorSpin}</span>
                      </div>
                    ) : (
                      <div className="role-block uni" title={g.Roles.MEMBER}>
                        <span className="font-lg">{ico.Building}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="l-pad-lg flex still v-center">
                  <div className="font-bold mention">@{T.NameMention}</div>
                  {T.UserName !== myAccount.UserName ? (
                    <div className="last a-right r-pad-xl">
                      <Tooltip
                        title="Report this user"
                        arrow="true"
                        position="bottom"
                      >
                        <span
                          className="font-xxl case-report"
                          onClick={() => OnReportCase(1)}
                        >
                          {ico.Report}
                        </span>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="last a-right r-pad-xl">
                      <Tooltip
                        title="Report a bug or issue"
                        arrow="true"
                        position="bottom"
                      >
                        <span
                          className="font-xl case-report"
                          onClick={() => OnReportCase(2)}
                        >
                          {ico.Bug}
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </div>

                <div className="l-pad-lg">
                  <div className="name-plate">
                    {T.FirstName} {T.LastName}
                  </div>
                  <div className="color-mid-gray">
                    <div>
                      {T.LastCourseAttended?.AcadDepartmentId},{" "}
                      {T.LastCourseAttended?.CourseCode}
                    </div>
                    <div className="font-smaller all-caps">
                      {T.LastCourseAttended?.SchoolName}
                    </div>
                  </div>
                  <div className="tb-pad-lg r-pad-lg">
                    <span className="q">&ldquo;</span>
                    {editPunch ? (
                      <>
                        <div className="font-lg">
                          <textarea
                            className="font-lg"
                            maxLength="250"
                            rows="3"
                            cols="50"
                            value={editableFields.PunchLine}
                            onChange={(e) =>
                              OnChangeEditableFields(e, fieldNames.PunchLine)
                            }
                            onKeyDown={DetectSaveOrDiscard}
                          ></textarea>
                        </div>
                      </>
                    ) : (
                      <>
                        <Tooltip
                          title="Click or tap to edit"
                          arrow="true"
                          arrowSize="big"
                          followCursor="true"
                        >
                          <span className="font-sm" onClick={OnEditPuncLine}>
                            {T.PunchLine}
                          </span>
                        </Tooltip>
                      </>
                    )}
                    <span className="q">&rdquo;</span>
                    {am.GetMyUserName() === T.UserName ? (
                      <>
                        {editPunch ? (
                          <div className="color-blue">
                            <hr />
                            <span className="r-pad-lg">
                              <span
                                title="Save"
                                className="font-xl hand"
                                onClick={() =>
                                  OnSaveEditableData(fieldNames.PunchLine)
                                }
                              >
                                {ico.Save}
                              </span>
                            </span>
                            <span
                              title="Cancel"
                              className="hand font-xl"
                              onClick={OnEditPuncLine}
                            >
                              {ico.Undo}
                            </span>
                            <hr />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <SocialBar />
                  </div>
                  <div>
                    <ul>
                      <li>
                        <div className="hand">
                          <Tooltip
                            title={`${am.Identity.IsMe() ? "Refresh" : ""}`}
                          >
                            <span
                              className="r-pad-md font-xl"
                              onClick={OnReloadActualConnections}
                            >
                              {ico.LinkVs}
                            </span>
                          </Tooltip>
                          <Tooltip
                            style={{ textAlign: "left !important" }}
                            title={`Number of connected people${
                              am.Identity.IsMe()
                                ? " to you.<br/>Click the left chain icon to reload."
                                : ` to user <br/> [${T.FirstName} ${T.LastName}].`
                            }`}
                          >
                            <span
                              id="__numFriends"
                              onClick={ShowMyFriends}
                              className="color-blue"
                            >
                              <b>{T.ConnectionsCount}</b>{" "}
                              {T.ConnectionsCount > 1
                                ? "Connections"
                                : "Connection"}
                            </span>
                          </Tooltip>
                        </div>
                      </li>
                      <li title="DATE JOINED">
                        <div>
                          <span className="r-pad-lg font-lg">
                            {ico.Calendar}
                          </span>
                          <span className="font-sm">
                            {u.Date.FormatDate(
                              T.DateRegistered,
                              "MMM-DD-YYYY hh:mm a"
                            )}
                          </span>
                        </div>
                      </li>
                      <li title="LOCATION">
                        <div>
                          <span className="r-pad-lg font-lg">{ico.Geo}</span>
                          <span>
                            {T.CountryCode}, {T.City}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div title="WEBSITE">
                          <span className="r-pad-md font-xl">{ico.Globe}</span>
                          {editWebsite ? (
                            <input
                              type="text"
                              maxLength="100"
                              style={{ borderRadius: "20px" }}
                              value={editableFields.Website}
                              onChange={(e) =>
                                OnChangeEditableFields(e, fieldNames.Website)
                              }
                            />
                          ) : (
                            <span>
                              <a
                                href={`http://${T.Website}`}
                                title={T.Website}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {T.Website ? T.Website.substring(0, 23) : ""}
                              </a>
                            </span>
                          )}
                          <span>
                            {am.GetMyUserName() === T.UserName ? (
                              <>
                                {editWebsite ? (
                                  <span className="tb-pad-lg color-blue">
                                    <span
                                      className="r-pad-lg font-xl hand"
                                      title="Save"
                                      onClick={() =>
                                        OnSaveEditableData(fieldNames.Website)
                                      }
                                    >
                                      {ico.Save}
                                    </span>
                                    <span
                                      title="Cancel"
                                      className="font-lg hand"
                                      onClick={OnEditWebsite}
                                    >
                                      {ico.Undo}
                                    </span>
                                  </span>
                                ) : (
                                  <span
                                    onClick={OnEditWebsite}
                                    className="l-pad-sm hand font-xl"
                                    title="Edit"
                                  >
                                    {ico.Edit}
                                  </span>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="pad-lg">
              <InlineMessageBar props={{ options: msgBar }} />
            </div>
          </>
        )}
      </div>
      <DialogBox Dialog={dialog} key={"profile_header"} />
    </>
  );
};

export default ProfileHeader;
