import React, { useEffect, useRef, useState } from "react";
import { useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";

import InlineMessageBar from "../Components/Widgets/InlineMessageBar";
import PagingControl from "../Components/Widgets/PagingControl";

import useAccountManager from "../Globals/useAccountManager";
import useDataModels from "../Globals/useDataModels";
import useFetchErrorHandler from "../Globals/useFetchErrorHandler";
import useGlobalConstants from "../Globals/useGlobalConstants";
import useCommonUtil from "../Hooks/useCommonUtil";

import "./Connections.css";

const Connections = () => {
  const u = useCommonUtil();
  const g = useGlobalConstants();
  const h = useHistory();
  const am = useAccountManager();
  const dm = useDataModels();

  const [T, setTargetUser] = useState();

  const fe = useFetchErrorHandler();

  const [myAccount] = useState(am.GetMyAccount());
  const [connections, setConnections] = useState();
  const [cntCount, setCntCount] = useState();
  const [msg, setMessage] = useState({
    mode: "p",
    message: "Loading connection list...",
  });

  const refPagerModel = useRef(dm.PagingModelFriends);

  const [, setDialog] = useState();

  //#region  :: Methods

  useLayoutEffect(() => {
    am.ValidateAccount(() => {      
      am.Identity.GetTargetUser((tuser) => {
        setTargetUser(tuser);
      });
    });
    // eslint-disable-next-line
  }, []);

  const SetFriendStatus = (cnt) => {
    //if (!myAccount) return;
    let _msg = am.Identity.IsMe()
      ? `<b class='color-blue'>${myAccount.FirstName}</b>, below are your ${cnt} connections or friends.`
      : `<b class='color-blue'>${T.FirstName}'s</b> connections or friends.`;

    setMessage({
      mode: "i",
      message: _msg,
    });
  };

  const GetUserHiveFriends = (pm) => {
    if (pm) {
      if (refPagerModel.current.CP === refPagerModel.current.TP || refPagerModel.current.TR === 0) {
        pm.MR = false;
        pm.SL = false;
        pm.setMarker(0);
        console.log("*** No more friends to list.");
        return;
      }
    }

    fetch(`${g.Api.ApiRoute}/user/GetUserHiveFriends`, {
      method: g.Fetch.POST,
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(refPagerModel.current),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          fe.CatchResponse(r, (m) => {
            setMessage({ mode: "e", message: m });
          });

          return null;
        }
      })
      .then((r) => {
        if (r) {
          if (r.Succeeded) {
            let newList = [];

            if (!connections) {
              newList = r.ModelData.List;
              setConnections(newList);
            } else {
              newList = [...connections, ...r.ModelData.List];
              setConnections(newList);
            }

            u.Data.StoreData(g.DbKeys.UserHiveFriends, newList);
            refPagerModel.current = r.ModelData.PageModel;
            let p = r.ModelData.PageModel;

            SetFriendStatus(p.TR);
            setCntCount(p.TR);

            if (pm) {
              if (p.TP === p.CP) {
                pm.MR = false;
              }
              pm.SL = false;
              pm.setMarker(0);
            }
          }
        }
      });
  };

  //[T - UrlUser]
  useEffect(() => {
    if (T) {
      refPagerModel.current.UserName = T.UserName;
      GetUserHiveFriends();
    }
    // eslint-disable-next-line
  }, [T]);

  const LoadNextRecords = (pageRef) => {
    if (pageRef.SL) {
      console.log(">>>Loading next records...");
      GetUserHiveFriends(pageRef);
    }
  };

  const OpenProfile = (cId) => {
    setDialog({
      mode: "p",
      title: "LOADING PROFILE",
      message: "Please wait...",
    });

    h.push(`/${cId}`);
  };

  //const [searchKey, setSearchKey] = useState("");

  const OnSearchConnections = (e) => {
    let key = e.currentTarget.value.trim();
    //setSearchKey(key);
    SearchFriends(key);
  };

  const SearchFriends = (searchKey) => {
    if (searchKey.length > 2) {
      u.Data.RetrieveData(g.DbKeys.UserHiveFriends, (d) => {
        if (d.Connections.length <= 0) return;
        let myList = d.Connections.filter(
          (cnn) =>
            cnn.FullNameOnConnect.toLowerCase().includes(searchKey) ||
            cnn.ConnectionUserName.toLowerCase().includes(searchKey)
        );
        setConnections(myList);
      });
    } else if (searchKey.length === 0) {
      u.Data.RetrieveData(g.DbKeys.UserHiveFriends, (d) => {
        if (d.Connections.length <= 0) return;
        setConnections(d.Connections);
        //console.log(myList);
      });
    }
  };

  //#endregion

  return (
    <>
      <div className="connections">
        <div className="body-container">
          <div className="pad-lg">
            <h1>Connections</h1>
            <InlineMessageBar props={{ options: msg }} />

            <div>
              <input
                type="text"
                className="search"
                name=""
                id="txtSearch1"
                placeholder="Search"
                //value={searchKey}
                //onKeyDown={OnPressEnter}
                onChange={(e) => OnSearchConnections(e)}
              />
              {/* <button className="go" onClick={SearchFriends}>
                {ico.Search}
              </button> */}
            </div>
            <div className="a-right b-pad-md">
              <div className="r-pad-lg">
                <div className="f-count">
                  <span className="font-smaller">TOTAL</span>
                  <span className="font-bold">: {cntCount}</span>
                </div>
              </div>
            </div>
            <div className="tb-pad-md">
              {connections ? (
                connections.map((c) => (
                  <div
                    key={c.ConnectionUserName}
                    className="avatar-block hand"
                    onClick={() => OpenProfile(c.ConnectionUserName)}
                  >
                    <div className="pad-sm">
                      <div className="flex still v-center lr-pad-sm">
                        <div
                          className="avatar2"
                          style={{
                            backgroundImage: `url(${g.Api.CdnRoute}/${c.ConnectionUserName}/profile/${c.ConnectionUserName}.jpg)`,
                          }}
                        ></div>
                        <div className="l-pad-sm break-words">
                          <div className="color-blue">{c.FullNameOnConnect}</div>
                          <div className="color-gray2">
                            {u.Date.FormatDate(c.ConnectedDate, "MMM-DD-YYYY hh:mm a")}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="font-sm">{c.ConnectionUserName}</div> */}
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
            <div className="r-pad-lg">
              <PagingControl
                props={{
                  callBack: (pg) => {
                    LoadNextRecords(pg);
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Connections;
