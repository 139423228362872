import React from "react";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import "./TermsAndConditions.css";

const TermsConditions = () => {
  const g = useGlobalConstants();
  const tm = g.AppName.TRADE_MARK;
  const lm = g.AppName.LONG_NAME;

  return (
    <div className="terms-condi">
      <div className="pad-sm">
        <h3 className="font-bold">Terms and Conditions</h3>
        <p>Updated: Wednesday, May 05, 2021</p>
        <hr />
        <div className="tb-pad-md"></div>
        <p>
          '<strong>{tm}</strong>' or '<strong>{lm}</strong>' refers to the name or trademark of this
          website and the product itself, or can refer to the owner of the website. The term '
          <strong>you</strong>' refers to the user or viewer of this website.
        </p>
        <p>
          <strong>The use of this website is subject to the following terms of use:</strong>
        </p>
        <p className="font-bold">Intellectual Property Rights</p>
        <p>
          Other than the content you own, under these Terms, {tm} and/or its licensors own all the
          intellectual property rights and materials contained in this Website. You are granted
          limited license only for purposes of viewing the material contained on this Website.
        </p>
        <p className="font-bold">Restrictions</p>
        <p>You are specifically restricted from all of the following:</p>
        <div>
          <ul>
            <li>Selling, sublicensing and/or otherwise commercializing any Website material;</li>
            <li>Using this Website in any way that is or may be damaging to this Website;</li>
            <li>Using this Website in any way that impacts user access to this Website;</li>
            <li>
              Using this Website contrary to applicable laws and regulations, or in any way may
              cause harm to the Website, or to any person or business entity;
            </li>
            <li>
              Engaging in any data mining, data harvesting, data extracting or any other similar
              activity in relation to this Website;
            </li>
          </ul>
        </div>
        <p>
          <strong>Limitation of Liability</strong>
        </p>
        <p>
          Neither us nor any third parties provide any warranty or guarantee as to the accuracy,
          timeliness, performance, completeness or suitability of the information and materials
          found or offered on this website for any particular purpose. You acknowledge that such
          information and materials may contain inaccuracies or errors and we expressly exclude
          liability for any such inaccuracies or errors to the fullest extent permitted by law.
        </p>
        <p>
          Your use of any information or materials on this website is entirely at your own risk, for
          which we shall not be liable. It shall be your own responsibility to ensure that any
          products, services or information available through this website meet your specific
          requirements.
        </p>
        <p>
          This website contains material which is owned by or licensed to us. This material
          includes, but is not limited to, the design, layout, look, appearance and graphics.
          Reproduction is prohibited other than in accordance with the copyright notice, which forms
          part of these terms and conditions.
        </p>
        <p>
          All trademarks reproduced in this website which are not the property of, or licensed to,
          the operator are acknowledged on the website.
        </p>
        <p>
          Unauthorized use of this website may give rise to a claim for damages and/or be a criminal
          offense.
        </p>
        <p>
          <strong>Your Personal Data and Information</strong>
        </p>
        <p>
          We will collect the technology you use, specifically, browser name and its version, and
          operating system and its version you are using. This is to help us better improve our
          services and improve the systems compatibility against old or current browsers and
          operating systems. The means of collecting this information is completely anonymous. We
          will not identify your name nor any other information when we are collecting these set of
          information.
        </p>
        <p>
          All the information you provide or entered in this system will be set a property of {tm} {" "}
          However, we will NOT extract nor produce materials, soft nor hard copy of any information
          that will directly identify your name, location and other personal private information.
          The data that we will gather will be in general anonymous statistics format. For example, a list or
          numbers of populations belonging to a certain country. The numbers of members who are
          performing common hobbies or interests, these, to name a few.
        </p>
        <p>
          In these Website Standard Terms and Conditions, "Your Content" shall mean any photo,
          video, text, images or other material you choose to display on this website. By displaying
          Your Content, you grant {tm} a non-exclusive, worldwide irrevocable, sub licensable
          license to use, reproduce, adapt, publish, translate and distribute it in any and all
          media. Your Content must be your own and must not be invading any other third-party's
          rights. HIVE reserves the rights to remove any of Your Content from this Website at any
          time without notice.
        </p>
        <p>
          <span className="font-xl font-bold"> Indemnification </span>
        </p>
        <p>
          <strong>
            You hereby indemnify to the fullest extent {tm} from and against any and/or all
            liabilities, costs, demands, causes of action, damages and expenses arising in any way
            related to your breach of any of the provisions of these Terms.
          </strong>
        </p>
        <p>
          <strong> Severability </strong>
        </p>
        <p>
          If any provision of these Terms is found to be invalid under any applicable law, such
          provisions shall be deleted without affecting the remaining provisions herein.
        </p>
        <p>
          <strong> Variation of Terms </strong>
        </p>
        <p>
          {tm} is permitted to revise these Terms at any time as it sees fit, and by using this
          Website you are expected to review these Terms on a regular basis.
        </p>
        <p>
          <strong> Reassignment </strong>
        </p>
        <p>
          {tm} is allowed to assign, transfer, and subcontract its rights and/or obligations under
          these Terms without any notification. However, you are not allowed to assign, transfer, or
          subcontract any of your rights and/or obligations under these Terms.
        </p>
        <p>
          <strong> Entire Agreement </strong>
        </p>
        <p>
          These Terms constitute the entire agreement between {tm} and you in relation to your use
          of this Website, and supersede all prior agreements and understandings.
        </p>
        <p>
          <strong> Governing Law {"&"} Jurisdiction </strong>
        </p>
        <p>
          These Terms will be governed by and interpreted in accordance with the laws of
          Philippines, and you submit to the non-exclusive jurisdiction of the state and federal
          courts located in Philippines for the resolution of any disputes.
        </p>
        <p>
          <strong>Invitation and Contributions </strong>
        </p>
        <p>
          The HIVE web application system is continually being developed and opensource. Should you
          or any interested parties would like to participate or contribute to the system, you may
          contact the administrator or owner of this website at admin@hivepteltd.pte.
        </p>
        <p>
          Your use of this website and any dispute arising out of such use of the website is subject
          to the laws of Philippines.
        </p>
        <p>{"//"} End of Statement. Nothing follows.</p>
      </div>
    </div>
  );
};

export default React.memo(TermsConditions);
