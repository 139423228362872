import React, { useContext, useEffect, useRef, useState } from "react";
import useAccountManager from "../Globals/useAccountManager";
import useGlobalConstants from "../Globals/useGlobalConstants";
import useCommonUtil from "../Hooks/useCommonUtil";
import useAsyncState from "../Hooks/useCustomExtensions";

import { TriggerNavToUserContext } from "../AppContexts";

import "./CommentBox.css";
import useFetchErrorHandler from "../Globals/useFetchErrorHandler";
import { Link } from "react-router-dom";
import useReactIcons from "../Hooks/useReactIcons";
import InlineMessageBar from "./Widgets/InlineMessageBar";

const CommentBox = (props) => {
  const story = props.story;

  const g = useGlobalConstants();
  const u = useCommonUtil();
  const am = useAccountManager();
  const fe = useFetchErrorHandler();
  const ico = useReactIcons();

  const [T] = useState(props.user);
  const [myAccount] = useState(am.GetMyAccount());
  const [limited, setLimited] = useState(props.limited);
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState(false);
  const { SetTriggerNavToUser } = useContext(TriggerNavToUserContext);

  const [, setComment] = useAsyncState({
    UserName: "",
    Body: "",
    FullName: "",
    PostId: 0,
  });

  const [msg1, setMsg1] = useState({
    message: "Refresh Comments",
    mode: "rel",
  });

  const [commentList, setCommentList] = useState();
  // const [pager, setPager] = useAsyncState(
  //   JSON.parse(JSON.stringify(dm.AppUserDto))
  // );

  const myAvatar = useRef(am.GetMyUrlAvatar());
  const post_comment = "post comment...";

  //#region :: Effects and states
  useEffect(() => {
    if (limited === 1) {
      setCommentList(story.Comments);
    }
    // eslint-disable-next-line
  }, [commentList]);

  useEffect(() => {
    if (props.story) {
      if (limited === 0) {
        setLoading(true);
        //fetch
        fetch(`${g.Api.ApiRoute}/postapi/GetStoryCommentsAsync`, {
          method: "POST",
          headers: am.Identity.GetAuthHeader(),
          body: JSON.stringify({ PostId: story.PostId }),
        })
          .then((r) => {
            if (r.ok) {
              return r.json();
            }
          })
          .then((j) => {
            if (!j) {
              console.log("no server response.");
              return;
            }
            if (j.Succeeded) {
              setCommentList(j.ModelData);
              setMsg1({ message: "Refresh Comments", mode: "rel" });
              // let y = document.querySelector(`#scroll_${story.PostId}`).getBoundingClientRect();
              // console.log(y.top);
              // animateScroll.scrollTo(y.top);
            }

            setLoading(false);
          });
      }
    }

    // eslint-disable-next-line
  }, [limited]);

  //#endregion

  //#region :: Events

  const OnViewMoreComments = () => {
    setLimited(0);
  };

  const OnRefreshComments = () => {
    setLimited(1);
    setMsg1({ message: "Reloading more comments...", mode: "p" });
    setTimeout(() => {
      setLimited(0);
    }, 1000);
  };

  const OnFocusCmtEntry = (e) => {
    if (e.target.innerText.length) {
      if (e.target.innerText.toLowerCase() === post_comment) {
        e.target.innerText = "";
      }
    }
    e.target.focus();
  };

  const OnBlurCmtEntry = (e) => {
    if (e.target.innerText.trim().length === 0) {
      e.target.innerText = post_comment;
    }
  };

  const OnCmtKeyDown = (e) => {
    if (e.target.innerText.length > 200) {
      e.preventDefault();
      e.target.innerText = e.target.innerText.substring(0, 200);
      return;
    }

    if (e.key === "Enter" && e.shiftKey) return;

    if (e.key === "Enter") {
      e.preventDefault();
      if (
        e.target.innerText.toLowerCase().trim() === post_comment ||
        !e.target.innerText.toLowerCase().trim()
      ) {
        return;
      }

      if (e.target.innerText.length > 150) {
        e.preventDefault();
      }

      if (u.Data.ValidateLongEntry(e.target.innerText)) {
        e.target.innerText = "System found ambiguous or spam comment entry.";
        return;
      }

      setComment({
        UserName: am.GetMyUserName(),
        Body: e.target.innerText,
        PostId: story.PostId,
        FullName: `${myAccount.FirstName} ${myAccount.LastName}`,
      }).then((co) => {
        let cmt_entry = document.getElementById(`cmt_${story.PostId}`);
        cmt_entry.innerText = "";
        cmt_entry.classList.add("no-touch");

        PostComment(co, () => {
          setTimeout(() => {
            cmt_entry.classList.remove("no-touch");
          }, 2000);
        });
      });
    }
  };

  //post to web api server
  const PostComment = (co, fn) => {
    console.log(co);
    fetch(`${g.Api.ApiRoute}/postapi/PostStoryCommentAsync`, {
      method: g.Fetch.POST,
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(co),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }

        fe.CatchResponse(r, () => {
          //console.log(m);
        });
      })
      .then((r) => {
        if (r) {
          if (r.Succeeded) {
            console.log(r);
            if (commentList) {
              let newList = [...commentList, r.ModelData];
              story.Comments = newList;
              setCommentList(newList);
              // setIsNewComment(true);
              // setLimited(0);
            } else {
              setCommentList(r.ModelData);
            }
            props.onCrudCallBack(story, "a");
          }
        }

        if (typeof fn === "function") {
          fn.call(this);
        }
      });
  };

  const OpenUserProfile = () => {
    u.Ui.ScrollToY(0, () => {
      //h.push(`/${usrName}`);
      SetTriggerNavToUser(u.Security.GenerateRandomString(10));
    });
  };

  const OnRemoveComment = (com) => {
    //console.log(com);
    setRemoving(true);
    let prog = document.querySelector(`#remove_c${com.PostCommentId}`);
    if (prog) {
      prog.classList.remove("display-none");
    }
    let model = {
      PostCommentId: com.PostCommentId,
      PostId: com.PostId,
    };
    fetch(`${g.Api.ApiRoute}/PostApi/RemoveStoryCommentAsync`, {
      method: "POST",
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(model),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          fe.CatchResponse(r, (m) => {
            console.log(m);
          });
        }
      })
      .then((j) => {
        if (!j) return;
        if (j.Succeeded) {
          let cList = commentList.filter(
            (f) => f.PostCommentId !== model.PostCommentId
          );
          setCommentList(cList);
          story.Comments = cList;
          props.onCrudCallBack(story, "r");
        } else {
          console.log(j.Message);
        }
        setRemoving(false);
      });
  };
  //#endregion

  return (
    <div className="comment-box">
      <hr />
      <div className="font-sm tb-pad-sm color-mid-gray">
        NOTE: For transparency, comments cannot be edited once posted.
      </div>
      <div className="tb-pad-md">
        <div>
          {commentList ? (
            <div className={`msg-body`}>
              <ul>
                {commentList.map((c) => (
                  <li key={`c_${c.PostCommentId}`}>
                    <div className="pad-sm">
                      {T.UserName === c.UserName ? (
                        <div className="flex nowrap a-end a-right">
                          <div>
                            <div className="font-xm all-caps">
                              <Link
                                className="fullname"
                                to={`/${T.UserName}`}
                                onClick={() => OpenUserProfile()}
                              >
                                {`${T.FirstName} ${T.LastName}`}
                              </Link>
                            </div>
                            <div className="font-xm color-mid-gray ">
                              {u.Date.FormatDate(
                                c.Date,
                                "DDD MM/DD/YYYY hh:mm:ss a"
                              )}
                            </div>
                            <div className="my-comment flex still v-center nowrap">
                              <div
                                className="pad-md font-sm color-black"
                                //dangerouslySetInnerHTML={{ __html: c.Body }}
                              >
                                {u.Data.ParseLinks(c.Body)}
                              </div>
                              {c.UserName === myAccount.UserName ? (
                                <div>
                                  <span
                                    id={`remove_c${c.PostCommentId}`}
                                    className="font-lg r-pad-sm display-none"
                                  >
                                    {ico.CircleSpinner}
                                  </span>
                                  {!removing ? (
                                    <span
                                      className="font-lg color-mid-gray r-pad-sm hand"
                                      onClick={() => OnRemoveComment(c)}
                                    >
                                      {ico.CloseCircle}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="mr-sm">
                            <div
                              title={`${T.FirstName} ${T.LastName}`}
                              className="avatar-comment"
                              style={{
                                backgroundImage: `url(${g.Api.CdnRoute}/${c.UserName}/profile/${T.UserName}.jpg?ver=${T.PicVer})`,
                              }}
                            ></div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex nowrap">
                          <div>
                            <Link to={`/${c.UserName}`}>
                              <div
                                className="avatar-comment"
                                onClick={() => OpenUserProfile(c.UserName)}
                                style={{
                                  backgroundImage: `url(${g.Api.CdnRoute}/${c.UserName}/profile/${c.UserName}.jpg?ver=${c.PicVer})`,
                                }}
                              ></div>
                            </Link>
                          </div>
                          <div>
                            <div className="font-xm all-caps">
                              <Link
                                className="fullname"
                                to={`/${c.UserName}`}
                                onClick={() => OpenUserProfile(c.UserName)}
                              >
                                {`${c.FullName}`}
                              </Link>
                            </div>
                            <div className="font-xm color-mid-gray ">
                              {u.Date.FormatDate(
                                c.Date,
                                "DDD MM/DD/YYYY hh:mm:ss a"
                              )}
                            </div>
                            <div className="user-comment flex still v-center nowrap">
                              <div className="pad-md font-sm">
                                {u.Data.ParseLinks(c.Body)}
                              </div>
                              {c.UserName === myAccount.UserName ||
                              am.Identity.IsMe() ? (
                                <div>
                                  {removing ? (
                                    <span className="font-lg color-mid-gray r-pad-sm">
                                      {ico.CircleSpinner}
                                    </span>
                                  ) : (
                                    <span
                                      className="font-lg color-mid-gray r-pad-sm hand"
                                      onClick={() => OnRemoveComment(c)}
                                    >
                                      {ico.CloseCircle}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {story.NumComments > 2 && limited ? (
        <div
          className="a-right color-blue hand pad-md"
          onClick={OnViewMoreComments}
        >
          <span className="font-lg r-pad-md">{ico.Messages}</span>{" "}
          {story.NumComments - 2} more comments
        </div>
      ) : (
        <></>
      )}
      {loading ? (
        <div className="a-right">
          <span className="font-xl">{ico.CircleSpinner}</span>
        </div>
      ) : (
        ""
      )}
      <div className="c-box">
        <div
          className="avatar-comment"
          style={{ backgroundImage: myAvatar.current }}
        ></div>
        <div
          id={`cmt_${story.PostId}`}
          suppressContentEditableWarning={true}
          className="comment-entry"
          contentEditable="true"
          onFocus={OnFocusCmtEntry}
          onClick={OnFocusCmtEntry}
          onBlur={OnBlurCmtEntry}
          onKeyDown={OnCmtKeyDown}
          //onInput={OnInputComment}
        >
          Post comment...
        </div>
      </div>
      <div className="flex v-center a-end color-blue">
        <div className="hand reload" onClick={OnRefreshComments}>
          <hr />
          <InlineMessageBar props={{ options: msg1 }} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CommentBox);
