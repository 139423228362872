import React, { useEffect, useRef, useState } from "react";
import useReactIcons from "../../Hooks/useReactIcons";
import "./SectionExpander.css";

export const SectionExpander = (props) => {
  const icons = useReactIcons();
  const options = props.props;
  const [expanded, setExpanded] = useState(true);

  const Refs = {
    refBody: useRef(),
  };

  const OnHideOrExpandBody = () => {
    Refs.refBody.current.classList.toggle("hidden");
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (options) {
      if (options.collapsed) {
        OnHideOrExpandBody();
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {options ? (
        <div className="section-expander">
          <div>
            <div className="header pad-sm">
              <div className="flex nowrap v-center title-bar">
                <div
                  //onClick={OnHideOrExpandBody}
                  className="grow full-width flex v-center nowrap"
                >
                  {options.icon ? (
                    <div className="lr-pad-sm">
                      <span className="font-xl">{options.icon}</span>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div>
                    <div style={{ paddingTop: "10px" }} className={options.classes || "font-sm"}>
                      <span
                        className="h"
                        style={{ fontSize: options.titleSize ? options.titleSize : "" }}
                      >
                        {options.title}
                      </span>
                    </div>
                    {options.caption ? (
                      <div
                        className="caption"
                        dangerouslySetInnerHTML={{ __html: options.caption }}
                      ></div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div
                  onClick={OnHideOrExpandBody}
                  className={`exp-cont a-right ${options.hideControl ? "dn" : ""}`}
                >
                  {expanded ? icons.ChevronUp : icons.ChevronDown}
                </div>
              </div>
            </div>
            <div
              ref={Refs.refBody}
              style={{ minHeight: options.minHeight ? `${options.minHeight}` : "auto" }}
            >
              <hr />
              <div className="pad-lg">{props.children}</div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
