
export const usePageLocationService = () => {
  const f = {
    PageNames: {
      wall: "wall",
      feeds: "feeds",
      media: "media",
      about: "about",
      myaccount: "myaccount",
    },
    GetCurrentPage: () => {
      let loc = window.location.pathname.split("/");
      return loc[loc.length - 1];
    },
  };

  return f;
};
