import React from "react";
import { useRef } from "react";
import HiveLogo from "../Components/Widgets/HiveLogo";
import useCommonUtil from "../Hooks/useCommonUtil";
import InlineMessageBar from "../Components/Widgets/InlineMessageBar";
import { useState } from "react";
import useDataModels from "../Globals/useDataModels";
import { useEffect } from "react";
import useGlobalConstants from "../Globals/useGlobalConstants";
import { Link } from "react-router-dom";
import useReactIcons from "../Hooks/useReactIcons";
import { InfoBar } from "../Components/Widgets/InfoBar";
import { useDataManager } from "../Globals/useDataManager";
import TermsAndConditions from "../Components/Widgets/TermsAndConditions";
import DatePicker from "../Components/Widgets/DatePicker";

const Register = () => {
  const u = useCommonUtil();
  const dm = useDataModels();
  const dmgr = useDataManager();
  const gc = useGlobalConstants();
  const ico = useReactIcons();
  const [msgReg, setMsgReg] = useState({ mode: "cl" });
  const [msgCountry, setMsgCountry] = useState({ message: "", mode: "p" });
  const [msgPwdRating, setMsgPwdRating] = useState({ mode: "h" });

  const [regModel, setRegModel] = useState(dm.RegModel);
  const controller = new AbortController();

  const [countryList, setCountryList] = useState();
  const [selCountry, setSelCountry] = useState();

  const [cityList, setCityList] = useState();
  const [selCity, setSelCity] = useState();
  const [birthDate, setBirthDate] = useState();

  //ui element references
  const inputRefs = {
    loginLabel: useRef(),
    registerBtn: useRef(),
    acceptAgreenment: useRef(),
    btnRegGoogle: useRef(),
  };

  useEffect(() => {
    if (birthDate) {
      setRegModel({ ...regModel, DateOfBirth: birthDate });
    }

    // eslint-disable-next-line
  }, [birthDate]);
  /* handlers */
  const OnChangeInput = (e, f) => {
    //console.log(e.currentTarget.value);
    const v = e.currentTarget.value;
    switch (f) {
      case "email":
        setRegModel({ ...regModel, UserName: v });
        break;
      case "firstname":
        setRegModel({ ...regModel, FirstName: v });
        break;
      case "lastname":
        setRegModel({ ...regModel, LastName: v });
        break;
      case "namemention":
        setRegModel({ ...regModel, NameMention: v });
        break;
      case "password":
        setRegModel({ ...regModel, Password: v });
        let rating = u.Security.ScorePassword(v);
        console.log(rating);
        setMsgPwdRating({
          mode: rating.score < 50 ? "e" : rating.score <= 75 ? "w" : "s",
          message: `Password rating : <b>${rating.score}%</b>`,
        });
        break;
      case "password2":
        setRegModel({ ...regModel, Password2: v });
        break;
      case "birthdate":
        setRegModel({ ...regModel, DateOfBirth: birthDate });
        break;
      case "gender":
        setRegModel({ ...regModel, Gender: v });
        break;
      default:
        break;
    }
  };

  const OnAcceptAgreement = (e) => {
    let checked = e.currentTarget.checked;
    if (checked) {
      inputRefs.registerBtn.current.classList.remove("no-touch");
      //inputRefs.btnRegGoogle.current.classList.remove("no-touch");
    } else {
      inputRefs.registerBtn.current.classList.add("no-touch");
      //inputRefs.btnRegGoogle.current.classList.add("no-touch");
    }
  };

  const EnableAgreement = (b) => {
    let a = inputRefs.acceptAgreenment.current;

    if (a) {
      if (b) {
        a.classList.remove("dn");
      } else {
        a.classList.add("dn");
      }
    }
  };

  useEffect(() => {
    controller.onabort = () => {
      console.log("Aborting registration...");
    };

    dmgr.GetCountries((c) => {
      if (c) {
        if (!c.ModelData.length) {
          setMsgCountry({
            mode: "e",
            message: "No country locations were set from the Web API DB Server.",
          });
          return;
        }

        setCountryList(c.ModelData);
        let ctry = c.ModelData.find((f) => f.CountryCode === "PHL");
        if (ctry) {
          setSelCountry(ctry);
          dmgr.GetCities(ctry.CountryCode, (cities) => {
            if (cities) {
              if (cities.ModelData.length) {
                setCityList(cities.ModelData);
                let city = cities.ModelData.find((f) => f.CityId === 12274);
                if (city) {
                  setSelCity(city);
                  EnableAgreement(true);
                }
              } else {
                setMsgCountry({ mode: "e", message: "The city list does not contain records." });
              }
            } else {
              setMsgCountry({ mode: "e", message: "Unable to communicate with Web API Server." });
            }
          });
        }
      } else {
        setMsgCountry({ mode: "e", message: "Unable to communicate with Web API Server." });
      }
    });

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, []);

  const OnSelectCountry = (e) => {
    let ctry = countryList.find((f) => f.CountryCode === e.target.value);

    if (ctry) {
      setSelCountry(ctry);
      dmgr.GetCities(ctry.CountryCode, (cities) => {
        if (cities) {
          setCityList(cities.ModelData);
          setSelCity(cities.ModelData[0]);
        }
      });
    }
  };

  const OnSelectCity = (e) => {
    let ct = cityList.find((f) => f.CityId === parseInt(e.target.value, 10));
    if (ct) {
      setSelCity(ct);
    }
  };

  /* methods */
  const [recoveryCodes, setRecoveryCodes] = useState();

  const OnRegister = () => {
    console.log(regModel);

    if (ValidateEntries()) {
      setMsgReg({ message: "Submitting registration...", mode: "p" });

      setRegModel({
        ...regModel,
        NameMention: u.Data.ParseSlug(regModel.FirstName, true),
        CountryCode: selCountry.CountryCode,
        CityId: selCity.CityId,
      });

      const _signal = controller.signal;
      let payLoad = {
        ...regModel,
        NameMention: u.Data.ParseSlug(regModel.FirstName, true),
        CountryCode: selCountry.CountryCode,
        CityId: selCity.CityId,
      };

      u.Ui.DisableEl(inputRefs.registerBtn.current, true);

      fetch(`${gc.Api.ApiRoute}/authapi/RegisterAsync`, {
        method: gc.Fetch.POST,
        signal: _signal,
        headers: gc.Fetch.TYPEJSON,
        body: JSON.stringify(payLoad),
      })
        .then((r) => {
          if (r) {
            return r.json();
          } else {
            setMsgReg({
              message: `${r.Message}\nUnable to communicate with service process.`,
              mode: "e",
            });
          }
        })
        .then((r) => {
          console.log(r);

          if (r) {
            if (r.Succeeded) {
              setMsgReg({
                message: `${r.Message}.\nPlease safely take note of the following and keep them safe.`,
                mode: "s",
              });
              if (r.ModelData) {
                setRecoveryCodes(r.ModelData.split(";"));
              }
            } else {
              u.Ui.DisableEl(inputRefs.registerBtn.current);
              setMsgReg({
                message: `${r.Message}`,
                mode: "e",
              });
            }
          } else {
            setMsgReg({
              message: `${r.Message}\nUnable to connect with service process.`,
              mode: "e",
            });
          }
        })
        .catch((err) => {
          setMsgReg({
            message: `ERROR: Unable to connect or communicate with service process.`,
            mode: "e",
          });
          u.Ui.DisableEl(inputRefs.registerBtn.current);
          console.log(err);
        });
    }
  };

  const ValidateEntries = () => {
    if (!u.Validator.checkEmail(`${regModel.UserName}`)) {
      setMsgReg({ message: "Invalid email address", mode: "w" });
      return 0;
    }

    if (gc.ValidateRegDomain.Enabled) {
      if (!regModel.UserName.includes(gc.ValidateRegDomain.Domain)) {
        setMsgReg({
          message: `We only accept registration from our company domain at the moment, i.e. ${gc.ValidateRegDomain.Domain}.`,
          mode: "w",
        });
        return 0;
      }
    }

    if (!u.Validator.CheckMinMax(regModel.FirstName, 2, 50)) {
      setMsgReg({
        message: "First Name should be 2 up to 20 characters long.",
        mode: "w",
      });
      //inputRefs.password.current.focus();
      return 0;
    }
    if (!u.Validator.CheckMinMax(regModel.LastName, 2, 50)) {
      setMsgReg({
        message: "Last Name should be 2 up to 20 characters long.",
        mode: "w",
      });
      //inputRefs.password.current.focus();
      return 0;
    }

    if (!u.Validator.CheckMinMax(regModel.Password, 8, 30)) {
      setMsgReg({ message: "Invalid password length", mode: "w" });
      //inputRefs.password.current.focus();
      return 0;
    }

    if (!u.Validator.CheckMatch(regModel.Password, regModel.Password2)) {
      setMsgReg({ message: "Passwords do not match.", mode: "w" });
      //inputRefs.password.current.focus();
      return 0;
    }

    const s = u.Security.ScorePassword(regModel.Password);

    if (s.score <= 75) {
      setMsgReg({
        mode: "e",
        message: `Your password score is weak at ${s.score}%. Please make it more complex.`,
      });
      return 0;
    }

    // if (!u.Date.IsValidDate(regModel.DateOfBirth)) {
    //   setMsg1({ message: "Invalid birth date", mode: "w" });
    //   return 0;
    // }

    if (regModel.Gender === "G") {
      setMsgReg({ message: "Select gender", mode: "w" });
      return 0;
    }

    return 1; //or true
  };

  return (
    <>
      <div className="login-page">
        {/* <DialogBox props={{ Buttons: _buttons }} /> */}
        <div className="flex v-center login-banner tb-pad-xl">
          <div className="one-3rd l-pad-lg r-pad-sm">
            <h1 className="font-bold">Register</h1>
            <HiveLogo props={{ width: "150px" }} />
            <div className="all-caps font-sm">HOME OF INTERCONNECTED VISIONARIES ENVIRONMENT</div>
            <h3>
              A private social media platform for every company, university or any organization.
            </h3>
            <div className="tb-pad-lg">
              <Link to={"/login"}>
                <span className="font-xl color-blue r-pad-sm">{ico.ChevronLeft}</span>
                <span className="color-blue">Back to Login Page</span>
              </Link>
            </div>
          </div>
          <div className="r-pad-sm one-half">
            <div className="pad-lg">
              <div className="login-box">
                <div className="pad-lg">
                  <ul className="login">
                    <li className="font-lg">
                      <input
                        ref={inputRefs.UserName}
                        tabIndex="1"
                        type="text"
                        placeholder="Email Address"
                        autoComplete="false"
                        value={regModel.UserName}
                        onChange={(e) => OnChangeInput(e, "email")}
                      />
                    </li>
                    <li>
                      <input
                        type="text"
                        tabIndex="2"
                        name=""
                        id="txtFirstName"
                        className="short"
                        placeholder="First Name"
                        value={regModel.FirstName}
                        onChange={(e) => OnChangeInput(e, "firstname")}
                      />
                      <span className="r-pad-sm"></span>
                      <div className="fool-chrome">
                        <input type="text" tabIndex="99" />
                      </div>
                      <input
                        type="text"
                        tabIndex="3"
                        name=""
                        id="txtLastName"
                        className="short"
                        autoComplete={`${u.Security.GenerateRandomString(10)}`}
                        placeholder="Last Name"
                        value={regModel.LastName}
                        onChange={(e) => OnChangeInput(e, "lastname")}
                      />
                      <div className="fool-chrome">
                        <input type="text" tabIndex="100" />
                      </div>
                    </li>
                    {/* <li>
                      <div className="tb-pad-sm">@Name Mention</div>
                      <div>
                        <input
                          type="text"
                          className="short"
                          maxLength="20"
                          value={regModel.NameMention}
                          onChange={(e) => OnChangeInput(e, "namemention")}
                        />
                      </div>
                    </li> */}
                    <li>
                      <div className="tb-pad-sm">
                        <div>
                          Type a strong password, repeat on the next entry. Example:{" "}
                          <span className="code2">M@rlbor0!Red</span>
                          {/* fool chrome browser, do not autofill password entry */}
                          <div className="fool-chrome">
                            <input className="short" type="password" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <input
                          className="short"
                          tabIndex="4"
                          ref={inputRefs.password}
                          placeholder="Password"
                          type="password"
                          value={regModel.Password}
                          onChange={(e) => OnChangeInput(e, "password")}
                        />
                        <span className="r-pad-sm"></span>
                        <input
                          ref={inputRefs.password2}
                          tabIndex="5"
                          placeholder="Repeat Password"
                          className="short"
                          type="password"
                          value={regModel.Password2}
                          onChange={(e) => OnChangeInput(e, "password2")}
                        />
                      </div>
                      <div>
                        <InlineMessageBar props={{ options: msgPwdRating }} />
                      </div>
                    </li>
                    <li>
                      <div className="tb-pad-sm">
                        <div>Birth Date</div>
                      </div>
                      <div>
                        <div>
                          <DatePicker options={{ SetDate: setBirthDate }} key={"Register"} />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <select
                          name="gender"
                          tabIndex="6"
                          id="optGender"
                          ref={inputRefs.gender}
                          value={regModel.Gender}
                          onChange={(e) => OnChangeInput(e, "gender")}
                        >
                          <option value="G">-- Gender --</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="X">Rather Not Say</option>
                        </select>
                      </div>
                    </li>
                    <li>
                      <div>
                        {countryList && cityList && selCountry && selCity ? (
                          <div className="flex">
                            <div>
                              <select value={selCountry.CountryCode} onChange={OnSelectCountry}>
                                {countryList.map((c) => (
                                  <option key={c.CountryCode} value={c.CountryCode}>
                                    {c.Name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <select value={selCity.CityId} onChange={OnSelectCity}>
                                {cityList.map((c) => (
                                  <option key={`c_${c.CityId}`} value={c.CityId}>
                                    {c.Name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : (
                          <>
                            <InlineMessageBar props={{ options: msgCountry }} />
                          </>
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="tb-pad-md"></div>
                    </li>
                    <li>
                      <div>
                        <div className="r-pad-lg">
                          <InfoBar
                            props={{
                              //flash: 1,
                              expanded: 0,
                              // height: "225px",
                              caption: "Terms and Conditions".toUpperCase(),
                              title: "Read ".toUpperCase(),
                            }}
                          >
                            <TermsAndConditions />
                          </InfoBar>
                        </div>
                        <div ref={inputRefs.acceptAgreenment} className="dn">
                          <input
                            type="checkbox"
                            name="chkAgree"
                            id="chkAgree"
                            className="agree"
                            onChange={(e) => OnAcceptAgreement(e)}
                          />
                          <label htmlFor="chkAgree">Accept Terms and Conditions</label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="tb-pad-sm">
                        <input
                          id="btnRegister"
                          ref={inputRefs.registerBtn}
                          type="submit"
                          value="Register"
                          className="hand no-touch"
                          onClick={OnRegister}
                        />
                      </div>
                    </li>
                    <li className="tb-pad-md">
                      <InlineMessageBar props={{ options: msgReg }} />
                    </li>
                    <li>
                      {recoveryCodes ? (
                        <div className="bordered radiused4 rcodes">
                          <div className="pad-lg">
                            <div className="b-pad-md">
                              <strong>IMPORTANT</strong>: In case you have forgetten your password,
                              you can either use one of the recovery codes below to reset your
                              password. Please keep this private and safe. CODES ARE CASE SENSITIVE.
                            </div>
                            <div className="font-bold font-lg color-blue">
                              <ul>
                                {recoveryCodes.map((r) => (
                                  <li key={r}>
                                    <code>{r}</code>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className="tb-pad-lg all-caps">
                              <Link to="/login">
                                {" "}
                                <span className="font-xl">{ico.ChevronLeft}</span> Back to Login
                                Page
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </li>
                    {/* <li>
                      <div className="t-pad-lg a-right">
                        <button
                          id="google"
                          className="google no-touch"
                          onClick={() => {
                            setDialog({
                              mode: "i",
                              title: "Not Implemented",
                              message: "This featue is not available yet.",
                            });
                          }}
                          ref={inputRefs.btnRegGoogle}
                        >
                          <span className="font-xl">{ico.GoogleColor} </span>
                          <span>Register via Google</span>
                        </button>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
