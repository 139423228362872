import React, { useEffect, useRef, useState } from "react";
import InlineMessageBar from "../../../../Components/Widgets/InlineMessageBar";
import { SectionExpander } from "../../../../Components/Widgets/SectionExpander";
import Chart from "react-apexcharts";
import useReactIcons from "../../../../Hooks/useReactIcons";
import useAccountManager from "../../../../Globals/useAccountManager";
import useGlobalConstants from "../../../../Globals/useGlobalConstants";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const CaseReports = () => {
  const ico = useReactIcons();
  const am = useAccountManager();
  const g = useGlobalConstants();

  const [tabIndex, setTabIndex] = useState(0);
  const [msg, setMsg] = useState();

  const controller = new AbortController();
  const signal = controller.signal;
  let timer;
  const refs = {
    refGenBtn: useRef(),
  };

  const [chartUserCase, setChartUserCase] = useState({
    options: {
      theme: {
        palette: "palette9", // upto palette10
      },
      title: {
        text: `User Reported Cases`,
        align: "center",
        style: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "normal",
          marginTop: "20px",
        },
      },
      chart: {
        id: "Title",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          align: "center",
          dataLabels: {
            position: "center",
            //orientation: "vertical"
          },
        },
      },
      xaxis: {
        floating: false,
        type: "Category",
        labels: {
          align: "center",
          min: 0,
          formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        forceNiceScale: true,
        floating: false,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 350,
          style: {
            fontSize: "16px",
            color: "#ffcc00",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "color-blue",
          },
          offsetX: 5,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return value;
          },
        },
      },
    },
    series: [
      {
        name: "Tags",
        data: [{ x: "", y: 0 }],
      },
    ],
  });

  const [chartSystemCases, setChartSystemCases] = useState({
    options: {
      theme: {
        palette: "palette9", // upto palette10
      },
      title: {
        text: `User-Reported System Cases`,
        align: "center",
        style: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "normal",
          marginTop: "20px",
        },
      },
      chart: {
        id: "Title",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          align: "center",
          dataLabels: {
            position: "center",
            //orientation: "vertical"
          },
        },
      },
      xaxis: {
        floating: false,
        type: "Category",
        labels: {
          align: "center",
          min: 0,
          formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        // forceNiceScale: true,
        floating: false,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 350,
          style: {
            fontSize: "16px",
            color: "#ffcc00",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "color-blue",
          },
          offsetX: 5,
          offsetY: 0,
          rotate: 0,
        },
      },
    },
    series: [
      {
        name: "Tags",
        data: [{ x: "", y: 0 }],
      },
    ],
  });

  useEffect(() => {
    return () => {
      clearTimeout(timer);
      controller.abort();
    };
    // eslint-disable-next-line
  }, []);

  const DisableControls = (b) => {
    if (refs.refGenBtn.current) {
      if (b) {
        refs.refGenBtn.current.classList.add("display-none");
      } else {
        refs.refGenBtn.current.classList.remove("display-none");
      }
    }
  };

  const GenerateReport = () => {
    DisableControls(1);

    setMsg({ mode: "p", message: "" });

    fetch(`${g.Api.ApiRoute}/ReportsApi/GetCaseReportAsync/?mode=${tabIndex + 1}`, {
      method: "GET",
      signal: signal,
      headers: am.Identity.GetAuthHeader(),
      //body: JSON.stringify(dateRange),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        if (!j) {
          setMsg({ mode: "e", message: "Ooops. Something went wrong :(" });
          return;
        }

        if (j.Succeeded) {
          if (!j.ModelData.length) {
            setMsg({ mode: "s", message: "No report cases." });
            DisableControls(0);
            return;
          }
          console.log(j.ModelData[0].Resolved);
          //return;
          if (j.ModelData) {
            let m = [{ name: "Frequency", data: [] }];
            let total = j.ModelData[0].Resolved + j.ModelData[0].Unresolved;
            m[0].data.push({ x: "Resolved", y: j.ModelData[0].Resolved });
            m[0].data.push({ x: "Unresolved", y: j.ModelData[0].Unresolved });

            switch (tabIndex) {
              case 0:
                setChartUserCase({
                  ...chartUserCase,
                  series: m,
                  options: {
                    ...chartUserCase.options,
                    title: {
                      ...chartUserCase.options.title,
                      text: `User Reported Cases [ TOTAL : ${total} ]`.toUpperCase(),
                    },
                    xaxis: { ...chartUserCase.xaxis, categories: ["Resolved", "Unresolved"] },
                  },
                });
                break;
              case 1:
                setChartSystemCases({
                  ...chartSystemCases,
                  series: m,
                  options: {
                    ...chartSystemCases.options,
                    title: {
                      ...chartSystemCases.options.title,
                      text: `User-Reported System Cases [ TOTAL : ${total} ]`.toUpperCase(),
                    },
                    xaxis: { ...chartSystemCases.xaxis, categories: ["Resolved", "Unresolved"] },
                  },
                });
                break;
              default:
                break;
            }
          }

          setMsg({ mode: "s", message: "" });
        } else {
          setMsg({ mode: "e", message: "Ooops. Something went wrong :(" });
        }

        timer = setTimeout(() => {
          DisableControls(0);
        }, 3000);
      });
  };

  return (
    <>
      <SectionExpander
        props={{
          icon: ico.Report,
          title: "Case Reports Statistics",
          classes: "font-sm all-caps",
          caption: "List of user reported cases, including system-reported issues.",
          collapsed: true,
        }}
      >
        <div className="flex v-center">
          <div className="last">
            <InlineMessageBar props={{ options: msg }} />
            <span ref={refs.refGenBtn}>
              <button onClick={GenerateReport}>Generate Report</button>
            </span>
          </div>
        </div>
        <div className="tb-pad-sm"></div>
        <div>
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab>User Cases</Tab>
              <Tab>System Cases</Tab>
            </TabList>
            <TabPanel>
              <div className="bordered">
                <Chart
                  options={chartUserCase.options}
                  series={chartUserCase.series}
                  type="bar"
                  width="100%"
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bordered">
                <Chart
                  options={chartSystemCases.options}
                  series={chartSystemCases.series}
                  type="bar"
                  width="100%"
                />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </SectionExpander>
    </>
  );
};

export default React.memo(CaseReports);
