import useCommonUtil from "../../Hooks/useCommonUtil";
import useAccountManager from "../useAccountManager";
import useGlobalConstants from "../useGlobalConstants";
import useNotificationManager from "./useNotificationManager";

const useChatManager = () => {
  const u = useCommonUtil();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const noti = useNotificationManager();

  const f = {
    StoreMessage: (msgModel) => {
      console.log(msgModel);
      let header = {
        UserName: msgModel.FromAppUserId,
        ConnectionId: msgModel.FromConnectionId,
        From: `${msgModel.FirstName} ${msgModel.LastName}`,
        Read: false,
      };
      let msgArchive = u.Data.GetData(g.DbKeys.MessageArchive);
      if (msgArchive) {
        let userMsgRecord = msgArchive.find(
          (u) => u.UserName === msgModel.FromAppUserId
        );

        if (userMsgRecord) {
          userMsgRecord.ConnectionId = msgModel.FromConnectionId;
          u.Data.StoreData(g.DbKeys.MessageArchive, msgArchive);
        } else {
          console.log("adding new user");
          msgArchive.push(header);
          u.Data.StoreData(g.DbKeys.MessageArchive, msgArchive);
        }
      } else {
        //add to store
        let newArchive = [];
        newArchive.push(header);
        u.Data.StoreData(g.DbKeys.MessageArchive, newArchive);
      }
    },
    HasUnread: (msgArray, myUserId) => {
      //console.log(messages);

      if (msgArray) {
        let idx = msgArray.findIndex(
          (m) => parseInt(m.ToRead, 10) === 0 || parseInt(m.FromRead) === 0
        );

        return idx >= 0;
      } else {
        return 0;
      }
    },
    GetIfHasUnreadMessages: (fn) => {
      let payLoad = {
        UserName: am.GetMyUserName(),
      };
      fetch(`${g.Api.HubApi}/GetIfHasUnreadMessages`, {
        method: g.Fetch.POST,
        headers: am.Identity.GetAuthHeader(),
        body: JSON.stringify(payLoad),
      })
        .then((r) => {
          return r.ok ? r.json() : null;
        })
        .then((r) => {
          if (!r) return;

          if (r.Succeeded) {
            if (r.ModelData) {
              if (r.ModelData.HasUnread) {
                noti.ShowMessage(true);
              }
            }
          }
        })
        .catch((err) => {
          if (typeof fn === "function") fn.call(this, err);
        });
    },
    MarkAsRead: (messageHeader) => {},
    GetMyChatContacts: (fn) => {
      let payLoad = {
        UserName: am.GetMyUserName(),
      };

      fetch(`${g.Api.HubApi}/GetMyChatContacts`, {
        method: g.Fetch.POST,
        headers: am.Identity.GetAuthHeader(),
        body: JSON.stringify(payLoad),
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            return null;
          }
        })
        .then((r) => {
          if (r) {
            if (r.ModelData) {
              let chat_contacts = [];

              if (r.ModelData.MembersOf) {
                r.ModelData.MembersOf.forEach((m) => {
                  let _contact = {
                    HubGroupId: m.HubGroupId,
                    GroupGuid: m.GroupGuid,
                    GroupType: m.GroupType,
                    GroupName: m.GroupName,
                    GroupSlug: m.GroupSlug,
                    HasMessage: m.Messages.length > 0,
                    Members: m.Members,
                    UserName: "",
                    Messages: m.Messages,
                  };

                  if (m.GroupType === 2) {
                    const ka_chat = m.Members.find(
                      (u) => u.UserName !== payLoad.UserName
                    );
                    if (ka_chat) {
                      let ka_chat_name = r.ModelData.Contacts.find(
                        (u) => u.Cn === ka_chat.UserName
                      );
                      if (ka_chat_name) {
                        _contact.GroupName = ka_chat_name.Fn;
                        _contact.UserName = ka_chat_name.Cn;
                      }
                    }
                  } else if (m.GroupType === 3) {
                    _contact.GroupName = `[${m.Members.length}] ${m.GroupName}`;
                    _contact.UserName = m.GroupGuid;
                  }
                  chat_contacts.push(_contact);
                });
              }

              r.ModelData.MembersOf = chat_contacts;

              u.Data.StoreData(g.DbKeys.MyChatContacts, r.ModelData);
              if (typeof fn === "function") {
                fn.call(this, r.ModelData, null);
              }
            }
          }
        })
        .catch((err) => {
          if (typeof fn === "function") {
            fn.call(this, null, err);
          }
        });
    },
  };

  return f;
};

export default useChatManager;
