import React, { useEffect, useRef, useState } from "react";
import InlineMessageBar from "../../../../Components/Widgets/InlineMessageBar";
import { SectionExpander } from "../../../../Components/Widgets/SectionExpander";
import Chart from "react-apexcharts";
import useReactIcons from "../../../../Hooks/useReactIcons";
import useAccountManager from "../../../../Globals/useAccountManager";
import useGlobalConstants from "../../../../Globals/useGlobalConstants";

const UserPlatform = () => {
  const ico = useReactIcons();
  const am = useAccountManager();
  const g = useGlobalConstants();

  const [msg, setMsg] = useState();
  const [pfMode, setPfMode] = useState(-1);

  const controller = new AbortController();
  const signal = controller.signal;
  let timer;
  const refs = {
    refGenBtn: useRef(),
  };

  const [chartData, setChartData] = useState({
    options: {
      theme: {
        palette: "palette6", // upto palette10
      },
      title: {
        text: `Platform Statistics`.toUpperCase(),
        align: "center",
        style: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "normal",
          marginTop: "20px",
        },
      },
      chart: {
        id: "Title",
        foreColor: "#000",
        background: "#f9f9f9",
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          align: "center",
          dataLabels: {
            position: "center",
            //orientation: "vertical"
          },
        },
      },
      xaxis: {
        floating: false,
        type: "Category",
        labels: {
          align: "center",
          min: 0,
          formatter: (val) => parseInt(val, 10),
        },
      },
      yaxis: {
        forceNiceScale: true,
        floating: false,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 350,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "break-words",
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return value;
          },
        },
      },
    },
    series: [
      {
        name: "Course and School",
        data: [{ x: "", y: 0 }],
      },
    ],
  });

  useEffect(() => {
    DisableControls(1)

    return () => {
      clearTimeout(timer);
      controller.abort();
    };
    // eslint-disable-next-line
  }, []);

  const DisableControls = (b) => {
    if (refs.refGenBtn.current) {
      if (b) {
        refs.refGenBtn.current.classList.add("display-none");
      } else {
        refs.refGenBtn.current.classList.remove("display-none");
      }
    }
  };

  const GenerateReport = () => {
    DisableControls(1);

    setMsg({ mode: "p", message: "" });

    fetch(`${g.Api.ApiRoute}/ReportsApi/GetPlatformsAsync/?mode=${pfMode}`, {
      method: "GET",
      signal: signal,
      headers: am.Identity.GetAuthHeader(),
      //body: JSON.stringify(dateRange),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        if (!j) {
          setMsg({ mode: "e", message: "Ooops. Something went wrong :(" });
          return;
        }

        if (j.Succeeded) {
          let m = [{ name: "Frequency", data: [] }];
          let arr = j.ModelData;
          let arr_caption = [];
          for (let i = 0; i < j.ModelData.length; i++) {
            arr_caption.push(`${arr[i].Name}`);
            m[0].data.push({
              x: `${arr[i].Name}`,
              y: parseInt(arr[i].Total, 10),
            });
          }

          setChartData({
            ...chartData,
            series: m,
            options: {
              ...chartData.options,
              title: {
                ...chartData.options.title,
                text: `${pfMode === 0 ? "Browser" : "Operating System"} Statistics`.toUpperCase(),
              },
              xaxis: { ...chartData.xaxis, categories: arr_caption },
            },
          });
          setMsg({ mode: "s" });
        } else {
          setMsg({ mode: "e", message: "Ooops. Something went wrong :(" });
        }

        timer = setTimeout(() => {
          DisableControls(0);
        }, 2000);
      });
  };

  useEffect(() => {
    if (pfMode < 0) return;
    GenerateReport();

    // eslint-disable-next-line
  }, [pfMode]);

  const OnPlatChange = (e) => {
    setPfMode(parseInt(e.target.value, 10));
  };

  return (
    <>
      <SectionExpander
        props={{
          icon: ico.CodeBox,
          title: "Users Platforms Statistics",
          classes: "font-sm all-caps",
          caption: "Information regarding users browsers and operating systems",
          collapsed: true,
        }}
      >
        <div>
          <div className="group">
            <div className="pad-md">
              <span className="r-pad-sm">
                <input type="radio" name="eng" id="past" value={0} onChange={OnPlatChange} />
                <label htmlFor="past">Browser</label>
              </span>
              <input type="radio" name="eng" id="curr" value={1} onChange={OnPlatChange} />
              <label htmlFor="curr">Operating System</label>
            </div>
          </div>
          <span ref={refs.refGenBtn} className="l-pad-sm display-none">
            <button onClick={GenerateReport}>
              <span className='r-pad-sm font-lg'>{ico.Refresh}</span> Reload
            </button>
          </span>
          <InlineMessageBar props={{ options: msg }} />
        </div>

        <div className="tb-pad-sm"></div>
        <div className="bordered">
          <Chart options={chartData.options} series={chartData.series} type="bar" width="100%" />
        </div>
      </SectionExpander>
    </>
  );
};

export default React.memo(UserPlatform);
