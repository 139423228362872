import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import "./MainNav.css";
import { useContext } from "react";
import { TriggerNavToUserContext } from "../AppContexts";

import HiveLogo from "./Widgets/HiveLogo";
import useCommonUtil from "../Hooks/useCommonUtil";
import useGlobalConstants from "../Globals/useGlobalConstants";
import { useState } from "react";
import { SocketContext } from "../Globals/RealtimeComm/WebSocketReducer";
import useAccountManager from "../Globals/useAccountManager";
import useReactIcons from "../Hooks/useReactIcons";
import { AvatarContext } from "../Globals/AvatarNotifierReducer";
import DialogBox from "./Widgets/DialogBox";
import NotificationPanel from "./Widgets/NotificationPanel";
import platform from "platform";
import useDataModels from "../Globals/useDataModels";

const MainNav = () => {
  //#region :: Declarations

  //console.log("Trace: Render MainNav");

  const am = useAccountManager();
  const dm = useDataModels();
  const g = useGlobalConstants();
  const h = useHistory();
  const ico = useReactIcons();
  const u = useCommonUtil();

  const [ready, setReady] = useState(false);
  const [notiState, setNotiState] = useState({ hidden: true });
  const [searchResult, setSearchResult] = useState([]);
  const hmethod = g.Hub.ReducerMethods;
  const { HubDispatch } = useContext(SocketContext);

  const { SetTriggerNavToUser } = useContext(TriggerNavToUserContext);
  const { AvatarState } = useContext(AvatarContext);

  let [avatarMenu, setAvatarMenu] = useState("");

  let throttle,
    speed = 500; //control search typing speed for performance
  const Refs = { panel: useRef(), desktopMenu: useRef() };

  const [myAccount] = useState(am.GetMyAccount());
  const [dialog, setDialog] = useState();

  //#endregion

  //#region :: Events

  const SendPlatformStatistics = () => {
    let pf = dm.UserPlatform;
    pf.Browser = `${platform.name} ${platform.version}; ${platform.layout || "NA"}`.substring(
      0,
      64
    );
    pf.Os = `${platform.os?.family} ${platform.os?.version || ""}; x${
      platform.os?.architecture || "Arc:na"
    }`.substring(0, 64);

    fetch(`${g.Api.ApiRoute}/user/RecordUserPlatformAsync`, {
      method: "POST",
      headers: g.Fetch.TYPEJSON,
      body: JSON.stringify(pf),
    }).catch((ex) => {
      console.log(ex);
    });
  };

  useLayoutEffect(() => {
    // am.ValidateAccount(() => {
    //   setReady(true);
    // });
    SendPlatformStatistics();

    am.ValidateAccount(() => {
      //if during starting the app or the user navigates to the root url
      //redirect natin sa profile ni user
      setReady(true);
      if (window.location.pathname === "/") {
        if (myAccount) {
          window.location.replace(`/${myAccount.UserName}`);
        }
      }
    });

    return () => {
      setNotiState(null);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!ready) return;

    if (AvatarState?.AvatarLink) {
      setAvatarMenu(AvatarState.AvatarLink);
    } else {
      setAvatarMenu(am.GetMyUrlAvatar());
    }

    // eslint-disable-next-line
  }, [AvatarState, ready]);

  const PushToHome = () => {
    ScrollTop(() => {
      am.Identity.RemoveTargetUser(() => {
        h.push(`/${myAccount.UserName}`);
        SetTriggerNavToUser(u.Security.GenerateRandomString(10));
      });
    });
  };

  const ScrollTop = (fn) => {
    u.Ui.ScrollToY(0);
    if (typeof fn === "function") {
      fn.call();
    }
  };

  const LogOff = () => {
    setDialog({
      mode: "q",
      title: "Log Off",
      message: "Are you sure you want to log off?",
      buttons: [
        { id: "d2", caption: "Cancel" },
        {
          id: "d1",
          caption: "Ok",
          OnClick: () => {
            HubDispatch({
              method: hmethod.DISCONNECT,
              callBack: () => {
                am.LogMeOff();
                //h.push("/login");
              },
            });
          },
        },
      ],
    });
  };

  const HideSearchPanel = (b) => {
    if (b) {
      Refs.panel.current.classList.remove("shown");
    } else {
      Refs.panel.current.classList.add("shown");
    }
  };

  const OnTextSearch = (v) => {
    //console.log(v);
    if (v) {
      if (v.length >= g.Mins.SearchPeople) {
        HideSearchPanel(false);
        clearTimeout(throttle);
        throttle = setTimeout(() => {
          fetch(`${g.Api.ApiRoute}/user/SearchUsersAsync/?searchKey=${v}`, {
            headers: am.Identity.GetAuthHeader(),
          })
            .then((r) => {
              return r.ok ? r.json() : null;
            })
            .then((r) => {
              let arr = [];
              if (r.length) {
                for (let i = 0; i < r.length; ++i) {
                  arr.push({
                    Name: `${r[i].split("|")[0]} | ${r[i].split("|")[2]}`,
                    UserName: r[i].split("|")[1].trim(),
                  });
                }
                setSearchResult(arr);
              } else {
                setSearchResult([]);
              }
            });
        }, speed);
      } else {
        HideSearchPanel(true);
      }
    } else {
      HideSearchPanel(true);
    }
  };

  const NavigateToUser = (uid) => {
    //am.Identity.RemoveTargetUser(() => {
    h.push(`/${uid}`);
    SetTriggerNavToUser(u.Security.GenerateRandomString(10));
    //});
  };

  const ShowMobileMenu = (e) => {
    e.stopPropagation();
    Refs.desktopMenu.current.classList.toggle("shown");
  };

  const ShowNotif = () => {
    //e.target.classList.toggle("color-orange");
    console.log(u.Data.GetLsSize());
    setNotiState({ ...notiState, hidden: !notiState.hidden });
  };
  //#endregion
  return (
    <>
      {myAccount ? (
        <div id="mainNav" className="main-nav flex v-center">
          <div>
            <div className="flex v-center nowrap logo-mobile">
              <div className="logo-search l-pad-lg color-mid-gray">
                <div>
                  <HiveLogo props={{ width: "40px" }} />
                </div>
              </div>

              <div className="l-pad-lg">
                <input
                  type="text"
                  className="search-people"
                  autoComplete={u.Security.GenerateRandomString(10)}
                  placeholder="Find people"
                  onFocus={() => HideSearchPanel(false)}
                  onBlur={() => HideSearchPanel(true)}
                  onChange={(e) => OnTextSearch(e.currentTarget.value)}
                />
                <span className="search-button">
                  <i className="fa fa-search-plus fa-lg search-button"></i>
                </span>
                <div className="search-panel" ref={Refs.panel}>
                  <div className="a-left pad-sm">
                    <ul>
                      {searchResult.length ? (
                        searchResult.map((s) => (
                          <li key={s.UserName}>
                            <div onClick={() => NavigateToUser(s.UserName)}>{s.Name}</div>
                            {/* <div>
                              <Link to={`/${s.UserName}`}>{s.Name}</Link>                              
                            </div> */}
                          </li>
                        ))
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-child">
            <div className="pad-md color-mid-gray menu dekstop-menu" ref={Refs.desktopMenu}>
              <ul>
                <li title="Home">
                  <div className="hand" onClick={PushToHome}>
                    {ico.House}
                  </div>
                </li>
                {/* <li onClick={(ScrollTop, OnUrlUserChange)} title="Connections">
                  <Link to={`/${myAccount.UserName}/connections`}>
                    {ico.Users}
                  </Link>
                </li> */}
                <li onClick={ScrollTop} title="Messages">
                  <Link to={`/${myAccount.UserName}/messages`}>
                    {ico.Messages}
                    <span className="noti envelope dn">{ico.ExclamationSolid}</span>
                  </Link>
                </li>
                <li className="hand" title="Notifications" onClick={ShowNotif}>
                  <span className="item-bell">{ico.Bell}</span>
                  <span className="noti bell font-bold dn">{ico.WarningSolid}</span>
                </li>

                <li className="log-off hand" title="Log Off" onClick={(() => ScrollTop, LogOff)}>
                  {ico.Power}
                </li>
                <li onClick={ScrollTop} title="My Account">
                  <Link to={"/myaccount"}>
                    <div className="avatar-menu" style={{ backgroundImage: avatarMenu }}></div>
                  </Link>
                </li>
                <li className="burger-bar" onClick={ShowMobileMenu}>
                  <div className="r-pad-md">
                    <span className="color-white">{ico.ChevUp}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="burger-btn hand" onClick={ShowMobileMenu}>
              <div>{ico.Bars}</div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <NotificationPanel options={notiState} />
      <DialogBox Dialog={dialog} key={"MainNav"} />
    </>
  );
};

export default React.memo(MainNav);
