import React, { useEffect, useRef, useState } from "react";
import useReactIcons from "../../Hooks/useReactIcons";
import "./DialogBox.css";

const DialogBox = (props) => {
  const [icon, setIcon] = useState();
  const [inputMode, setInputMode] = useState(false);
  const [textMode, setTextMode] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const [mode, setMode] = useState();
  const [buttons, setButtons] = useState([]);
  const [percent, setPercent] = useState(0);
  const [txtLen, setTxtLen] = useState(0);
  const ico = useReactIcons();
  const Dialog = props.Dialog;

  const Refs = {
    modalRef: useRef(),
    dialogRef: useRef(),
    inputRef: useRef(),
    textRef: useRef(),
    selectRef: useRef(),
    progressRef: useRef(),
  };

  const CloseDialog = () => {
    Refs.modalRef.current.classList.add("hide-modal");
    Refs.dialogRef.current.classList.add("hide-dialog");
    setMode("");
    setButtons([]);
    setPercent(1);
    setInputMode(false);
    setTextMode(false);
    setSelectMode(false);
  };

  useEffect(() => {
    if (Dialog) {
      setMode(Dialog.mode);
      setButtons(Dialog.buttons);
      setPercent(Dialog.callBackPercent);
      //console.log("displaying...");
    }
    // eslint-disable-next-line
  }, [Dialog]);

  useEffect(() => {
    if (mode) {
      //console.log("mode", mode);
      if (!Dialog.buttons) {
        setButtons([{ id: "ok", caption: "ok" }]);
      }

      if (mode === "p" || mode === "d" || mode === "cl" || mode === "pb") {
        setButtons([]);
      }

      switch (mode) {
        case "d":
          setIcon(ico.CloudDownloadA);
          setButtons([]);
          break;
        case "u":
          setIcon(ico.CloudUploadA);
          setButtons([]);
          break;
        case "p":
          setIcon(ico.GearSpin);
          setButtons([]);
          break;
        case "pb":
          setButtons([]);
          setIcon(ico.CloudUploadA);
          Refs.progressRef.current.classList.remove("display-none");
          break;
        case "r":
          setIcon(ico.Info);
          break;
        case "e":
          setIcon(ico.Warning);
          break;
        case "l":
          setIcon(ico.PadLock);
          break;
        case "q":
          setIcon(ico.Question);
          break;
        case "s":
          setIcon(ico.Check);
          break;
        case "w":
          setIcon(ico.Warning);
          break;
        case "wi":
          setIcon(ico.Wink);
          break;
        case "in":
          setInputMode(true);
          setIcon(ico.Edit);
          break;
        case "text":
          setTextMode(true);
          setIcon(ico.TextArea);
          break;
        case "select-text":
          setTextMode(true);
          setSelectMode(true);
          setIcon(ico.Calendar);
          break;
        case "cl":
          CloseDialog();
          break;
        default:
          setIcon(ico.Info);
          break;
      }

      if (Dialog.mode !== "pb") {
        Refs.progressRef.current.classList.add("display-none");
      }

      if (Dialog.mode !== "cl") {
        Refs.modalRef.current.classList.remove("hide-modal");
        Refs.dialogRef.current.classList.remove("hide-dialog");
      }
    }
    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    if (Dialog) {
      if (percent) {
        //console.log(percent);
        Refs.progressRef.current.style.width = `${percent}%`;
      }
    }
    // eslint-disable-next-line
  }, [percent]);

  useEffect(() => {
    if (inputMode) {
      Refs.inputRef.current.focus();
    }
    if (textMode) {
      Refs.textRef.current.focus();
    }
    // eslint-disable-next-line
  }, [inputMode, textMode]);

  const OnInputText = (e) => {
    setTxtLen(e.target.value.trim().length);
  };

  return (
    <>
      {Dialog ? (
        <>
          <div className="modal hide-modal" ref={Refs.modalRef}></div>
          <div className="dialog-box hide-dialog" ref={Refs.dialogRef}>
            <div className="pad-lg font-bold font-md head">
              {"//"} HIVE
              {Dialog.title ? <span className="b-pad-sm"> &mdash; {Dialog.title}</span> : ""}
            </div>
            <hr className="d" />
            <div className="message-area">
              <div className="lr-pad-lg full-width">
                <div className="pad-lg">
                  <div className="flex-d">
                    <div className="r-pad-lg" style={{ fontSize: "2em", position: "relative" }}>
                      {icon}
                    </div>
                    <div className="full-width msg-area lr-pad-sm">
                      <div className="display-none" ref={Refs.progressRef}>
                        <div className="progress-bar"></div>
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: Dialog.message }}></div>
                    </div>
                  </div>
                </div>

                <div className="tb-pad-sm">
                  {inputMode ? (
                    <input
                      ref={Refs.inputRef}
                      type="text"
                      maxLength={Dialog.maxLength ? Dialog.maxLength : 100}
                      style={{ borderRadius: "20px" }}
                      defaultValue={Dialog.inputVal}
                    />
                  ) : (
                    <></>
                  )}

                  {textMode ? (
                    <div>
                      {Dialog.maxLength ? (
                        <div className="font-sm a-right r-pad-lg">
                          <span>
                            {txtLen}/{Dialog.maxLength}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div>
                        <textarea
                          ref={Refs.textRef}
                          type="text"
                          maxLength={Dialog.maxLength ? Dialog.maxLength : 100}
                          style={{ borderRadius: "10px" }}
                          defaultValue={Dialog.textVal}
                          onKeyUp={OnInputText}
                          defaultValue={Dialog.defaultVal ? Dialog.defaultVal : ""}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {selectMode ? (
                    <>
                      <div className="flex still v-center">
                        <div>Duration</div>
                        <div>
                          <div className="l-pad-lg">
                            <select ref={Refs.selectRef}>
                              {Dialog.select.options.map((d) => (
                                <option key={d.id} value={d.value}>
                                  {d.text}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {/* <hr className="d" /> */}
            <div>
              <div className="a-right">
                {buttons ? (
                  buttons.map((b) => (
                    <input
                      key={b.id}
                      type="button"
                      value={b.caption}
                      onClick={() => {
                        CloseDialog();
                        if (typeof b.OnClick === "function") {
                          b.OnClick(
                            mode === "in"
                              ? Refs.inputRef.current.value
                              : mode === "text"
                              ? Refs.textRef.current.value
                              : mode === "select-text"
                              ? {
                                  text: Refs.textRef.current.value,
                                  selectValue: Refs.selectRef.current.value,
                                }
                              : ""
                          );
                        }
                      }}
                    />
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(DialogBox);
