import React, { useEffect } from "react";
import useAccountManager from "../../Globals/useAccountManager";

const AccountHome = () => {
  
  const am = useAccountManager();

  useEffect(() => {
    am.ValidateAccount();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="tb-pad-lg">
        <h1>Information and Settings</h1>

        <div className="tb-pad-md">
          Click the menu items left/above to set your information.
        </div>
        <hr />
        <div className="tb-pad-lg"></div>
      </div>
    </>
  );
};

export default AccountHome;
