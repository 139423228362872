import useCommonUtil from "../Hooks/useCommonUtil";
import useGlobalConstants from "./useGlobalConstants";

const useAccountManager = () => {
  const u = useCommonUtil();
  const g = useGlobalConstants();

  const f = {
    GetMyFullName: () => {
      let user = u.Data.GetData(g.DbKeys.UserData);
      if (user) {
        return `${user.FirstName} ${user.LastName}`;
      }
    },
    GetMyAccount: (fn) => {
      let user = u.Data.GetData(g.DbKeys.UserData);
      if (typeof fn === "function") {
        fn.call(this, user);
      }
      return user;
    },
    LogMeOff: () => {
      u.Data.RemoveData(g.DbKeys.UserData, () => {
        u.Data.RemoveData(g.DbKeys.NotificationList);
        window.location.replace("/login");
      });
    },
    TrackLastSeen: (fn) => {
      f.Identity.GetTargetUser((t_user) => {
        if (t_user) {
          fetch(
            `${g.Api.ApiRoute}/user/PostOrGetLastSeen/?usr=${t_user.UserName}`,
            {
              method: g.Fetch.GET,
              headers: f.Identity.GetAuthHeader(),
            }
          )
            .then((r) => {
              return r.json();
            })
            .then((r) => {
              if (r.Succeeded) {
                if (typeof fn === "function") {
                  fn.call(this, r.ModelData);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    StoreMyAccount: (act, fn) => {
      u.Data.StoreData(g.DbKeys.UserData, act, () => {
        if (typeof fn === "function") {
          fn.call(this, u.Data.GetData(g.DbKeys.UserData));
        }
      });
    },
    RemoveMyAccount: () => {
      u.Data.RemoveData(g.DbKeys.UserData);
    },
    GetMyUserName: () => {
      let user = u.Data.GetData(g.DbKeys.UserData); //JSON.parse(localStorage.getItem(g.DbKeys.UserData));

      if (user) {
        return user.UserName;
      } else {
        return null;
      }
    },
    GetMyDeviceId: (userName) => {
      return `H${u.FileManager.HashToHexDigit(userName)}`;
    },
    GetMyUrlAvatar: (uname) => {
      let userName;
      let user = u.Data.GetData(g.DbKeys.UserData);
      if (!user) {
        f.LogMeOff();
      } else {
        if (!uname) {
          userName = user.UserName;
        }
      }

      return `url(${g.Api.CdnRoute}/${userName}/profile/${user.UserName}.jpg?v=${user.PicVer})`;
    },

    GetMyAvatar: (uname) => {
      let userName, ava;
      if (!uname) {
        let user = u.Data.GetData(g.DbKeys.UserData);
        if (!user) {
          f.LogMeOff();
        } else {
          userName = user.UserName;
        }
      } else {
        userName = uname;
      }
      ava = `${
        g.Api.CdnRoute
      }/${userName}/profile/profilepic.jpg?v=${u.Security.GenerateRandomString(
        10
      )}`;

      return ava;
    },

    GetUserHiveFriends: () => {
      return u.Data.GetData(g.DbKeys.UserHiveFriends);
      //return JSON.parse(localStorage.getItem(g.DbKeys.UserHiveFriends));
    },
    GetMyRoles: () => {
      let user = u.Data.GetData(g.DbKeys.UserData);
      return user.Roles;
    },
    GetMyAuthToken: () => {
      let user = u.Data.GetData(g.DbKeys.UserData);
      return user.Token.TokenKey;
    },
    ValidateAccount: (fn) => {
      f.GetMyAccount((d) => {
        //console.log("ValidateAccount executed");
        if (d) {
          let exp = parseInt(new Date(d.Token.ValidTo).getTime() / 1000);
          let now = new Date();
          let utc_timestamp = Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate(),
            now.getUTCHours(),
            now.getUTCMinutes(),
            now.getUTCSeconds(),
            now.getUTCMilliseconds()
          );

          utc_timestamp = parseInt(utc_timestamp / 1000);
          if (utc_timestamp > exp) {
            f.LogMeOff();
            //if (typeof cb === "function") cb.call(this, d, true);
          } else if (typeof fn === "function") {
            fn.call(this, d);
            return true;
          }
        } else {
          if (window.location.pathname.toLowerCase().indexOf("login") >= 0)
            return;
          if (window.location.pathname.toLowerCase().indexOf("register") >= 0)
            return;
          if (window.location.pathname.toLowerCase().indexOf("pages") >= 0)
            return;
          //do not execute this
          f.LogMeOff();
        }
      });
    },
    GetMyEducations: (userName, fn) => {
      fetch(
        `${g.Api.ApiRoute}/UserInfoApi/GetUserEducsAsync/?userName=${userName}`,
        {
          method: "GET",
          headers: f.Identity.GetAuthHeader(),
        }
      )
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            fn.call(this, null);
          }
        })
        .then((j) => {
          if (j.Succeeded) {
            fn.call(this, j.ModelData);
            //setMyEducList(j.ModelData);
          } else {
            fn.call(this, null);
          }
        })
        .catch((err) => {
          fn.call(this, null);
        });
    },
    GetMyJobs: (userName, fn) => {
      fetch(
        `${g.Api.ApiRoute}/UserInfoApi/GetUserJobsAsync/?userName=${userName}`,
        {
          method: "GET",
          headers: f.Identity.GetAuthHeader(),
        }
      )
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            fn.call(this, null);
          }
        })
        .then((j) => {
          if (j.Succeeded) {
            fn.call(this, j.ModelData);
            //setMyJobs(j.ModelData);
          } else {
            fn.call(this, null);
          }
        })
        .catch((err) => {
          fn.call(this, null);
        });
    },
    GetMyHobbies: (userName, fn) => {
      fetch(
        `${g.Api.ApiRoute}/UserInfoApi/GetUserHobbiesAsync/?userName=${userName}`,
        {
          method: "GET",
          headers: f.Identity.GetAuthHeader(),
        }
      )
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            fn.call(this, null);
          }
        })
        .then((j) => {
          if (j.Succeeded) {
            fn.call(this, j.ModelData);
            //setMyJobs(j.ModelData);
          } else {
            fn.call(this, null);
          }
        })
        .catch((err) => {
          fn.call(this, null);
        });
    },
    GetMyNumFriends: (fn) => {
      fetch(`${g.Api.ApiRoute}/user/GetMyNumFriends`, {
        method: "GET",
        headers: f.Identity.GetAuthHeader(),
      })
        .then((r) => {
          if (r) return r.json();
        })
        .then((res) => {
          if (res.Succeeded) {
            f.GetMyAccount((me) => {
              me.ConnectionsCount = res.ModelData.NumCount.ConnectionsCount;
              f.StoreMyAccount(me);
              if (typeof fn === "function") {
                fn.call(this, me.ConnectionsCount);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Identity: {
      GetUrlUserName: () => {
        return window.location.pathname.split("/")[1];
      },
      GetAuthHeader: () => {
        let d = u.Data.GetData(g.DbKeys.UserData);
        if (d) {
          let auth = g.Fetch.TYPEAUTH;
          auth.Authorization = `Bearer ${d.Token.TokenKey}`;
          return auth;
        }
        return "no-key";
      },
      GetBinAuthHeader: () => {
        let d = u.Data.GetData(g.DbKeys.UserData);
        if (d) {
          let auth = {};
          auth.Authorization = `Bearer ${d.Token.TokenKey}`;
          return auth;
        }
        return "no-key";
      },
      IsMe: () => {
        let d = u.Data.GetData(g.DbKeys.UserData);
        if (d) {
          let urlUser = window.location.pathname;
          let isme = urlUser.toLowerCase().includes(d.UserName.toLowerCase());
          return isme;
        } else {
          return false;
        }
      },
      GetTargetUser: (fn, firstCaller) => {
        if (f.Identity.IsMe()) {
          var me = f.GetMyAccount();
          if (typeof fn === "function") {
            fn.call(this, me);
          }
        } else {
          if (firstCaller) {
            const params = new URLSearchParams({
              userName: f.Identity.GetUrlUserName(),
            });

            fetch(`${g.Api.ApiRoute}/user/GetUser/?${params}`, {
              headers: f.Identity.GetAuthHeader(),
            })
              .then((r) => {
                if (r.ok) {
                  return r.json();
                } else {
                  return null;
                }
              })
              .then((r) => {
                if (r) {
                  if (r.Succeeded) {
                    u.Data.StoreData(g.DbKeys.TargetUser, r.ModelData);
                    if (typeof fn === "function") {
                      fn.call(this, r.ModelData);
                    }
                  }
                }
              })
              .catch((err) => {
                if (typeof fn === "function") {
                  fn.call(
                    this,
                    err.stack ? err.stack : "API Communication error."
                  );
                }
              });
          } else {
            let targ,
              tmr,
              ctr = 0;
            tmr = setInterval(function () {
              //console.log("Waiting for target user from storage...");
              targ = u.Data.GetData(g.DbKeys.TargetUser);
              if (targ) {
                //console.log("Target user acquired from storage...");
                fn.call(this, targ);
                clearTimeout(tmr);
              }

              if (ctr >= 30) {
                //kill it.
                ctr = 0;
                clearTimeout(tmr);
              }
              ctr++;
            }, 500);
          }
        }
      },
      RemoveTargetUser: (fn) => {
        u.Data.RemoveData(g.DbKeys.TargetUser, () => {
          if (typeof fn === "function") {
            fn.call(this);
          }
        });
      },
    },
  };

  return f;
};

export default useAccountManager;
