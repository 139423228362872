import React from "react";

import {
  AiOutlineAudio,
  AiOutlineCodeSandbox,
  AiOutlineEdit,
  AiOutlineIdcard,
  AiOutlineLink,
  AiOutlineUndo,
  AiOutlineWarning,
  AiOutlineRead,
} from "react-icons/ai";

import {
  BiGlobe,
  BiMapAlt,
  BiSave,
  BiChevronDownCircle,
  BiChevronUpCircle,
  BiChevronLeftCircle,
  BiChevronRightCircle,
  BiWinkSmile,
  BiHappy,
  BiHash,
  BiPhotoAlbum,
  BiCookie,
} from "react-icons/bi";

import {
  BsBell,
  BsBuilding,
  BsCalendar,
  BsCameraVideo,
  BsCardHeading,
  BsChatSquareQuote,
  BsChevronBarUp,
  BsEnvelope,
  BsExclamation,
  BsGear,
  BsGeoAlt,
  BsHouse,
  BsInfoCircle,
  BsImage,
  BsImages,
  BsLink,
  BsList,
  BsPencil,
  BsPower,
  BsCloudUpload,
  BsCloudDownload,
  BsCheckCircle,
  BsExclamationCircle,
  BsFillExclamationCircleFill,
  BsQuestionCircle,
  BsTextareaT,
  BsBriefcase,
  BsChevronDown,
  BsChevronUp,
  BsPlusCircle,
  BsFolderPlus,
  BsGraphUp,
  BsSearch,
  BsDashCircle,
} from "react-icons/bs";

import { CgSpinner } from "react-icons/cg";

import {
  FaGraduationCap,
  FaRegAngry,
  FaRegGrinTongueWink,
  FaRegLaughBeam,
  FaExclamationCircle,
} from "react-icons/fa";

import { FcGoogle } from "react-icons/fc";
import {
  FiUsers,
  FiArrowUpCircle,
  FiHeart,
  FiThumbsUp,
  FiThumbsDown,
  FiRefreshCw,
} from "react-icons/fi";
import {
  ImFilm,
  ImSad,
  ImShocked,
  ImBaffled,
  ImFrustrated,
  ImWondering,
  ImTongue,
  ImGrin,
  ImConfused,
} from "react-icons/im";

import { GoTerminal, GoStop, GoRadioTower, GoReport } from "react-icons/go";

import {
  GiStarfighter,
  GiStarSattelites,
  GiBoltShield,
  GiBrokenHeartZone,
  GiAngelOutfit,
} from "react-icons/gi";

import { GrCodepen } from "react-icons/gr";

import { IoMdWarning, IoIosSend } from "react-icons/io";

import {
  RiLightbulbFlashLine,
  RiCloseCircleLine,
  RiDeleteBin6Line,
  RiFileEditLine,
  RiBroadcastLine,
  RiEmotionSadLine,
  RiFileExcel2Line,
} from "react-icons/ri";

import {
  VscLink,
  VscLock,
  VscDeviceCamera,
  VscRadioTower,
  VscReactions,
  VscDebug,
} from "react-icons/vsc";
import { WiCloudDown, WiCloudUp } from "react-icons/wi";

const useReactIcons = () => {
  const i = {
    Angel: <GiAngelOutfit />,
    ArrowCircleUp: <FiArrowUpCircle />,
    Audio: <AiOutlineAudio />,
    Bars: <BsList />,
    Bell: <BsBell />,
    Bin: <RiDeleteBin6Line />,
    Book: <AiOutlineRead />,
    BriefCase: <BsBriefcase />,
    BroadCast: <RiBroadcastLine />,
    Bug: <VscDebug />,
    Building: <BsBuilding />,
    Bulb: <RiLightbulbFlashLine />,
    BusinessCard: <BsCardHeading />,
    Calendar: <BsCalendar />,
    Camera: <VscDeviceCamera className="font-lg" />,
    Check: <BsCheckCircle />,
    CheckGreen: <BsCheckCircle className="color-green" />,
    ChevUp: <BsChevronUp />,
    ChevDown: <BsChevronDown />,
    ChevronDown: <BiChevronDownCircle />,
    ChevronUp: <BiChevronUpCircle />,
    ChevronLeft: <BiChevronLeftCircle />,
    ChevronRight: <BiChevronRightCircle />,
    CircleSpinner: <CgSpinner className="icon-spin color-purple" />,
    CloseCircle: <RiCloseCircleLine />,
    CloudDownload: <BsCloudDownload />,
    CloudDownloadA: <WiCloudDown className="downloading animated fast font-lg" />,
    CloudUpload: <BsCloudUpload />,
    CloudUploadA: <WiCloudUp className="uploading animated fast font-lg" />,
    Code: <AiOutlineCodeSandbox />,
    CodeBox: <GrCodepen />,
    CodeSpin: <AiOutlineCodeSandbox className="icon-spin slow" />,
    ContactCard: <AiOutlineIdcard />,
    Cookie: <BiCookie />,
    Edit: <AiOutlineEdit />,
    Envelope: <BsEnvelope />,
    Error: <BsFillExclamationCircleFill className="color-red" />,
    ExcelFile: <RiFileExcel2Line />,
    Exclamation2: <BsExclamationCircle />,
    Exclamation: <BsExclamation />,
    ExclamationSolid : <FaExclamationCircle/>,
    Film: <ImFilm />,
    Gear: <BsGear />,
    GearSpin: <BsGear className="icon-spin slow" />,
    Geo: <BsGeoAlt />,
    Globe: <BiGlobe />,
    GoogleColor: <FcGoogle />,
    GraduationCap: <FaGraduationCap />,
    GraphUp: <BsGraphUp />,
    HashTag: <BiHash />,
    Heart: <FiHeart />,
    HideUp: <BsChevronBarUp />,
    House: <BsHouse />,
    Image: <BsImage />,
    Images: <BsImages />,
    Info: <BsInfoCircle />,
    InfoBlue: <BsInfoCircle className="color-blue" />,
    Link: <BsLink />,
    LinkVs: <VscLink />,
    Map: <BiMapAlt />,
    Messages: <BsChatSquareQuote />,
    Navigator: <GiStarSattelites />,
    NavigatorSpin: <GiStarSattelites className="icon-spin slow" />,
    NewFolder: <BsFolderPlus />,
    PadLock: <VscLock />,
    Pencil: <BsPencil />,
    PhotoAlbum: <BiPhotoAlbum />,
    PlusCircle: <BsPlusCircle />,
    Power: <BsPower />,
    Question: <BsQuestionCircle />,
    RadioTower: <GoRadioTower />,
    RadioTower2: <VscRadioTower />,

    ReactionPlus: <VscReactions />,
    ReactionAngry: <FaRegAngry />,
    ReactionBaffled: <ImBaffled />,
    ReactionConfused: <ImConfused />,
    ReactionCrySad: <RiEmotionSadLine />,
    ReactionFrustrated: <ImFrustrated />,
    ReactionGrin: <ImGrin />,
    ReactionGrin2: <FaRegGrinTongueWink />,
    ReactionHappy: <BiHappy />,
    ReactionHeartBroken: <GiBrokenHeartZone />,
    ReactionLaugh: <FaRegLaughBeam />,
    ReactionSad: <ImSad />,
    ReactionTongue: <ImTongue />,
    RactionWondering: <ImWondering />,
    ReactionWow: <ImShocked />,

    Refresh: <FiRefreshCw />,
    Remove: <BsDashCircle />,
    Rename: <RiFileEditLine />,
    Report: <GoReport />,
    Save: <BiSave />,
    Search: <BsSearch />,
    Send: <IoIosSend />,
    Shield2l: <GiBoltShield />,
    Starfighter: <GiStarfighter />,
    Stop: <GoStop />,
    Terminal: <GoTerminal className="impulse" />,
    TextArea: <BsTextareaT />,
    ThumbDown: <FiThumbsDown />,
    ThumbUp: <FiThumbsUp />,
    Undo: <AiOutlineUndo />,
    Unlinked: <AiOutlineLink />,
    Users: <FiUsers />,
    Video: <BsCameraVideo />,
    Warning: <AiOutlineWarning />,
    WarningSolid: <IoMdWarning />,
    WarningOrange: <AiOutlineWarning className="color-orange" />,
    Wink: <BiWinkSmile />,

    GetReactionIcon: (id) => {
      let ix = {
        ico: undefined,
        text: "I'm Feeling...",
      };
      switch (id) {
        case 1:
          ix.ico = i.ReactionAngry;
          ix.text = "Angry";
          ix.color = "color-red";
          return ix;
        case 2:
          ix.ico = i.ReactionBaffled;
          ix.text = "Baffled";
          ix.color = "color-green";
          return ix;
        case 3:
          ix.ico = i.ReactionConfused;
          ix.text = "Confused";
          ix.color = "color-green";
          return ix;
        case 4:
          ix.ico = i.ReactionCrySad;
          ix.text = "Sad Crying";
          ix.color = "color-orange";
          return ix;
        case 5:
          ix.ico = i.ReactionFrustrated;
          ix.text = "Frustrated";
          ix.color = "color-orange";
          return ix;
        case 6:
          ix.ico = i.ReactionGrin;
          ix.text = "Grin";
          ix.color = "color-green";
          return ix;
        case 7:
          ix.ico = i.ReactionHappy;
          ix.text = "Happy";
          ix.color = "color-blue2";
          return ix;
        case 8:
          ix.ico = i.ReactionHeartBroken;
          ix.text = "Heartbroken";
          ix.color = "color-yellow";
          return ix;
        case 9:
          ix.ico = i.ReactionLaugh;
          ix.text = "Laughing";
          ix.color = "color-green";
          return ix;
        case 10:
          ix.ico = i.ThumbUp;
          ix.text = "Like";
          ix.color = "color-blue";
          return ix;
        case 11:
          ix.ico = i.Heart;
          ix.text = "Love";
          ix.color = "color-pink";
          return ix;
        case 12:
          ix.ico = i.ReactionSad;
          ix.text = "Sad";
          ix.color = "color-yellow";
          return ix;
        case 13:
          ix.ico = i.ReactionWow;
          ix.text = "Shocked/Wow";
          ix.color = "color-purple";
          return ix;
        case 14:
          ix.ico = i.ReactionGrin2;
          ix.text = "Teasing";
          ix.color = "color-green";
          return ix;
        case 15:
          ix.ico = i.Angel;
          ix.text = "Blessed";
          ix.color = "color-purple";
          return ix;
        default:
          ix.ico = i.ThumbUp;
          ix.text = "Like";
          ix.color = "color-blue";
          return ix;
      }
    },
  };

  return i;
};

export default useReactIcons;
