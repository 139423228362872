//import * as signalR from "@aspnet/signalr";
import * as signalR from "@microsoft/signalr";
import { createContext } from "react";

import useGlobalConstants from "../useGlobalConstants";
import useAccountManager from "../useAccountManager";

const g = useGlobalConstants();
const am = useAccountManager();

const protocol = new signalR.JsonHubProtocol();
//const transport = signalR.HttpTransportType.LongPolling; //for shared hosting
const transport = signalR.HttpTransportType.WebSockets;
const signalROptions = {
  transport,
  logMessageContent: true,
  //logger: signalR.LogLevel.Information,
  skipNegotiation: true,
  accessTokenFactory: () => am.GetMyAuthToken(),
};

export const HubEnumStates = {
  /** The hub connection is disconnected. */
  Disconnected: "Disconnected",
  /** The hub connection is connecting. */
  Connecting: "Connecting",
  /** The hub connection is connected. */
  Connected: "Connected",
  /** The hub connection is disconnecting. */
  Disconnecting: "Disconnecting",
  /** The hub connection is reconnecting. */
  Reconnecting: "Reconnecting",
};

export const SocketContext = createContext();

export const hubConnection = new signalR.HubConnectionBuilder()
  .withUrl(`${g.Api.HubEndpoint}`, signalROptions)
  .withHubProtocol(protocol)
  //.withAutomaticReconnect()
  .withAutomaticReconnect([0,2000,10000,30000, 60000, null])
  .build();

const RegisterHubEvents = () => {
  hubConnection.onreconnecting((msg) => {
    console.log(msg);
  });

  hubConnection.onreconnected((cid) => {
    console.log("Hub Reconnected");
    var user = am.GetMyAccount();
    const _payLoad = {
      UserName: user.UserName,
      Roles: user.Roles,
      FullName: `${user.FirstName} ${user.LastName}`,
      //DeviceId: deviceId,
    };

    signalReducer(hubConnection, {
      method: methods.INVOKE,
      payload: {
        ServerMethod: "ServerJoinGroup",
        Args: [_payLoad],
      },      
    });
  });

  hubConnection.onclose = () => {
    console.log("#SignalR Connection Closed.");
  };

  hubConnection.ondisconnect = () => {
    console.log("#SignalR Connection disconnected.");
  };
};

const RegisterInitialEvents = () => {
  // hubConnection.on("ClientJoinGroup", (obj) => {
  //   if (am.GetMyUserName() === obj.UserName) {
  //     console.log(`[Reducer] CONNECTED`, obj.UserName);
  //   }
  // });
};

const ConnecToHub = (action) => {
  const state = hubConnection.connection.connectionState;
  RegisterHubEvents();
  //console.log("state", state);
  switch (state) {
    case HubEnumStates.Connected:
      console.log(`[Reducer] ${action.method} : CONNECTED to hub`);
      if (typeof action.callBack === "function") {
        action.callBack();
      }
      break;
    case HubEnumStates.Reconnecting:
      console.log(`[Reducer] ${action.method} : hub is still REconnecting...`);
      break;
    case HubEnumStates.Connecting:
      console.log(`[Reducer] ${action.method} : HUB CONNECTING...`);
      break;
    case HubEnumStates.Disconnected:
      console.log(`[Reducer] ${action.method}`);
      hubConnection
        .start()
        .then(() => {
          RegisterInitialEvents();
          if (typeof action.callBack === "function") {
            action.callBack();
          }
        })
        .catch((err) => {
          console.log("[Reducer] signalR ERROR: ", err);
        });
      break;
    default:
      break;
  }
};

//##############################################################
const methods = g.Hub.ReducerMethods;
export const signalReducer = (hubConn, action) => {
  switch (action.method) {
    case methods.CONNECT:
      ConnecToHub(action);
      return hubConn;
    case methods.DISCONNECT:
      DisconnectFromHub(action.method, () => {
        action.callBack();
      });
      return hubConn;
    case methods.INVOKE:
      hubConn
        .invoke(action.payload.ServerMethod, ...action.payload.Args)
        .then((v) => {
          if (typeof action.callBack === "function") {
            action.callBack(v);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return hubConn;
    case methods.REG_CLIENT_METHOD:
      let args;
      hubConn.on(action.payload.ClientMethod, args);
      action.callBack(args);
      return hubConn;
    default:
      return hubConn;
  }
};
//##############################################################

const DisconnectFromHub = (method, fn) => {
  hubConnection.stop().then(() => {
    if (typeof fn === "function") {
      console.log(`SignalR disconnected from ${g.AppName.LONG_NAME}.`);
      fn.call();
    }
  });
};
