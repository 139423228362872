/* eslint-disable */
import React from "react";
import LZString from "lz-string";
import useGlobalConstants from "../Globals/useGlobalConstants";
import parse, { domToReact } from "html-react-parser";
import { Link } from "react-router-dom";

const useCommonUtil = () => {
  const g = useGlobalConstants();

  const f = {
    Security: {
      GenerateRandomString: function (plength) {
        var keylistalpha =
          "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz";
        var keylistint = "1234567890";
        var keylistspec = "-_";
        var temp = "";
        var len = plength / 2;
        //var len = len - 1;
        var lenspec = plength - len - len;
        var i;

        for (i = 0; i < len; i++)
          temp += keylistalpha.charAt(
            Math.floor(Math.random() * keylistalpha.length)
          );

        for (i = 0; i < lenspec; i++)
          temp += keylistspec.charAt(
            Math.floor(Math.random() * keylistspec.length)
          );

        for (i = 0; i < len; i++)
          temp += keylistint.charAt(
            Math.floor(Math.random() * keylistint.length)
          );

        temp = temp
          .split("")
          .sort(function () {
            return 0.5 - Math.random();
          })
          .join("");

        return temp;
      },
      ScorePassword: (pass) => {
        let rating = {
          score: 0,
          rate: "UNACCEPTABLE",
        };

        var score = 0;
        if (!pass) return rating;

        // award every unique letter until 5 repetitions
        var letters = {}; // new Object();
        for (var i = 0; i < pass.length; i++) {
          letters[pass[i]] = (letters[pass[i]] || 0) + 1;
          score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        const variations = {
          digits: /\d/.test(pass),
          lower: /[a-z]/.test(pass),
          upper: /[A-Z]/.test(pass),
          nonWords: /\W/.test(pass),
        };

        let variationCount = 0;
        for (var check in variations) {
          variationCount += variations[check] === true ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        let rate;

        if (score >= 0 && score <= 35) {
          rate = "UNACCEPTABLE";
        } else if (score <= 50) {
          rate = "WEAK";
        } else if (score <= 75) {
          rate = "GOOD";
        } else if (score <= 85) {
          rate = "VGOOD";
        } else if (score <= 90 || score >= 91) {
          rate = "STRONG";
        }

        rating = {
          score: parseInt(score, 10),
          rate: rate,
        };

        //console.log("util", rating);

        return rating;
      },
    },
    Number: {
      ToFixed: (n, d) => {
        return n.toLocaleString("en-US", {
          minimumFractionDigits: d,
          maximumFractionDigits: d,
        });
      },
      IsNumber: function (obj) {
        return (
          (typeof obj === "number" || typeof obj === "string") &&
          !isNaN(obj - parseFloat(obj))
        );
      },
      IsInt: function (value) {
        return (
          !isNaN(value) &&
          parseInt(Number(value)) === parseInt(value, 10) &&
          !isNaN(parseInt(value, 10))
        );
      },
      ParseIntBase10: (value) => {
        return parseInt(value, 10);
      },
    },
    Data: {
      ValidateLongEntry: (txt, len) => {
        let arr_entry = txt.split(" ");
        let v = len || 60;
        let invalid = false;
        if (arr_entry.length > 0) {
          let i = 0;
          do {
            if (arr_entry[i].length >= v) {
              invalid = true;
            }
            i++;
          } while (i < arr_entry.length && !invalid);
        }

        return invalid;
      },
      ParseMessage: (errNum) => {
        const _num = parseInt(errNum, 10);
        switch (_num) {
          case 401:
            return "Access Denied. Token expired.";
          case 500:
            return "Fatal error.";
          default:
            return "Unknown error";
        }
      },
      EstimateReadTime: (text) => {
        let s = text;
        s = s.replace(/(^\s*)|(\s*$)/gi, "");
        s = s.replace(/[ ]{2,}/gi, " ");
        s = s.replace(/\n /, "\n");

        let totalWords = s.split(" ").length;
        let readTime = Math.floor(totalWords / 200);

        return readTime === 0 ? "Less than 1" : readTime;
      },
      ParseSlug: (str, noDash) => {
        str = str.replace(/^\s+|\s+$/g, ""); // trim
        str = str.toLowerCase();
        // remove accents, swap ñ for n, etc
        let from =
          "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
        let to =
          "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";
        for (let i = 0, l = from.length; i < l; i++) {
          str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
        }
        str = str
          .replace(/[^a-z0-9 -]/g, "-") // remove invalid chars
          .replace(/-+/g, "-") // collapse dashes
          .replace(/\s+/g, noDash ? "" : "-"); // collapse whitespace and replace by - or none

        return str;
      },
      SortListBy: (field, reverse, primer) => {
        const key = primer
          ? function (x) {
              return primer(x[field]);
            }
          : function (x) {
              return x[field];
            };

        reverse = !reverse ? 1 : -1;

        return function (a, b) {
          return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
        };
      },
      StoreData: (keyName, value, fn) => {
        let compressed = g.Data.CompressedEnc;

        let v = compressed ? LZString.compress(JSON.stringify(value)) : value;

        localStorage.setItem(keyName, compressed ? v : JSON.stringify(v));
        if (typeof fn === "function") {
          fn.call(
            this,
            compressed
              ? JSON.parse(LZString.decompress(localStorage.getItem(keyName)))
              : JSON.parse(localStorage.getItem(keyName))
          );
        }
      },
      RemoveData: (keyName, fn) => {
        localStorage.removeItem(keyName);
        if (typeof fn === "function") {
          setTimeout(() => {
            fn.call();
          }, 100); //give time to remove
        }
      },
      RetrieveData: (keyName, fn) => {
        let compressed = g.Data.CompressedEnc;
        let v = localStorage.getItem(keyName);
        let d;
        if (v) {
          if (compressed) {
            d = JSON.parse(LZString.decompress(v));
          } else {
            d = JSON.parse(v);
          }
        }

        if (typeof fn === "function") {
          fn.call(this, d);
        }
      },
      ClearStorage: (fn) => {
        localStorage.clear();
        if (typeof fn === "function") {
          fn.call();
        }
      },
      GetData: (keyName) => {
        let compressed = g.Data.CompressedEnc;
        let v = localStorage.getItem(keyName);

        try {
          if (v) {
            return compressed
              ? JSON.parse(LZString.decompress(localStorage.getItem(keyName)))
              : JSON.parse(localStorage.getItem(keyName));
          } else {
            return null;
          }
        } catch (err) {
          console.log(err);
          return null;
        }
      },
      StoreSs: (k, val) => {
        sessionStorage.setItem(k, JSON.stringify(val));
      },
      GetSs: (k) => {
        return JSON.parse(sessionStorage.getItem(k));
      },
      RemoveSs: (k) => {
        sessionStorage.removeItem(k);
      },
      GetAcronym: (words) => {
        if (!words || words.length === 0) return "";

        let special = words.replace(/[^a-zA-Z ]/g, "");
        let acro = special
          .match(/\b(\w)/g)
          .join("")
          .toUpperCase();
        if (acro.length === 1) {
          acro = special.substr(0, 3).toUpperCase();
        }
        return acro;
      },
      GetAcronym2: (words) => {
        if (!words || words.length === 0) return "";
        let acro = "";
        let arr = words.toLowerCase().split(" ");
        let filtered = arr.filter(
          (w) => w !== "in" && w !== "of" && w !== "the" && w !== "de"
        );
        filtered = filtered.filter((f) => f.length !== 1);

        //console.log(filtered);

        filtered.forEach((el) => {
          acro += el.substr(0, 1);
        });

        return acro.toUpperCase();
      },
      GetLsSize: () => {
        let allStrings = "";
        for (let key in window.localStorage) {
          if (window.localStorage.hasOwnProperty(key)) {
            allStrings += window.localStorage[key];
          }
        }
        return allStrings
          ? 3 + ((allStrings.length * 16) / (8 * 1024)).toFixed(2) + " KB"
          : "Empty (0 KB)";
      },
      GetImageTag: (str) => {
        let tag = str.match(/(<img[^>]+>)/g);
        let data = [];
        if (tag) {
          tag.forEach((i) => {
            data.push(i.split(",")[1].split(" ")[0].replace('"', ""));
          });
        }
        return data;
      },
      GroupBy: (arr, propertyName) => {
        return arr.reduce(function (acc, obj) {
          let key = obj[propertyName];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});
      },
      ParseLinks: (txt, cb) => {
        let exp_match =
          /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        let element_content = txt.replace(exp_match, "<a href='$1'>$1</a>");
        let new_exp_match = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        let new_content = element_content.replace(
          new_exp_match,
          '$1<a target="_blank" href="http://$2">$2</a>'
        );

        const options = {
          replace: ({ name, attribs, children }) => {
            if (name === "a" && attribs.href) {
              if (attribs.href.indexOf("#") >= 0) {
                let hrf = attribs.href.replace("#", ""); //alising natin for the link
                hrf = hrf.toLowerCase().trim();
                return (
                  <Link to={hrf} onClick={f.Ui.ScrollToY}>
                    {domToReact(children)}
                  </Link>
                );
              }
            }
          },
        };
        //parse the hashtags dito
        new_content = new_content.replace(
          /(^|\s)(#[a-zA-Z0-9ñÑ]{1,30})/gi, // /(^|\s)#([^&%$_-]\S{1,30})\b/gi, // ,
          `<a href='/hashtags/$2'>$1<span class='hash-tag __hashtag'>$2</span></a>`
        );

        return parse(new_content.trim(), options);
      },
      GetHashTags: (txt) => {
        var regex = /(^|\s)#([^&%$_-]\S{2,30})\b/gi;
        var matches = [];
        var match;

        while ((match = regex.exec(txt))) {
          matches.push(match[1]);
        }

        return matches;
      },
      Cookies: {
        GetValue: (cookieName) => {
          let val = document.cookie
            .split(";")
            .some((item) => item.includes(`${cookieName}`));
          if (val) {
            let jArr = document.cookie.split(";");
            let cv = jArr.find((c) => c.includes(cookieName));
            if (cv) {
              let result = JSON.parse(cv.split("=")[1]);
              return result;
            } else {
              return undefined;
            }
          }
        },
        StoreValue: (cookieName, value, fn) => {
          let v = JSON.stringify(value);
          document.cookie = `${cookieName}=${v};expires=Fri, 31 Dec 9999 23:59:59 GMT`;
          if (typeof fn === "function") {
            fn.call(this);
          }
        },
        ClearValue: (cookieName) => {
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        },
      },
      GetLocalStorageSize: () => {
        let info = "";
        let data = "";
        for (var key in window.localStorage) {
          if (window.localStorage.hasOwnProperty(key)) {
            data += window.localStorage[key];
            info += `${key} = ${(
              (window.localStorage[key].length * 16) /
              (8 * 1024)
            ).toFixed(2)} KB | '`;
          }
        }
        return {
          info: info,
          used: data
            ? ((data.length * 16) / (8 * 1024)).toFixed(2) + " KB"
            : "Empty (0 KB)",
          free: data
            ? 5120 - ((data.length * 16) / (8 * 1024)).toFixed(2) + " KB"
            : "5 MB",
        };
      },
    },
    Date: {
      IsValidDate: (dateString) => {
        // First check for the pattern
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

        // Parse the date parts to integers
        var parts = dateString.split("/");
        var day = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[2], 10);

        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month == 0 || month > 12)
          return false;

        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
          monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
      },
      FormatDate: (dateEntry, formatx) => {
        let date = new Date(dateEntry);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const dayNames = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const getProperDigits = (format, regex, value) => {
          return format.replace(regex, function (m) {
            let length = m.length;
            if (length === 1) return value;
            else if (length === 2) return ("0" + value).slice(-2);
            return m;
          });
        };
        const getHours12 = (hours) => {
          return (hours + 24) % 12 || 12;
        };
        const getAmPm = (hours) => {
          return hours >= 12 ? "pm" : "am";
        };
        const getFullOr3Letters = (format, regex, nameArray, value) => {
          return format.replace(regex, function (s) {
            let len = s.length;
            if (len === 3) return nameArray[value].substr(0, 3);
            else if (len === 4) return nameArray[value];
            return s;
          });
        };

        let format = getProperDigits(formatx, /d+/gi, date.getDate());

        format = getProperDigits(format, /M+/g, date.getMonth() + 1);
        format = format.replace(/y+/gi, function (y) {
          let len = y.length;
          let year = date.getFullYear();
          if (len === 2) return (year + "").slice(-2);
          else if (len === 4) return year;
          return y;
        });
        format = getProperDigits(format, /H+/g, date.getHours());
        format = getProperDigits(format, /h+/g, getHours12(date.getHours()));
        format = getProperDigits(format, /m+/g, date.getMinutes());
        format = getProperDigits(format, /s+/gi, date.getSeconds());
        format = format.replace(/a/gi, function (a) {
          let amPm = getAmPm(date.getHours());
          if (a === "A") return amPm.toUpperCase();
          return amPm;
        });
        format = getFullOr3Letters(format, /d+/gi, dayNames, date.getDay());
        format = getFullOr3Letters(format, /M+/g, monthNames, date.getMonth());

        return format.toUpperCase();
      },
      IsLeapYear: (year) => {
        return new Date(year, 1, 29).getDate() === 29;
      },
      GetLastDateOfMonth: (dateParam) => {
        var date = new Date(dateParam);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return lastDay.getDate();
      },
    },

    Forms: {
      ClearTextInputs: (parentEl) => {
        let inputs = parentEl.getElementsByTagName("input");
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i].type === "text") {
            inputs[i].value = "";
          }
        }
      },
      MoveCursorToEnd: (el) => {
        if (!el) return;
        el.focus();
        if (typeof el.selectionStart == "number") {
          el.selectionStart = el.selectionEnd = el.value.length;
        } else if (typeof el.createTextRange != "undefined") {
          var range = el.createTextRange();
          range.collapse(false);
          range.select();
        }
      },
    },
    Ui: {
      makeMenuItemActive: (el, className) => {
        [...el.parentElement.children].forEach((sib) =>
          sib.classList.remove(className || "active")
        );
        el.classList.add(className || "active");
      },
      setMenuItemActive: (tParent, idx, className) => {
        let t = document.getElementById(tParent);
        if (t) {
          t.childNodes.forEach((sib) =>
            sib.classList.remove(className || "active")
          );
          t.childNodes[idx].classList.add(className || "active");
        }
      },
      seekMenuItemActive: (tParent, text, className) => {
        let t = document.getElementById(tParent);
        if (t) {
          t.childNodes.forEach((sib) =>
            sib.classList.remove(className || "active")
          );
          t.childNodes.forEach((sib) => {
            //console.log(sib.innerText);
            if (sib.innerText.toLowerCase() === text.toLowerCase()) {
              sib.classList.add(className || "active");
              return false;
            }
          });
          //t.childNodes[idx].classList.add(className || 'active');
        }
      },
      DisableElById: (el, b) => {
        let _el = document.getElementById(el);

        if (!_el) {
          _el = document.getElementsByName(el);
        }

        //if element found
        if (_el) {
          if (b) {
            _el.classList.add("no-touch");
          } else {
            _el.classList.remove("no-touch");
          }
        }
      },
      DisableEl: (el, b) => {
        if (el) {
          if (b) {
            el.classList.add("no-touch");
          } else {
            el.classList.remove("no-touch");
          }
        }
      },
      HideUi: (el, b) => {
        let e = document.getElementById(el);
        if (e) {
          if (b) {
            e.classList.remove("visible");
          } else {
            e.classList.add("visible");
          }
        }
      },
      ToggleShow: (el) => {
        let e = document.getElementById(el);
        e.classList.toggle("visible");
      },
      ScrollTo: (elId) => {
        if (elId) {
          const uix = document.getElementById(elId);
          if (uix) {
            uix.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        }
      },
      ScrollToY: (nY, fn) => {
        window.scrollTo({
          top: nY || 0,
          behavior: "smooth",
        });
        if (typeof fn === "function") {
          fn.call();
        }
      },
    },
    Network: {
      FetchGet: (options) => {
        const _default = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };

        fetch(options.url, _default)
          .then((res) => res.json())
          .then((res) => options.then(res))
          .catch((err) => options.catch(err));
      },
      FetchPost: (options) => {
        const _default = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(options.body),
        };

        fetch(options.url, _default)
          .then((res) => res.json())
          .then((res) => options.then(res))
          .catch((err) => options.catch(err));
      },
      FetchGetAuth: (options) => {
        const hrds = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${options.token}`,
        };
        const _default = {
          method: "GET",
          headers: hrds,
        };

        fetch(options.url, _default)
          .then((res) => {
            //console.log(res.status);
            if (res.status !== 200) {
              throw new Error(res.status);
            } else {
              return res.json();
            }
          })
          .then((res) => options.then(res))
          .catch((err) => options.catch(err));
      },
      FetchPostAuth: (options) => {
        const hrds = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${options.token}`,
        };
        const _default = {
          method: "POST",
          headers: hrds,
          body: JSON.stringify(options.body),
        };

        fetch(options.url, _default)
          .then((res) => {
            if (res.status !== 200) {
              throw new Error(res.status);
            } else {
              return res.json();
            }
          })
          .then((res) => options.then(res))
          .catch((err) => {
            //console.log(err);
            options.catch(err);
          });
      },
    },
    Validator: {
      checkEmail: (email) => {
        if (!email) {
          //console.log("email entry is null");
          return;
        }
        //eslint-disable-line
        const newLocal = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,7})+$/;
        const pattern = new RegExp(newLocal);
        return pattern.test(email.trim());
      },
      CheckMinMax: (val, min, max) => {
        const v = val.trim().length >= min && val.trim().length <= max;
        return v;
      },
      CheckAlphaNumeric: (textEntry) => {
        const regexp = /^[a-zA-Z0-9-_\s]+$/;
        return regexp.test(textEntry);
      },
      CheckMatch: (v1, v2) => {
        return v1 === v2;
      },
    },
    Element: {
      qsv: (el) => {
        const q = document.querySelector(el) || document.getElementById(el);
        if (q) {
          return q.value;
        } else {
          return "?";
        }
      },
      qs: (el) => {
        const q = document.querySelector(el) || document.getElementById(el);
        return q;
      },
    },
    FileManager: {
      UploadImage: (options) => {
        var frm_data = new FormData();
        let xhr = new XMLHttpRequest();
        let _options = options;

        _options.PhotoModels.forEach((d, i) => {
          if (d.FileStream instanceof Blob) {
            frm_data.append("FileStream", d.FileStream, d.FileName);
            frm_data.append("FileName", d.FileName);
            frm_data.append("OriginalSize", d.OriginalSize);
            frm_data.append("AdjustedSize", d.AdjustedSize);
          }
        });

        frm_data.append("AlbumId", _options.AlbumId ?? "wall");

        xhr.onerror = function () {
          if (typeof _options.OnError === "function") {
            _options.OnError.call(this, "OFFLINE *** Unable to connect.");
          }
        };

        xhr.onreadystatechange = function () {
          switch (xhr.readyState) {
            case 0: //unsent
              //console.log("readyState : unsent > 0 : " + xhr.status);
              break;
            case 1: //opened
              //console.log("readyState : opened > 1 : " + xhr.status);
              break;
            case 2: //headers received
              //console.log("readyState : headers received 2 : " + xhr.status);
              break;
            case 3: //loading
              //console.log("readyState : loading > 3 : " + xhr.status);
              break;
            case 4: //done
              //console.log("readyState : done > 4 : " + xhr.status);

              if (xhr.status === 0) {
                if (typeof _options.OnError === "function") {
                  _options.OnError.call(
                    this,
                    "OFFLINE *** Unable to communicate with the server."
                  );
                }
              }

              if (xhr.status === 404) {
                if (typeof _options.OnError === "function") {
                  _options.OnError.call(
                    this,
                    "404 NOT FOUND *** Unable to reach the endpoint of the server."
                  );
                }
              }
              break;
          }
        };
        xhr.upload.onprogress = function (e) {
          if (e.lengthComputable) {
            let percent_completed = Math.floor((e.loaded / e.total) * 100);
            if (percent_completed >= 100) {
              if (typeof _options.OnCompleted === "function") {
                _options.OnCompleted.call(this, percent_completed);
              }
            } else {
              if (typeof _options.OnProgress === "function") {
                _options.OnProgress.call(this, percent_completed);
              }
            }
          } else {
            if (typeof _options.OnError === "function") {
              _options.OnError.call(
                this,
                "Unable to compute file size. Please wait while upload completes."
              );
            }
          }
        };

        //meaning done na yung request
        xhr.onload = function () {
          var jresponse = JSON.parse(xhr.response);
          //console.log(jresponse);
          if (jresponse.Succeeded) {
            if (xhr.status === 200) {
              if (typeof _options.Succeeded === "function") {
                _options.Succeeded.call(this, JSON.stringify(jresponse));
              }
            } else if (xhr.status === 401) {
              if (typeof _options.OnError === "function") {
                _options.OnError.call(this, "Unauthorized");
              }
            } else if (xhr.status === 502) {
              if (typeof _options.OnError === "function") {
                _options.OnError.call(this, "Timed Out");
              }
            }
          } else {
            if (typeof _options.OnError === "function") {
              _options.OnError.call(this, JSON.stringify(jresponse));
            }
          }
        };

        //console.log(xhr);
        xhr.open("POST", _options.ApiEndPoint, true);
        xhr.setRequestHeader("Authorization", "Bearer " + _options.AuthToken);
        xhr.send(frm_data);
      },
      UploadMedia: (options) => {
        var frm_data = new FormData();
        let xhr = new XMLHttpRequest();
        let _options = options;

        _options.MediaModels.forEach((d, i) => {
          if (d.FileStream instanceof Blob) {
            frm_data.append("FileStream", d.FileStream, d.FileName);
            frm_data.append("FileName", d.FileName);
            frm_data.append("OriginalSize", d.OriginalSize);
            frm_data.append("AdjustedSize", d.AdjustedSize);
            frm_data.append("MediaType", d.MediaType);
          }
        });

        frm_data.append("AlbumId", _options.AlbumId ?? "wall");

        xhr.onerror = function () {
          if (typeof _options.OnError === "function") {
            _options.OnError.call(this, "*** Unable to upstream.");
          }
        };

        xhr.onreadystatechange = function () {
          switch (xhr.readyState) {
            case 0: //unsent
              //console.log("readyState : unsent > 0 : " + xhr.status);
              break;
            case 1: //opened
              //console.log("readyState : opened > 1 : " + xhr.status);
              break;
            case 2: //headers received
              //console.log("readyState : headers received 2 : " + xhr.status);
              break;
            case 3: //loading
              //console.log("readyState : loading > 3 : " + xhr.status);
              break;
            case 4: //done
              //console.log("readyState : done > 4 : " + xhr.status);

              if (xhr.status === 0) {
                if (typeof _options.OnError === "function") {
                  _options.OnError.call(
                    this,
                    "*** Unable to communicate with the server."
                  );
                }
              }

              if (xhr.status === 404) {
                if (typeof _options.OnError === "function") {
                  _options.OnError.call(
                    this,
                    "404 NOT FOUND *** Unable to reach the endpoint of the server."
                  );
                }
              }
              break;
          }
        };
        xhr.upload.onprogress = function (e) {
          if (e.lengthComputable) {
            let percent_completed = Math.floor((e.loaded / e.total) * 100);
            if (percent_completed >= 100) {
              if (typeof _options.OnCompleted === "function") {
                _options.OnCompleted.call(this, percent_completed);
              }
            } else {
              if (typeof _options.OnProgress === "function") {
                _options.OnProgress.call(this, percent_completed);
              }
            }
          } else {
            if (typeof _options.OnError === "function") {
              _options.OnError.call(
                this,
                "Unable to compute file size. Please wait while upload completes."
              );
            }
          }
        };

        //meaning done na yung request
        xhr.onload = function () {
          var jresponse = JSON.parse(xhr.response);
          //console.log(jresponse);
          if (jresponse.Succeeded) {
            if (xhr.status === 200) {
              if (typeof _options.Succeeded === "function") {
                _options.Succeeded.call(this, JSON.stringify(jresponse));
              }
            } else if (xhr.status === 401) {
              if (typeof _options.OnError === "function") {
                _options.OnError.call(this, "Unauthorized");
              }
            } else if (xhr.status === 502) {
              if (typeof _options.OnError === "function") {
                _options.OnError.call(this, "Timed Out");
              }
            }
          } else {
            if (typeof _options.OnError === "function") {
              _options.OnError.call(this, JSON.stringify(jresponse));
            }
          }
        };

        //console.log(xhr);
        xhr.open("POST", _options.ApiEndPoint, true);
        xhr.setRequestHeader("Authorization", "Bearer " + _options.AuthToken);
        xhr.send(frm_data);
      },
      CheckSize: (f, maxSize) => {
        return Math.floor(f.size / 1024) > maxSize;
      },
      ResizeImage: (imgFile, resolution, quality, fn) => {
        let res = resolution;
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let img = new Image();

        img.onload = function () {
          let width = img.naturalWidth;
          let height = img.naturalHeight;
          let originalSize = { w: width, h: height };

          if (resolution) {
            //get the original dimension of the image
            ctx.drawImage(img, 0, 0);

            let MAX_WIDTH = res.split("x")[0];
            let MAX_HEIGHT = res.split("x")[1];

            //console.log(width, height);

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
          }
          //adjust natin ang quality (down or up, depende sa height or di kaya sa width)
          canvas.width = width;
          canvas.height = height;
          ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              //newImg.src = url;
              const transformed = {
                urlBlob: URL.createObjectURL(blob),
                dataUrl: canvas.toDataURL(
                  "image/jpeg",
                  quality ? quality : 0.9
                ),
                origSize: originalSize,
              };

              if (typeof fn === "function") {
                fn.call(this, transformed);
              }
            },
            "image/jpeg",
            quality ? quality : 0.9
          );
        };

        //load image
        img.src = URL.createObjectURL(imgFile);
      },
      DataURItoBlob: (dataUri) => {
        let byteString, mimestring;

        if (dataUri.split(",")[0].indexOf("base64") !== -1) {
          byteString = atob(dataUri.split(",")[1]);
        } else {
          byteString = decodeURI(dataUri.split(",")[1]);
        }

        mimestring = dataUri.split(",")[0].split(":")[1].split(";")[0];

        let content = new Array();
        for (var i = 0; i < byteString.length; i++) {
          content[i] = byteString.charCodeAt(i);
        }

        return new Blob([new Uint8Array(content)], { type: mimestring });
      },
      GetHash: async function (d, algo) {
        const encoder = new TextEncoder();
        const data = encoder.encode(d);
        const hash = await crypto.subtle.digest("SHA-1", data);

        // convert natin buffer to byte array
        let hashArray = Array.from(new Uint8Array(hash));
        let hashHex = hashArray
          .map((b) => b.toString(16).padStart(2, "0"))
          .join(""); // convert bytes to hex string

        return hashHex;

        //return hash.byteLength; //test
      },
      HashToHexDigit: (str, fn, seed = 0) => {
        let hex1 = 0xdeadbeef ^ seed,
          hex2 = 0x41c6ce57 ^ seed;
        for (let i = 0, chr; i < str.length; i++) {
          chr = str.charCodeAt(i);
          hex1 = Math.imul(hex1 ^ chr, 2654435761);
          hex2 = Math.imul(hex2 ^ chr, 1597334677);
        }
        hex1 =
          Math.imul(hex1 ^ (hex1 >>> 16), 2246822507) ^
          Math.imul(hex2 ^ (hex2 >>> 13), 3266489909);
        hex2 =
          Math.imul(hex2 ^ (hex2 >>> 16), 2246822507) ^
          Math.imul(hex1 ^ (hex1 >>> 13), 3266489909);

        let result = 4294967296 * (2097151 & hex2) + (hex1 >>> 0);
        if (typeof fn === "function") fn.call(this, result);
      },
    },
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return f;
};

export default useCommonUtil;
