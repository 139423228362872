import React, { useState } from "react";
import useCommonUtil from "../../Hooks/useCommonUtil";
import useReactIcons from "../../Hooks/useReactIcons";
import "./ShowMoreText.css";

const ShowMoreText = (props) => {
  const u = useCommonUtil();
  const ico = useReactIcons();

  let text = "",
    clsName = props.children.props.className ? props.children.props.className : "";

  // if (props.children.props.dangerouslySetInnerHTML) {
  //   text = props.children.props.dangerouslySetInnerHTML.__html;
  // } else {
  text = props.children.props.plainText;

  
  //}

  const [shouldCollapse, setShouldCollapse] = useState(text?.length > props.textLength);

  const ShowMoreOrLess = () => {
    setShouldCollapse(!shouldCollapse);
  };

  return (
    <>
      <div className={`show-moretext ${shouldCollapse ? "collapsed" : ""} ${clsName}`}>
        {shouldCollapse ? (
          <>
            <span className="hashtag">
              {u.Data.ParseLinks(text?.substring(0, props.textLength))}
            </span>
            ...
            <span className="more" onClick={ShowMoreOrLess}>
              {" "}
              <span className="font-lg">{ico.ChevronRight}</span> show more
            </span>
          </>
        ) : (
          <>
            <div>{u.Data.ParseLinks(text || "empty")}</div>
            {text?.length > props.textLength ? (
              <span className="more" onClick={ShowMoreOrLess}>
                <span className="font-lg">{ico.ChevronLeft}</span> show less
              </span>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(ShowMoreText);
