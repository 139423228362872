import React, { useEffect, useState } from "react";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import useCommonUtil from "../../Hooks/useCommonUtil";
import "./DateRangePicker.css";

const DateRangePicker = (props) => {
  const g = useGlobalConstants();
  const u = useCommonUtil();
  //console.log(state);

  const [monthsFrom] = useState(g.Months);
  const [monthsTo] = useState(g.Months);
  const [years, setYears] = useState();
  const [dateRange, setDateRange] = useState({
    FromDate: 1,
    FromMonth: 1,
    FromYear: g.Years[1],
    ToDate: 1,
    ToMonth: new Date().getMonth() + 1,
    ToYear: g.Years[1],
    Params: [],
  });

  useEffect(() => {
    let r = [];
    let y = g.Years[0];
    do {
      r.push(y);
      y++;
    } while (y <= g.Years[1]);

    setYears(r);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dateRange) {
      let d;
      let today = new Date();
      let time;
      if (today.getMonth() + 1 === dateRange.ToMonth) {
        time = `${today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()}:${
          today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()
        }:${today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds()}`;
        d = new Date().getDate();
      } else {
        time = "23:59:59";
        d = u.Date.GetLastDateOfMonth(
          `${dateRange.ToMonth < 10 ? "0" : ""}${dateRange.ToMonth}/01/${dateRange.ToYear}`
        );
      }

      const RANGE = {
        //2021-04-18T23:59:59.000000
        From: u.Date.FormatDate(
          new Date(`${dateRange.FromMonth}/${dateRange.FromDate}/${dateRange.FromYear}`),
          "yyyy-MM-DDT00:00:00"
        ),
        To: u.Date.FormatDate(
          new Date(`${dateRange.ToMonth}/${d}/${dateRange.ToYear}`),
          `yyyy-MM-DDT${time}`
        ),
      };

      //console.log(RANGE);
      props.options.SetDateRange(RANGE);
    }

    // eslint-disable-next-line
  }, [dateRange]);

  const DisplayLastDay = () => {
    console.log(u.Date.GetLastDateOfMonth("03/15/2020 13:50:50"));
  };

  const OnChangeDateRange = (e, p) => {
    //console.log(e.target.value);
    let maxDate, month, year;

    switch (p) {
      case "fm":
        month = parseInt(`${e.target.value}`, 10);
        maxDate = u.Date.GetLastDateOfMonth(
          `${month < 10 ? "0" : ""}${month}/01/${dateRange.ToYear}`
        );

        setDateRange({
          ...dateRange,
          FromDate: 1,
          FromMonth: month,
        });
        break;
      case "fy":
        year = parseInt(e.target.value, 10);
        setDateRange({ ...dateRange, FromYear: year, FromDate: 1 });
        break;
      case "tm":
        month = parseInt(e.target.value, 10);
        maxDate = new Date().getDate();
        setDateRange({ ...dateRange, ToMonth: month, ToDate: maxDate });
        break;
      case "ty":
        year = parseInt(e.target.value, 10);
        setDateRange({ ...dateRange, ToYear: year });
        break;
      default:
        break;
    }

    //setDateRange(...dateRange, {  })
  };

  return (
    <>
      <div className="date-range">
        <div className="inline-block">
          <span>
            <select onChange={(e) => OnChangeDateRange(e, "fm")} value={dateRange.FromMonth}>
              {monthsFrom.map((m) => (
                <option key={m.D} value={m.M}>
                  {m.D}
                </option>
              ))}
            </select>
            <select onChange={(e) => OnChangeDateRange(e, "fy")} value={dateRange.FromYear}>
              {years?.map((y) => (
                <option key={y}>{y}</option>
              ))}
            </select>
          </span>
        </div>
        <span className="lr-pad-sm" onClick={DisplayLastDay}>
          TO
        </span>
        <div className="inline-block">
          <span>
            <select onChange={(e) => OnChangeDateRange(e, "tm")} value={dateRange.ToMonth}>
              {monthsTo.map((m) => (
                <option key={m.D} value={m.M}>
                  {m.D}
                </option>
              ))}
            </select>
            <select onChange={(e) => OnChangeDateRange(e, "ty")} value={dateRange.ToYear}>
              {years?.map((y) => (
                <option key={y}>{y}</option>
              ))}
            </select>
          </span>
        </div>
      </div>
    </>
  );
};

export default React.memo(DateRangePicker);
