import { createContext } from "react";

export const InitialAvatarState = {
  AvatarLink: "",
  ChangedFromPage: "",
};

export const AvatarContext = createContext();

export const AvatarReducer = (state, action) => {
  switch (action.method) {
    case "UPDATE":
      return {
        AvatarLink: action.AvatarLink,
        ChangedFromPage: action.ChangedFromPage,
      };
    default:
      return state;
  }
};
