import React, { useEffect, useState } from "react";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import useCommonUtil from "../../Hooks/useCommonUtil";
import "./DatePicker.css";

const DatePicker = (props) => {
  const g = useGlobalConstants();
  const u = useCommonUtil();

  const [days, setDays] = useState();
  const [months] = useState(g.Months);
  const [years, setYears] = useState();

  const [myDate, setMyDate] = useState({
    Day: 1,
    Month: 1,
    Year: g.Years[1] - 12,
    Params: [],
  });

  useEffect(() => {
    //days
    let d = [];
    for (let i = 1; i <= 31; i++) {
      d.push(i < 10 ? `0${i}` : i);
    }

    setDays(d);

    //years

    let r = [];
    let y = g.Years[1];
    do {
      r.push(y);
      y--;
    } while (y >= 1900);

    setYears(r);
    // eslint-disable-next-line
  }, []);

  const ReseedDay = (maxDay) => {
    let d = [];
    for (let i = 1; i <= maxDay; i++) {
      d.push(i < 10 ? `0${i}` : i);
    }

    setDays(d);
  };

  useEffect(() => {
    if (myDate) {
      let chosenDate = u.Date.FormatDate(
        new Date(`${myDate.Month}/${myDate.Day}/${myDate.Year}`),
        "yyyy-MM-DDT00:00:00"
      );
      props.options.SetDate(chosenDate);
    }
    // eslint-disable-next-line
  }, [myDate]);

  //detect natin ang leap year
  useEffect(() => {
    let maxDay = u.Date.GetLastDateOfMonth(`${myDate.Month}/01/${myDate.Year}`);
    //console.log(maxDay);
    ReseedDay(maxDay);
    // eslint-disable-next-line
  }, [myDate.Month, myDate.Year]);

  const OnChangeDateRange = (e, p) => {
    //console.log(e.target.value);
    let month, year;

    switch (p) {
      case "m":
        month = parseInt(`${e.target.value}`, 10);
        setMyDate({
          ...myDate,
          Day: 1,
          Month: month,
        });
        break;
      case "d":
        //day = parseInt(e.target.value, 10);
        setMyDate({ ...myDate, Day: e.target.value });
        break;
      case "y":
        year = parseInt(e.target.value, 10);
        setMyDate({ ...myDate, Year: year });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="date-picker">
        <div className="inline-block">
          <span>
            <select onChange={(e) => OnChangeDateRange(e, "y")} value={myDate.Year}>
              {years?.map((y) => (
                <option key={y}>{y}</option>
              ))}
            </select>
            <select onChange={(e) => OnChangeDateRange(e, "m")} value={myDate.Month}>
              {months.map((m) => (
                <option key={m.D} value={m.M}>
                  {m.D}
                </option>
              ))}
            </select>
            <select onChange={(e) => OnChangeDateRange(e, "d")} value={myDate.Day}>
              {days?.map((d) => (
                <option key={d}>{d}</option>
              ))}
            </select>
          </span>
        </div>
      </div>
    </>
  );
};

export default React.memo(DatePicker);
