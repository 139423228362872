import React, { useContext, useEffect, useState } from "react";
import { SocketContext } from "../Globals/RealtimeComm/WebSocketReducer";
import useAccountManager from "../Globals/useAccountManager";
import useDataModels from "../Globals/useDataModels";
import useFetchErrorHandler from "../Globals/useFetchErrorHandler";
import useGlobalConstants from "../Globals/useGlobalConstants";
import useCommonUtil from "../Hooks/useCommonUtil";
import usePostManager from "../Hooks/usePostManager";
import useReactIcons from "../Hooks/useReactIcons";

import "./SocialBar.css";
import DialogBox from "./Widgets/DialogBox";

const SocialBar = () => {
  //#region :: Declarations
  const u = useCommonUtil();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const dm = useDataModels();
  const ico = useReactIcons();
  const fe = useFetchErrorHandler();

  const postMgr = usePostManager();
  const [dialog, SetDialog] = useState();

  const { HubDispatch } = useContext(SocketContext);
  const hmethod = g.Hub.ReducerMethods;

  const [T, SetTargetUser] = useState();

  const [myAccount, setMyAccount] = useState(am.GetMyAccount());

  const [connectPayload, setConnectPayload] = useState();
  const [isConnected, setIsConnected] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [connectReady, setConnectReady] = useState(false);

  useEffect(() => {
    am.Identity.GetTargetUser((tuser) => {
      SetTargetUser(tuser);
    });
    // eslint-disable-next-line
  }, []);

  const OnConnectToUser = (e) => {
    let li = e.currentTarget;
    u.Ui.DisableEl(li, true);
    SetDialog({
      mode: "p",
      title: "Please wait...",
      message: "Adding friend connection...",
    });

    fetch(`${g.Api.ApiRoute}/user/ConnectToUserAsync`, {
      method: g.Fetch.POST,
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(connectPayload),
    })
      .then((r) => {
        //console.log(r);
        if (r.ok) {
          return r.json();
        } else {
          fe.CatchResponse(r, (m) => {
            SetDialog({
              mode: "e",
              title: "Oops",
              message: m,
            });
          });
          return null;
        }
      })
      .then((r) => {
        if (!r) {
          SetDialog({
            mode: "e",
            title: "Oops",
            message: "Communication Error.",
          });
        } else {
          if (r.Succeeded) {
            setIsConnected(true);
            SetDialog({
              mode: "s",
              title: "Success",
              message: "Friend connection added.",
            });
            var d = am.GetMyAccount();
            if (d) {
              d.ConnectionsCount += 1;
              am.StoreMyAccount(d);
              SetTargetUser({
                ...T,
                ConnectionsCount: (T.ConnectionsCount += 1),
              });
            }
          } else {
            SetDialog({
              mode: "e",
              title: "Unable to add friend connection",
              message: r.Message,
            });
          }
        }

        u.Ui.DisableEl(li);
        console.log(r);
      })
      .catch((err) => {
        fe.CatchError(err, (m) => {
          SetDialog({
            mode: "e",
            title: "Oops",
            message: m,
          });
        });
      });
  };

  const OnDisconnectFromUser = (e) => {
    let el = e.currentTarget;

    SetDialog({
      mode: "w",
      title: "Confirm Disconnection",
      message: "Are you sure you want to disconnected from this user?",
      buttons: [
        {
          id: "d1",
          caption: "Ok",
          OnClick: () => {
            console.log("Disconnecting...");
            setIsDisconnecting(true);

            var p = new URLSearchParams({
              targetUser: T.UserName,
              appUserId: myAccount.UserName,
            });

            fetch(`${g.Api.ApiRoute}/user/DisconnectFromUser/?${p}`, {
              headers: am.Identity.GetAuthHeader(),
            })
              .then((r) => {
                if (r.ok) {
                  return r.json();
                } else {
                  return null;
                }
              })
              .then((r) => {
                console.log(r);
                if (r.Succeeded) {
                  u.Ui.DisableEl(el, true);
                  SetTargetUser({
                    ...T,
                    ConnectionsCount: (T.ConnectionsCount -= 1),
                  });

                  setMyAccount({
                    ...myAccount,
                    ConnectionsCount: (myAccount.ConnectionsCount -= 1),
                  });

                  am.GetMyAccount((d) => {
                    d.ConnectionsCount -= 1;
                    am.StoreMyAccount(d);
                  });

                  setIsConnected(false);
                }

                setConnectReady(true);
                setIsDisconnecting(false);
              });
          },
        },
        {
          id: "d2",
          caption: "Cancel",
        },
      ],
    });
  };

  const PromoteUserAsync = (role, b) => {
    SetDialog({
      mode: "p",
      title: `${b ? "GRANTING" : "DEMOTING FROM"} ROLE ${role}...`,
      message: `Applying role changes. Please wait...`,
    });

    const payload = {
      UserName: T.UserName,
      StringValue: role,
      IntValue: b ? 1 : 0,
    };

    console.log(payload);

    fetch(`${g.Api.ApiRoute}/adminapi/PromoteUserAsync`, {
      method: g.Fetch.POST,
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(payload),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          SetDialog({
            mode: "e",
            title: "ERROR",
            message: "A server error has occure. Please try again later.",
          });
          return null;
        }
      })
      .then((r) => {
        if (r) {
          if (r.Succeeded) {
            SetDialog({
              mode: "s",
              title: `ROLE ${b ? "GRANTED" : "DEMOTED"}`,
              message: `The user has been ${b ? "granted the role of" : "demoted from the role"} ${role}.`,
            });
          }
        }
      });
  };

  const OnGrantRole = (role, b) => {
    let r = role === "ADMIN" ? "ADMINISTRATOR" : role === g.Roles.MOD ? "MODERATOR" : "DEVELOPER";

    SetDialog({
      mode: "w",
      title: `${b ? "PROMOTE" : "REVOKE"} ${b ? "to" : "from"} ${r} ROLE`,
      message:
        `Are you sure you need to ${b ? "promote" : "demote"} this user ${
          b ? "to" : "from"
        } ${r} role? <hr class='d'/> NOTE: This requires the user to log off and log-in again to HIVE.`,
      buttons: [
        { id: "cancel", caption: "Cancel" },
        {
          id: "go",
          caption: "OK",
          OnClick: () => {
            let _roles = T.Roles;

            if (b) {
              _roles.push(role);
              SetTargetUser({ ...T, Roles: _roles });
            } else {
              const ix = _roles.indexOf(role);
              if (ix >= 0) {
                _roles.splice(ix, 1);
                SetTargetUser({ ...T, Roles: _roles });
              }
            }

            PromoteUserAsync(role, b);
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (isDisconnecting) return;

    if (T) {
      setConnectPayload({
        UserName: myAccount.UserName,
        ConnectionAppUserId: T.UserName,
        ConnectedDate: new Date(),
      });

      postMgr.IsConnectedToUser(myAccount.UserName, T.UserName, (b) => {
        setIsConnected(b);
        setConnectReady(true);
      });

      //console.log(TUSER.TargetUser);
    }
    // eslint-disable-next-line
  }, [T]);

  const OnSendMessage = () => {
    SetDialog({
      mode: "text",
      title: "SEND MESSAGE",
      message: "Type your message below",
      buttons: [
        { id: "cancel", caption: "Cancel" },
        {
          id: "ok",
          caption: "Send",
          textVal: "Message",
          OnClick: (v) => {
            let msgModel = dm.HubMessageDto;
            msgModel.MessageId = 0;
            msgModel.HubGroupId = 0;
            msgModel.UserName = myAccount.UserName;            
            msgModel.ToUserName = T.UserName;            
            msgModel.MessageBody = v;            

            HubDispatch({
              method: hmethod.INVOKE,
              payload: {
                ServerMethod: "ServerSendDirectMessage",
                Args: [msgModel],
              },
              callBack: () => {
                //console.log(x);
              },
            });
          },
        },
      ],
    });

    return;
  };

  const OnBanUser = () => {
    //console.log("banning user...");
    SetDialog({
      mode: "select-text",
      title: "BAN USER",
      maxLength : 200,
      select: {
        value: 1,
        options: [
          { id: 1, value: 1, text: "1 day" },
          { id: 2, value: 2, text: "2 days" },
          { id: 3, value: 3, text: "3 days" },
          { id: 4, value: 4, text: "4 days" },
          { id: 5, value: 5, text: "5 days" },
          { id: 6, value: 6, text: "1 week" },
          { id: 7, value: 7, text: "2 weeks" },
          { id: 8, value: 8, text: "3 weeks" },
          { id: 9, value: 9, text: "1 month" },
          { id: 10, value: 10, text: "2 months" },
          { id: 11, value: 11, text: "3 months" },
          { id: 12, value: 12, text: "6 months" },
          { id: 13, value: 13, text: "1 year" },
          { id: 14, value: 14, text: "Forever" },
        ],
      },
      message: "Please enter below the reason for banning. MUST BE 10 CHARACTERS OR MORE.",
      buttons: [
        {
          id: "d1",
          caption: "Ok",
          OnClick: (reason) => {
            console.log("banning...", T.UserName);
            var payload = {
              AccountBandId: 0,
              TargetUserName: T.UserName,
              BannedByUserName: myAccount.UserName,
              BanUntilKey: reason.selectValue,              
              BanReason: reason.text,
            };

            if (reason.text.trim().length < 10) {
              SetDialog({ mode: "e", message: "Ban reason is required." });
            } else {
              SetDialog({ mode: "p", message: "Banning user. Please wait..." });
              fetch(`${g.Api.ApiRoute}/adminapi/BanUserAsync/`, {
                method: "POST",
                headers: am.Identity.GetAuthHeader(),
                body: JSON.stringify(payload),
              })
                .then((r) => {
                  if (r.ok) {
                    return r.json();
                  }
                })
                .then((j) => {
                  if (!j) {
                    SetDialog({ mode: "e", message: "Ooops. Something went wrong." });
                  } else {
                    if (j.Succeeded) {
                      SetDialog({ mode: "s", message: `User has been successfully banned.<hr/>Until ${u.Date.FormatDate(j.ModelData.DateUntil, "Ddd MM/DD/YYYY hh:mm:ss A")}` });
                      //console.log(j);

                      HubDispatch({
                        method: hmethod.INVOKE,
                        payload: {
                          ServerMethod: "ServerNotifyUserBan",
                          Args: [j.ModelData],
                        },
                        callBack: () => {
                          let banRecords = T.AccountBans || [];
                          banRecords.push(j.ModelData);                          
                          SetTargetUser({ ...T, AccountBans: banRecords });
                        },
                      });
                    } else {
                      SetDialog({ mode: "w", title: "Not Possible", message: j.Message });
                    }
                  }
                });
            }
          },
        },
        {
          id: "d2",
          caption: "Cancel",
        },
      ],
    });
  };
  //#endregion

  //console.log("SocialBar :: Rendered");

  return (
    <>
      {T ? (
        <>
          {myAccount.UserName !== T.UserName ? (
            <div className="all-caps social-bar tb-pad-lg">
              <div className="">
                <ul className="color-theme">
                  {isConnected ? (
                    <li
                      title="DISCONNECT"
                      id="liDisconnect"
                      onClick={(e) => OnDisconnectFromUser(e)}
                      className={`cntd selected ${connectReady ? "" : "no-touch"}`}
                    >
                      <span>{ico.LinkVs}</span>
                    </li>
                  ) : (
                    <li
                      title="CONNECT"
                      id="liConnect"
                      onClick={(e) => OnConnectToUser(e)}
                      className={`cntd ${connectReady ? "" : "no-touch"}`}
                    >
                      {ico.Unlinked}
                    </li>
                  )}
                  {isConnected ? (
                    <>
                      <li className="msg" onClick={OnSendMessage} title="SEND MESSAGE">
                        <span>{ico.Envelope}</span>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {!T.Roles.includes(g.Roles.DEV) ? (
                    myAccount.Roles.includes(g.Roles.ADMIN) ? (
                      !T.Roles.includes(g.Roles.ADMIN) ? (
                        <li className="admin" title="PROMOTE TO ADMIN" onClick={() => OnGrantRole("ADMIN", 1)}>
                          {ico.Shield2l}
                        </li>
                      ) : (
                        <li
                          className="selected admin"
                          title="REMOVE ADMIN ROLE"
                          onClick={() => OnGrantRole("ADMIN", 0)}
                        >
                          {ico.Shield2l}
                        </li>
                      )
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}

                  {!T.Roles.includes(g.Roles.DEV) ? (
                    myAccount.Roles.includes(g.Roles.ADMIN) ? (
                      !T.Roles.includes(g.Roles.MOD) && !T.Roles.includes(g.Roles.DEV) ? (
                        <li title="PROMOTE TO MODERATOR" onClick={() => OnGrantRole("MOD", 1)}>
                          <span>{ico.Audio}</span>
                        </li>
                      ) : (
                        <li className="selected" title="REMOVE MODERATOR ROLE" onClick={() => OnGrantRole("MOD", 0)}>
                          <span>{ico.Audio}</span>
                        </li>
                      )
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}

                  {(myAccount.Roles.includes(g.Roles.MOD) || myAccount.Roles.includes(g.Roles.ADMIN)) &&
                  !T.Roles.includes(g.Roles.ADMIN) &&
                  !T.Roles.includes(g.Roles.DEV) ? (
                    <li
                      onClick={OnBanUser}
                      title={`${
                        T.AccountBans.length
                          ? `BANNED UNTIL: ${u.Date.FormatDate(
                              T.AccountBans[0]?.DateUntil,
                              "Ddd MM/DD/YYYY hh:mm:ss A"
                            )}`
                          : "BAN USER"
                      }`}
                      className={`${T.AccountBans.length ? "selected" : ""}`}
                    >
                      <div>{ico.Stop}</div>
                    </li>
                  ) : (
                    <></>
                  )}

                  {myAccount.Roles.includes(g.Roles.DEV) ? (
                    !T.Roles.includes(g.Roles.ADMIN) &&
                    !T.Roles.includes(g.Roles.MOD) &&
                    !T.Roles.includes(g.Roles.DEV) ? (
                      !T.Roles.includes(g.Roles.DEV) ? (
                        <li onClick={() => OnGrantRole(g.Roles.DEV, 1)} title="SET AS DEVELOPER">
                          <span>{ico.Navigator}</span>
                        </li>
                      ) : (
                        <li
                          className="selected"
                          onClick={() => OnGrantRole(g.Roles.DEV, 0)}
                          title="DEMOTE FROM DEVELOPER"
                        >
                          <span>{ico.Navigator}</span>
                        </li>
                      )
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      <DialogBox Dialog={dialog} key={"SocialBar"} />
    </>
  );
};

export default React.memo(SocialBar);
