const useFetchErrorHandler = () => {
  const f = {
    CatchResponse: (er, fn) => {
      let r = er.r;
      let resp = r || er;
      let mode = er.mode ? er.mode : "s"; //silent without dialog

      console.log(resp);

      switch (resp.status) {
        case 401:
          if (mode !== "s") {
          }
          if (typeof fn === "function") {
            fn.call(this, "Access token might have expired. Please relogin.");
          }
          break;
        case 404:
          if (typeof fn === "function") {
            fn.call(
              this,
              "Your credentials lack the rights to access this resource."
            );
          }
          break;
        case 500:
          break;
        default:
          break;
      }
    },
    CatchError: (err, fn) => {
      if (err) {
        if (err.stack) {
          if (err.stack.toLowerCase().includes("failed to fetch"))
            if (typeof fn === "function") {
              fn.call(
                this,
                "Unable to communicate with the HIVE Web API Server."
              );
            }
        } else {
          fn.call(this, "An unknow error has occured or you are disconnected.");
        }
      }
    },
  };

  return f;
};

export default useFetchErrorHandler;
