import React, { useLayoutEffect } from "react";
import { useEffect } from "react";
import { useState } from "react";

import PhotoViewer from "../../Components/PhotoViewer";
import DialogBox from "../../Components/Widgets/DialogBox";
import InlineMessageBar from "../../Components/Widgets/InlineMessageBar";
import useAccountManager from "../../Globals/useAccountManager";
import useFetchErrorHandler from "../../Globals/useFetchErrorHandler";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import useCommonUtil from "../../Hooks/useCommonUtil";
import useReactIcons from "../../Hooks/useReactIcons";
import "./Media.css";

const PhotosVideos = () => {
  const u = useCommonUtil();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const fe = useFetchErrorHandler();
  const ico = useReactIcons();

  const [T, setTargetUser] = useState();

  //const [loading, setLoading] = useState({ isLoading: true });
  const [albumList, setAlbumList] = useState([]);
  const [tmpAlbums, setTmpAlbums] = useState();
  const [msg1, setMsg1] = useState({ message: "LOADING...", mode: "p" });
  const [createAlbum, setCreatAlbum] = useState(false);
  const [deleteAlbum, setDeleteAlbum] = useState(false);
  const [albumId, setAlbumId] = useState();
  const [newAlbuName, setNewAlbumName] = useState("");
  const [myAccount] = useState(am.GetMyAccount());

  const [dialog, setDialog] = useState();
  const [ready, setReady] = useState(false);

  useLayoutEffect(() => {
    am.ValidateAccount(() => {
      setReady(true);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    am.Identity.GetTargetUser((usr) => {
      setTargetUser(usr);
    });
    // eslint-disable-next-line
  }, []);

  //[]
  useEffect(() => {
    const dur = g.Timing.Message;
    const _aborter = new AbortController();

    if (T && ready) {
      let p = new URLSearchParams({ appUserId: T.UserName });

      fetch(`${g.Api.ApiRoute}/mediaapi/GetAlbumsAsync/?${p}`, {
        aborter: _aborter,
        headers: am.Identity.GetAuthHeader(),
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            fe.CatchResponse(r, (m) => {
              setMsg1({ mode: "w", message: m });
            });
            return null;
          }
        })
        .then((r) => {
          if (r) {
            if (r.Succeeded) {
              console.log(r);
              setAlbumList(r.ModelData);
              setTmpAlbums(r.ModelData);
            }
            setMsg1({ ...msg1, tl: dur });
          }
        })
        .catch((ex) => {
          fe.CatchError(ex, (m) => {
            setMsg1({ mode: "w", message: m });
          });
        });

      //destroy timer in case navigation occured while time is running
      return () => {
        _aborter.abort();
      };
    }
    // eslint-disable-next-line
  }, [T]);

  //[createAlbum]
  useEffect(() => {
    const _aborter = new AbortController();

    if (createAlbum) {
      let ex = albumList.filter((a) => a.AlbumName === newAlbuName);

      if (ex.length) {
        setDialog({
          mode: "e",
          title: "Woops!",
          message: "Album name already exists.",
          buttons: [
            {
              id: "d1",
              caption: "Ok",
            },
          ],
        });
        setCreatAlbum(false);
        return;
      }

      setDialog({
        mode: "p",
        title: "CREATING ALBUM",
        message: "Please wait...",
      });

      const p = {
        UserName: myAccount.UserName,
        Value: newAlbuName,
      };
      console.log(p);
      fetch(`${g.Api.ApiRoute}/MediaApi/CreateAlbumAsync`, {
        method: g.Fetch.POST,
        signal: _aborter.signal,
        headers: am.Identity.GetAuthHeader(),
        body: JSON.stringify(p),
      })
        .then((r) => {
          setCreatAlbum(false);
          if (r.ok) {
            return r.json();
          }
        })
        .then((r) => {
          if (r.Succeeded) {
            setAlbumList([r.ModelData, ...albumList]);
            setDialog({ mode: "cl" });
          } else {
            setDialog({
              mode: "e",
              title: "Uh-oh!",
              message: r.Message,
              buttons: [
                {
                  id: "d1",
                  caption: "Ok",
                },
              ],
            });
          }

          setCreatAlbum(false);
          console.log(r);
        });
    }

    // eslint-disable-next-line
  }, [createAlbum]);

  //[deleteAlbum]
  useEffect(() => {
    if (deleteAlbum) {
      setDialog({
        mode: "p",
        title: "DELETING ALBUM",
        message: "Please wait...",
      });

      let f = albumList.filter((a) => a.AlbumId !== albumId);
      let par = {
        UserName: myAccount.UserName,
        IntValue: albumId,
      };

      fetch(`${g.Api.ApiRoute}/MediaApi/RemoveAlbumAsync`, {
        method: g.Fetch.POST,
        headers: am.Identity.GetAuthHeader(),
        body: JSON.stringify(par),
      })
        .then((r) => {
          if (r.ok) {
            setDeleteAlbum(false);
            return r.json();
          }
          return null;
        })
        .then((r) => {
          console.log(r);
          if (r.Succeeded) {
            setAlbumList(f);
            setTmpAlbums(f);
            setDeleteAlbum(false);
            setDialog({ mode: "cl" });
          } else {
            setDialog({
              mode: "w",
              title: "ERROR",
              message: r.Message,
              buttons: [
                {
                  id: "d1",
                  caption: "Ok",
                },
              ],
            });
          }
        });
    }
    // eslint-disable-next-line
  }, [deleteAlbum]);

  const OnCreateAlbum = () => {
    setDialog({
      mode: "in",
      inputVal: "",
      maxLength: 50,
      title: "NEW ALBUM",
      message: "Type the name of the new album. e.g.: Christmas Vacation",
      buttons: [
        {
          id: "d1",
          caption: "Ok",
          OnClick: (albumName) => {
            if (!albumName) {
              setDialog({
                mode: 'e',
                title: "INVALID ENTRY",
                message: "Album name must be 2 characters or more, not exceeding 50.",
              });
              return false;
            }
            //if (albumName.length <= 1) return false;

            if (!u.Validator.CheckAlphaNumeric(albumName)) {
              //console.log("Album must contain only letters, space and numbers");
              setMsg1({
                message: "Album name must contain only letters, spaces and numbers",
                mode: "w",
              });

              setDialog({
                mode: 'e',
                title: "INVALID ENTRY",
                message: "Album name must contain only letters, spaces and numbers.",
              });

              return false;
            }

            if (albumName.includes("-") || albumName.includes("_") || albumName.includes("—")) {
              //console.log("Album must contain only letters, space and numbers");
              setMsg1({
                message: "Album name should NOT contain hyphens ( -, — ) or underscores ( _ )",
                mode: "w",
              });

              setDialog({
                mode: 'e',
                title: "INVALID ENTRY",
                message: "Album name should NOT contain hyphens (-, —) or underscores ( _ )",
              });

              return false;
            }

            if (!u.Validator.CheckMinMax(albumName, 2, 50)) {
              setDialog({
                mode: 'e',
                title: "INVALID ENTRY",
                message: "Album must be between 2 and 50 characters only.",
              });

              setMsg1({
                message: "Album must be between 2 and 50 characters only.",
                mode: "w",
              });

              return false;
            }

            setNewAlbumName(albumName);
            setCreatAlbum(true);
          },
        },
        {
          id: "d2",
          caption: "Cancel",
        },
      ],
    });
  };

  const OnSearchAlbum = (e) => {
    //console.log(e.currentTarget.value);
    let key = e.currentTarget.value;
    if (key.length >= 2) {
      let t = albumList.filter((a) => a.AlbumName.toLowerCase().includes(key.toLowerCase()));
      setAlbumList(t);
    } else {
      setAlbumList(tmpAlbums);
    }
  };

  const OnButtonCommand = (mode, id, extraVal) => {
    console.log(mode, id);
    switch (mode) {
      case "delete":
        setAlbumId(id);
        setDialog({
          mode: "w",
          title: "Confirm Delete",
          message: "This will delete all the photos inside the album!",
          buttons: [
            {
              id: "d1",
              caption: "Ok",
              OnClick: () => {
                setDeleteAlbum(true);
              },
            },
            {
              id: "d2",
              caption: "Cancel",
            },
          ],
        });
        break;
      case "rename":
        setDialog({
          mode: "in",
          inputVal: extraVal,
          title: "RENAME ALBUM",
          message: "Enter a different name below. Same name will not post an update.",
          buttons: [
            {
              id: "d1",
              caption: "Ok",
              OnClick: (newVal) => {
                if (!newVal) return false;
                if (newVal.length <= 1) return false;

                let alb = albumList.find((a) => a.AlbumId === id);
                if (alb.AlbumName.toLowerCase() === newVal.toLowerCase()) {
                  return false;
                }
                if (newVal.trim().length > 50) {
                  return false;
                }

                setDialog({
                  mode: "p",
                  title: "RENAME ALBUM",
                  message: "Please wait...",
                });

                let payload = {
                  UserName: am.GetMyUserName(),
                  IntValue: id,
                  StringValue: newVal,
                };
                fetch(`${g.Api.ApiRoute}/mediaapi/RenameAlbumAsync`, {
                  method: g.Fetch.POST,
                  headers: am.Identity.GetAuthHeader(),
                  body: JSON.stringify(payload),
                })
                  .then((r) => {
                    return r.ok ? r.json() : null;
                  })
                  .then((r) => {
                    console.log(r);
                    if (r.Succeeded) {
                      //alb = albums.find((a) => a.AlbumId == id);
                      if (alb) {
                        alb.AlbumName = newVal;
                        let ix = albumList.findIndex((a) => a.AlbumId === id);
                        let tmp = JSON.parse(JSON.stringify(albumList));
                        tmp.splice(ix, 1, alb);
                        setAlbumList(tmp);

                        setDialog({
                          mode: "cl",
                        });
                      }
                    } else {
                      setDialog({
                        mode: "e",
                        title: "RENAME ERROR",
                        message: r.Message,
                      });
                    }
                  });
              },
            },
            {
              id: "d2",
              caption: "Cancel",
            },
          ],
        });
        break;
      default:
        break;
    }
  };

  /* #region  :: View Album */
  const [selectedAlbum, setSelectedAlbum] = useState();
  const [coverMode, setCoverMode] = useState(false);

  const OnAlbumOpen = (alb) => {
    u.Ui.ScrollToY(0);
    setSelectedAlbum(alb);
    //console.log(alb);
  };

  //[selectedAlbum]
  useEffect(() => {
    if (selectedAlbum && coverMode) {
      console.log("Album details changed", selectedAlbum);

      var p = {
        IntValue: selectedAlbum.AlbumId,
        StringValue: selectedAlbum.AlbumCover,
      };
      fetch(`${g.Api.ApiRoute}/MediaApi/ChangeAlbumCoverAsync`, {
        method: g.Fetch.POST,
        headers: am.Identity.GetAuthHeader(),
        body: JSON.stringify(p),
      })
        .then((r) => {
          return r.ok ? r.json() : null;
        })
        .then((r) => {
          if (r.Succeeded) {
            let aidx = albumList.findIndex((a) => a.AlbumId === selectedAlbum.AlbumId);
            if (aidx >= 0) {
              //found it
              albumList.splice(aidx, 1, selectedAlbum);
            }
          } else {
            setDialog({
              mode: "e",
              title: "Cover Cover Error",
              message: r.Message,
            });
          }

          setCoverMode(false);
        });
    }
    // eslint-disable-next-line
  }, [selectedAlbum]);
  /* #endregion */

  return (
    <>
      {T ? (
        <div className="photo-album">
          <PhotoViewer
            props={{
              album: selectedAlbum,
              resetCallback: () => {
                setSelectedAlbum(null);
              },
              changeCoverCallback: (imgUrl) => {
                setCoverMode(true);
                setSelectedAlbum({ ...selectedAlbum, AlbumCover: imgUrl });
              },
            }}
          />
          <div>
            <InlineMessageBar props={{ options: msg1 }} />
          </div>
          {/* <h2>Media Albums</h2> */}
          <div className="flex v-center">
            <div className="one-half">
              <input type="text" placeholder="Search" onChange={(e) => OnSearchAlbum(e)} />
            </div>
            {am.Identity.IsMe() ? (
              <div className="a-right one-half">
                {/* <input type="button" value="Add Photo Album" onClick={OnCreateAlbum} /> */}

                <button className="pa" onClick={OnCreateAlbum}>
                  <span className="font-xxl ix">{ico.PhotoAlbum}</span>
                  <span className="l-pad-sm cap">Add Photo Album</span>
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="tb-pad-sm">
            <hr />
          </div>
          <div className="flex wrap justify font-sm">
            {albumList.map((album) => (
              <div className="photo-album" key={`album_${album.AlbumId}`}>
                <div
                  className="thumb"
                  onClick={() => OnAlbumOpen(album)}
                  style={{
                    backgroundImage: album.IsWallMedia
                      ? album.AlbumCover
                        ? `url(${g.Api.CdnRoute}/${T.UserName}/albums/wall/${album.AlbumName}/thumbs/t-${album.AlbumCover}.jpg)`
                        : ""
                      : `url(${g.Api.CdnRoute}/${T.UserName}/albums/${u.Data.ParseSlug(
                          album.AlbumName,
                          true
                        )}/thumbs/t-${album.AlbumCover}.jpg)`,
                    height: "150px",
                  }}
                >
                  {!album.AlbumCover ? (
                    <div className="pad-md">
                      <span className="font-xl r-pad-lg">{ico.Image}</span>
                      {am.Identity.IsMe() ? (
                        <span>Click here to add photos and set album cover.</span>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {am.Identity.IsMe() ? (
                  <div>
                    <div className="a-right pad-sm crud">
                      <span
                        title="Remove"
                        className="icon font-xl"
                        onClick={() => OnButtonCommand("delete", album.AlbumId)}
                      >
                        {ico.Bin}
                      </span>

                      {album.IsWallMedia === 0 ? (
                        <span
                          title="Rename"
                          className="icon font-xl"
                          onClick={() => OnButtonCommand("rename", album.AlbumId, album.AlbumName)}
                        >
                          {ico.Rename}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <hr />
                <div className="pad-sm">
                  <div className="font-bold">
                    {album.IsWallMedia ? "Wall Media" : album.AlbumName}
                  </div>
                  <div className="font-smaller">
                    {u.Date.FormatDate(album.DateCreated, "Ddd MMM-DD-YYYY")}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {albumList.length >= 3 ? (
            <div className="a-right r-pad-sm">
              <button className="pa" onClick={OnCreateAlbum}>
                <span className="font-xxl ix">{ico.PhotoAlbum}</span>
                <span className="l-pad-sm cap">Add Photo Album</span>
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <></>
      )}

      {/* <PagingControl /> */}
      <DialogBox Dialog={dialog} key={"UserWall"} />
    </>
  );
};

export default PhotosVideos;
