import React, { useEffect, useMemo, useReducer, useState } from "react";
import { Route, Switch } from "react-router-dom";
import {
  EducJobTriggerContext,
  SelfHashTriggerContext,
  TriggerNavToUserContext,
} from "./AppContexts";
import { IconContext } from "react-icons";

import ProfileHeader from "./Components/ProfileHeader";
import HeroBanner from "./Components/HeroBanner";
import Connections from "./MainComponents/Connections";
import ContentBlock from "./MainComponents/ContentBlock/ContentBlock";
import AppLayout from "./MainComponents/Masters/AppLayout";
import NotFound from "./MainComponents/NotFound";
import Messages from "./MainComponents/Messages";
import UserWall from "./MainComponents/ContentBlock/UserWall";
import Feeds from "./MainComponents/ContentBlock/Feeds";
import Media from "./MainComponents/ContentBlock/Media";
import AboutMe from "./MainComponents/ContentBlock/AboutMe";
import Login from "./MainComponents/Login";
import LoginLayout from "./MainComponents/Masters/LoginLayout";
import Register from "./MainComponents/Register";
import MyAccountLayout from "./MainComponents/Masters/MyAccountLayout";

import AccountHome from "./MainComponents/MyAccount/AccountHome";
import BasicInfo from "./MainComponents/MyAccount/BasicInfo";
import MyCaseReports from "./MainComponents/MyAccount/MyCaseReports";
import AddressLocation from "./MainComponents/MyAccount/AddressLocation";
import SecuritySettings from "./MainComponents/MyAccount/SecuritySettings";
import UserNotifications from "./Globals/RealtimeComm/UserNotifications";
import { useLocation } from "react-router-dom";

import {
  hubConnection,
  signalReducer,
  SocketContext,
} from "./Globals/RealtimeComm/WebSocketReducer";
import MyEducations from "./MainComponents/MyAccount/MyEducations";
import {
  AvatarContext,
  AvatarReducer,
  InitialAvatarState,
} from "./Globals/AvatarNotifierReducer";
import JobMgt from "./MainComponents/MyAccount/Admin/JobMgt";
import OtherAdminTasks from "./MainComponents/MyAccount/Admin/OtherAdminTasks";
import CourseMgt from "./MainComponents/MyAccount/Admin/CourseMgt";
import DataAnalytics from "./MainComponents/MyAccount/DataAnalytics";
import UniBroadCastSection from "./Components/UniBroadCastSection";
import MainNav from "./Components/MainNav";
import HashTags from "./MainComponents/HashTags";
import TermsAndConditions from "./Components/Widgets/TermsAndConditions";
import InfoLayout from "./MainComponents/Masters/InfoLayout";
import CookiePolicy from "./Components/Widgets/CookiePolicy";
import CookiePrompt from "./Components/Widgets/CookiePrompt";
import UsersMigration from "./MainComponents/MyAccount/Admin/UsersMigration";
import CollabLayout from "./MainComponents/Masters/CollabLayout";
import useCommonUtil from "./Hooks/useCommonUtil";
import useChatManager from "./Globals/RealtimeComm/useChatManager";
import useAccountManager from "./Globals/useAccountManager";

const App = () => {
  //#region Memoized states
  const u = useCommonUtil();
  const cm = useChatManager();
  const am = useAccountManager();
  const track_every = (process.env.REACT_APP_TRACK_SEEN || 1) * 60000; //1 minute minimum
  const location = useLocation();

  // track last seen
  useEffect(() => {
    let iloop;

    am.ValidateAccount((me) => {
      if (!me) return;
      console.log(u.Data.GetLocalStorageSize());
      cm.GetIfHasUnreadMessages();
    });

    if (!process.env.REACT_APP_ENABLE_TRACK_SEEN) return;

    const trackLastSeen = () => {
      setTimeout(() => {
        let plate = document.querySelector("#profileHeader .name-plate");
        if (!plate) {
          console.log("name-plate element not found.");
          return;
        }

        am.TrackLastSeen((dt) => {
          console.log(track_every, dt);
          plate.setAttribute(
            "title",
            dt ? `Last Seen: ${dt}` : `Has not logged-in`
          );
        });
      }, 1000);
    };

    trackLastSeen(); //hit on login or page view

    // look every 10 mins of the online status
    iloop = setInterval(() => {
      trackLastSeen();
    }, track_every);
  }, [location.pathname]);

  const [HubConnection, HubDispatch] = useReducer(signalReducer, hubConnection);
  const [triggerNavToUser, setTriggerNavToUser] = useState();
  const [triggerEducJob, setTriggerEducJob] = useState("default");
  const [avatarState, avatarDispatch] = useReducer(
    AvatarReducer,
    InitialAvatarState
  );

  const socketReducerProvider = useMemo(
    () => ({ HubConnection: HubConnection, HubDispatch: HubDispatch }),
    [HubConnection, HubDispatch]
  );

  const avatarProvider = useMemo(
    () => ({ AvatarState: avatarState, AvatarDispatch: avatarDispatch }),
    [avatarState, avatarDispatch]
  );

  const homeTriggeredProvider = useMemo(
    () => ({
      TriggerNavToUser: triggerNavToUser,
      SetTriggerNavToUser: setTriggerNavToUser,
    }),
    [triggerNavToUser, setTriggerNavToUser]
  );

  const [selfHashTrigger, setSelfHashTrigger] = useState(); //initial keystate
  const selfHashTiggerProvider = useMemo(
    () => ({
      selfHashTrigger: selfHashTrigger,
      setSelfHashTrigger: setSelfHashTrigger,
    }),
    [selfHashTrigger, setSelfHashTrigger]
  );

  const EducJobProvider = useMemo(
    () => ({
      TriggerEducJob: triggerEducJob,
      SetTriggerEducJob: setTriggerEducJob,
    }),
    [triggerEducJob, setTriggerEducJob]
  );
  //#endregion

  return (
    <>
      <CookiePrompt />
      <SocketContext.Provider value={socketReducerProvider}>
        <UserNotifications />

        <AvatarContext.Provider value={avatarProvider}>
          <TriggerNavToUserContext.Provider value={homeTriggeredProvider}>
            <IconContext.Provider
              value={{ style: { verticalAlign: "middle" } }}
            >
              <MainNav />
              <Switch>
                <Route exact path={["/hashtags/", "/hashtags/:keyword"]}>
                  <AppLayout>
                    <SelfHashTriggerContext.Provider
                      value={selfHashTiggerProvider}
                    >
                      <Route
                        exact
                        path="/hashtags/"
                        component={HashTags}
                      ></Route>
                      <Route
                        exact
                        path="/hashtags/:keyword"
                        component={HashTags}
                        key={selfHashTrigger}
                      ></Route>
                    </SelfHashTriggerContext.Provider>
                  </AppLayout>
                </Route>
                <Route path={["/myaccount", "/admin"]}>
                  <MyAccountLayout>
                    <Switch>
                      {/* BEGIN: *************  For Gab, Tupe, Mhidz ============================= */}
                      <Route
                        exact
                        path="/myaccount/basicinfo"
                        component={BasicInfo}
                      ></Route>
                      <Route
                        exact
                        path="/myaccount"
                        component={AccountHome}
                      ></Route>
                      <Route
                        exact
                        path="/myaccount/mycasereports"
                        component={MyCaseReports}
                      ></Route>
                      <Route
                        exact
                        path="/myaccount/addresslocation"
                        component={AddressLocation}
                      ></Route>
                      <Route
                        exact
                        path="/myaccount/security"
                        component={SecuritySettings}
                      ></Route>
                      <Route
                        exact
                        path="/myaccount/myeduc"
                        component={MyEducations}
                      ></Route>
                      <Route
                        exact
                        path="/admin/course"
                        component={CourseMgt}
                      ></Route>
                      <Route exact path="/admin/job" component={JobMgt}></Route>
                      <Route
                        exact
                        path="/admin/dataanalytics"
                        component={DataAnalytics}
                      ></Route>
                      <Route
                        exact
                        path="/admin/usersmigration"
                        component={UsersMigration}
                      ></Route>
                      <Route
                        exact
                        path="/admin/adminothers"
                        component={OtherAdminTasks}
                      ></Route>

                      {/* END: *************  For Gab, Tupe, Mhidz ============================= */}
                    </Switch>
                  </MyAccountLayout>
                </Route>
                <Route exact path={["/login", "/register"]}>
                  <LoginLayout>
                    <Switch>
                      <Route exact path="/login" component={Login}></Route>
                      <Route
                        exact
                        path="/register"
                        component={Register}
                      ></Route>
                    </Switch>
                  </LoginLayout>
                </Route>
                <Route
                  path={[
                    "/pages/terms",
                    "/pages/conditions",
                    "/pages/cookiepolicy",
                    "/pages/cookie",
                  ]}
                >
                  <InfoLayout>
                    <Switch>
                      <Route
                        exact
                        path="/pages/terms"
                        component={TermsAndConditions}
                      ></Route>
                      <Route
                        exact
                        path="/pages/conditions"
                        component={TermsAndConditions}
                      ></Route>
                      <Route
                        exact
                        path="/pages/cookiepolicy"
                        component={CookiePolicy}
                      ></Route>
                      <Route
                        exact
                        path="/pages/cookie"
                        component={CookiePolicy}
                      ></Route>
                    </Switch>
                  </InfoLayout>
                </Route>
                <Route exact path="/:username/messages">
                  <CollabLayout>
                    <Route
                      exact
                      path="/:username/messages"
                      component={Messages}
                    />
                  </CollabLayout>
                </Route>
                <Route
                  exact
                  path={[
                    "/",
                    "/:id",
                    //"/:id/messages",
                    "/:id/connections",
                    "/:id/about",
                    "/:id/feeds",
                    "/:id/media",
                  ]}
                >
                  <AppLayout>
                    <Switch>
                      <Route
                        exact
                        path="/:id/connections"
                        component={Connections}
                      />
                      {/* <Route exact path="/:id/messages" component={Messages} /> */}
                      <Route path="/:id" key={triggerNavToUser}>
                        <HeroBanner />
                        <div className="body-container">
                          <div className="flex">
                            <div>
                              <div className="pad-md">
                                <EducJobTriggerContext.Provider
                                  value={EducJobProvider}
                                >
                                  <ProfileHeader />
                                </EducJobTriggerContext.Provider>
                              </div>
                              <div className="pad-md">
                                <UniBroadCastSection />
                              </div>
                            </div>
                            <div className="grow">
                              <div className="pad-sm">
                                <ContentBlock key={triggerNavToUser}>
                                  <Switch>
                                    <Route
                                      exact
                                      path="/:id"
                                      component={UserWall}
                                    ></Route>
                                    <Route
                                      exact
                                      path="/:id/feeds"
                                      component={Feeds}
                                    ></Route>
                                    <Route
                                      exact
                                      path="/:id/media"
                                      component={Media}
                                    ></Route>
                                    <EducJobTriggerContext.Provider
                                      value={EducJobProvider}
                                    >
                                      <Route
                                        exact
                                        path="/:id/about"
                                        component={AboutMe}
                                      ></Route>
                                    </EducJobTriggerContext.Provider>
                                  </Switch>
                                </ContentBlock>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Route>
                    </Switch>
                  </AppLayout>
                </Route>
                {/* <Route exact path={["/admin"]}>
                  <AdminLayout></AdminLayout>
                </Route> */}
                <Route component={NotFound} />
              </Switch>
              {/* <FooterBar /> */}
            </IconContext.Provider>
          </TriggerNavToUserContext.Provider>
        </AvatarContext.Provider>
      </SocketContext.Provider>
    </>
  );
};

export default App;
