import React, { useContext, useEffect, useRef } from "react";
import "./UserWall.css";
import useCommonUtil from "../../Hooks/useCommonUtil";
import useGlobalConstants from "../../Globals/useGlobalConstants";
import { useState } from "react";
import InlineMessageBar from "../../Components/Widgets/InlineMessageBar";
import useAccountManager from "../../Globals/useAccountManager";
import PagingControl from "../../Components/Widgets/PagingControl";
import useDataModels from "../../Globals/useDataModels";
import CommentBox from "../../Components/CommentBox";
import useAsyncState from "../../Hooks/useCustomExtensions";
import useFetchErrorHandler from "../../Globals/useFetchErrorHandler";
import useReactIcons from "../../Hooks/useReactIcons";
import DialogBox from "../../Components/Widgets/DialogBox";
import WallMediaViewer from "../../Components/WallMediaViewer";
import { Tooltip } from "react-tippy";
import { SocketContext } from "../../Globals/RealtimeComm/WebSocketReducer";
import ReactionPanel from "../../Components/Widgets/ReactionPanel";
import ShowMoreText from "../../Components/Widgets/ShowMoreText";
import FeelingPanel from "../../Components/Widgets/FeelingPanel";
import CheckBan from "../CheckBan";

const UserWall = () => {
  //console.log("Trace: Render Wall");
  const { HubDispatch } = useContext(SocketContext);

  const u = useCommonUtil();
  const g = useGlobalConstants();
  const am = useAccountManager();
  const dm = useDataModels();

  const fe = useFetchErrorHandler();
  const ico = useReactIcons();

  const [myAccount] = useState(am.GetMyAccount());

  const methods = g.Hub.ReducerMethods;

  const [wallStatus, setWallStatus] = useState({
    message: "LOADING...",
    mode: "p",
  });

  const cls = {
    DISPLAY_NONE: "dn",
    NO_TOUCH: "no-touch",
  };

  //const [story, setStory] = useState();

  const [dialog, setDialog] = useState();
  const [T, setTargetUser] = useState();
  const _aborter = new AbortController();

  const [postPayload, SetPostPayload] = useState({
    UserName: "",
    Banned: 0,
    BannedByAppUserId: "",
    Likes: 0,
    Hearts: 0,
    PostBody: "",
    PostComments: [],
    PostDate: null,
    PostId: 1,
    PostModified: null,
    PostTitle: "",
    Reactions: [],
    Reads: 0,
    UniBroadcast: 0,
  });
  const [postList, setPostList] = useAsyncState();

  let pagerModel = useRef(JSON.parse(JSON.stringify(dm.PagingModel)));

  //#region :: Effects and methods

  useEffect(() => {
    am.ValidateAccount(() => {
      am.Identity.GetTargetUser((usr) => {
        setTargetUser(usr);
      });
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      _aborter.abort();
      setPostList(null);
    };

    // eslint-disable-next-line
  }, []);
  //#endregion
  //#region  POST ENTRY

  const _refs = {
    titleRef: useRef(),
    storyRef: useRef(),
    postWindowRef: useRef(),
  };

  const [chev, setChev] = useState(ico.ChevronDown);
  const [cw, setCw] = useState(false);
  const [newPost, setNewPost] = useState(false);
  const [imFeeling, setImFeeling] = useState({
    ReactionTypeId: 0,
    PostFeel: 0,
  });
  const refPostCnt = useRef();

  const CountChars = (e) => {
    let cnt = e.currentTarget.value.length;
    refPostCnt.current.innerText = `${cnt} / ${g.MaxChars.Post}`;
    if (cnt > g.MaxChars.Post) {
      e.preventDefault();
    }
  };

  const OnChangePostFields = (e, f) => {
    let v = e.currentTarget.value;
    switch (f) {
      case "PostTitle":
        SetPostPayload({ ...postPayload, PostTitle: v });
        break;
      case "PostBody":
        SetPostPayload({ ...postPayload, PostBody: v });
        break;
      case "UniBroadcast":
        SetPostPayload({
          ...postPayload,
          UniBroadcast: e.target.checked ? 1 : 0,
        });
        break;
      default:
        break;
    }
  };

  const OnClearForm = () => {
    // _refs.titleRef.current.value = "";
    // _refs.storyRef.current.value = "";

    SetPostPayload({
      ...postPayload,
      PostTitle: "",
      PostBody: "",
      UniBroadcast: 0,
    });
    setImgAtts(null);
    setStateVideo(null);
  };

  const OnClickNewPost = () => {
    setCw(!cw);
    if (_refs.postWindowRef.current) {
      _refs.postWindowRef.current.classList.toggle("show");
    }
  };

  //[imFeeling]
  // useEffect(() => {
  //   console.log(imFeeling);
  // }, [imFeeling]);

  //[cw]
  useEffect(() => {
    if (cw) {
      setChev(ico.ChevronUp);
    } else {
      setChev(ico.ChevronDown);
    }

    setImFeeling({ ReactionTypeId: 0, PostFeel: 0 });
    // eslint-disable-next-line
  }, [cw]);

  useEffect(() => {
    if (T) {
      pagerModel.current = JSON.parse(JSON.stringify(dm.PagingModel));
      GetUserStories(_aborter);
    }
    // eslint-disable-next-line
  }, [T]);

  const LoadNextRecords = (pageRef) => {
    if (pageRef.SL) {
      console.log(">>> Loading next set of records...");
      GetUserStories(_aborter, pageRef);
    }
  };

  const GetUserStories = (ab, pm) => {
    if (pm) {
      console.log(pagerModel.current);

      if (
        pagerModel.current.CP === pagerModel.current.TP ||
        pagerModel.current.TR === 0
      ) {
        pm.MR = false;
        pm.SL = false;
        pm.setMarker(0);
        console.log("*** No more post entries.");
        return;
      }
    }

    pagerModel.current.UserName = T.UserName;

    fetch(`${g.Api.ApiRoute}/postapi/GetUserStories`, {
      signal: ab.signal,
      method: g.Fetch.POST,
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(pagerModel.current),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          fe.CatchResponse({ r: r, mode: "s" }, (m) => {
            setWallStatus({
              mode: "w",
              message: m,
            });
          });
          return null;
        }
      })

      .then(async (r) => {
        if (!r) {
          return;
        }

        if (r.Succeeded) {
          let p = r.ModelData;

          pagerModel.current = p.PageModel;
          if (postList) {
            await setPostList([...postList, ...p.List]);
          } else {
            await setPostList([...p.List]);
          }

          if (pm) {
            if (p.PageModel.TP === p.PageModel.CP) {
              pm.MR = false;
            }
            pm.SL = false;
            pm.setMarker(0);
          }
        } else {
          setDialog({
            mode: "e",
            title: "SERVER ERROR",
            message:
              "The Web Api Server has encountered a problem. Please try again later.",
          });
        }
        setWallStatus({ ...wallStatus, tl: g.Timing.Message });
      })
      .catch((er) => {
        console.log(er);
        if (!ab.signal.aborted) {
          fe.CatchError(er, (m) => {
            setWallStatus({ message: m, mode: "e" });
          });
        }
        //setLoader({ message: "Error loading stories.", mode: "e" });
      });
  };

  const [statePost, setStatePost] = useState({
    PostId: 0,
    PostTitle: "",
    PostBody: "",
    Edited: 0,
  });

  const GetEntryElements = (postId) => {
    let postBlock = document.querySelector(`#liPost_${postId}`);
    if (postBlock) {
      let elems = {
        title: postBlock.querySelector(`.postTitle_${postId}`),
        roTitle: postBlock.querySelector(`.ro_postTitle_${postId}`),
        post: postBlock.querySelector(`.postBody_${postId}`),
        postRead: postBlock.querySelector(`.postBody_read${postId}`),
        crudEditDel: postBlock.querySelector(`.crudEditDel_${postId}`),
        crudSaveUndo: postBlock.querySelector(`.crudSaveUndo_${postId}`),
        prog: postBlock.querySelector(`.prog_${postId}`),
      };
      if (
        elems.title &&
        elems.post &&
        elems.crudEditDel &&
        elems.crudSaveUndo &&
        elems.prog
      ) {
        return elems;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const EditMode = (story, b) => {
    let form = GetEntryElements(story.PostId);

    if (form) {
      form.title.setAttribute("contentEditable", b);
      form.post.setAttribute("contentEditable", b);

      if (b) {
        form.title.classList.add("edit-post");
        form.title.classList.remove("dn");
        form.roTitle.classList.add("dn");
        form.post.classList.add("edit-post");
        form.post.classList.remove("dn");
        form.postRead.classList.add("dn");
        form.crudSaveUndo.classList.remove("dn");
        form.crudEditDel.classList.add("dn");
      } else {
        form.title.classList.remove("edit-post");
        form.title.classList.add("dn");
        form.roTitle.classList.remove("dn");
        form.post.classList.remove("edit-post");
        form.crudSaveUndo.classList.add("dn");
        form.crudEditDel.classList.remove("dn");

        form.post.classList.add("dn");
        form.postRead.classList.remove("dn");
      }

      form.title.focus();
    } else {
      setDialog({
        mode: "e",
        message: "Form elements have been maliciously modified!",
      });
    }
  };

  const ReplotStory = (post, fn) => {
    var edited = post;
    edited.Edited = 1;

    let idx = postList.findIndex((f) => f.PostId === edited.PostId);
    let newList = postList.filter((f) => f.PostId !== edited.PostId);
    newList.splice(idx, 0, edited);
    setPostList(newList);
    if (typeof fn === "function") {
      fn.call(this, 1);
      if (post.UniBroadcast) {
        HubDispatch({
          method: methods.INVOKE,
          payload: {
            ServerMethod: "ServerUniBroadcast",
            Args: [g.NotificationType.Announcement, edited],
          },
        });
      }
    }
  };

  const OnPostCommand = (story, cmd) => {
    switch (cmd) {
      case "u":
        {
          EditMode(story, false);
          let form = GetEntryElements(story.PostId);
          if (form) {
            form.title.innerHTML = statePost.PostTitle;
            form.post.innerHTML = statePost.PostBody;
          }
        }
        break;
      case "e":
        {
          EditMode(story, true);
          let form = GetEntryElements(story.PostId);
          if (form) {
            let payload = {
              PostId: story.PostId,
              PostTitle: form.title.innerText,
              PostBody: form.post.innerText,
            };
            setStatePost(payload);
          }
        }
        break;
      case "s":
        {
          let form = GetEntryElements(story.PostId);

          const exitProg = (sw) => {
            if (sw) {
              form.crudSaveUndo.classList.add(cls.DISPLAY_NONE);
              form.prog.classList.remove(cls.DISPLAY_NONE);
            } else {
              form.crudSaveUndo.classList.remove(cls.DISPLAY_NONE);
              form.prog.classList.add(cls.DISPLAY_NONE);
            }
          };

          //exitProg(false);

          if (form) {
            let payload = {
              PostId: story.PostId,
              PostTitle: form.title.innerText,
              PostBody: form.post.innerText,
              Edited: 1,
            };

            if (u.Data.ValidateLongEntry(payload.PostBody)) {
              setDialog({
                mode: "e",
                title: "Span detected",
                message: "Ambigous text entry found.",
              });
              return;
            }

            if (u.Data.ValidateLongEntry(payload.PostTitle)) {
              setDialog({
                mode: "e",
                title: "Span detected",
                message: "Ambigous title entry found.",
              });
              return;
            }

            payload = {
              PostId: story.PostId,
              PostTitle: form.title.innerText,
              PostBody: form.post.innerText,
              Edited: 1,
            };

            //console.log(u.Data.GetImageTag(payload.PostBody));

            if (!u.Validator.CheckMinMax(payload.PostTitle, 1, 125)) {
              setDialog({
                mode: "e",
                message:
                  "Story title must be one or more characters and not exceeding 125.",
              });
              exitProg();
              return;
            }

            if (!u.Validator.CheckMinMax(payload.PostBody, 2, 800)) {
              setDialog({
                mode: "e",
                message:
                  "Story body must be two or more characters and not exceeding 800.",
              });
              exitProg();
              return;
            }

            if (
              statePost.PostTitle.toLowerCase().trim() ===
                payload.PostTitle.toLowerCase().trim() &&
              statePost.PostBody.toLowerCase().trim() ===
                payload.PostBody.toLowerCase().trim()
            ) {
              EditMode(story, false);
              OnPostCommand(story, "u");
              return;
            }

            exitProg(true);

            fetch(`${g.Api.ApiRoute}/PostApi/EditStoryAsync`, {
              method: "POST",
              headers: am.Identity.GetAuthHeader(),
              body: JSON.stringify(payload),
            })
              .then((r) => {
                if (r.ok) {
                  return r.json();
                } else {
                  fe.CatchResponse(r, (m) => {
                    setDialog({ mode: "e", message: m });
                  });
                  exitProg();
                  return null;
                }
              })
              .then((j) => {
                if (!j) {
                  setDialog({
                    mode: "e",
                    message: "Ooops, something went wrong.",
                  });
                } else {
                  if (j.Succeeded) {
                    story = j.ModelData;
                    ReplotStory(story, () => {
                      setStatePost(payload);
                      exitProg(false);
                      EditMode(story, false);
                    });
                  } else {
                    exitProg();
                    setDialog({
                      mode: "e",
                      message: "Ooops. Something went wrong. try again later.",
                    });
                  }
                }
              })
              .catch((err) => {
                exitProg();
                fe.CatchError(err, () => {
                  setDialog({
                    mode: "e",
                    message: "Ooops. Something went wrong. try again later.",
                  });
                });
              });
          } else {
            exitProg();
            setDialog({
              mode: "e",
              message: "Post has been maliciously modified!",
            });
          }
        }
        break;
      case "r":
        setDialog({
          mode: "w",
          title: "Confirm Delete",
          message: "Are you sure you want to delete this post?",
          buttons: [
            {
              id: "d2",
              caption: "NO",
            },
            {
              id: "d1",
              caption: "Yes",
              OnClick: () => {
                let _payLoad = {
                  UserName: myAccount.UserName,
                  RecordId: story.PostId,
                };
                fetch(`${g.Api.ApiRoute}/postapi/RemoveStory`, {
                  method: g.Fetch.POST,
                  headers: am.Identity.GetAuthHeader(),
                  body: JSON.stringify(_payLoad),
                })
                  .then((r) => {
                    if (r.ok) {
                      return r.json();
                    } else {
                      fe.CatchResponse(r, (m) => {
                        setDialog({ mode: "e", message: m });
                      });
                    }
                  })
                  .then((r) => {
                    if (r) {
                      if (r.Succeeded) {
                        setPostList(
                          postList.filter((p) => p.PostId !== story.PostId)
                        );
                        HubDispatch({
                          method: methods.INVOKE,
                          payload: {
                            ServerMethod: "ServerRemoveAnnouncement",
                            Args: ["Remove Announcement", story],
                          },
                        });
                      }
                    }
                  })
                  .catch((err) => {
                    fe.CatchError(err, (m) => {
                      setDialog({ mode: "e", message: m });
                    });
                  });
              },
            },
          ],
        });

        break;
      default:
        break;
    }
  };

  //#endregion

  //#region  :: Images and videos
  const [imgAtts, setImgAtts] = useState();
  //[postPayload]
  useEffect(() => {
    if (postPayload.PostTitle && postPayload.PostBody) {
      if (
        postPayload.PostTitle.length >= 2 &&
        postPayload.PostBody.length >= 2
      ) {
        setNewPost(true);
      } else {
        setNewPost(false);
      }
    } else {
      setNewPost(false);
    }
  }, [postPayload]);

  const refCrud = {
    refImage: useRef(),
    refVideo: useRef(),
    refPostProgress: useRef(),
    refImgVid: useRef(),
    refClearPost: useRef(),
  };
  const DisableCrudBtns = (b) => {
    if (b) {
      refCrud.refImgVid.current.classList.add(cls.NO_TOUCH);
      refCrud.refClearPost.current.classList.add(cls.NO_TOUCH);
      refCrud.refPostProgress.current.classList.remove(cls.DISPLAY_NONE);
    } else {
      refCrud.refImgVid.current.classList.remove(cls.NO_TOUCH);
      refCrud.refClearPost.current.classList.remove(cls.NO_TOUCH);
      refCrud.refPostProgress.current.classList.add(cls.DISPLAY_NONE);
    }
  };
  const onOpenFileAttachment = (mode) => {
    if (mode === "i") {
      refCrud.refImage.current.click();
    } else if (mode === "v") {
      refCrud.refVideo.current.click();
    }
  };

  const [stateVideo, setStateVideo] = useState();

  const OnFilesSelected = (e, mode) => {
    let files = e.target.files;
    const checkImgCnt = () => {
      setDialog({
        mode: "e",
        message: `Only a maximum of ${g.Image.MAX_WALL_UPLOAD} images is allowed.`,
      });
    };
    if (mode === "i") {
      if (files) {
        if (imgAtts) {
          if (imgAtts.length + files.length > g.Image.MAX_WALL_UPLOAD) {
            checkImgCnt();
            e.target.value = "";
            return;
          }
        } else {
          if (files.length > g.Image.MAX_WALL_UPLOAD) {
            checkImgCnt();
            e.target.value = "";
            return;
          }
        }

        if (imgAtts) {
          setImgAtts([...imgAtts, ...files]);
        } else {
          setImgAtts([...files]);
        }
      }
    } else if (mode === "v") {
      if (e.target.files.length) {
        let video = e.target.files[0];
        if (!video.name.toLowerCase().includes(g.Video.ALLOWED_FORMAT)) {
          setDialog({
            mode: "e",
            message: "Only mp4 video format is supported.",
          });
          e.target.value = "";
          return;
        }

        let size = Math.ceil(video.size / 1024);
        if (size > g.Video.MAX_SIZE) {
          setDialog({
            mode: "e",
            message: `The system only allows ${g.Video.MAX_SIZE.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 0,
              }
            )} MB of video size. You are uploading ${size.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 0,
              }
            )} MB. Please reduce the video size down to a lower resolution. e.g: 420p or 240p.`,
          });
          e.target.value = "";
          return;
        }

        setStateVideo(video);
        e.target.value = "";
      }
    }
  };

  const onRemoveAttachment = (img) => {
    console.log(img);
    let newList = imgAtts.filter((f) => f.name !== img.name);
    setImgAtts(newList);
  };

  const OnPostStory = () => {
    if (newPost) {
      DisableCrudBtns(true);
      let d = u.Date.FormatDate(new Date(), "YYYY-MM-DDTHH:mm:ss");
      let _payLoad = postPayload;

      _payLoad.UserName = myAccount.UserName;
      _payLoad.PostDate = d;
      _payLoad.PostModified = d;
      _payLoad.AuthorFeeling = null;
      if (imFeeling.ReactionTypeId !== 0) {
        _payLoad.AuthorFeeling = {
          ReactionTypeId: imFeeling.ReactionTypeId,
          PostFeel: 1,
        };
      }

      if (u.Data.ValidateLongEntry(_payLoad.PostBody)) {
        setDialog({
          mode: "w",
          title: "Spam Detected",
          message: "Found ambiguous entry from story post.",
        });
        DisableCrudBtns(false);
        return;
      }

      //console.log(_payLoad);

      fetch(`${g.Api.ApiRoute}/postapi/PostStory`, {
        method: g.Fetch.POST,
        headers: am.Identity.GetAuthHeader(),
        body: JSON.stringify(_payLoad),
        signal: _aborter.signal,
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            r.mode = "s";
            fe.CatchResponse(r, () => {
              //setDataHover({ mode: "e", message: m });
              setNewPost(false);
            });
            DisableCrudBtns();
            return null;
          }
        })
        .then((r) => {
          if (!r) {
            DisableCrudBtns();
            return;
          }

          if (r.Succeeded) {
            //upload images next getting the id of the post

            if (!imgAtts && !stateVideo) {
              _payLoad.PostId = r.ModelData.PostId;
              setPostList([_payLoad, ...postList]);
              //clear form
              // _refs.titleRef.current.value = "";
              // _refs.storyRef.current.value = "";
              setImgAtts(null);

              //hide the data post entry
              OnClickNewPost();

              if (postPayload.UniBroadcast) {
                HubDispatch({
                  method: methods.INVOKE,
                  payload: {
                    ServerMethod: "ServerUniBroadcast",
                    Args: [g.NotificationType.Announcement, r.ModelData],
                  },
                  callBack: () => {
                    SetPostPayload({
                      ...postPayload,
                      PostTitle: "",
                      PostBody: "",
                      UniBroadcast: 0,
                    });
                  },
                });
              } else {
                SetPostPayload({
                  ...postPayload,
                  PostTitle: "",
                  PostBody: "",
                  UniBroadcast: 0,
                });
              }

              //clear data post entry

              //if there are few records below pager threshold, set is as last page.
              if (postList.length <= pagerModel.current.PS) {
                pagerModel.current.CP = 1;
                pagerModel.current.TP = 1;
              }
              DisableCrudBtns();
              setNewPost(false);
              OnClearForm();
              return;
            } else {
              // :: ========= generate main photos

              let remaining =
                (imgAtts ? imgAtts.length : 0) + (stateVideo ? 1 : 0); //include thumbs
              let tmr;

              //prepare payload
              let mediaPayload = {
                ApiEndPoint: `${g.Api.ApiRoute}/PostApi/UploadPostAttachmentsAsync`,
                AuthToken: am.GetMyAuthToken(),
                AlbumId: `post_${r.ModelData.PostId}`, //this is the album name based on original specs
                MediaModels: [],
                OnProgress: () => {},
                OnCompleted: () => {},
                Succeeded: (xhrResult) => {
                  let jsonResult = JSON.parse(xhrResult);
                  _payLoad = jsonResult.ModelData;

                  setPostList([_payLoad, ...postList]);
                  //clear form
                  // _refs.titleRef.current.value = "";
                  // _refs.storyRef.current.value = "";
                  setImgAtts(null);

                  //hide the data post entry

                  OnClickNewPost();
                  //clear data post entry
                  SetPostPayload({
                    ...postPayload,
                    PostTitle: "",
                    PostBody: "",
                    UniBroadcast: 0,
                  });

                  if (postPayload.UniBroadcast) {
                    HubDispatch({
                      method: methods.INVOKE,
                      payload: {
                        ServerMethod: "ServerUniBroadcast",
                        Args: [g.NotificationType.Announcement, _payLoad],
                      },
                      callBack: () => {
                        SetPostPayload({
                          ...postPayload,
                          PostTitle: "",
                          PostBody: "",
                          UniBroadcast: 0,
                        });
                      },
                    });
                  }

                  //if there are few records below pager threshold, set is as last page.
                  if (postList.length <= pagerModel.current.PS) {
                    pagerModel.current.CP = 1;
                    pagerModel.current.TP = 1;
                  }
                  DisableCrudBtns();
                  setNewPost(false);
                  OnClearForm();
                },
                OnError: (err) => {
                  setDialog({ mode: "e", title: "ERROR", message: err });
                  DisableCrudBtns();
                },
              };

              if (stateVideo) {
                mediaPayload.MediaModels.push({
                  FileName: `v-${u.Security.GenerateRandomString(20)}`,
                  FileStream: stateVideo,
                  OriginalSize: `na`,
                  AdjustedSize: "na",
                  MediaType: "v",
                });
                remaining--;
              }

              //photoPayload.PhotoModels
              if (imgAtts) {
                imgAtts.forEach((f) => {
                  let pad = u.Security.GenerateRandomString(4);
                  //created reduced main photos
                  u.FileManager.ResizeImage(
                    f,
                    g.Image.REDUCE_DIM,
                    g.Image.REDUCE_TO,
                    (trans) => {
                      u.FileManager.HashToHexDigit(trans.dataUrl, (fHash) => {
                        mediaPayload.MediaModels.push({
                          FileName: `m-${pad}${fHash}`,
                          FileStream: u.FileManager.DataURItoBlob(
                            trans.dataUrl
                          ),
                          OriginalSize: `${trans.origSize.w}x${trans.origSize.h}`,
                          AdjustedSize: g.Image.REDUCE_DIM,
                          MediaType: "i",
                        });

                        //create thumbs
                        u.FileManager.ResizeImage(
                          f,
                          g.Image.REDUCE_THUMB_DIM,
                          g.Image.REDUCE_TO,
                          (trans) => {
                            mediaPayload.MediaModels.push({
                              FileName: `t-${pad}${fHash}`,
                              FileStream: u.FileManager.DataURItoBlob(
                                trans.dataUrl
                              ),
                              OriginalSize: `${trans.origSize.w}x${trans.origSize.h}`,
                              AdjustedSize: g.Image.REDUCE_THUMB_DIM,
                              MediaType: "i",
                            });
                            remaining--;
                          }
                        );
                      });
                    }
                  );
                });
              }
              //set this in interval as above operations are asynchronous
              tmr = setInterval(() => {
                if (remaining <= 0) {
                  console.log(remaining);
                  u.FileManager.UploadMedia(mediaPayload);
                  clearInterval(tmr);
                }
              }, 500);
            }
          } else {
            DisableCrudBtns();
            setNewPost(true);
            setDialog({ mode: "e", message: r.Message });
          }
        })
        .catch((err) => {
          setNewPost(true);
          DisableCrudBtns();
          console.log(err);
        });
    }
  };

  const [wallAlbum, setWallAlbum] = useState();
  const LaunchWallViewer = (userName, targetMedia, album) => {
    setWallAlbum({
      UserName: userName,
      TargetMedia: targetMedia,
      Album: album,
    });
  };
  //#endregion

  //#region :: Comments
  const AddOrDeductCnt = (story, mode) => {
    //console.log(story, mode);
    let cnt = document.querySelector(`#numComments_${story.PostId}`);
    if (cnt) {
      switch (mode) {
        case "r": //remove comment
          cnt.innerText = parseInt(cnt.innerText) - 1;
          break;
        case "a": //remove comment
          cnt.innerText = parseInt(cnt.innerText) + 1;
          break;
        default:
          break;
      }
    }
  };
  //#endregion

  //validations:
  // const CheckTitleEntry = (e) => {
  //   if (e.target.innerText.length > 100) {
  //     e.preventDefault();
  //     e.target.innerText = e.target.innerText.substring(0, 100);
  //     return;
  //   }
  // };

  const ModeratePost = (post) => {
    fetch(`${g.Api.ApiRoute}/adminapi/ModeratePostAsync`, {
      method: "POST",
      headers: am.Identity.GetAuthHeader(),
      body: JSON.stringify(post),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((j) => {
        if (j) {
          if (j.Succeeded) {
            //console.log(j);
            setDialog({
              mode: "s",
              title: "Banned",
              message:
                post.Banned == 1
                  ? `Story post has been marked for suspension/ban. This may take a while. It will be removed gradually by the system.`
                  : `Story has been moderated. This may take a while to update on all users' FEEDS.`,
            });
          } else {
            setDialog({
              mode: "e",
              message: "Ooops. Technical error occured.",
            });
          }
        } else {
          setDialog({ mode: "e", message: "Ooops. Technical error occured." });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const OnModeratePost = (post) => {
    setDialog({
      mode: "text",
      title: "Moderate Post",
      message:
        "Moderate or delete post. Your name will appear in the post should you wish to edit/moderate.",
      defaultVal: post.PostBody,
      buttons: [
        {
          id: "delete",
          caption: "Delete",
          OnClick: () => {
            let _payload = post;
            _payload.Banned = 1;
            ModeratePost(_payload);
          },
        },
        {
          id: "moderate",
          caption: "Moderate",
          OnClick: (text) => {
            let _payload = post;
            _payload.Banned = 2;
            _payload.PostBody = `[MODERATED BY : ${myAccount.UserName}] ${text}`;
            ModeratePost(_payload);
          },
        },
        { id: "cancel", caption: "Cancel" },
      ],
    });
    console.log(post);
  };

  return (
    <>
      <div className="my-wall">
        <div>
          <InlineMessageBar props={{ options: wallStatus }} />
        </div>
        <div>
          {am.Identity.IsMe() ? (
            <div className="post-block">
              <div className="pad-md">
                <div className="display-block a-right">
                  <div className="hand col-expand-btn" onClick={OnClickNewPost}>
                    <span className="r-pad-sm">NEW POST</span>
                    <span className="font-xl">{chev}</span>
                  </div>
                </div>

                <div
                  id="postEntry"
                  className="pad-sm"
                  ref={_refs.postWindowRef}
                >
                  <FeelingPanel
                    state={{ imFeeling: imFeeling, setImFeeling: setImFeeling }}
                  />
                  <div>
                    <input
                      ref={_refs.titleRef}
                      type="text"
                      maxLength="150"
                      placeholder="title/topic or #hashtags"
                      value={postPayload.PostTitle}
                      onChange={(e) => OnChangePostFields(e, "PostTitle")}
                    />
                  </div>
                  <div>
                    <div className="font-sm a-right lr-pad-sm">
                      <span ref={refPostCnt}>0/{g.MaxChars.Post}</span>
                    </div>
                    <textarea
                      ref={_refs.storyRef}
                      name="txtPost"
                      id="txtPost"
                      rows="4"
                      maxLength="800"
                      placeholder="Story here mixed with your favorite #hashtags"
                      onKeyUp={CountChars}
                      value={postPayload.PostBody}
                      onChange={(e) => OnChangePostFields(e, "PostBody")}
                    ></textarea>
                  </div>
                  {imgAtts ? (
                    <div className="inline-block post-thumbs">
                      {imgAtts.map((i) => (
                        <img
                          title="Click/tap to remove"
                          key={i.name}
                          src={URL.createObjectURL(i)}
                          alt="img"
                          onClick={() => onRemoveAttachment(i)}
                          onLoad={(e) => {
                            URL.revokeObjectURL(e.target.src);
                          }}
                        ></img>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                  {stateVideo ? (
                    <div className="inline-block b-pad-md">
                      <video width="320" height="auto" controls>
                        <source
                          src={URL.createObjectURL(stateVideo)}
                          onLoad={(e) => URL.revokeObjectURL(e.target.src)}
                        />
                      </video>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="flex v-center">
                    {myAccount.Roles.includes(g.Roles.ADMIN) ||
                    myAccount.Roles.includes(g.Roles.DEV) ? (
                      <div className="tb-pad-md">
                        <Tooltip
                          title="Broadcast to all HIVE members"
                          arrow="yes"
                          arrowSize="big"
                        >
                          <span>
                            <span className="font-xl r-pad-sm">
                              {ico.RadioTower}
                            </span>
                            <input
                              type="checkbox"
                              id="chkAdminBroadcast"
                              checked={postPayload.UniBroadcast}
                              onChange={(e) =>
                                OnChangePostFields(e, "UniBroadcast")
                              }
                            />
                            <label htmlFor="chkAdminBroadcast">Broadcast</label>
                          </span>
                        </Tooltip>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="last r-pad-sm">
                      <div
                        className="inline-block post-icons"
                        ref={refCrud.refImgVid}
                      >
                        <Tooltip
                          title="Include photos. Maximum of three."
                          arrow="true"
                          arrowSize="big"
                        >
                          <div
                            className="font-xl inline-block r-pad-sm ico"
                            onClick={() => onOpenFileAttachment("i")}
                          >
                            {ico.Images}
                          </div>
                        </Tooltip>
                        <Tooltip
                          title="Include a video."
                          arrow="true"
                          arrowSize="big"
                        >
                          <div
                            className="font-xl inline-block lr-pad-lg ico"
                            onClick={() => onOpenFileAttachment("v")}
                          >
                            {ico.Video}
                          </div>
                        </Tooltip>
                        <div className="r-pad-lg inline-block"></div>
                      </div>
                      <div
                        className="button-area inline-block"
                        ref={refCrud.refClearPost}
                      >
                        <input
                          type="button"
                          onClick={OnClearForm}
                          value="CLEAR"
                        />
                        <span className={newPost ? "" : "no-touch"}>
                          <input
                            type="button"
                            name=""
                            id=""
                            value="POST"
                            onClick={OnPostStory}
                          />
                        </span>
                      </div>
                      <div
                        className="inline-block l-pad-sm dn"
                        ref={refCrud.refPostProgress}
                      >
                        <span className="font-xl">{ico.CircleSpinner}</span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="dn">
                    <input
                      type="file"
                      id="fileImages"
                      ref={refCrud.refImage}
                      multiple
                      accept=".jpg, .jpeg, .jfif, .png"
                      onChange={(e) => OnFilesSelected(e, "i")}
                    />
                    <input
                      type="file"
                      id="fileVideo"
                      ref={refCrud.refVideo}
                      accept=".mp4"
                      onChange={(e) => OnFilesSelected(e, "v")}
                    />
                  </div>

                  <div className="tb-pad-lg"></div>
                </div>
              </div>{" "}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div>
          {postList ? (
            <div>
              <ul>
                {postList.map((p) => (
                  <li key={`post_${p.PostId}`} id={`liPost_${p.PostId}`}>
                    
                      <div key={`post_${p.PostId}`} className="post-block">
                        <div>
                          <div className="pad-lg">
                            <div className="color-mid-gray flex nowrap v-center">
                              <div>
                                <span className="r-pad-md">{ico.Calendar}</span>
                                <span className="font-xm">
                                  {u.Date.FormatDate(
                                    p.PostDate,
                                    "ddd | MMM-DD-YYYY hh:mm a"
                                  )}
                                  {p.Edited ? <span> | Edited</span> : ""}
                                </span>
                              </div>
                              <div
                                className={`crudEditDel_${p.PostId} last font-lg`}
                              >
                                {am.Identity.IsMe() ? (
                                  <>
                                    <span
                                      className="hand r-pad-sm"
                                      onClick={() => OnPostCommand(p, "e")}
                                    >
                                      {ico.Pencil}
                                    </span>
                                    <span
                                      className="hand l-pad-sm"
                                      title="Delete"
                                      onClick={() => OnPostCommand(p, "r")}
                                    >
                                      {ico.CloseCircle}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              {p.UserName !== myAccount.UserName ? (
                                myAccount.Roles?.includes("MOD") ||
                                myAccount.Roles?.includes("ADMIN") ? (
                                  <div className="tb-pad-sm a-center">
                                    <div className="b-pad-md"></div>
                                    <Tooltip title="Moderate/Delete">
                                      <span
                                        onClick={() => OnModeratePost(p)}
                                        className="font-xl color-orange r-pad-sm hand"
                                        title="Moderate/Remove"
                                      >
                                        {ico.Warning}
                                      </span>
                                    </Tooltip>
                                  </div>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="tb-pad-sm">
                              {p.UniBroadcast ? (
                                <div className="r-pad-sm color-theme inline-block font-xl">
                                  {ico.BroadCast}
                                </div>
                              ) : (
                                ""
                              )}

                              <div
                                className={`postTitle_${p.PostId} font-lg pad-sm break-words dn`}
                              >
                                {p.PostTitle}
                              </div>

                              <div
                                className={`ro_postTitle_${p.PostId} font-lg pad-sm break-words`}
                              >
                                {u.Data.ParseLinks(p.PostTitle)}
                              </div>
                              {p.AuthorFeeling ? (
                                p.AuthorFeeling.PostFeel === 1 ? (
                                  <Tooltip
                                    title={
                                      ico.GetReactionIcon(
                                        p.AuthorFeeling.ReactionTypeId
                                      ).text
                                    }
                                    arrow="true"
                                    arrowSize="big"
                                  >
                                    <div
                                      className={`font-smaller all-caps inline-block ${
                                        ico.GetReactionIcon(
                                          p.AuthorFeeling.ReactionTypeId
                                        ).color
                                      }`}
                                    >
                                      &mdash;{" "}
                                      {am.Identity.IsMe()
                                        ? "I'm Feeling "
                                        : `${p.AuthorFeeling.FullName} is feeling `}
                                      <span className="font-xxl">
                                        {
                                          ico.GetReactionIcon(
                                            p.AuthorFeeling.ReactionTypeId
                                          ).ico
                                        }
                                      </span>
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="b-pad-md">
                              <div
                                className={`postBody_${p.PostId} font-sm pad-md post-body dn`}
                              >
                                {p.PostBody}
                              </div>

                              <ShowMoreText textLength={200}>
                                <div
                                  className={`postBody_read${p.PostId} font-sm pad-md post-body`}
                                  plainText={p.PostBody}
                                ></div>
                              </ShowMoreText>
                              {/* <div className={`postBody_read${p.PostId} font-sm pad-md post-body`}>
                              {u.Data.ParseLinks(p.PostBody)}
                            </div> */}
                              <div>
                                {p.Album?.Media ? (
                                  <div className="post-thumbs pad-md">
                                    {p.Album.Media.map((media) => (
                                      <div
                                        key={media.MediaHashId}
                                        className="inline-block"
                                      >
                                        {media.MediaType === "i" ? (
                                          <img
                                            src={
                                              p.Album.IsWallMedia === 1
                                                ? `${g.Api.CdnRoute}/${T.UserName}/albums/wall/${p.Album.AlbumName}/thumbs/t-${media.MediaHashId}.jpg`
                                                : `${g.Api.CdnRoute}/${T.UserName}/albums/${p.Album.AlbumNameRoute}/thumbs/t-${media.MediaHashId}.jpg`
                                            }
                                            alt=""
                                            onClick={() =>
                                              LaunchWallViewer(
                                                p.UserName,
                                                media.MediaHashId,
                                                p.Album
                                              )
                                            }
                                          ></img>
                                        ) : (
                                          <div className="inline-block">
                                            <video controls>
                                              <source
                                                src={
                                                  p.Album.IsWallMedia === 1
                                                    ? `${g.Api.CdnRoute}/${p.UserName}/albums/wall/post_${p.PostId}/v-${media.MediaHashId}.mp4`
                                                    : `${g.Api.CdnRoute}/${p.UserName}/albums/${p.Album.AlbumNameRoute}/post_${p.PostId}/v-${media.MediaHashId}.mp4`
                                                }
                                              />
                                            </video>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div
                                className={`crudSaveUndo_${p.PostId} a-right dn tb-pad-md`}
                              >
                                <div>
                                  <span
                                    onClick={() => OnPostCommand(p, "s")}
                                    className={`save_${p.PostId} font-xl hand`}
                                  >
                                    {ico.Save}
                                  </span>
                                  <span className="lr-pad-sm"></span>
                                  <span
                                    onClick={() => OnPostCommand(p, "u")}
                                    className={`undo_${p.PostId} font-xl hand`}
                                  >
                                    {ico.Undo}
                                  </span>
                                </div>
                              </div>
                              <div
                                className={`prog_${p.PostId} font-xl a-right tb-pad-md dn`}
                              >
                                <span>{ico.CircleSpinner}</span>
                              </div>
                              <hr />
                            </div>

                            <div className="flex nowrap">
                              <div>
                                <span>
                                  <span className="r-pad-sm font-lg">
                                    {ico.Messages}
                                  </span>
                                </span>
                                <span
                                  id={`numComments_${p.PostId}`}
                                  title="See who loved this post"
                                >
                                  {p.NumComments}
                                </span>
                                <span className="r-pad-lg"></span>
                              </div>
                              <div className="last a-right">
                                <ReactionPanel
                                  story={p}
                                  key={`RP_${p.PostId}`}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="pad-lg">
                            <CommentBox
                              story={p}
                              user={T}
                              limited={1}
                              onCrudCallBack={AddOrDeductCnt}
                            />
                          </div>
                        </div>
                      </div>
                    
                  </li>
                ))}
              </ul>
              <PagingControl
                props={{
                  callBack: (pg) => {
                    LoadNextRecords(pg);
                  },
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <DialogBox Dialog={dialog} key={"UserWall"} />
      <WallMediaViewer WallAlbum={wallAlbum} key={"WallPhotoViewer"} />
      <CheckBan />
    </>
  );
};

export default React.memo(UserWall);
